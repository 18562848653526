import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { Edit } from "@material-ui/icons";
import axios from "axios";
import Cookies from "universal-cookie";
import { dateFormatter } from "../../components/DtFormatter";
const cookies = new Cookies();

export default class EditSpecialPayroll extends Component {
  constructor(props) {
    super();
    this.state = {
      showModal: false,
      disableSubmit: false,
      updated_by: cookies.get("email"),
    };
    this.handleshowModal = this.handleshowModal.bind(this);
  }

  handleshowModal = (e) => {
    this.setState({ showModal: !this.state.showModal });
  };

  handleSubmit = (e) => {
    this.setState({ disableSubmit: true });
    axios
      .post("api/special_edit_pay/" + this.props.schedData.id, this.state)
      .then((res) => {
        this.props.parentCallback(true);
        this.setState({ showModal: false, disableSubmit: false });
      });
  };

  render() {
    return (
      <>
        <button
          className="btn btn-sm btn-outline-success"
          title="Edit Special Payroll Run"
          onClick={this.handleshowModal}
        >
          <Edit /> Edit Special Run
        </button>
        <Modal
          size="md"
          show={this.state.showModal}
          onHide={() => this.setState({ showModal: !this.state.showModal })}
        >
          <Modal.Header closeButton>
            <h4>
              Edit Special Payroll Run:
              <br />
            </h4>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={this.handleSubmit} id="contact-form">
              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  <label>Decription</label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    onChange={(e) => {
                      this.setState({
                        description: e.target.value,
                      });
                    }}
                    defaultValue={this.props.schedData.description}
                  />
                </div>
                <div className="form-group col-md-12 col-sm-12">
                  <label>Pay Period ID</label>
                  <select
                    className="form-control custom-select"
                    required
                    onChange={(e) => {
                      this.setState({ paysched_id: e.target.value });
                    }}
                    defaultValue={this.props.schedData.paysched_id}
                  >
                    <option value="">Select Pay Period ID</option>
                    <option
                      key={this.props.payschedDD.id}
                      value={this.props.payschedDD.id}
                      selected
                    >
                      {this.props.payschedDD.code +
                        " (" +
                        dateFormatter(this.props.payschedDD.start_date) +
                        " - " +
                        dateFormatter(this.props.payschedDD.end_date) +
                        ")"}
                    </option>
                  </select>
                </div>
                <div className="form-group col-md-12 col-sm-12">
                  <label>Payment Date</label>
                  <input
                    class="form-control"
                    type="date"
                    required
                    onChange={(e) =>
                      this.setState({ payment_date: e.target.value })
                    }
                    defaultValue={this.props.schedData.payment_date}
                    min={this.props.payschedDD.start_date}
                    max={this.props.payschedDD.end_date}
                  />
                </div>
              </div>
              <div className="float-right">
                <button
                  type="submit"
                  className="btn btn-primary btn-sm mr-2"
                  disabled={this.state.disableSubmit}
                >
                  Save
                </button>
                <button
                  onClick={this.handleshowModal}
                  className="btn btn-danger btn-sm"
                  type="button"
                >
                  Cancel
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
