import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import { Link } from "react-router-dom";
import { FindInPage, Add } from "@material-ui/icons";
import axios from "axios";
import ReactExport from "react-data-export";
import Cookies from "universal-cookie";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

export default function YearPerEmp(props) {
  const cookies = new Cookies();
  const ability = cookies.get("ability")[2];
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      let apiLink = "api/year_total/" + props.match.params.year;
      const res = await axios.get(apiLink);

      setData(res.data);
      setFilteredData(res.data);
    };
    fetchData();
  }, []);

  const pages = [];
  for (let i = 1; i <= Math.ceil(filteredData.length / recordsPerPage); i++) {
    pages.push(i);
  }

  const handleRecordPerPage = (e) => {
    console.log(e.target.value);
    setRecordsPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const handleSearch = (e) => {
    const filterer = data.filter(function (data) {
      for (var key in data) {
        if (
          !(
            data["last_name"]
              .toLowerCase()
              .includes(e.target.value.toLowerCase()) ||
            data["first_name"]
              .toLowerCase()
              .includes(e.target.value.toLowerCase())
          )
        ) {
          return false;
        }
        return true;
      }
    });
    setFilteredData(filterer);
  };

  const handleBack = () => {
    props.history.goBack();
  };

  const startIndex = currentPage * recordsPerPage - recordsPerPage;
  const endIndex = startIndex + recordsPerPage;
  const currentData = filteredData.slice(startIndex, endIndex);

  return (
    <div className="hk-pg-wrapper">
        <Header />
        <div className="mt-20">
        {ability.permission > 0 ? (
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12">
                <h3 className="pb-10">
                  {data.length > 0
                    ? data[0]["company_name"] +
                      " Year " +
                      data[0]["year"] +
                      " Total"
                    : "Year Total"}
                </h3>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <button
                  className="btn btn-sm btn-danger float-right "
                  onClick={handleBack}
                >
                  Back to Previous Page
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <div className="table-wrap">
                  <div className="row mb-10">
                    <div className="col-md-6">
                      <label>
                        <select
                          className="custom-select custom-select-sm form-control form-control-sm"
                          onChange={handleRecordPerPage}
                        >
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                      </label>{" "}
                      items
                    </div>
                    <div className="col-md-6">
                      <input
                        type="search"
                        className="form-control form-control-sm col-md-6 col-sm-6 float-right"
                        placeholder="Search"
                        onChange={handleSearch}
                      />
                    </div>
                  </div>
                  <div className="table-responsive mb-10">
                    <table className="table table-sm table-bordered table-striped mb-0">
                      <thead className="thead-secondary">
                        <tr>
                          <th>Name</th>
                          <th>Total OT Hours</th>
                          <th>Total Pay(Gross)</th>
                          <th style={{ width: "85px" }}>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredData.length > 0 ? (
                          currentData.map((cutoff) => (
                            <tr key={cutoff.id}>
                              <td>
                                {cutoff.last_name + ", " + cutoff.first_name}
                              </td>
                              <td className="text-right">
                                {(1 * cutoff.total_ot_hours).toFixed(2)}
                              </td>
                              <td className="text-right">
                                {(1 * cutoff.total_pay).toFixed(2)}
                              </td>
                              <td>
                                <div className="btn-group">
                                  <Link
                                    to={{
                                      pathname: `/report/year_total/${cutoff.year}/${cutoff.employee_id}`,
                                      query: `/year_total`,
                                    }}
                                    className="btn btn-sm btn-outline-primary"
                                    title={"View per employee"}
                                  >
                                    <FindInPage />
                                  </Link>
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="5" className="text-center">
                              No Data to show
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="drew-pagination">
                  {filteredData.length > 0
                    ? "Showing " +
                      (startIndex + 1) +
                      " to " +
                      (endIndex > filteredData.length
                        ? filteredData.length
                        : endIndex) +
                      " of " +
                      filteredData.length
                    : ""}
                  <ul class="pagination ml-2">
                    {pages.map((number) => (
                      <li
                        key={number}
                        id={number}
                        className={
                          currentPage == number
                            ? "paginate_button page-item active"
                            : "paginate_button page-item"
                        }
                        onClick={() => setCurrentPage(number)}
                      >
                        <a class="page-link">{number}</a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <h1 className="m-0 text-center">
            The Credentials Supplied Are Not Sufficient To Access This Module
            <br />
            Please Contact Administrator
          </h1>
        )}
      </div>
    </div>
  );
}
