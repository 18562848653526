import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import axios from "axios";
import { Link } from "react-router-dom";
import { FindInPage, FileCopy, CloudUpload, Person } from "@material-ui/icons";
import AddEmployee from "./AddEmployee";
import multiColumnSort from "multi-column-sort";
import Cookies from "universal-cookie";
import UploadEmployee from "./UploadEmployee";
import UploadSchedule from "./UploadSchedule";
import ExportSchedule from "./ExportSchedule";
import ExportEmployee from "./ExportEmployee";
import AddEmployeeInt from "./AddEmployeeInt";

export default function Employee(props) {
  const cookies = new Cookies();
  const permissions = cookies.get("permissions")["master_data"];
  const setting = cookies.get("setting");
  const access = ["No Access", "View Only", "Add", "Add / Edit", "Full Access"];
  const [data, setData] = useState([]);
  const [companyDD, setCompanyDD] = useState([]);
  const [schemaDD, setSchemaDD] = useState([]);
  const [bschemaDD, setBschemaDD] = useState([]);
  const [bankDD, setBankDD] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [filteredData, setFilteredData] = useState([]);
  const [fileLoaded, setFileLoaded] = useState(false);
  const [nameSort, setNameSort] = useState("DESC");
  const [empNoSort, setEmpNoSort] = useState("DESC");
  const [statusSort, setStatusSort] = useState("DESC");
  const [bschemaSort, setBschemaSort] = useState("DESC");
  const [oschemaSort, setOschemaSort] = useState("DESC");
  const [search, setSearch] = useState(
    props.location.search
      ? decodeURI(props.location.search.split("=").pop())
      : ""
  );
  const empStatus = ["INACTIVE", "ACTIVE"];

  useEffect(() => {
    const fetchData = async () => {
      let company_id = 0;
      if (cookies.get("transCompany")) company_id = cookies.get("transCompany");
      if (company_id == 0) {
        cookies.set("load", "1");
        alert("Please Select Module Company");
        window.location.replace("/#/app");
      } else {
        let apiLink = "api/employees/" + company_id
        const res = await axios.get(apiLink);
        setData(res.data.employees);
        setFilteredData(res.data.employees);
        setCompanyDD(res.data.companyDD);
        setSchemaDD(res.data.schemaDD);
        setBschemaDD(res.data.bSchemaDD);
        setBankDD(res.data.bankDD);
        const filterer = searcher(res.data.employees, search);
        setFilteredData(filterer);
      }
    };

    if (!fileLoaded) {
      fetchData();
      setFileLoaded(true);
    }
  });

  const handleAddCallback = (editData) => {
    if (editData) {
      setFileLoaded(false);
    }
  };

  const pages = [];
  for (let i = 1; i <= Math.ceil(filteredData.length / recordsPerPage); i++) {
    pages.push(i);
  }

  const handleRecordPerPage = (e) => {
    console.log(e.target.value);
    setRecordsPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const handleSearch = (e) => {
    const filterer = searcher(data, e.target.value);
    setFilteredData(filterer);
    setSearch(e.target.value);
    setCurrentPage(1);
  };

  const searcher = (data, searchString) => {
    const searchedData = data.filter(function (data) {
      for (var key in data) {
        if (
          !(
            data["first_name"]
              .toLowerCase()
              .includes(searchString.toLowerCase()) ||
            data["last_name"]
              .toLowerCase()
              .includes(searchString.toLowerCase()) ||
            (data["last_name"] + ", " + data["first_name"])
              .toLowerCase()
              .includes(searchString.toLowerCase()) ||
            data["company_name"]
              .toLowerCase()
              .includes(searchString.toLowerCase()) ||
            data["emp_no"].toLowerCase().includes(searchString.toLowerCase()) ||
            data["company_email"]
              .toLowerCase()
              .includes(searchString.toLowerCase()) ||
            data["bschema_name"]
              .toLowerCase()
              .includes(searchString.toLowerCase()) ||
            data["schema_name"]
              .toLowerCase()
              .includes(searchString.toLowerCase()) ||
            data["emp_status_f"]
              .toLowerCase()
              .includes(searchString.toLowerCase())
          )
        ) {
          return false;
        }
        return true;
      }
    });

    return searchedData;
  };

  const handleSort = (columnChanged, currentSort) => {
    let setSort;
    let sorter = [];
    const getColumnValue = (column, value) => {
      switch (column) {
        default:
          return value;
      }
    };

    if (currentSort == "ASC") setSort = "DESC";
    else setSort = "ASC";

    if (columnChanged == "name") {
      sorter = [
        ["emp_status", "DESC"],
        ["last_name", nameSort],
        ["first_name", "ASC"],
        ["emp_no", "ASC"],
        ["bschema_name", "ASC"],
        ["schema_name", "ASC"],
      ];

      setNameSort(setSort);
    } else if (columnChanged == "empNo") {
      sorter = [
        ["emp_status", "DESC"],
        ["emp_no", empNoSort],
        ["last_name", "ASC"],
        ["first_name", "ASC"],
        ["bschema_name", "ASC"],
        ["schema_name", "ASC"],
      ];

      setEmpNoSort(setSort);
    } else if (columnChanged == "status") {
      sorter = [
        ["emp_status", statusSort],
        ["last_name", "ASC"],
        ["first_name", "ASC"],
        ["emp_no", "ASC"],
        ["bschema_name", "ASC"],
        ["schema_name", "ASC"],
      ];

      setStatusSort(setSort);
    } else if (columnChanged == "bschema") {
      sorter = [
        ["bschema_name", bschemaSort],
        ["emp_status", "DESC"],
        ["last_name", "ASC"],
        ["first_name", "ASC"],
        ["emp_no", "ASC"],
        ["schema_name", "ASC"],
      ];

      setBschemaSort(setSort);
    } else if (columnChanged == "oschema") {
      sorter = [
        ["schema_name", oschemaSort],
        ["emp_status", "DESC"],
        ["last_name", "ASC"],
        ["first_name", "ASC"],
        ["emp_no", "ASC"],
        ["bschema_name", "ASC"],
      ];

      setOschemaSort(setSort);
    }

    const sorted = multiColumnSort(filteredData, sorter, getColumnValue);

    setFilteredData(sorted);
  };
  const startIndex = currentPage * recordsPerPage - recordsPerPage;
  const endIndex = startIndex + recordsPerPage;
  const currentData = filteredData.slice(startIndex, endIndex);

  return (
    <div className="hk-wrapper hk-vertical-nav">
      <div class="pg-hk-wrapper">
        <Header />
        <div className="mt-20 hk-pg-wrapper">
          {permissions > 0 ? (
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-4 col-md-4 col-sm-12">
                  <h3>Employees</h3>
                </div>

                <div className="col-lg-8 col-md-8 col-sm-12">
                  {permissions > 1 ? (
                    <>
                      <div className="btn-group float-right ml-2">
                        <div className="dropdown">
                          <button
                            className="btn btn-sm btn-outline-secondary dropdown-toggle"
                            type="button"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <FileCopy /> Download Templates
                          </button>
                          <div
                            className="dropdown-menu dropdown-menu-right"
                            aria-labelledby="dropdownMenuButton"
                          >
                            <ExportEmployee />
                            <ExportSchedule />
                          </div>
                        </div>
                      </div>
                      <div className="btn-group float-right ml-2">
                        <div className="dropdown">
                          <button
                            className="btn btn-sm btn-outline-primary dropdown-toggle"
                            type="button"
                            id="dropdownMenuButton"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <CloudUpload /> Upload Data
                          </button>
                          <div
                            className="dropdown-menu dropdown-menu-right"
                            aria-labelledby="dropdownMenuButton"
                          >
                            <UploadEmployee
                              parentCallback={handleAddCallback}
                            />
                            <UploadSchedule
                              parentCallback={handleAddCallback}
                            />
                          </div>
                        </div>
                      </div>

                      {setting.stand_alone > 0 ? (
                        <AddEmployee
                          companyDD={companyDD}
                          schemaDD={schemaDD}
                          bschemaDD={bschemaDD}
                          bankDD={bankDD}
                          parentCallback={handleAddCallback}
                        />
                      ) : (
                        <AddEmployeeInt parentCallback={handleAddCallback} />
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 mt-10">
                  <div class="table-wrap">
                    <div class="row mb-10">
                      <div class="col-md-6">
                        <label>
                          <select
                            class="custom-select custom-select-sm form-control form-control-sm"
                            onChange={handleRecordPerPage}
                          >
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </label>{" "}
                        items
                      </div>
                      <div class="col-md-6">
                        <input
                          type="search"
                          class="form-control form-control-sm col-md-6 col-sm-6 float-right"
                          placeholder="Search"
                          defaultValue={
                            props.location.search
                              ? decodeURI(
                                  props.location.search.split("=").pop()
                                )
                              : ""
                          }
                          onChange={handleSearch}
                        />
                      </div>
                    </div>
                    <div class="table-responsive mb-10">
                      <table class="table table-sm table-bordered table-striped mb-0">
                        <thead className="thead-secondary">
                          <tr className="text-center">
                            <th
                              style={{ cursor: "pointer" }}
                              onClick={() => handleSort("empNo", empNoSort)}
                            >
                              Emp # &nbsp;
                              <i
                                className={
                                  empNoSort == "ASC"
                                    ? "fa fa-arrow-down"
                                    : "fa fa-arrow-up"
                                }
                              ></i>
                            </th>
                            <th
                              style={{ cursor: "pointer" }}
                              onClick={() => handleSort("name", nameSort)}
                            >
                              Name &nbsp;
                              <i
                                className={
                                  nameSort == "ASC"
                                    ? "fa fa-arrow-down"
                                    : "fa fa-arrow-up"
                                }
                              ></i>
                            </th>
                            <th>Monthly Basic</th>
                            <th>Hourly Rate</th>
                            <th>Required Working Hrs</th>
                            <th
                              style={{ cursor: "pointer" }}
                              onClick={() => handleSort("bschema", bschemaSort)}
                            >
                              Basic Schema &nbsp;
                              <i
                                className={
                                  bschemaSort == "ASC"
                                    ? "fa fa-arrow-down"
                                    : "fa fa-arrow-up"
                                }
                              ></i>
                            </th>
                            <th
                              style={{ cursor: "pointer" }}
                              onClick={() => handleSort("oschema", oschemaSort)}
                            >
                              OT Schema &nbsp;
                              <i
                                className={
                                  oschemaSort == "ASC"
                                    ? "fa fa-arrow-down"
                                    : "fa fa-arrow-up"
                                }
                              ></i>
                            </th>
                            <th
                              style={{ cursor: "pointer" }}
                              onClick={() => handleSort("status", statusSort)}
                            >
                              Status &nbsp;
                              <i
                                className={
                                  statusSort == "ASC"
                                    ? "fa fa-arrow-down"
                                    : "fa fa-arrow-up"
                                }
                              ></i>
                            </th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {filteredData.length > 0 ? (
                            currentData.map((employee) => (
                              <tr key={employee.id}>
                                <td>
                                  {" "}
                                  <Link
                                    to={{
                                      pathname: `/employee/view/${employee.id}`,
                                      query: `/employee`,
                                      search: "?search=" + search,
                                    }}
                                    className="text-primary"
                                    title={"View Employee Details"}
                                  >
                                    {employee.emp_no}
                                  </Link>
                                </td>
                                <td className="text-nowrap">
                                  {employee.last_name +
                                    ", " +
                                    employee.first_name}
                                  <br />
                                  <small className="text-muted">
                                    {employee.position}
                                  </small>
                                  <br />
                                  <small className="text-muted">
                                    {employee.department}
                                  </small>
                                </td>
                                <td className="text-right">
                                  {employee.basic_rate_f}
                                </td>
                                <td className="text-right">
                                  {employee.hour_rate_f}
                                </td>
                                <td className="text-right">
                                  {employee.required_working_hours_f}
                                </td>
                                <td>{employee.bschema_name}</td>
                                <td>{employee.schema_name}</td>
                                <td>
                                  {employee.emp_status == 0 ? (
                                    <strong className="text-danger">
                                      INACTIVE
                                    </strong>
                                  ) : (
                                    <strong className="text-success">
                                      ACTIVE
                                    </strong>
                                  )}
                                </td>
                                <td>
                                  <div className="btn-group">
                                    <Link
                                      to={{
                                        pathname: `/employee/view/${employee.id}`,
                                        query: `/employee`,
                                        search: "?search=" + search,
                                      }}
                                      className="btn btn-sm btn-outline-primary"
                                      title={"View Employee Details"}
                                    >
                                      <FindInPage />
                                    </Link>
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colspan="9" className="text-center">
                                No Data to show
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="drew-pagination">
                    {filteredData.length > 0
                      ? "Showing " +
                        (startIndex + 1) +
                        " to " +
                        (endIndex > filteredData.length
                          ? filteredData.length
                          : endIndex) +
                        " of " +
                        filteredData.length
                      : ""}
                    <ul class="pagination ml-2">
                      {pages.map((number) => (
                        <li
                          key={number}
                          id={number}
                          className={
                            currentPage == number
                              ? "paginate_button page-item active"
                              : "paginate_button page-item"
                          }
                          onClick={() => setCurrentPage(number)}
                        >
                          <a class="page-link">{number}</a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <h1 className="m-0 text-center">
                Your credentials is not sufficient to access this:
                <br />
              </h1>
              <br />
              <h2 className="text-center">
                Module : Master Data
                <br />
                Access Type : {access[permissions]}
                <br />
                Please contact your Payroll Administrator.
                <br />
                <a className="btn btn-primary btn-sm" href="/#/app">
                  Back to Dashboard
                </a>
              </h2>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
