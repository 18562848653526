import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import { dateFormatter } from "../../components/DtFormatter";
import axios from "axios";
import Cookies from "universal-cookie";
import { Link } from "react-router-dom";
import { ChevronLeft, ChevronRight } from "@material-ui/icons";
import Processing from "../../components/Processing";
const cookies = new Cookies();
const access = ["No Access", "View Only", "Add", "Add / Edit", "Full Access"];

export default function RegularPayslip(props) {
  const permissions = cookies.get("permissions")["payroll"];
  const [employee, setEmployee] = useState([]);
  const [cutoff, setCutoff] = useState([]);
  const [basicEarning, setBasicEarning] = useState([]);
  const [basicDeduction, setBasicDeduction] = useState([]);
  const [basicStatutory, setBasicStatutory] = useState([]);
  const [otherEarning, setOtherEarning] = useState([]);
  const [otherDeduction, setOtherDeduction] = useState([]);
  const [otherStatutory, setOtherStatutory] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [filteredData, setFilteredData] = useState([]);
  const [searchMain, setSearchMain] = useState("")
  const [search, setSearch] = useState("");

  const [nextData, setNextData] = useState([]);
  const [prevData, setPrevData] = useState([]);
  const [processing, setProcessing] = useState(false);

  useEffect(() => {
    if (props.match.params.next == "yes") {
      setProcessing(true);
    }

    const fetchBasic = async () => {
      const getValue = await axios.get(
        "api/regular_payslip/" +
          props.match.params.cutoff_id +
          "/" +
          props.match.params.paysched_id +
          "/" +
          props.match.params.employee_id
      );
      setPrevData(getValue.data.prevEmp);
      setNextData(getValue.data.nextEmp);
      setBasicEarning(getValue.data.basicEarning);
      setBasicDeduction(getValue.data.basicDeduction);
      setBasicStatutory(getValue.data.basicStatutory);
      setEmployee(getValue.data.employee);
      setCutoff(getValue.data.regularPayroll);
      setOtherEarning(getValue.data.otherEarning);
      setOtherDeduction(getValue.data.otherDeduction);
      setOtherStatutory(getValue.data.otherStatutory);
      
    };

    fetchBasic();
    if (props.match.params.next == "yes") {
      setTimeout(function () {
        setProcessing(false);
      }, 1000);
    }
  }, [props.match.params.employee_id]);

  const handleBack = () => {
    props.history.goBack();
  };

  const basicEarningSum = basicEarning.reduce(
    (a, v) =>
      v.is_add == 1
        ? (a = a + parseFloat(v.amount))
        : (a = a - parseFloat(v.amount)),
    0
  );

  const basicDeductionSum = basicDeduction.reduce(
    (a, v) =>
      v.is_add == 1
        ? (a = a + parseFloat(v.amount))
        : (a = a - parseFloat(v.amount)),
    0
  );
  
  const basicStatutorySum = basicStatutory.reduce(
    (a, v) => (a = a + parseFloat(v.amount)),
    0
  );

  const wh_tax = basicStatutory.reduce(
    (a, v) =>
      v.ed_id == 24 ? (a = a + parseFloat(v.amount)) : (a = a + parseFloat(0)),
    0
  );

  const otherEarningSum = otherEarning.reduce(
    (a, v) => (a = a + parseFloat(v.amount)),
    0
  );

  const otherDeductionSum = otherDeduction.reduce(
    (a, v) => (a = a + parseFloat(v.amount)),
    0
  );

  const otherStatutorySum = otherStatutory.reduce(
    (a, v) => (a = a + parseFloat(v.amount)),
    0
  );
  

  return (
    <div className="hk-wrapper hk-vertical-nav">
      <div class="pg-hk-wrapper">
        <Header />
        <Processing show={processing} />
        <div className="mt-20 hk-pg-wrapper">
          {permissions > 0 ? (
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-10 col-md-10 col-sm-12">
                  <h3 className="pb-10">Regular Run Employee Payslip</h3>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-12">
                  <Link
                    to={{
                      pathname: `/reg_payroll/${props.match.params.cutoff_id}/${props.match.params.from}`,
                      query: `/reg_payroll`,
                      search: props.location.search
                    }}
                    className="btn btn-sm btn-danger float-right"
                    title={"View per employee"}
                  >
                   Back to Previous Page
                  </Link>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="card">
                    <div className="card-header card-header-action">
                      <h5>Employee Detail</h5>
                      <div className="d-flex align-items-center card-action-wrap-tools"></div>
                    </div>
                    <div className="card-body">
                    <div className="row mb-10">
                        <div className="col-xl-4 col-sm-10">
                          <strong>Employee #</strong>
                        </div>
                        <div className="col-xl-8 col-sm-10">
                          :&nbsp;&nbsp;&nbsp;
                          {employee.emp_no}
                        </div>
                      </div>
                      <div className="row mb-10">
                        <div className="col-xl-4 col-sm-10">
                          <strong>Name</strong>
                        </div>
                        <div className="col-xl-8 col-sm-10">
                          :&nbsp;&nbsp;&nbsp;
                          {employee.last_name + ", " + employee.first_name}
                        </div>
                      </div>

                      <div className="row mb-10">
                        <div className="col-xl-4 col-sm-10">
                          <strong>Pay Period ID</strong>
                        </div>
                        <div className="col-xl-8 col-sm-10">
                          :&nbsp;&nbsp;&nbsp;
                          {cutoff.code}
                        </div>
                      </div>

                      <div className="row mb-30">
                        <div className="col-xl-4 col-sm-10">
                          <strong>Period</strong>
                        </div>
                        <div className="col-xl-8 col-sm-10">
                          :&nbsp;&nbsp;&nbsp;
                          {dateFormatter(cutoff.start_date) +
                            " to " +
                            dateFormatter(cutoff.end_date)}
                        </div>
                      </div>
                      <div className="row mb-10">
                        <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 text-center">
                          {prevData ? (
                            <Link
                              to={{
                                pathname: `/reg_payroll/${props.match.params.cutoff_id}/${prevData.employee_id}/${props.match.params.paysched_id}/${props.match.params.from}/yes`,
                                query: `/reg_payroll`,
                              }}
                              className="btn btn-sm btn-secondary"
                              title={"View Employee Details"}
                            >
                              <ChevronLeft />
                              Prev Employee
                            </Link>
                          ) : (
                            <button
                              className="btn btn-sm btn-secondary"
                              disabled="disabled"
                            >
                              <ChevronLeft />
                              Prev Employee
                            </button>
                          )}
                        </div>
                        <div className="col-xl-6  col-lg-6 col-md-12 col-sm-12 text-center">
                          {nextData ? (
                            <Link
                              to={{
                                pathname: `/reg_payroll/${props.match.params.cutoff_id}/${nextData.employee_id}/${props.match.params.paysched_id}/${props.match.params.from}/yes`,
                                query: `/reg_payroll`,
                              }}
                              className="btn btn-sm btn-secondary"
                              title={"View Employee Details"}
                            >
                              Next Employee
                              <ChevronRight />
                            </Link>
                          ) : (
                            <button
                              className="btn btn-sm btn-secondary"
                              disabled="disabled"
                            >
                              Next Employee
                              <ChevronRight />
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="card">
                    <div className="card-header card-header-action">
                      <h5>Totals</h5>
                      <div className="d-flex align-items-center card-action-wrap-tools"></div>
                    </div>
                    <div className="card-body">
                      <div className="row mb-10">
                        <div className="col-xl-6 col-sm-10">
                          <strong>Total Earnings</strong>
                        </div>
                        <div className="col-xl-6 col-sm-10">
                          :&nbsp;&nbsp;&nbsp;
                          <span className="float-right">
                            {(basicEarningSum + otherEarningSum)
                              .toFixed(2)
                              .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                          </span>
                        </div>
                      </div>

                      <div className="row mb-10">
                        <div className="col-xl-6 col-sm-10">
                          <strong>Total Deductions</strong>
                        </div>
                        <div className="col-xl-6 col-sm-10">
                          :&nbsp;&nbsp;&nbsp;
                          <span className="float-right">
                            {(basicDeductionSum + otherDeductionSum)
                              .toFixed(2)
                              .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                          </span>
                        </div>
                      </div>

                      <div className="row mb-10">
                        <div className="col-xl-6 col-sm-10">
                          <strong>Total Statutories</strong>
                        </div>
                        <div className="col-xl-6 col-sm-10">
                          :&nbsp;&nbsp;&nbsp;
                          <span className="float-right">
                            {basicStatutorySum
                              .toFixed(2)
                              .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                          </span>
                        </div>
                      </div>
                      <div className="row mb-10">
                        <div className="col-xl-6 col-sm-10">
                          <strong></strong>
                        </div>
                        <div className="col-xl-6 col-sm-10">
                          &nbsp;&nbsp;&nbsp;
                        </div>
                      </div>

                      <hr />
                      <div className="row mb-10">
                        <div className="col-xl-6 col-sm-10">
                          <strong>Net Pay</strong>
                        </div>
                        <div className="col-xl-6 col-sm-10">
                          :&nbsp;&nbsp;&nbsp;
                          <span className="float-right">
                            {(
                              basicEarningSum +
                              otherEarningSum -
                              (basicDeductionSum +
                                otherDeductionSum + 
                                otherStatutorySum +
                                basicStatutorySum)
                            )
                              .toFixed(2)
                              .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="col-lg-4 col-md-4 col-sm-12">
                  <div className="card">
                    <div className="card-header card-header-action">
                      <h5>YTD</h5>
                      <div className="d-flex align-items-center card-action-wrap-tools"></div>
                    </div>
                    <div className="card-body">
                      <div className="row mb-10">
                        <div className="col-xl-6 col-sm-10">
                          <strong>YTD Earning</strong>
                        </div>
                        <div className="col-xl-6 col-sm-10">
                          :&nbsp;&nbsp;&nbsp;
                          <span className="float-right">
                            {(basicEarningSum + otherEarningSum)
                              .toFixed(2)
                              .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                          </span>
                        </div>
                      </div>

                      <div className="row mb-10">
                        <div className="col-xl-6 col-sm-10">
                          <strong>YTD Deduction</strong>
                        </div>
                        <div className="col-xl-6 col-sm-10">
                          :&nbsp;&nbsp;&nbsp;
                          <span className="float-right">
                            {(basicDeductionSum + otherDeductionSum)
                              .toFixed(2)
                              .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                          </span>
                        </div>
                      </div>

                      <div className="row mb-10">
                        <div className="col-xl-6 col-sm-10">
                          <strong>YTD WH Tax</strong>
                        </div>
                        <div className="col-xl-6 col-sm-10">
                          :&nbsp;&nbsp;&nbsp;
                          <span className="float-right">
                            {wh_tax
                              .toFixed(2)
                              .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                          </span>
                        </div>
                      </div>

                      <hr />
                      <div className="row mb-10">
                        <div className="col-xl-6 col-sm-10">
                          <strong>YTD Net Pay</strong>
                        </div>
                        <div className="col-xl-6 col-sm-10">
                          :&nbsp;&nbsp;&nbsp;
                          <span className="float-right">
                            {(
                              basicEarningSum +
                              otherEarningSum -
                              (basicDeductionSum +
                                otherDeductionSum +
                                basicStatutorySum)
                            )
                              .toFixed(2)
                              .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>

              <div className="row">
                <div className="col-lg-4 col-md-12 col-sm-12">
                  <div className="table-responsive mb-10">
                    <table className="table table-sm table-bordered table-striped mb-0">
                      <thead className="thead-success">
                        <tr className="text-center">
                          <th>Earnings</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {basicEarning.map((earnings) => (
                          <tr>
                            <td>{earnings.name}</td>
                            <td className="text-right">
                              {(earnings.amount + "")
                                .toLocaleString()
                                .replace(
                                  /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                  ","
                                )}
                            </td>
                          </tr>
                        ))}
                        {otherEarning.map((otherEarnings) => (
                          <tr>
                            <td>
                              Other Earning{otherEarnings.non_tax == 1 ? (" (Non Taxed)"):("")}:
                              <br />
                              {otherEarnings.name}
                            </td>
                            <td className="text-right">
                              {(otherEarnings.amount + "")
                                .toLocaleString()
                                .replace(
                                  /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                  ","
                                )}
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td className="text-right">
                            <strong>Total Earning:</strong>
                          </td>
                          <td className="text-right">
                            <strong>
                              {(basicEarningSum + otherEarningSum)
                                .toFixed(2)
                                .replace(
                                  /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                  ","
                                )}
                            </strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                  <div className="table-responsive mb-10">
                    <table className="table table-sm table-bordered table-striped mb-0">
                      <thead className="thead-danger">
                        <tr className="text-center">
                          <th>Deductions</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {basicDeduction.map((deductions) => (
                          <tr>
                            <td>{deductions.name}</td>
                            <td className="text-right">
                              {(deductions.amount + "")
                                .toLocaleString()
                                .replace(
                                  /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                  ","
                                )}
                            </td>
                          </tr>
                        ))}
                        {otherDeduction.map((otherDeductions) => (
                          <tr>
                            <td>
                              Other Deduction:
                              <br />
                              {otherDeductions.name}
                            </td>
                            <td className="text-right">
                              {(otherDeductions.amount + "")
                                .toLocaleString()
                                .replace(
                                  /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                  ","
                                )}
                            </td>
                          </tr>
                        ))}
                        <tr>
                          <td className="text-right">
                            <strong>Total Deduction:</strong>
                          </td>
                          <td className="text-right">
                            <strong>
                              {(basicDeductionSum + otherDeductionSum)
                                .toFixed(2)
                                .replace(
                                  /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                  ","
                                )}
                            </strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12 col-sm-12">
                  <div className="table-responsive mb-10">
                    <table className="table table-sm table-bordered table-striped mb-0">
                      <thead className="thead-warning">
                        <tr className="text-center">
                          <th>Statutories</th>
                          <th>Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        {basicStatutory.map((statutories) =>
                          statutories.ed_id != 24 ? (
                            <tr>
                              <td>{statutories.name}</td>
                              <td className="text-right">
                                {(statutories.amount + "")
                                  .toLocaleString()
                                  .replace(
                                    /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                    ","
                                  )}
                              </td>
                            </tr>
                          ) : (
                            ""
                          )
                        )}
                        {otherStatutory.map((statutories) =>
                            <tr>
                              <td>{statutories.name}</td>
                              <td className="text-right">
                                {(statutories.amount + "")
                                  .toLocaleString()
                                  .replace(
                                    /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                    ","
                                  )}
                              </td>
                            </tr>
                        )}
                        {basicStatutory.map((statutories) =>
                          statutories.ed_id == 24 ? (
                            <tr>
                              <td>{statutories.name}</td>
                              <td className="text-right">
                                {(statutories.amount + "")
                                  .toLocaleString()
                                  .replace(
                                    /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                    ","
                                  )}
                              </td>
                            </tr>
                          ) : (
                            ""
                          )
                        )}
                        <tr>
                          <td className="text-right">
                            <strong>Total Statutories:</strong>
                          </td>
                          <td className="text-right">
                            <strong>
                              {(basicStatutorySum+otherStatutorySum)
                                .toFixed(2)
                                .replace(
                                  /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                  ","
                                )}
                            </strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <h1 className="m-0 text-center">
                Your credentials is not sufficient to access this:
                <br />
              </h1>
              <br />
              <h2 className="text-center">
                Module : Payroll Processing
                <br />
                Access Type : {access[permissions]}
                <br />
                Please contact your Payroll Administrator.
                <br />
                <a className="btn btn-primary btn-sm" href="/#/app">
                  Back to Dashboard
                </a>
              </h2>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
