import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import axios from "axios";
import EditSchema from "./EditSchema";
import AddSchemaDetail from "./AddSchemaDetail";
import EditSchemaDetail from "./EditSchemaDetail";
import DeleteSchemaDetail from "./DeleteSchemaDetail";
import Cookies from "universal-cookie";
const cookies = new Cookies();
const access = ["No Access", "View Only", "Add", "Add / Edit", "Full Access"];

export default class SchemaDetail extends Component {
  state = {
    permissions: cookies.get("permissions")['config'],
    schemaMain: [],
    schemaDetail: [],
    conditionDD: [],
    filteredData: [],
  };

  constructor() {
    super();
    this.handleBack = this.handleBack.bind(this);
  }

  componentDidMount() {
    this.getDetails();
    axios.get("api/condition").then((res) => {
      this.setState({ conditionDD: res.data });
    });
  }

  getDetails = () => {
    const schemaId = this.props.match.params.id;
    axios.get("api/schema/" + schemaId).then((res) => {
      this.setState({ schemaMain: res.data });
    });

    axios.get("api/schema_detail/" + schemaId).then((res) => {
      this.setState({ schemaDetail: res.data, filteredData: res.data });
    });
  };

  handleCallback = (cb) => {
    if (cb) {
      this.getDetails();
      this.setState({ updateOccured: true });
    }
  };

  handleBack() {
    this.props.history.goBack();
  }

  handleSearch = (e) => {
    const filterer = this.state.schemaDetail.filter(function (data) {
      for (var key in data) {
        if (
          !(
            data["condition_type_name"].toLowerCase().includes(e.target.value.toLowerCase()) ||
            data["description"].toLowerCase().includes(e.target.value.toLowerCase())
          )
        ) {
          return false;
        }
        return true;
      }
    });
    this.setState({ filteredData: filterer });
  };
  componentDidUpdate() {}
  render() {
    return (
      <div className="hk-wrapper hk-vertical-nav">
        <div class="pg-hk-wrapper">
          <Header />
          <div className="mt-20 hk-pg-wrapper">
            {this.state.permissions > 0 ? (
              <div className="container-fluid ">
                <div className="row pt-10 pb-10">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <h3>Overtime Schema View</h3>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12">
                    {/* <button
                      className="btn btn-sm btn-danger float-right"
                      onClick={this.handleBack}
                    >
                      Back to Previous Page
                    </button> */}
                    <Link
                      to={{
                        pathname: `/schema`,
                        query: `/schema`,
                        search: "?search="+this.props.location.search.split("=").pop()
                      }}
                      className="btn btn-sm btn-danger float-right "
                      title={"View Transaction Details"}
                    >
                      Back to Previous Page
                    </Link>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="card">
                      <div className="card-header card-header-action">
                        <h5>Details</h5>
                        <div className="d-flex align-items-center card-action-wrap-tools">
                          {this.state.permissions > 2 ? (
                            <EditSchema
                              parentCallback={this.handleCallback}
                              schemaMain={this.state.schemaMain}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="row mb-10">
                          <div className="col-xl-4 col-sm-10">
                            <strong>Code</strong>
                          </div>
                          <div className="col-xl-8 col-sm-10">
                            :&nbsp;&nbsp;&nbsp;
                            {this.state.schemaMain.code}
                          </div>
                        </div>
                        <div className="row mb-10">
                          <div className="col-xl-4 col-sm-10">
                            <strong>Description</strong>
                          </div>
                          <div className="col-xl-8 col-sm-10">
                            :&nbsp;&nbsp;&nbsp;
                            {this.state.schemaMain.description}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row pb-10">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    {this.state.permissions > 1 ? (
                      <AddSchemaDetail
                        schema_id={this.props.match.params.id}
                        conditionDD={this.state.conditionDD}
                        parentCallback={this.handleCallback}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-md-6 pt-10"></div>
                  <div className="col-md-6 pt-10">
                    <input
                      type="search"
                      className="form-control form-control-sm col-md-6 col-sm-6 float-right"
                      placeholder="Search..."
                      onChange={this.handleSearch}
                    />
                  </div>
                </div>
                <div className="table-responsive">
                  <table className="table table-sm table-bordered table-striped mb-0">
                    <thead className="thead-secondary">
                      <tr className="text-center">
                        <th>Step</th>
                        <th>Counter</th>
                        <th>Condition Type</th>
                        <th>Description</th>
                        <th>Compute Full Hours?</th>
                        <th>Actual Rate(%)</th>
                        <th style={{ width: "140px" }}>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.filteredData.length > 0 ? (
                        this.state.filteredData.map((detail) => (
                          <tr key={detail.id}>
                            <td>{detail.step}</td>
                            <td>{detail.counter}</td>
                            <td>{detail.condition_type_name}</td>
                            <td>{detail.description}</td>
                            <td>{detail.restday_qualified}</td>
                            <td className="text-right">
                              {detail.actual_rate * 100} %
                            </td>
                            <td>
                              {this.state.permissions > 3 ? (
                                <DeleteSchemaDetail
                                  parentCallback={this.handleCallback}
                                  id={detail.id}
                                  name={detail.condition_type_name}
                                />
                              ) : (
                                ""
                              )}
                              {this.state.permissions > 2 ? (
                                <EditSchemaDetail
                                  conditionDD={this.state.conditionDD}
                                  parentCallback={this.handleCallback}
                                  schemaDetail={detail}
                                />
                              ) : (
                                ""
                              )}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={10} className="text-center">
                            No Data to show
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              <>
                <h1 className="m-0 text-center">
                  Your credentials is not sufficient to access this:
                  <br />
                </h1>
                <br />
                <h2 className="text-center">
                  Module : Configration
                  <br />
                  Access Type : {access[this.state.permissions]}
                  <br />
                  Please contact your Payroll Administrator.
                  <br />
                  <a className="btn btn-primary btn-sm" href="/#/app">
                    Back to Dashboard
                  </a>
                </h2>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}
