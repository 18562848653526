import React, { Component } from "react";
import { FileCopy } from "@material-ui/icons";
import axios from "axios";

export default class ExportSssTemp extends Component {
  constructor(props) {
    super();
    this.state = {
      showDeleteModal: false,
      disableSubmit: false,
      status: 2,
    };
    this.handleshowDeleteModal = this.handleshowDeleteModal.bind(this);
  }

  handleshowDeleteModal = (e) => {
    this.setState({ showDeleteModal: !this.state.showDeleteModal });
  };

  handleSubmit = (e) => {
    axios({
      url: process.env.REACT_APP_API_LINK+"api/sss_temp", //your url
      method: "GET",
      responseType: "blob", // important
    }).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "SSSTable_Temp.xlsx"); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
    
  };

  render() {
    return (
      <>
        <button
          className="btn btn-outline-secondary btn-sm float-right"
          title="Export SSS Table Template"
          onClick={this.handleSubmit}
        >
          <FileCopy /> Download Template
        </button>
      </>
    );
  }
}
