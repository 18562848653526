import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { Check } from "@material-ui/icons";
import axios from "axios";
import Cookies from "universal-cookie";
import Processing from "../../components/Processing";
const cookies = new Cookies();

export default class SpecialApprove extends Component {
  constructor(props) {
    super();
    this.state = {
      showModal: false,
      disableSubmit: false,
      status: 2,
      updated_by: cookies.get("email"),
      company_id: cookies.get("transCompany"),
      refreshDisabled: false,
    };
    this.handleshowDeleteModal = this.handleshowDeleteModal.bind(this);
  }

  handleshowDeleteModal = (e) => {
    this.setState({ showModal: !this.state.showModal });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    // this.setState({ disableSubmit: true });
    this.setState({
      disableSubmit: true,
      refreshDisabled: true,
      showModal: false,
    });
    axios
      .post("api/special_review/" + this.props.schedData.id, this.state)
      .then((res) => {
        // this.props.parentCallback(true);
        // this.setState({ showModal: false, disableSubmit: false });
        setTimeout(
          function () {
            this.props.parentCallback(true);
            this.setState({
              refreshDisabled: false,
              showModal: false,
              hasError: false,
              disableSubmit: false,
            });
          }.bind(this),
          1000
        );
      });
  };

  render() {
    return (
      <>
        <button
          className={
            this.props.schedData.status == 1
              ? "btn btn-gradient-info"
              : "btn btn-sm btn-info"
          }
          title="Reviewed For Posting"
          onClick={this.handleshowDeleteModal}
          disabled={this.props.schedData.status != 1 ? "disabled" : ""}
        >
          Reviewed For Posting
        </button>
        <Modal
          size="md"
          show={this.state.showModal}
          onHide={() => this.setState({ showModal: !this.state.showModal })}
        >
          <Modal.Header closeButton>
            <h4>
              Reviewed For Posting:
              <br />
            </h4>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={this.handleSubmit} id="contact-form">
              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  Are you sure?
                </div>
              </div>
              <div className="float-right">
                <button
                  type="submit"
                  className="btn btn-primary btn-sm mr-2"
                  disabled={this.state.disableSubmit}
                >
                  Approve
                </button>
                <button
                  onClick={this.handleshowDeleteModal}
                  className="btn btn-danger btn-sm"
                  type="button"
                >
                  Cancel
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
        <Processing
          show={this.state.refreshDisabled}
          name="Special Review Run "
        />
      </>
    );
  }
}
