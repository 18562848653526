import React, { useState, useEffect } from "react";
import axios from "axios";
import { dateFormatter } from "../../../components/DtFormatter";
import EditPagibig from "../statutories/EditPagibig";
import EditPhilhealth from "./EditPhilHealth";
import Cookies from "universal-cookie";
const cookies = new Cookies();
export default function Statutories() {
  const permissions = cookies.get("permissions")["config"];
  const [data, setData] = useState([]);
  const [PhData, setPhData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [filteredData, setFilteredData] = useState([]);
  const [fileLoaded, setFileLoaded] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      let apiLink = "api/pagibig";
      const res = await axios.get(apiLink);
      setData(res.data);
      setFilteredData(res.data);

      let apiLink2 = "api/philhealth";
      const res2 = await axios.get(apiLink2);
      setPhData(res2.data);
    };

    if (!fileLoaded) {
      fetchData();
      setFileLoaded(true);
    }
  });

  const handleAddCallback = (editData) => {
    if (editData) {
      setFileLoaded(false);
    }
  };

  const pages = [];
  for (let i = 1; i <= Math.ceil(filteredData.length / recordsPerPage); i++) {
    pages.push(i);
  }

  const handleRecordPerPage = (e) => {
    console.log(e.target.value);
    setRecordsPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const handleSearch = (e) => {
    const filterer = data.filter(function (data) {
      for (var key in data) {
        if (
          !data["name"].toLowerCase().includes(e.target.value.toLowerCase())
        ) {
          return false;
        }
        return true;
      }
    });
    setFilteredData(filterer);
  };

  const startIndex = currentPage * recordsPerPage - recordsPerPage;
  const endIndex = startIndex + recordsPerPage;
  const currentData = filteredData.slice(startIndex, endIndex);

  return (
    <div>
      <div className="row mb-3">
        <div className="col-lg-12 col-md-12 col-sm-12"></div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div class="card-header">
              <h5>PAGIBIG</h5>
            </div>
            <div className="card-body">
              <div className="table-wrap">
                <div className="table-responsive mb-10">
                  <table className="table table-sm table-bordered table-striped mb-0">
                    <thead className="thead-secondary">
                      <tr className="text-center">
                        <th>Contribution</th>
                        <th>Status</th>
                        <th>Date Created</th>
                        <th>Created By</th>
                        <th style={{ width: "90px" }}>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.length > 0 ? (
                        data.map((pagibig) => (
                          <tr key={pagibig.id}>
                            <td>
                              {pagibig.contribution
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </td>
                            <td>
                              <strong
                                className={
                                  pagibig.active == 0
                                    ? "text-danger"
                                    : "text-success"
                                }
                              >
                                {pagibig.active == 0 ? "INACTIVE" : "ACTIVE"}
                              </strong>
                            </td>
                            <td>
                              {dateFormatter(
                                pagibig.created_at,
                                "MMM dd, yyyy hh:mm bb"
                              )}
                            </td>
                            <td>{pagibig.created_by}</td>
                            <td>
                              <div className="btn-group">
                                {pagibig.active == 0 ? (
                                  ""
                                ) : (
                                  permissions > 2 ? (
                                  <EditPagibig
                                    parentCallback={handleAddCallback}
                                  />):("")
                                )}
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="5" className="text-center">
                            No Data to show
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-sm-12">
          <div className="card">
            <div class="card-header">
              <h5>PHILHEALTH</h5>
            </div>
            <div className="card-body">
              <div className="table-wrap">
                <div className="table-responsive mb-10">
                  <table className="table table-sm table-bordered table-striped mb-0">
                    <thead className="thead-secondary">
                      <tr className="text-center">
                        <th>Lower Limit</th>
                        <th>Upper Limit</th>
                        <th>Rate</th>
                        <th>Status</th>
                        <th>Date Created</th>
                        <th>Created By</th>
                        <th style={{ width: "70px" }}>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {PhData.length > 0 ? (
                        PhData.map((philhealth) => (
                          <tr key={philhealth.id}>
                            <td>
                              {philhealth.lower_range
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </td>
                            <td>
                              {philhealth.upper_range
                                .toString()
                                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                            </td>
                            <td className="text-nowrap">
                              {(philhealth.rate * 100).toFixed(2)} %
                            </td>
                            <td>
                              <strong
                                className={
                                  philhealth.active == 0
                                    ? "text-danger"
                                    : "text-success"
                                }
                              >
                                {philhealth.active == 0 ? "INACTIVE" : "ACTIVE"}
                              </strong>
                            </td>
                            <td>
                              {dateFormatter(
                                philhealth.created_at,
                                "MMM dd, yyyy hh:mm bb"
                              )}
                            </td>
                            <td>{philhealth.created_by}</td>
                            <td>
                              <div className="btn-group">
                                {philhealth.active == 0 ? (
                                  ""
                                ) : (
                                  permissions > 2 ? (
                                  <EditPhilhealth
                                    parentCallback={handleAddCallback}
                                  />):("")
                                )}
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="5" className="text-center">
                            No Data to show
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
