import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { Edit } from "@material-ui/icons";
import axios from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();

export default class EditPaymentDate extends Component {
  constructor(props) {
    super();
    this.state = {
      payment_date: props.data.payment_date,
      showEditModal: false,
      disableSubmit: false,
      updated_by: cookies.get("email"),
      created_by: cookies.get("email"),
    };
    this.handleshowEditModal = this.handleshowEditModal.bind(this);
  }

  handleshowEditModal = (e) => {
    this.setState({ showEditModal: !this.state.showEditModal });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    let data = {
      payment_date: this.state.payment_date,
      updated_by: cookies.get("email"),
      created_by: cookies.get("email"),
      company_id: cookies.get("transCompany"),
    };
    this.setState({ disableSubmit: true });
    axios
      .post("api/edit_payment_date/" + this.props.data.id, data)
      .then((res) => {
        this.props.parentCallback(true);
        this.setState({ showEditModal: false, disableSubmit: false });
      });
  };

  render() {
    return (
      <>
        <button
          className="btn btn-sm btn-outline-success"
          title="Edit Payment Date"
          onClick={this.handleshowEditModal}
        >
          <Edit />
        </button>
        <Modal
          size="md"
          show={this.state.showEditModal}
          onHide={() =>
            this.setState({ showEditModal: !this.state.showEditModal })
          }
        >
          <Modal.Header closeButton>
            <h4>
              Edit Payment Date :<br />
            </h4>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={this.handleSubmit} id="contact-form">
              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  <label>Payment Date</label>
                  <input
                    class="form-control"
                    type="date"
                    onChange={(e) =>
                      this.setState({ payment_date: e.target.value })
                    }
                    defaultValue={this.props.data.payment_date}
                    min={this.props.data.start_date}
                    max={this.props.data.end_date}
                  />
                </div>
              </div>
              <div className="float-right">
                <button
                  type="submit"
                  className="btn btn-primary btn-sm mr-2"
                  disabled={this.state.disableSubmit}
                >
                  Save
                </button>
                <button
                  onClick={this.handleshowEditModal}
                  className="btn btn-danger btn-sm"
                  type="button"
                >
                  Cancel
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
