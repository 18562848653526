import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { Check, Receipt } from "@material-ui/icons";
import axios from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();

export default class ExportPhicPrContribution extends Component {
  constructor(props) {
    super();
    this.state = {
      showDeleteModal: false,
      disableSubmit: false,
      status: 2,
    };
    this.handleshowDeleteModal = this.handleshowDeleteModal.bind(this);
  }

  handleshowDeleteModal = (e) => {
    this.setState({ showDeleteModal: !this.state.showDeleteModal });
  };

  handleSubmit = (e) => {
    let checked = this.props.dataCheck;
    let checkfiltered = this.props.data.filter(function (data) {
      let checkValue = false;
      checked.map((checkData) => {
        if (checkData["checked"])
          if ((data["payroll_id"] == checkData["id"])) {
            checkValue = true;
          }
      });
      return checkValue;
    });

    if(checkfiltered.length > 0){
      axios({
        url: process.env.REACT_APP_API_LINK+"api/philhealth_register_export/"+ cookies.get("transCompany"), //your url
        method: "POST",
        data: checkfiltered,
        responseType: "blob", // important
      }).then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download","PHILHEALTH Contribution-Accounting.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    } else {
      alert("No Data Selected")
    }
  };

  render() {
    return (
      <>
        <button
          className="btn btn-outline-success btn-sm float-right"
          title="Export PHILHEALTH Contribution"
          onClick={this.handleSubmit}
        >
          <Receipt /> Export to Excel
        </button>
      </>
    );
  }
}

