import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import { dateFormatter } from "../../components/DtFormatter";
import axios from "axios";
import { Link } from "react-router-dom";
import { FindInPage, Add } from "@material-ui/icons";
import Cookies from "universal-cookie";
import multiColumnSort from "multi-column-sort";
import ExportPayrollRun from "./ExportPayrollRun";
const statuses = ["DRAFT", "FOR POSTING", "POSTED"];
const cookies = new Cookies();
const access = ["No Access", "View Only", "Add", "Add / Edit", "Full Access"];

export default function RegularPayrollPerEmp(props) {
  const permissions = cookies.get("permissions")["payroll"];
  const [data, setData] = useState([]);
  const [runData, setRunData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [filteredData, setFilteredData] = useState([]);
  const [empNoSort, setEmpNoSort] = useState("DESC");
  const [nameSort, setNameSort] = useState("DESC");
  const [searchMain, setSearchMain] = useState("");
  const [search, setSearch] = useState("");
  useEffect(() => {
    const fetchData = async () => {
      let apiLink = "api/regular_peremp/" + props.match.params.id;
      const res = await axios.get(apiLink);
      const params = getQueryParams(props.location.search);

      setData(res.data.employee);
      setRunData(res.data.regularPayroll);

      setSearchMain(params.searchMain ? params.searchMain : "");
      setSearch(params.search ? params.search : "");
      const filterer = searcher(
        res.data.employee,
        params.search ? params.search : ""
      );
      setFilteredData(filterer);
    };
    fetchData();
  }, []);

  const handleRecordPerPage = (e) => {
    setRecordsPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const getQueryParams = (query) => {
    let params = {};
    new URLSearchParams(query).forEach((value, key) => {
      let decodedKey = decodeURIComponent(key);
      let decodedValue = decodeURIComponent(value);
      // This key is part of an array
      if (decodedKey.endsWith("[]")) {
        decodedKey = decodedKey.replace("[]", "");
        params[decodedKey] || (params[decodedKey] = []);
        params[decodedKey].push(decodedValue);
        // Just a regular parameter
      } else {
        params[decodedKey] = decodedValue;
      }
    });

    return params;
  };

  const handleSearch = (e) => {
    const filterer = searcher(data, e.target.value);
    setFilteredData(filterer);
    setSearch(e.target.value);
    setCurrentPage(1);
  };

  const searcher = (data, searchString) => {
    const searchedData = data.filter(function (data) {
      for (var key in data) {
        if (
          !(
            (data["last_name"] + ", " + data["first_name"])
              .toLowerCase()
              .includes(searchString.toLowerCase()) ||
            data["first_name"]
              .toLowerCase()
              .includes(searchString.toLowerCase()) ||
            data["last_name"]
              .toLowerCase()
              .includes(searchString.toLowerCase()) ||
            data["emp_no"].toLowerCase().includes(searchString.toLowerCase())
          )
        ) {
          return false;
        }
        return true;
      }
    });

    return searchedData;
  };

  const handleSort = (columnChanged, currentSort) => {
    let setSort;
    let sorter = [];
    const getColumnValue = (column, value) => {
      switch (column) {
        default:
          return value;
      }
    };

    if (currentSort == "ASC") setSort = "DESC";
    else setSort = "ASC";

    if (columnChanged == "name") {
      sorter = [
        ["last_name", nameSort],
        ["first_name", "ASC"],
        ["emp_no", "ASC"],
      ];

      setNameSort(setSort);
    } else if (columnChanged == "empNo") {
      sorter = [
        ["emp_no", empNoSort],
        ["last_name", "ASC"],
        ["first_name", "ASC"],
      ];

      setEmpNoSort(setSort);
    }

    const sorted = multiColumnSort(filteredData, sorter, getColumnValue);

    setFilteredData(sorted);
  };

  const startIndex = currentPage * recordsPerPage - recordsPerPage;
  const endIndex = startIndex + recordsPerPage;
  const currentData = filteredData.slice(startIndex, endIndex);

  const pages = [];
  for (let i = 1; i <= Math.ceil(data.length / recordsPerPage); i++) {
    pages.push(i);
  }

  return (
    <div className="hk-wrapper hk-vertical-nav">
      <div class="pg-hk-wrapper">
        <Header />
        <div className="mt-20 hk-pg-wrapper">
          {permissions > 0 ? (
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-10 col-md-10 col-sm-12">
                  <h3 className="pb-10">Regular Run Per Employee</h3>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-12">
                  {props.match.params.from == "main" ? (
                    <Link
                      className="btn btn-sm btn-danger float-right "
                      to={{
                        pathname: `/reg_payroll`,
                        query: `/reg_payroll`,
                        search: "?search=" + searchMain,
                      }}
                    >
                      Back to Previous Page
                    </Link>
                  ) : (
                    <Link
                      className="btn btn-sm btn-danger float-right "
                      to={{
                        pathname: `/app`,
                        query: `/app`,
                        search: "?search=" + searchMain,
                      }}
                    >
                      Back to Previous Page
                    </Link>
                  )}
                </div>
              </div>
              <div>
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="card">
                      <div className="card-header card-header-action">
                        <h5>Run Details</h5>
                        <div className="d-flex align-items-center card-action-wrap-tools"></div>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <div className="row">
                              <div className="col-xl-6 col-sm-12 mb-10">
                                <strong>Pay Period ID</strong>
                              </div>
                              <div className="col-xl-6 col-sm-12 mb-10">
                                :&nbsp;&nbsp;&nbsp;
                                {runData.code ? runData.code : ""}
                              </div>
                              <div className="col-xl-6 col-sm-12 mb-10">
                                <strong>Period</strong>
                              </div>
                              <div className="col-xl-6 col-sm-12 mb-10">
                                :&nbsp;&nbsp;&nbsp;
                                {dateFormatter(runData.start_date) +
                                  " to " +
                                  dateFormatter(runData.end_date)}
                              </div>
                              <div className="col-xl-6 col-sm-12 mb-10">
                                <strong>Created By</strong>
                              </div>
                              <div className="col-xl-6 col-sm-12 mb-10">
                                :&nbsp;&nbsp;&nbsp;
                                {runData.created_by}
                              </div>
                              <div className="col-xl-6 col-sm-12 mb-10">
                                <strong>Created On</strong>
                              </div>
                              <div className="col-xl-6 col-sm-12 mb-10">
                                :&nbsp;&nbsp;&nbsp;
                                {dateFormatter(
                                  runData.created_at,
                                  "MMM dd, yyyy hh:mm bb"
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <div className="row">
                              <div className="col-xl-6 col-sm-12 mb-10">
                                <strong>Status</strong>
                              </div>
                              <div className="col-xl-6 col-sm-12 mb-10">
                                :&nbsp;&nbsp;&nbsp;
                                {runData.status == 2 ? (
                                  <strong className="text-success">
                                    {statuses[runData.status]}
                                  </strong>
                                ) : runData.status == 1 ? (
                                  <strong className="text-info">
                                    {statuses[runData.status]}
                                  </strong>
                                ) : (
                                  <strong className="text-warning">
                                    {statuses[runData.status]}
                                  </strong>
                                )}
                              </div>
                              <div className="col-xl-6 col-sm-12 mb-10">
                                <strong>Draft Run Date</strong>
                              </div>
                              <div className="col-xl-6 col-sm-12 mb-10">
                                :&nbsp;&nbsp;&nbsp;
                                {runData.draft_date ? (
                                  <strong className="text-info">
                                    {dateFormatter(
                                      runData.draft_date,
                                      "MMM dd, yyyy"
                                    )}
                                  </strong>
                                ) : (
                                  <strong className="text-danger">
                                    PENDING
                                  </strong>
                                )}
                              </div>
                              <div className="col-xl-6 col-sm-12 mb-10">
                                <strong>Reviewed Date</strong>
                              </div>
                              <div className="col-xl-6 col-sm-12 mb-10">
                                :&nbsp;&nbsp;&nbsp;
                                {runData.review_date ? (
                                  <strong className="text-info">
                                    {dateFormatter(
                                      runData.review_date,
                                      "MMM dd, yyyy"
                                    )}
                                  </strong>
                                ) : (
                                  <strong className="text-danger">
                                    PENDING
                                  </strong>
                                )}
                              </div>
                              <div className="col-xl-6 col-sm-12 mb-10">
                                <strong>Date Posted</strong>
                              </div>
                              <div className="col-xl-6 col-sm-12 mb-10">
                                :&nbsp;&nbsp;&nbsp;
                                {runData.post_date != "PENDING" ? (
                                  <strong className="text-info">
                                    {dateFormatter(
                                      runData.post_date,
                                      "MMM dd, yyyy"
                                    )}
                                  </strong>
                                ) : (
                                  <strong className="text-danger">
                                    PENDING
                                  </strong>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="table-wrap">
                      <ExportPayrollRun data={runData} />
                      <div className="row mb-10">
                        <div className="col-md-6">
                          <label>
                            <select
                              className="custom-select custom-select-sm form-control form-control-sm"
                              onChange={handleRecordPerPage}
                            >
                              <option value="10">10</option>
                              <option value="25">25</option>
                              <option value="50">50</option>
                              <option value="100">100</option>
                            </select>
                          </label>{" "}
                          items
                        </div>
                        <div className="col-md-6">
                          <input
                            type="search"
                            className="form-control form-control-sm col-md-6 col-sm-6 float-right"
                            placeholder="Search"
                            defaultValue={search}
                            onChange={handleSearch}
                          />
                          <br />
                        </div>
                      </div>
                      <div className="table-responsive mb-10">
                        <table className="table table-sm table-bordered table-striped mb-0">
                          <thead className="thead-secondary">
                            <tr className="text-center">
                              <th
                                className="text-nowrap"
                                onClick={() => handleSort("empNo", empNoSort)}
                                style={{ cursor: "pointer" }}
                              >
                                Employee # &nbsp;
                                <i
                                  className={
                                    empNoSort == "ASC"
                                      ? "fa fa-arrow-down"
                                      : "fa fa-arrow-up"
                                  }
                                ></i>
                              </th>
                              <th
                                className="text-nowrap"
                                onClick={() => handleSort("name", nameSort)}
                                style={{ cursor: "pointer" }}
                              >
                                Employee Name &nbsp;
                                <i
                                  className={
                                    nameSort == "ASC"
                                      ? "fa fa-arrow-down"
                                      : "fa fa-arrow-up"
                                  }
                                ></i>
                              </th>
                              <th>Basic</th>
                              <th>De Minimis</th>
                              <th>Other Earnings</th>
                              <th>Non Taxed Earnings</th>
                              <th>Gross Pay</th>
                              <th>SSS Premium</th>
                              <th>SSS Provident</th>
                              <th>PhilHealth</th>
                              <th>PAGIBIG</th>
                              <th>Other Statutories</th>
                              <th>Other Deductions</th>
                              <th>WH Tax</th>
                              <th>Net Pay</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredData.length > 0 ? (
                              currentData.map((cutoff) => (
                                <tr key={"row" + cutoff.bc_id}>
                                  <td>
                                    <Link
                                      to={{
                                        pathname: `/reg_payroll/${cutoff.id}/${cutoff.employee_id}/${runData.paysched_id}/${props.match.params.from}/no`,
                                        query: `/reg_payroll`,
                                        search:
                                          "?search=" +
                                          search +
                                          "&searchMain=" +
                                          searchMain,
                                      }}
                                      className="text-primary"
                                      title={"View Employee Details"}
                                    >
                                      {cutoff.emp_no}
                                    </Link>
                                  </td>
                                  <td className="text-nowrap">
                                    {cutoff.last_name +
                                      ", " +
                                      cutoff.first_name}
                                  </td>
                                  <td className="text-right">
                                    {cutoff.basic
                                      .toLocaleString()
                                      .replace(
                                        /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                        ","
                                      )}
                                  </td>
                                  <td className="text-right">
                                    {cutoff.deminimis
                                      .toLocaleString()
                                      .replace(
                                        /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                        ","
                                      )}
                                  </td>
                                  <td className="text-right">
                                    {(
                                      parseFloat(cutoff.other_earning) +
                                      parseFloat(cutoff.basic_earning) +
                                      parseFloat(cutoff.t_month_basic)
                                    )
                                      .toFixed(2)
                                      .replace(
                                        /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                        ","
                                      )}
                                  </td>
                                  <td className="text-right">
                                    {parseFloat(cutoff.nontax_earning)
                                      .toFixed(2)
                                      .replace(
                                        /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                        ","
                                      )}
                                  </td>
                                  <td className="text-right">
                                    {parseFloat(cutoff.gross)
                                      .toFixed(2)
                                      .replace(
                                        /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                        ","
                                      )}
                                  </td>

                                  <td className="text-right">
                                    {cutoff.sss_premium
                                      .toLocaleString()
                                      .replace(
                                        /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                        ","
                                      )}
                                  </td>
                                  <td className="text-right">
                                    {cutoff.sss_provident
                                      .toLocaleString()
                                      .replace(
                                        /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                        ","
                                      )}
                                  </td>

                                  <td className="text-right">
                                    {cutoff.ph_deduction
                                      .toLocaleString()
                                      .replace(
                                        /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                        ","
                                      )}
                                  </td>
                                  <td className="text-right">
                                    {cutoff.hmdf_deduction
                                      .toLocaleString()
                                      .replace(
                                        /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                        ","
                                      )}
                                  </td>
                                  <td className="text-right">
                                    {cutoff.other_statutory
                                      .toLocaleString()
                                      .replace(
                                        /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                        ","
                                      )}
                                  </td>
                                  <td className="text-right">
                                    {cutoff.other_deductions
                                      .toLocaleString()
                                      .replace(
                                        /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                        ","
                                      )}
                                  </td>
                                  <td className="text-right">
                                    {cutoff.wh_tax
                                      .toLocaleString()
                                      .replace(
                                        /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                        ","
                                      )}
                                  </td>
                                  <td
                                    className={
                                      cutoff.gross / 2 > cutoff.net
                                        ? "text-right text-danger"
                                        : "text-right"
                                    }
                                  >
                                    {parseFloat(cutoff.net)
                                      .toFixed(2)
                                      .replace(
                                        /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                        ","
                                      )}
                                  </td>
                                  <td>
                                    <div className="btn-group">
                                      <Link
                                        to={{
                                          pathname: `/reg_payroll/${cutoff.id}/${cutoff.employee_id}/${runData.paysched_id}/${props.match.params.from}/no`,
                                          query: `/reg_payroll`,
                                          search:
                                            "?search=" +
                                            search +
                                            "&searchMain=" +
                                            searchMain,
                                        }}
                                        className="btn btn-sm btn-outline-primary"
                                        title={"View Employee Details"}
                                      >
                                        <FindInPage />
                                      </Link>
                                    </div>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="12" className="text-center">
                                  No Data to show
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="drew-pagination">
                      {filteredData.length > 0
                        ? "Showing " +
                          (startIndex + 1) +
                          " to " +
                          (endIndex > filteredData.length
                            ? filteredData.length
                            : endIndex) +
                          " of " +
                          filteredData.length
                        : ""}
                      <ul class="pagination ml-2">
                        {pages.map((number) => (
                          <li
                            key={"pager" + number}
                            id={number}
                            className={
                              currentPage == number
                                ? "paginate_button page-item active"
                                : "paginate_button page-item"
                            }
                            onClick={() => setCurrentPage(number)}
                          >
                            <a class="page-link">{number}</a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <h1 className="m-0 text-center">
                Your credentials is not sufficient to access this:
                <br />
              </h1>
              <br />
              <h2 className="text-center">
                Module : Payroll Processing
                <br />
                Access Type : {access[permissions]}
                <br />
                Please contact your Payroll Administrator.
                <br />
                <a className="btn btn-primary btn-sm" href="/#/app">
                  Back to Dashboard
                </a>
              </h2>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
