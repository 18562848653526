import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import { FindInPage, Check, DirectionsRun } from "@material-ui/icons";
import axios from "axios";
import Cookies from "universal-cookie";
import { dateFormatter } from "../../components/DtFormatter";
import ExportPhicContributionEmp from "./ExportPhicContributionEmp";
const statuses = ["FOR PROCESSING", "FOR POSTING", "POSTED", "REVOKED"];

export default function PhicContributionEmp(props) {
  const cookies = new Cookies();
  const ability = cookies.get("ability")[2];
  const [data, setData] = useState([]);
  const [phicMain, setPhicMain] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [filteredData, setFilteredData] = useState([]);
  const [fileLoaded, setFileLoaded] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      let apiLink =
        "api/phic_contribution_emp/" +
        cookies.get("transCompany") +
        "/" +
        props.match.params.employee_id;
      const res = await axios.get(apiLink);

      setData(res.data.phicContrib);
      setFilteredData(res.data.phicContrib);
      setPhicMain(res.data.phicMain);
    };

    if (!fileLoaded) {
      fetchData();
      setFileLoaded(true);
    }
  });

  const handleBack = () => {
    props.history.goBack();
  };

  const handleAddCallback = (editData) => {
    if (editData) {
      setFileLoaded(false);
    }
  };

  const pages = [];
  for (let i = 1; i <= Math.ceil(filteredData.length / recordsPerPage); i++) {
    pages.push(i);
  }

  const handleRecordPerPage = (e) => {
    setRecordsPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const handleSearch = (e) => {
    const filterer = data.filter(function (data) {
      for (var key in data) {
        if (
          !(
            data["emp_no"]
              .toLowerCase()
              .includes(e.target.value.toLowerCase()) ||
            data["first_name"]
              .toLowerCase()
              .includes(e.target.value.toLowerCase()) ||
            data["last_name"]
              .toLowerCase()
              .includes(e.target.value.toLowerCase()) ||
            (data["first_name"] + " " + data["last_name"])
              .toLowerCase()
              .includes(e.target.value.toLowerCase())
          )
        ) {
          return false;
        }
        return true;
      }
    });
    setFilteredData(filterer);
  };

  //   const startIndex = currentPage * recordsPerPage - recordsPerPage;
  //   const endIndex = startIndex + recordsPerPage;
  //   const currentData = filteredData.slice(startIndex, endIndex);

  return (
    <div className="hk-wrapper hk-vertical-nav">
      <div class="pg-hk-wrapper">
        <Header />
        <div className="mt-20 hk-pg-wrapper">
          {ability.permission > 0 ? (
            <div className="container-fluid">
              <div>
                <div className="row">
                  <div className="col-lg-7 col-md-7 col-sm-12">
                    <h3 className="mb-10">PHILHEALTH Contributions (For Employee)</h3>
                  </div>
                  <div className="col-lg-5 col-md-5 col-sm-12">
                    {/* <button
                      className="btn btn-sm btn-danger float-right ml-10"
                      onClick={handleBack}
                    >
                      Back to Previous Page
                    </button> */}
                    <Link
                      to={{
                        pathname: `/phic_contribution`,
                        query: `/phic_contribution`,
                        search: props.location.search
                      }}
                      className="btn btn-sm btn-danger float-right ml-10"
                      title={"View per employee"}
                    >
                      Back to Previous Page
                    </Link>
                    <ExportPhicContributionEmp
                      type={1}
                      employee_id={phicMain.employee_id}
                      employee_name={
                        phicMain.first_name + " " + phicMain.last_name
                      }
                    /> 
                  </div> 
                </div>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="row mb-10">
                          <div className="col-xl-4 col-sm-10">
                            <strong>Employee #</strong>
                          </div>
                          <div className="col-xl-8 col-sm-10">
                            :&nbsp;&nbsp;&nbsp;{phicMain.emp_no}
                          </div>
                        </div>
                        <div className="row mb-10">
                          <div className="col-xl-4 col-sm-10">
                            <strong>Name</strong>
                          </div>
                          <div className="col-xl-8 col-sm-10">
                            :&nbsp;&nbsp;&nbsp;
                            {phicMain.first_name +
                              " " +
                              phicMain.last_name}
                          </div>
                        </div>

                        <div className="row mb-10">
                          <div className="col-xl-4 col-sm-10">
                            <strong>PHILHEALTH #</strong>
                          </div>
                          <div className="col-xl-8 col-sm-10">
                            :&nbsp;&nbsp;&nbsp;{phicMain.philhealth_no}
                          </div>
                        </div>

                        <div className="row mb-10">
                          <div className="col-xl-4 col-sm-10">
                            <strong>Date Period</strong>
                          </div>
                          <div className="col-xl-8 col-sm-10">
                            :&nbsp;&nbsp;&nbsp;
                            {dateFormatter(phicMain.start_date) +
                              " to " +
                              dateFormatter(phicMain.end_date)}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="table-wrap">
                      <div className="row mb-10">
                        <div className="col-md-6">
                          {/* <label>
                            <select
                              className="custom-select custom-select-sm form-control form-control-sm"
                              onChange={handleRecordPerPage}
                            >
                              <option value="10">10</option>
                              <option value="25">25</option>
                              <option value="50">50</option>
                              <option value="100">100</option>
                            </select>
                          </label>{" "}
                          items */}
                        </div>
                        <div className="col-md-6">
                          {/* <input
                            type="search"
                            className="form-control form-control-sm col-md-6 col-sm-6 float-right"
                            placeholder="Search"
                            onChange={handleSearch}
                          /> */}
                        </div>
                      </div>
                      <div className="table-responsive mb-10">
                        <table className="table table-sm table-bordered table-striped mb-0">
                          <thead className="thead-secondary">
                            <tr className="text-center">
                              <th>Date Period</th>
                              <th>Employer Share</th>
                              <th>Employee Share</th>
                              <th>Total Contribution</th>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredData.length > 0 ? (
                              <>
                                {filteredData.map((phic) => (
                                  <tr key={phic.id} className="text-nowrap">
                                    <td className="text-center">
                                      {dateFormatter(phic.start_date) +
                                        " to " +
                                        dateFormatter(phic.end_date)}
                                    </td>
                                    <td className="text-right">
                                      {(parseFloat(phic.amount) * 1)
                                        .toFixed(2)
                                        .replace(
                                          /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                          ","
                                        )}
                                    </td>
                                    <td className="text-right">
                                      {(parseFloat(phic.amount) * 1)
                                        .toFixed(2)
                                        .replace(
                                          /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                          ","
                                        )}
                                    </td>
                                    <td className="text-right">
                                      {(parseFloat(phic.amount * 2) * 1)
                                        .toFixed(2)
                                        .replace(
                                          /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                          ","
                                        )}
                                    </td>
                                  </tr>
                                ))}
                                {phicMain.first_name != "" ? (
                                  <tr>
                                    <td className="text-right" colSpan={3}>
                                      <strong>TOTAL</strong>
                                    </td>
                                    <td className="text-right">
                                      {(parseFloat(phicMain.amount) * 2)
                                        .toFixed(2)
                                        .replace(
                                          /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                          ","
                                        )}
                                    </td>
                                  </tr>
                                ) : (
                                  ""
                                )}
                              </>
                            ) : (
                              <tr>
                                <td colSpan="4" className="text-center">
                                  No Data to show
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {/* <div className="drew-pagination">
                      {filteredData.length > 0
                        ? "Showing " +
                          (startIndex + 1) +
                          " to " +
                          (endIndex > filteredData.length
                            ? filteredData.length
                            : endIndex) +
                          " of " +
                          filteredData.length
                        : ""}
                      <ul class="pagination ml-2">
                        {pages.map((number) => (
                          <li
                            key={number}
                            id={number}
                            className={
                              currentPage == number
                                ? "paginate_button page-item active"
                                : "paginate_button page-item"
                            }
                            onClick={() => setCurrentPage(number)}
                          >
                            <a class="page-link">{number}</a>
                          </li>
                        ))}
                      </ul>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <h1 className="m-0 text-center">
              The Credentials Supplied Are Not Sufficient To Access This Module
              <br />
              Please Contact Administrator
            </h1>
          )}
        </div>
      </div>
    </div>
  );
}
