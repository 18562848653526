import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import Processing from "../../components/Processing";
import { dateFormatter } from "../../components/DtFormatter";
import axios from "axios";
import { Link } from "react-router-dom";
import { ChevronRight, ChevronLeft } from "@material-ui/icons";
import Cookies from "universal-cookie";
const statuses = ["DRAFT", "FOR POSTING", "POSTED"];
const cookies = new Cookies();
const access = ["No Access", "View Only", "Add", "Add / Edit", "Full Access"];

export default function SpecialPayrollDetail(props) {
  const permissions = cookies.get("permissions")["payroll"];
  const [data, setData] = useState([]);
  const [runData, setRunData] = useState([]);
  const [employee, setEmployee] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [filteredData, setFilteredData] = useState([]);
  const [nextData, setNextData] = useState([]);
  const [prevData, setPrevData] = useState([]);
  const [processing, setProcessing] = useState(false);
  useEffect(() => {
    if (props.match.params.next == "yes") {
      setProcessing(true);
    }

    const fetchData = async () => {
      let apiLink =
        "api/special_detail/" +
        props.match.params.id +
        "/" +
        props.match.params.emp_id;
      const res = await axios.get(apiLink);

      setPrevData(res.data.prevEmp);
      setNextData(res.data.nextEmp);
      setData(res.data.specialPays);
      setFilteredData(res.data.specialPays);
      setRunData(res.data.specialPayroll);
      setEmployee(res.data.employee);
    };
    fetchData();

    if (props.match.params.next == "yes") {
      setTimeout(function () {
        setProcessing(false);
      }, 1000);
    }
  }, [props.match.params.emp_id]);

  const handleRecordPerPage = (e) => {
    console.log(e.target.value);
    setRecordsPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const handleSearch = (e) => {
    const filterer = data.filter(function (data) {
      for (var key in data) {
        if (
          !data["ed_name"].toLowerCase().includes(e.target.value.toLowerCase())
        ) {
          return false;
        }
        return true;
      }
    });
    setFilteredData(filterer);
    setCurrentPage(1);
  };

  const startIndex = currentPage * recordsPerPage - recordsPerPage;
  const endIndex = startIndex + recordsPerPage;
  const currentData = filteredData.slice(startIndex, endIndex);

  const pages = [];
  for (let i = 1; i <= Math.ceil(data.length / recordsPerPage); i++) {
    pages.push(i);
  }

  const specialTotal = filteredData.reduce(
    (a, v) => (a = a + parseFloat(v.amount)),
    0
  );

  const whTax = filteredData.reduce(
    (a, v) => (a = a + parseFloat(v.wh_tax)),
    0
  );

  const handleBack = () => {
    this.props.history.goBack();
  };

  return (
    <div className="hk-wrapper hk-vertical-nav">
      <div class="pg-hk-wrapper">
        <Header />
        <Processing show={processing} />
        <div className="mt-20 hk-pg-wrapper">
          {permissions > 0 ? (
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-10 col-md-10 col-sm-12">
                  <h3 className="pb-10">Special Run Per Employee Detail</h3>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-12">
                  <Link
                    to={{
                      pathname: `/spl_payroll/${props.match.params.id}/${props.match.params.from}`,
                      query: `/spl_payroll`,
                      search: props.location.search
                    }}
                    className="btn btn-sm btn-danger float-right"
                    title={"Back to Previous Page"}
                  >
                    Back to Previous Page
                  </Link>
                </div>
              </div>
              <div>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="card">
                      <div className="card-header card-header-action">
                        <h5>Employee Detail</h5>
                        <div className="d-flex align-items-center card-action-wrap-tools"></div>
                      </div>
                      <div className="card-body">
                        <div className="row mb-10">
                          <div className="col-xl-4 col-sm-10">
                            <strong>Employee #</strong>
                          </div>
                          <div className="col-xl-8 col-sm-10">
                            :&nbsp;&nbsp;&nbsp;
                            {employee.emp_no}
                          </div>
                        </div>
                        <div className="row mb-10">
                          <div className="col-xl-4 col-sm-10">
                            <strong>Name</strong>
                          </div>
                          <div className="col-xl-8 col-sm-10">
                            :&nbsp;&nbsp;&nbsp;
                            {employee.last_name + ", " + employee.first_name}
                          </div>
                        </div>

                        <div className="row mb-10">
                          <div className="col-xl-4 col-sm-10">
                            <strong>Run Code</strong>
                          </div>
                          <div className="col-xl-8 col-sm-10">
                            :&nbsp;&nbsp;&nbsp;
                            {runData.code}
                          </div>
                        </div>

                        <div className="row mb-30">
                          <div className="col-xl-4 col-sm-10">
                            <strong>Date Posted</strong>
                          </div>
                          <div className="col-xl-8 col-sm-10">
                            :&nbsp;&nbsp;&nbsp;
                            {runData.post_date ? (
                              dateFormatter(
                                runData.post_date,
                                "MMM dd, yyyy hh:mm bb"
                              )
                            ) : (
                              <strong className="text-danger">PENDING</strong>
                            )}
                          </div>
                        </div>
                        <div className="row mb-10">
                          <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 text-center">
                            {prevData ? (
                              <Link
                                to={{
                                  pathname: `/spl_payroll/${props.match.params.id}/${props.match.params.from}/${prevData.employee_id}/yes`,
                                  query: `/spl_payroll`,
                                }}
                                className="btn btn-sm btn-secondary"
                                title={"View Employee Details"}
                              >
                                <ChevronLeft />
                                Prev Employee
                              </Link>
                            ) : (
                              <button
                                className="btn btn-sm btn-secondary"
                                disabled="disabled"
                              >
                                <ChevronLeft />
                                Prev Employee
                              </button>
                            )}
                          </div>
                          <div className="col-xl-6  col-lg-6 col-md-12 col-sm-12 text-center">
                            {nextData ? (
                              <Link
                                to={{
                                  pathname: `/spl_payroll/${props.match.params.id}/${props.match.params.from}/${nextData.employee_id}/yes`,
                                  query: `/spl_payroll`,
                                }}
                                className="btn btn-sm btn-secondary"
                                title={"View Employee Details"}
                              >
                                Next Employee
                                <ChevronRight />
                              </Link>
                            ) : (
                              <button
                                className="btn btn-sm btn-secondary"
                                disabled="disabled"
                              >
                                Next Employee
                                <ChevronRight />
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="card">
                      <div className="card-header card-header-action">
                        <h5>Totals</h5>
                        <div className="d-flex align-items-center card-action-wrap-tools"></div>
                      </div>
                      <div className="card-body">
                        <div className="row mb-10">
                          <div className="col-xl-6 col-sm-10">
                            <strong>Total Earnings</strong>
                          </div>
                          <div className="col-xl-6 col-sm-10">
                            :&nbsp;&nbsp;&nbsp;
                            <span className="float-right">
                              {specialTotal
                                .toFixed(2)
                                .replace(
                                  /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                  ","
                                )}
                            </span>
                          </div>
                        </div>
                        <div className="row mb-10">
                          <div className="col-xl-6 col-sm-10">
                            <strong>Total Deductions</strong>
                          </div>
                          <div className="col-xl-6 col-sm-10">
                            :&nbsp;&nbsp;&nbsp;
                            <span className="float-right">0.00</span>
                          </div>
                        </div>
                        <div className="row mb-10">
                          <div className="col-xl-6 col-sm-10">
                            <strong>Total Statutories</strong>
                          </div>
                          <div className="col-xl-6 col-sm-10">
                            :&nbsp;&nbsp;&nbsp;
                            <span className="float-right">
                              {whTax
                                .toFixed(2)
                                .replace(
                                  /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                  ","
                                )}
                            </span>
                          </div>
                        </div>
                        <div className="row mb-10">
                          <div className="col-xl-6 col-sm-10">
                            <strong></strong>
                          </div>
                          <div className="col-xl-6 col-sm-10">
                            &nbsp;&nbsp;&nbsp;
                            <span className="float-right"></span>
                          </div>
                        </div>

                        <hr />
                        <div className="row mb-10">
                          <div className="col-xl-6 col-sm-10">
                            <strong>Net Pay</strong>
                          </div>
                          <div className="col-xl-6 col-sm-10">
                            :&nbsp;&nbsp;&nbsp;
                            <span className="float-right">
                              {(specialTotal - whTax)
                                .toFixed(2)
                                .replace(
                                  /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                  ","
                                )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className="col-lg-4 col-md-4 col-sm-12">
                    <div className="card">
                      <div className="card-header card-header-action">
                        <h5>YTD</h5>
                        <div className="d-flex align-items-center card-action-wrap-tools"></div>
                      </div>
                      <div className="card-body">
                        <div className="row mb-10">
                          <div className="col-xl-6 col-sm-10">
                            <strong>YTD Earning</strong>
                          </div>
                          <div className="col-xl-6 col-sm-10">
                            :&nbsp;&nbsp;&nbsp;
                            <span className="float-right">
                              {specialTotal
                                .toFixed(2)
                                .replace(
                                  /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                  ","
                                )}
                            </span>
                          </div>
                        </div>
                        <div className="row mb-10">
                          <div className="col-xl-6 col-sm-10">
                            <strong>YTD Deduction</strong>
                          </div>
                          <div className="col-xl-6 col-sm-10">
                            :&nbsp;&nbsp;&nbsp;
                            <span className="float-right">0.00</span>
                          </div>
                        </div>
                        <div className="row mb-10">
                          <div className="col-xl-6 col-sm-10">
                            <strong>YTD WH Tax</strong>
                          </div>
                          <div className="col-xl-6 col-sm-10">
                            :&nbsp;&nbsp;&nbsp;
                            <span className="float-right">
                              {whTax
                                .toFixed(2)
                                .replace(
                                  /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                  ","
                                )}
                            </span>
                          </div>
                        </div>

                        <hr />
                        <div className="row mb-10">
                          <div className="col-xl-6 col-sm-10">
                            <strong>YTD Net Pay</strong>
                          </div>
                          <div className="col-xl-6 col-sm-10">
                            :&nbsp;&nbsp;&nbsp;
                            <span className="float-right">
                              {(specialTotal-whTax)
                                .toFixed(2)
                                .replace(
                                  /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                  ","
                                )}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                </div>
                <div className="row">
                  <div className="col-lg-4 col-md-12 col-sm-12">
                    <div className="table-wrap">
                      {/* <div className="row mb-10">
                        <div className="col-md-6">
                        </div>
                        <div className="col-md-6">
                          <input
                            type="search"
                            className="form-control form-control-sm col-md-6 col-sm-6 float-right"
                            placeholder="Search"
                            onChange={handleSearch}
                          />
                        </div>
                      </div> */}
                      <div className="table-responsive mb-10">
                        <table className="table table-sm table-bordered table-striped mb-0">
                          <thead className="thead-success">
                            <tr className="text-center">
                              <th>Earnings</th>
                              <th>Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredData.map((cutoff) => (
                              <tr key={cutoff.id}>
                                <td>{cutoff.ed_name}{cutoff.is_taxable == 0 ?("(Non Taxed)"):("")}</td>
                                <td className="text-right">
                                  {cutoff.amount
                                    .toLocaleString()
                                    .replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                </td>
                              </tr>
                            ))}
                            <tr className="text-right">
                              <td>
                                <strong>Total Earning: </strong>
                              </td>
                              <td>
                                <strong>
                                  {specialTotal
                                    .toFixed(2)
                                    .replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                </strong>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-12 col-sm-12">
                    <div className="table-wrap">
                      {/* <div className="row mb-10">
                        <div className="col-md-6">
                        </div>
                        <div className="col-md-6">
                          <input
                            type="search"
                            className="form-control form-control-sm col-md-6 col-sm-6 float-right"
                            placeholder="Search"
                            onChange={handleSearch}
                          />
                        </div>
                      </div> */}
                      <div className="table-responsive mb-10">
                        <table className="table table-sm table-bordered table-striped mb-0">
                          <thead className="thead-danger">
                            <tr className="text-center">
                              <th>Deductions</th>
                              <th>Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className="text-right">
                              <td>
                                <strong>Total Deduction: </strong>
                              </td>
                              <td>
                                <strong>0.00</strong>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <div className="col-lg-4 col-md-12 col-sm-12">
                    <div className="table-wrap">
                      {/* <div className="row mb-10">
                        <div className="col-md-6">
                        </div>
                        <div className="col-md-6">
                          <input
                            type="search"
                            className="form-control form-control-sm col-md-6 col-sm-6 float-right"
                            placeholder="Search"
                            onChange={handleSearch}
                          />
                        </div>
                      </div> */}
                      <div className="table-responsive mb-10">
                        <table className="table table-sm table-bordered table-striped mb-0">
                          <thead className="thead-warning">
                            <tr className="text-center">
                              <th>Statutories</th>
                              <th>Amount</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>WH Tax</td>
                              <td className="text-right">
                                {whTax
                                  .toFixed(2)
                                  .replace(
                                    /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                    ","
                                  )}
                              </td>
                            </tr>
                            <tr className="text-right">
                              <td>
                                <strong>Total Statutory: </strong>
                              </td>
                              <td>
                                <strong>
                                  {whTax
                                    .toFixed(2)
                                    .replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                </strong>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <h1 className="m-0 text-center">
                Your credentials is not sufficient to access this:
                <br />
              </h1>
              <br />
              <h2 className="text-center">
                Module : Payroll Processing
                <br />
                Access Type : {access[permissions]}
                <br />
                Please contact your Payroll Administrator.
                <br />
                <a className="btn btn-primary btn-sm" href="/#/app">
                  Back to Dashboard
                </a>
              </h2>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
