import React, { Component } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { timeFormatter, dateFormatter } from "../../components/DtFormatter";
import Header from "../../components/Header";
import axios from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();
const textColor = ["success", "warning", "danger", "danger"];

class TransactionUploadDetail extends Component {
  state = {
    ability: cookies.get("ability")[0],
    transactionData: [],
    detailData: [],
  };
  constructor() {
    super();
    this.handleBack = this.handleBack.bind(this);
  }

  componentDidMount() {
    this.getDetails();
  }

  componentDidUpdate() {
    if (this.state.shiftEdited) {
      this.getDetails();
      this.setState({ shiftEdited: false });
    }
  }

  getDetails = () => {
    const TransactionId = this.props.match.params.id;
    axios.get("api/transaction_upload_single/" + TransactionId).then((res) => {
      this.setState({ transactionData: res.data[0] });
    });

    axios.get("api/transaction_upload_detail/" + TransactionId).then((res) => {
      this.setState({ detailData: res.data });
    });
  };

  handleBack() {
    this.props.history.goBack();
  }

  handleEditShiftCallback = (cb) => {
    if (cb) {
      this.setState({ shiftEdited: true });
    }
  };

  render() {
    return (
      <div className="hk-wrapper hk-vertical-nav">
        <div class="pg-hk-wrapper">
          <Header />
          <div className="mt-20 hk-pg-wrapper">
            {this.state.ability.permission > 0 ? (
              <div className="container-fluid">
                <div className="row mb-10">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <h3>Transaction Upload Detail</h3>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12">
                    {/* <button
                      className="btn btn-sm btn-danger float-right "
                      onClick={this.handleBack}
                    >
                      Back to Previous Page
                    </button> */}
                    <Link
                      to={{
                        pathname: `/transaction_upload`,
                        query: `/transaction_upload`,
                        search: "?search="+this.props.location.search.split("=").pop()
                      }}
                      className="btn btn-sm btn-danger float-right "
                      title={"View Transaction Details"}
                    >
                      Back to Previous Page
                    </Link>
                  </div>
                  
                </div>
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="card">
                      <div className="card-header card-header-action">
                        <h5></h5>
                        <div className="d-flex align-items-center card-action-wrap-tools"></div>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <div className="row">
                              <div className="col-xl-6 col-sm-12 mb-10">
                                <strong>Description</strong>
                              </div>
                              <div className="col-xl-6 col-sm-12 mb-10">
                                :&nbsp;&nbsp;&nbsp;
                                {this.state.transactionData.description}
                              </div>
                              <div className="col-xl-6 col-sm-12 mb-10">
                                <strong>Uploaded by</strong>
                              </div>
                              <div className="col-xl-6 col-sm-12 mb-10">
                                :&nbsp;&nbsp;&nbsp;
                                {this.state.transactionData.created_by}
                              </div>
                              <div className="col-xl-6 col-sm-12 mb-10">
                                <strong>Uploaded On</strong>
                              </div>
                              <div className="col-xl-6 col-sm-12 mb-10">
                                :&nbsp;&nbsp;&nbsp;
                                {dateFormatter(
                                  this.state.transactionData.created_at,
                                  "MMM dd, yyyy hh:mm bb"
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <div className="row">
                              <div className="col-xl-6 col-sm-12 mb-10">
                                <strong>File</strong>
                              </div>
                              <div className="col-xl-6 col-sm-12 mb-10">
                                :&nbsp;&nbsp;&nbsp;
                                <a
                                  href={
                                    process.env.REACT_APP_API_LINK +
                                    "transactionUpload/" +
                                    this.state.transactionData.file_name
                                  }
                                  target="_blank"
                                  download
                                >
                                  {this.state.transactionData.file_name}
                                </a>
                              </div>
                              <div className="col-xl-6 col-sm-12 mb-10">
                                <strong>Updated by</strong>
                              </div>
                              <div className="col-xl-6 col-sm-12 mb-10">
                                :&nbsp;&nbsp;&nbsp;
                                {this.state.transactionData.updated_by}
                              </div>
                              <div className="col-xl-6 col-sm-12">
                                <strong>Updated On</strong>
                              </div>
                              <div className="col-xl-6 col-sm-12">
                                :&nbsp;&nbsp;&nbsp;
                                {dateFormatter(
                                  this.state.transactionData.updated_at,
                                  "MMM dd, yyyy hh:mm bb"
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 col-sm-12">
                    <div class="table-responsive">
                      <table class="table table-sm table-bordered table-striped mb-0">
                        <thead className="thead-secondary">
                          <tr className="text-center">
                            <th>Employee #</th>
                            <th>Name</th>
                            <th>Earning/Deduction</th>
                            <th>Pay Period Release</th>
                            <th>Total Amount</th>
                            <th>
                              Per Payment
                              <br />
                              Period
                              <br />
                              Amount
                            </th>
                            <th>Transaction Period</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.detailData.length > 0 ? (
                            this.state.detailData.map((detail) => (
                              <tr key={detail.id}>
                                <td>
                                  {detail.emp_no}
                                </td>
                                <td>
                                  {detail.last_name + ", " + detail.first_name}
                                </td>
                                <td>
                                  {detail.ed_code + " - " + detail.ed_name}
                                </td>
                                <td>{detail.pay_cycle_name}</td>
                                <td className="text-right">
                                  {detail.amount
                                    .toLocaleString()
                                    .replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                </td>
                                <td className="text-right">
                                  {detail.per_payment
                                    .toLocaleString()
                                    .replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                </td>
                                <td>
                                  {detail.ps_from_code +
                                    " to " +
                                    detail.ps_to_code}
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colspan="6" className="text-center">
                                No Data to show
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <h1 className="m-0 text-center">
                The Credentials Supplied Are Not Sufficient To Access This
                Module
                <br />
                Please Contact Administrator
              </h1>
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(TransactionUploadDetail);
