import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { Delete, Close, Check } from "@material-ui/icons";
import axios from "axios";

export default class DeleteUser extends Component {
  constructor(props) {
    super();
    this.state = {
      showDeleteModal: false,
      disableSubmit: false,
    };
    this.handleshowDeleteModal = this.handleshowDeleteModal.bind(this);
  }

  handleshowDeleteModal = (e) => {
    this.setState({ showDeleteModal: !this.state.showDeleteModal });
  };

  handleSubmit = (e) => {
    e.preventDefault();
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ disableSubmit: true });
    axios
      .put("api/delete_user/" + this.props.id, { status: this.props.status })
      .then((res) => {
        this.props.parentCallback(true);
        this.setState({ showDeleteModal: false, disableSubmit: false });
      });
  };

  render() {
    return (
      <>
        {this.props.type == 1 ? (
          <div
            className="dropdown-item"
            title={
              this.props.status == "ACTIVE"
                ? "Deactivate User"
                : "Activate User"
            }
            onClick={this.handleshowDeleteModal}
          >
            {this.props.status == "ACTIVE" ? <><Close className="text-danger"/> Deactivate User</> : <><Check className="text-success"/> Activate User</>} 
          </div>
        ) : (
          <button
            className={
              this.props.status == "ACTIVE"
                ? "btn btn-outline-danger btn-sm float-right"
                : "btn btn-outline-success btn-sm float-right"
            }
            title={
              this.props.status == "ACTIVE"
                ? "Deactivate User"
                : "Activate User"
            }
            onClick={this.handleshowDeleteModal}
          >
            {this.props.status == "ACTIVE" ? <Close /> : <Check />}
          </button>
        )}

        <Modal
          size="md"
          backdrop="static"
          show={this.state.showDeleteModal}
          onHide={() =>
            this.setState({ showDeleteModal: !this.state.showDeleteModal })
          }
        >
          <Modal.Header closeButton>
            <h4>
              {this.props.status == "ACTIVE"
                ? "Deactivate User"
                : "Activate User"}{" "}
              : {this.props.name}
            </h4>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={this.handleSubmit} id="contact-form">
              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  Are you sure?
                </div>
              </div>
              <div className="float-right">
                <button
                  type="submit"
                  className="btn btn-primary btn-sm mr-2"
                  disabled={this.state.disableSubmit}
                >
                  {this.props.status == "ACTIVE" ? "Deactivate" : "Activate"}
                </button>
                <button
                  onClick={this.handleshowDeleteModal}
                  className="btn btn-danger btn-sm"
                  type="button"
                >
                  Cancel
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
