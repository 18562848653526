import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { dateFormatter } from "../../../components/DtFormatter";
import AddRoles from "./AddRoles";
import EditRoles from "./EditRoles";
import Cookies from "universal-cookie";
const cookies = new Cookies();
const permission = [
  "No Access",
  "View Only",
  "Add",
  "Add / Edit",
  "Full Access",
];
const textColor = ["danger", "success", "success", "danger", "info"];

export default function Roles() {
  const permissions = cookies.get("permissions")["user_management"];
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [filteredData, setFilteredData] = useState([]);
  const [fileLoaded, setFileLoaded] = useState(false);
  const [search, setSearch] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      let apiLink = "api/roles/"+cookies.get('uid');
      const res = await axios.get(apiLink);
      setData(res.data);
      const filterer = searcher(res.data, search);
      setFilteredData(filterer);
    };

    if (!fileLoaded) {
      fetchData();
      setFileLoaded(true);
    }
  });

  const handleAddCallback = (editData) => {
    if (editData) {
      setFileLoaded(false);
    }
  };

  const pages = [];
  for (let i = 1; i <= Math.ceil(filteredData.length / recordsPerPage); i++) {
    pages.push(i);
  }

  const handleRecordPerPage = (e) => {
    console.log(e.target.value);
    setRecordsPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const handleSearch = (e) => {
    const filterer = searcher(data, e.target.value);
    setFilteredData(filterer);
    setSearch(e.target.value);
    setCurrentPage(1);
  };

  const searcher = (data, searchString) => {
    const searchedData = data.filter(function (data) {
      for (var key in data) {
        if (
          !(
            data["name"].toLowerCase().includes(searchString.toLowerCase()) 
          )
        ) {
          return false;
        }
        return true;
      }
    });

    return searchedData;
  };

  const startIndex = currentPage * recordsPerPage - recordsPerPage;
  const endIndex = startIndex + recordsPerPage;
  const currentData = filteredData.slice(startIndex, endIndex);

  return (
    <div>
      <div className="row mb-3">
        <div className="col-lg-12 col-md-12 col-sm-12">
          {permissions > 1 ? (
            <AddRoles parentCallback={handleAddCallback} />
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="table-wrap">
            <div className="row mb-10">
              <div className="col-md-6">
                <label>
                  <select
                    className="custom-select custom-select-sm form-control form-control-sm"
                    onChange={handleRecordPerPage}
                  >
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </label>{" "}
                items
              </div>
              <div className="col-md-6">
                <input
                  type="search"
                  className="form-control form-control-sm col-md-6 col-sm-6 float-right"
                  placeholder="Search"
                  onChange={handleSearch}
                />
              </div>
            </div>
            <div className="table-responsive mb-10">
              <table className="table table-sm table-bordered table-striped mb-0">
                <thead className="thead-secondary">
                  <tr className="text-center">
                    <th>Name</th>
                    <th>Status</th>
                    <th>Timesheets</th>
                    <th>Transactions</th>
                    <th>
                      Payroll
                      <br />
                      Processing
                    </th>
                    <th>
                      YTD
                      <br />
                      Adjustments
                    </th>
                    <th>
                      Master
                      <br />
                      Data
                    </th>
                    <th>
                      User
                      <br />
                      Management
                    </th>
                    <th>Configuration</th>
                    <th>Reports</th>
                    <th>Last Updated/Updated By</th>
                    <th style={{ width: "100px" }}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData.length > 0 ? (
                    currentData.map((role) => (
                      <tr key={role.id} className="text-center text-nowrap">
                        <td>
                          <button className="btn btn-default text-success">
                            {permissions > 2 ? (
                              role.id == 0 ? (
                                role.name
                              ) : (
                                <EditRoles
                                  buttonType={0}
                                  role={role}
                                  parentCallback={handleAddCallback}
                                />
                              )
                            ) : (
                              role.name
                            )}
                          </button>
                        </td>
                        <td>
                          <strong className={"text-" + textColor[role.status]}>
                            {role.status_name}
                          </strong>
                        </td>
                        <td>{permission[role.timesheet]}</td>
                        <td>{permission[role.transaction]}</td>
                        <td>{permission[role.payroll]}</td>
                        <td>{permission[role.ytd]}</td>
                        <td>{permission[role.master_data]}</td>
                        <td>{permission[role.user_management]}</td>
                        <td>{permission[role.config]}</td>
                        <td>{permission[role.report]}</td>
                        <td>
                          {dateFormatter(
                            role.updated_at,
                            "MMM dd, yyyy hh:mm aa"
                          )}
                          <br />
                          {role.updated_by}
                        </td>
                        <td>
                          <div className="btn-group">
                            {permissions > 2 ? (
                              role.id == 0 ? (
                                ""
                              ) : (
                                <EditRoles
                                  buttonType={1}
                                  role={role}
                                  parentCallback={handleAddCallback}
                                />
                              )
                            ) : (
                              ""
                            )}
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="12" className="text-center">
                        No Data to show
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="drew-pagination">
            {filteredData.length > 0
              ? "Showing " +
                (startIndex + 1) +
                " to " +
                (endIndex > filteredData.length
                  ? filteredData.length
                  : endIndex) +
                " of " +
                filteredData.length
              : ""}
            <ul class="pagination ml-2">
              {pages.map((number) => (
                <li
                  key={number}
                  id={number}
                  className={
                    currentPage == number
                      ? "paginate_button page-item active"
                      : "paginate_button page-item"
                  }
                  onClick={() => setCurrentPage(number)}
                >
                  <a class="page-link">{number}</a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
