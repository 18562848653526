import React, { Component } from "react";
import { timeFormatter } from "../../components/DtFormatter";
import { Modal } from "react-bootstrap";
import { Edit } from "@material-ui/icons";
import axios from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();

export default class EditBank extends Component {
  constructor(props) {
    super();
    this.state = {
      updated_by: cookies.get('email'),
      showEditModal: false,
      hasError: false,
    };
    this.handleshowEditModal = this.handleshowEditModal.bind(this);
  }

  handleshowEditModal = (e) => {
    this.setState({ showEditModal: !this.state.showEditModal });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    axios.put("api/update_bank/" + this.props.bankData.id, this.state).then((res) => {
      this.props.parentCallback(true);
      this.setState({ showEditModal: false });
    }).catch((err) => {
      this.setState({ disableAdd: false, hasError: true });
      console.log(err);
    });
  };

  render() {
    return (
      <>
        <button
          className="btn btn-outline-success btn-sm"
          title="Edit Bank"
          onClick={this.handleshowEditModal}
        >
          <Edit />
        </button>
        <Modal
          size="md"
          backdrop="static"
          show={this.state.showEditModal}
          onHide={() =>
            this.setState({ showEditModal: !this.state.showEditModal })
          }
        >
          <Modal.Header closeButton>
            <h4>Edit Bank</h4>
          </Modal.Header>
          <Modal.Body>
          <div className="row">
              <div className="col-md-12 col-sm-12">
                <div
                  className={
                    this.state.hasError
                      ? "alert alert-danger"
                      : "alert alert-danger d-none"
                  }
                  role="alert"
                >
                  Bank code already existing.
                </div>
              </div>
            </div>
            <form onSubmit={this.handleSubmit} id="contact-form">
              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  <label>Code</label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    onChange={(e) => this.setState({ code: e.target.value })}
                    defaultValue={this.props.bankData.code}
                  />
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  <label>Name</label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    onChange={(e) => this.setState({ name: e.target.value })}
                    defaultValue={this.props.bankData.name}
                  />
                </div>
              </div>
              
              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  <label>Contact Person</label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    onChange={(e) => this.setState({ contact_person: e.target.value })}
                    defaultValue={this.props.bankData.contact_person}
                  />
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  <label>Contact #</label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    onChange={(e) => this.setState({ contact_no: e.target.value })}
                    defaultValue={this.props.bankData.contact_no}
                  />
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  <label>Contact Email</label>
                  <input
                    type="email"
                    className="form-control"
                    required
                    onChange={(e) => this.setState({ contact_email: e.target.value })}
                    defaultValue={this.props.bankData.contact_email}
                  />
                </div>
              </div>

              <div className="float-right">
                <button type="submit" className="btn btn-primary btn-sm mr-2">
                  Save
                </button>
                <button
                  onClick={this.handleshowEditModal}
                  className="btn btn-danger btn-sm"
                  type="button"
                >
                  Cancel
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
