import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import axios from "axios";
import { dateFormatter } from "../../components/DtFormatter";
import { Link } from "react-router-dom";
import { FindInPage, Add } from "@material-ui/icons";
import Cookies from "universal-cookie";
const cookies = new Cookies();
const access = ["No Access", "View Only", "Add", "Add / Edit", "Full Access"];

export default function YtdPrDetail(props) {
  const permissions = cookies.get("permissions")["report"];
  const [data, setData] = useState([]);
  const [mainYtd, setMainYtd] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [filteredData, setFilteredData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      let apiLink =
        "api/ytd_ps_detail/" +
        cookies.get("transCompany") +
        "/" +
        props.match.params.year +
        "/" +
        props.match.params.paysched_id;
      const res = await axios.get(apiLink);

      setData(res.data.ytdData);
      setFilteredData(res.data.ytdData);
      setMainYtd(res.data.mainYtd);
    };

    fetchData();
  }, []);

  const handleRecordPerPage = (e) => {
    console.log(e.target.value);
    setRecordsPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const handleBack = () => {
    props.history.goBack();
  };

  const handleSearch = (e) => {
    const filterer = data.filter(function (data) {
      for (var key in data) {
        if (
          !(
            data["first_name"]
              .toLowerCase()
              .includes(e.target.value.toLowerCase()) ||
            data["last_name"]
              .toLowerCase()
              .includes(e.target.value.toLowerCase())
          )
        ) {
          return false;
        }
        return true;
      }
    });
    setFilteredData(filterer);
    setCurrentPage(1);
  };

  const startIndex = currentPage * recordsPerPage - recordsPerPage;
  const endIndex = startIndex + recordsPerPage;
  const currentData = filteredData.slice(startIndex, endIndex);

  const pages = [];
  for (let i = 1; i <= Math.ceil(filteredData.length / recordsPerPage); i++) {
    pages.push(i);
  }
  return (
    <div className="hk-wrapper hk-vertical-nav">
      <div class="pg-hk-wrapper">
        <Header />
        <div className="mt-20 hk-pg-wrapper">
          {permissions > 0 ? (
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-10 col-md-10 col-sm-12">
                  <h3 className="pb-10">Accounting YTD Ledger Details</h3>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-12">
                  <button
                    className="btn btn-sm btn-danger float-right "
                    onClick={handleBack}
                  >
                    Back to Previous Page
                  </button>
                </div>
              </div>
              <div>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="card">
                      {/* <div className="card-header card-header-action">
                    <h5></h5>
                    <div className="d-flex align-items-center card-action-wrap-tools">
                    </div>
                  </div> */}
                      <div className="card-body">
                        <div className="row mb-10">
                          <div className="col-xl-4 col-sm-10">
                            <strong>YTD Year</strong>
                          </div>
                          <div className="col-xl-8 col-sm-10">
                            :&nbsp;&nbsp;&nbsp;
                            {props.match.params.year}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xl-4 col-sm-10">
                            <strong>Pay Period ID</strong>
                          </div>
                          <div className="col-xl-8 col-sm-10">
                            :&nbsp;&nbsp;&nbsp;
                            {mainYtd.ps_code}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="table-wrap">
                      <div className="row mb-10">
                        <div className="col-md-6"></div>
                        <div className="col-md-6">
                          {/* <input
                            type="search"
                            className="form-control form-control-sm col-md-6 col-sm-6 float-right"
                            placeholder="Search"
                            onChange={handleSearch}
                          /> */}
                        </div>
                      </div>
                      <div className="table-responsive mb-10">
                        <table className="table table-sm table-bordered table-striped mb-0">
                          <thead className="thead-secondary">
                            <tr className="text-center">
                              <th>Transaction Type</th>
                              <th className="text-nowrap">Employee #</th>
                              <th className="text-nowrap">Name</th>
                              <th>Date Posted</th>
                              <th>Basic</th>
                              <th className="text-nowrap">De Minimis</th>
                              <th className="text-nowrap">
                                O.Earnings
                                <br />
                                (Tax Exempt)
                              </th>
                              <th>O.Earnings</th>
                              <th className="text-nowrap">
                                Non-Taxed
                                <br />
                                Earnings
                              </th>
                              <th>13th Month Pay</th>
                              <th>Gross Pay</th>
                              <th>SSS Premium</th>
                              <th>SSS Provident</th>
                              <th>PhilHealth</th>
                              <th>PAGIBIG</th>
                              <th>O.Deduction</th>
                              <th>WH Tax</th>
                              <th>Net Pay</th>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredData.length > 0 ? (
                              filteredData.map((cutoff) => (
                                <tr key={cutoff.id}>
                                  <td className="text-nowrap">{cutoff.type}</td>
                                  <td>{cutoff.emp_no}</td>
                                  <td className="text-nowrap">
                                    {cutoff.last_name +
                                      ", " +
                                      cutoff.first_name}
                                  </td>
                                  <td className="text-nowrap">
                                    {dateFormatter(
                                      cutoff.post_date,
                                      "MMM dd, yyyy hh:mm bb"
                                    )}
                                  </td>
                                  <td className="text-right">
                                    {cutoff.basic_pay.replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                  </td>
                                  <td className="text-right">
                                    {cutoff.deminimis.replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                  </td>
                                  <td className="text-right">
                                    {cutoff.other_earning_nt.replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                  </td>
                                  <td className="text-right">
                                    {cutoff.other_earning.replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                  </td>
                                  <td className="text-right">
                                    {cutoff.non_tax_earning.replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                  </td>
                                  <td className="text-right">
                                    {cutoff.t_month_pay_total.replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                  </td>

                                  <td className="text-right">
                                    {cutoff.gross_pay.replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                  </td>
                                  <td className="text-right">
                                    {cutoff.sss_premium.replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                  </td>
                                  <td className="text-right">
                                    {cutoff.sss_provident.replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                  </td>
                                  <td className="text-right">
                                    {cutoff.philhealth.replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                  </td>
                                  <td className="text-right">
                                    {cutoff.pagibig.replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                  </td>

                                  <td className="text-right">
                                    {cutoff.other_deduction.replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                  </td>
                                  <td className="text-right">
                                    {cutoff.wh_tax.replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                  </td>

                                  <td className="text-right">
                                    {cutoff.net_pay.replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="11" className="text-center">
                                  No Data to show
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <h1 className="m-0 text-center">
                Your credentials is not sufficient to access this:
                <br />
              </h1>
              <br />
              <h2 className="text-center">
                Module : Reports
                <br />
                Access Type : {access[permissions]}
                <br />
                Please contact your Payroll Administrator.
                <br />
                <a className="btn btn-primary btn-sm" href="/#/app">
                  Back to Dashboard
                </a>
              </h2>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
