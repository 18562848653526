import axios from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();
// const domain = 'payroll.my247i.com'
// const domain = 'payrolldev.my247i.com'
// const domain = 'payroll.hermosabright.com'
// const domain = 'devpayroll.hermosabright.com'
// const domain = 'payroll.uplines.net'
// const domain = 'devpayroll.uplines.net'
// const domain = 'otc.my247i.com'
// const domain = 'localhost'
const domain = process.env.REACT_APP_DOMAIN;


class Auth {
  constructor() {
    this.authenticated = false;
    this.errors = "";
    if (cookies.get("token")) {
      this.authenticated = true;
    }
  }

  login(creds) {
    axios.get("sanctum/csrf-cookie").then(() => {
      axios
        .post("api/login", creds)
        .then((res) => {
          this.authenticated = true;
          let expTime = new Date(Date.now() + 7200000);
          cookies.set("token", res.data.token, {
            path: "/",
            domain: domain,
            expires: expTime,
          });
          cookies.set("name", res.data.user.name, {
            path: "/",
            domain: domain,
            expires: expTime,
          });
          cookies.set("uid", res.data.user.id, {
            path: "/",
            domain: domain,
            expires: expTime,
          });
          cookies.set("email", res.data.user.email, {
            path: "/",
            domain: domain,
            expires: expTime,
          });
          cookies.set("ability", res.data.ability, {
            path: "/",
            domain: domain,
            expires: expTime,
          });
          cookies.set("user_companies", res.data.user_companies, {
            path: "/",
            domain: domain,
          });
          cookies.set("permissions", res.data.permissions, {
            path: "/",
            domain: domain,
          });
          cookies.set("setting", res.data.setting, {
            path: "/",
            domain: domain,
          });
          cookies.set("load", "0");
          cookies.remove("badCreds", { path: "/", domain: domain });

          window.location.reload();
        })
        .catch((err) => {
          this.authenticated = false;
          this.errors = "invalid";
          let expTime = new Date(Date.now() + 7200000);
          cookies.set("badCreds", "Invalid Email or Password", {
            expires: expTime,
          });
        });
    });

    return this.errors;
  }

  logout() {
    axios.get("sanctum/csrf-cookie").then(() => {
      axios.post("api/logout").then((res) => {
        this.authenticated = false;
        cookies.remove("token", { path: "/", domain: domain });
        cookies.remove("setting", { path: "/", domain: domain });
        cookies.remove("name", { path: "/", domain: domain });
        cookies.remove("uid", { path: "/", domain: domain });
        cookies.remove("email", { path: "/", domain: domain });
        cookies.remove("ability", { path: "/", domain: domain });
        cookies.remove("user_companies", { path: "/", domain: domain });
        cookies.remove("permissions", { path: "/", domain: domain });
        cookies.remove("badCreds", { path: "/", domain: domain });
        cookies.remove("logo", { path: "/", domain: domain });
        cookies.remove("transPay", { path: "/", domain: domain });
        cookies.remove("transText", { path: "/", domain: domain });
        cookies.remove("transCompany", { path: "/", domain: domain });
        window.location.reload();
      });
    });
  }

  auto_logout() {
    axios.get("sanctum/csrf-cookie").then(() => {
      axios.post("api/logout").then((res) => {
        this.authenticated = false;
        cookies.remove("token", { path: "/", domain: domain });
        cookies.remove("name", { path: "/", domain: domain });
        cookies.remove("uid", { path: "/", domain: domain });
        cookies.remove("email", { path: "/", domain: domain });
        cookies.remove("ability", { path: "/", domain: domain });
        cookies.remove("user_companies", { path: "/", domain: domain });
        cookies.remove("permissions", { path: "/", domain: domain });
        cookies.remove("badCreds", { path: "/", domain: domain });

        window.location.replace("/#/sess_expired");
      });
    });
  }

  isAuthenticated() {
    return this.authenticated;
  }

  checklogin() {
    if (cookies.get("token")) {
    } else {
      window.location.replace("/#/sess_expired");
    }
  }
}

export default new Auth();
