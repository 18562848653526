import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { Add } from "@material-ui/icons";
import axios from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();
const d = new Date();

export default class AddHoliday extends Component {
  constructor(props) {
    super();
    this.state = {
      showAddModal: false,
      holiday_type: 1,
      hasError: false,
      disableSubmit: false,
    };
    this.handleshowAddModal = this.handleshowAddModal.bind(this);
  }

  handleshowAddModal = (e) => {
    this.setState({ showAddModal: !this.state.showAddModal, hasError: false });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ disableSubmit: true });
    let companyId = this.props.companyId;
    let data = {
      company_id: companyId,
      name: this.state.name,
      holiday_type: this.state.holiday_type,
      holiday_date: this.state.holiday_date,
      updated_by: cookies.get("email"),
      created_by: cookies.get("email"),
    };
    axios
      .post("api/holiday", data)
      .then((res) => {
        this.props.parentCallback(true);
        this.setState({ showAddModal: false, disableSubmit: false });
      })
      .catch((err) => {
        this.setState({ disableSubmit: false, hasError: true });
      });
  };

  render() {
    return (
      <>
        <button
          className="btn btn-outline-primary btn-sm float-right"
          title="Add New Holiday"
          onClick={this.handleshowAddModal}
        >
          <Add /> New Holiday
        </button>
        <Modal
          size="md"
          backdrop="static"
          show={this.state.showAddModal}
          onHide={() =>
            this.setState({ showAddModal: !this.state.showAddModal })
          }
        >
          <Modal.Header closeButton>
            <h4>Add New Holiday</h4>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <div
                  className={
                    this.state.hasError
                      ? "alert alert-danger"
                      : "alert alert-danger d-none"
                  }
                  role="alert"
                >
                  Holiday Date already existing.
                </div>
              </div>
            </div>
            <form onSubmit={this.handleSubmit} id="contact-form">
              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  <label>Status</label>
                  <select
                    class="form-control custom-select"
                    onChange={(e) =>
                      this.setState({ holiday_type: e.target.value })
                    }
                  >
                    <option value={1}>REGULAR</option>
                    <option value={2}>SPECIAL</option>
                  </select>
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  <label>Name</label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    onChange={(e) => this.setState({ name: e.target.value })}
                  />
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  <label>Date</label>
                  <input
                    type="date"
                    min={d.getFullYear()+"-01-01"}
                    className="form-control"
                    required
                    onChange={(e) =>
                      this.setState({ holiday_date: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="float-right">
                <button
                  type="submit"
                  className="btn btn-primary btn-sm mr-2"
                  disabled={this.state.disableSubmit}
                >
                  Save
                </button>
                <button
                  onClick={this.handleshowAddModal}
                  className="btn btn-danger btn-sm"
                  type="button"
                >
                  Cancel
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
