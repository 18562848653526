import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { PictureAsPdf, Email } from "@material-ui/icons";
import axios from "axios";
import { data } from "jquery";

export default class GeneratePayslip extends Component {
  constructor(props) {
    super();
    this.state = {
      showDeleteModal: false,
      disableSubmit: false,
      status: 2,
    };
    this.handleshowDeleteModal = this.handleshowDeleteModal.bind(this);
  }

  handleshowDeleteModal = (e) => {
    this.setState({ showDeleteModal: !this.state.showDeleteModal });
  };

  handleSubmit = (e) => {
    this.setState({ disableSubmit: true });
    axios.get("api/payslip_genarate/"+this.props.id).then((res) => {
      this.props.parentCallback(true);
      this.setState({ showDeleteModal: false, disableSubmit: false });
    });
  };

  render() {
    return (
      <>
        <button
          className="btn btn-outline-success btn-sm float-right"
          title="Send email payslip to all employee"
          onClick={this.handleshowDeleteModal}
        >
          <Email />
        </button>
        <Modal
          size="md"
          backdrop="static"
          show={this.state.showDeleteModal}
          onHide={() =>
            this.setState({ showDeleteModal: !this.state.showDeleteModal })
          }
        >
          <Modal.Header>
            <h4>
              Send email payslip to all employee
              <br />
            </h4>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="form-group col-md-12 col-sm-12">
                Are you sure?
              </div>
            </div>
            <div className="float-right">
              <button
                type="button"
                className="btn btn-primary btn-sm mr-2"
                disabled={this.state.disableSubmit}
                onClick={this.handleSubmit}
              >
                Send Email {this.state.disableSubmit ? <span class="spinner spinner-border spinner-border-sm" id="spinner" role="status" aria-hidden="true">
                    </span> : ""
                    }
              </button>
              <button
                onClick={this.handleshowDeleteModal}
                className="btn btn-danger btn-sm"
                type="button"
                disabled={this.state.disableSubmit}
              >
                Cancel
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
