import React, { useState, useEffect } from "react";
import { FindInPage, Add } from "@material-ui/icons";
import { Link } from "react-router-dom";
import { dateFormatter } from "../components/DtFormatter";
import Header from "../components/Header";
import axios from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();
const statuses = ["DRAFT", "FOR POSTING", "POSTED"];
cookies.set("load", "0");

export default function Public(props) {
  const permissions = cookies.get("permissions");
  const [companyDD, setCompanyDD] = useState([]);
  const [fileLoaded, setFileLoaded] = useState(false);
  const [company, setCompany] = useState([]);
  const [currentPaySched, setCurrentPaySched] = useState(0);
  const [empCount, setEmpCount] = useState(0);
  const [lastPay, setLastPay] = useState(0);
  const [lastRun, setLastRun] = useState("");

  const [earnHoldCount, setEarnHoldCount] = useState(0);
  const [dedcHoldCount, setDedcHoldCount] = useState(0);
  const [spHoldCount, setSpHoldCount] = useState(0);
  const [ytdHoldCount, setYtdHoldCount] = useState(0);

  const [regularHistory, setRegularHistory] = useState([]);
  const [regularFilteredData, setRegularFilteredData] = useState([]);
  const [regularCurrentPage, setRegularCurrentPage] = useState(1);
  const [regularRecordsPerPage, setRegularRecordsPerPage] = useState(12);

  const [specialHistory, setSpecialHistory] = useState([]);
  const [specialFilteredData, setSpecialFilteredData] = useState([]);
  const [specialCurrentPage, setSpecialCurrentPage] = useState(1);
  const [specialRecordsPerPage, setSpecialRecordsPerPage] = useState(12);

  const [ytdHistory, setYtdHistory] = useState([]);
  const [ytdFilteredData, setYtdFilteredData] = useState([]);
  const [ytdCurrentPage, setYtdCurrentPage] = useState(1);
  const [ytdRecordsPerPage, setYtdRecordsPerPage] = useState(12);
  
  useEffect(() => {
    const fetchDD = async () => {
      const getCompany = await axios.get("api/companyDD");
      setCompanyDD(getCompany.data);

      if (cookies.get("transCompany")) {
        const res = await axios.get(
          "api/dboard/" + cookies.get("transCompany")
        );
        setCompany(res.data.company);
        setEmpCount(res.data.empCount);
        setCurrentPaySched(res.data.currentPaySched);
        setLastPay(res.data.lastPay);
        setLastRun(res.data.lastRun);

        setEarnHoldCount(res.data.earnHoldCount);
        setDedcHoldCount(res.data.dedcHoldCount);
        setSpHoldCount(res.data.spHoldCount);
        setYtdHoldCount(res.data.ytdHoldCount);

        setRegularHistory(res.data.regularHistory);
        setRegularFilteredData(res.data.regularHistory);

        setSpecialHistory(res.data.specialHistory);
        setSpecialFilteredData(res.data.specialHistory);

        setYtdHistory(res.data.ytdHistory);
        setYtdFilteredData(res.data.ytdHistory);
      }
    };

    if (!fileLoaded) {
      fetchDD();
      setFileLoaded(true);
    }
  });

  const specialPages = [];
  for (
    let i = 1;
    i <= Math.ceil(specialFilteredData.length / specialRecordsPerPage);
    i++
  ) {
    specialPages.push(i);
  }

  const handleSpecialRecordPerPage = (e) => {
    console.log(e.target.value);
    setSpecialRecordsPerPage(parseInt(e.target.value));
    setSpecialCurrentPage(1);
  };

  const handleSpecialSearch = (e) => {
    const filterer = specialHistory.filter(function (data) {
      for (var key in data) {
        if (
          !(
            data["code"].toLowerCase().includes(e.target.value.toLowerCase()) ||
            data["description"]
              .toLowerCase()
              .includes(e.target.value.toLowerCase())
          )
        ) {
          return false;
        }
        return true;
      }
    });
    setSpecialFilteredData(filterer);
  };

  const specialStartIndex =
    specialCurrentPage * specialRecordsPerPage - specialRecordsPerPage;
  const specialEndIndex = specialStartIndex + specialRecordsPerPage;
  const currentSpecialData = specialFilteredData.slice(
    specialStartIndex,
    specialEndIndex
  );

  const regularPages = [];
  for (
    let i = 1;
    i <= Math.ceil(regularFilteredData.length / regularRecordsPerPage);
    i++
  ) {
    regularPages.push(i);
  }

  const handleRegularRecordPerPage = (e) => {
    console.log(e.target.value);
    setRegularRecordsPerPage(parseInt(e.target.value));
    setRegularCurrentPage(1);
  };

  const handleRegularSearch = (e) => {
    const filterer = regularHistory.filter(function (data) {
      for (var key in data) {
        if (
          !data["code"].toLowerCase().includes(e.target.value.toLowerCase())
        ) {
          return false;
        }
        return true;
      }
    });
    setRegularFilteredData(filterer);
  };

  const regularStartIndex =
    regularCurrentPage * regularRecordsPerPage - regularRecordsPerPage;
  const regularEndIndex = regularStartIndex + regularRecordsPerPage;
  const currentRegularData = regularFilteredData.slice(
    regularStartIndex,
    regularEndIndex
  );

  const ytdPages = [];
  for (
    let i = 1;
    i <= Math.ceil(ytdFilteredData.length / ytdRecordsPerPage);
    i++
  ) {
    ytdPages.push(i);
  }

  const handleYtdRecordPerPage = (e) => {
    console.log(e.target.value);
    setYtdRecordsPerPage(parseInt(e.target.value));
    setYtdCurrentPage(1);
  };

  const handleYtdSearch = (e) => {
    const filterer = ytdHistory.filter(function (data) {
      for (var key in data) {
        if (
          !(
            data["code"].toLowerCase().includes(e.target.value.toLowerCase()) ||
            data["description"]
              .toLowerCase()
              .includes(e.target.value.toLowerCase())
          )
        ) {
          return false;
        }
        return true;
      }
    });
    setYtdFilteredData(filterer);
  };
  const ytdStartIndex = ytdCurrentPage * ytdRecordsPerPage - ytdRecordsPerPage;
  const ytdEndIndex = ytdStartIndex + ytdRecordsPerPage;
  const currentYtdData = ytdFilteredData.slice(ytdStartIndex, ytdEndIndex);

  return (
    <div className="hk-wrapper hk-vertical-nav">
      <div className="pg-hk-wrapper">
        <Header />
        <div className="mt-20 hk-pg-wrapper">
          <div className="container-fluid">
            <div className="row mb-10">
              <div className="col-lg-6 col-md-6 col-sm-12">
                <h3>Welcome {cookies.get("name")}!</h3>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="card">
                  <div className="card-header card-header-action">
                    <h5>Module Company</h5>
                    <div className="d-flex align-items-center card-action-wrap-tools"></div>
                  </div>
                  <div className="card-body">
                    <div className="row">
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <div className="row">
                          <div className="col-xl-6 col-sm-12">
                            <strong>Company</strong>
                          </div>
                          <div className="col-xl-6 col-sm-12">
                            <form id="contact-form">
                              <select
                                className="form-control form-control-sm custom-select"
                                required
                                onChange={(e) => {
                                  var index =
                                    e.nativeEvent.target.selectedIndex;
                                  cookies.get("user_companies");
                                  if (e.target.value) {
                                    cookies.set("transCompany", e.target.value);
                                    cookies.set(
                                      "transText",
                                      e.nativeEvent.target[index].text
                                    );
                                    cookies.set(
                                      "transPay",
                                      e.nativeEvent.target[index].getAttribute(
                                        "pay_cycle"
                                      )
                                    );
                                    cookies.set(
                                      "logo",
                                      e.nativeEvent.target[index].getAttribute(
                                        "logo"
                                      )
                                    );
                                  } else {
                                    cookies.set("transCompany", "");
                                    cookies.set("transText", "");
                                    cookies.set("transPay", "");
                                    cookies.set("logo", "");
                                  }
                                  window.location.reload();
                                }}
                                value={
                                  cookies.get("transCompany")
                                    ? cookies.get("transCompany")
                                    : ""
                                }
                              >
                                <option value="">Select Company</option>
                                {cookies
                                  .get("user_companies")
                                  .map((company) => (
                                    <option
                                      key={company.id}
                                      value={company.company_id}
                                      pay_cycle={company.pay_period}
                                      logo={company.logo_file_name}
                                    >
                                      {company.company_name}
                                    </option>
                                  ))}
                              </select>
                            </form>
                          </div>

                          <div className="col-xl-6 col-sm-12 mb-10 mt-10">
                            <strong>Calendar Year</strong>
                          </div>
                          <div className="col-xl-6 col-sm-12 mb-10 mt-10">
                            :&nbsp;&nbsp;&nbsp;
                            {currentPaySched
                              ? currentPaySched.sched_year
                                ? currentPaySched.sched_year
                                : "N/A"
                              : "N/A"}
                          </div>
                          <div className="col-xl-6 col-sm-12 mb-10">
                            <strong># of Active Employees</strong>
                          </div>
                          <div className="col-xl-6 col-sm-12 mb-10">
                            :&nbsp;&nbsp;&nbsp;{empCount ? empCount : "N/A"}
                          </div>
                          {/* <div className="col-xl-6 col-sm-12 mb-10">
                            <strong>Basic Schema</strong>
                          </div>
                          <div className="col-xl-6 col-sm-12 mb-10">
                            :&nbsp;&nbsp;&nbsp;
                            {company.bschema_name
                              ? company.bschema_name
                              : "N/A"}
                          </div> */}
                          <div className="col-xl-6 col-sm-12 mb-10">
                            <strong>Payroll Cycle</strong>
                          </div>
                          <div className="col-xl-6 col-sm-12 mb-10">
                            :&nbsp;&nbsp;&nbsp;
                            {company.pay_period_name
                              ? company.pay_period_name
                              : "N/A"}
                          </div>
                        </div>
                      </div>
                      <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                        <div className="row">
                          <div className="col-xl-5 col-sm-12 mb-10">
                            <strong></strong>
                          </div>
                          <div className="col-xl-7 col-sm-12 mb-10">
                            &nbsp;&nbsp;&nbsp;
                          </div>

                          <div className="col-xl-5 col-sm-12 mb-10 mt-10">
                            <strong>Last Payroll Run</strong>
                          </div>
                          <div className="col-xl-7 col-sm-12 mb-10 mt-10">
                            :&nbsp;&nbsp;&nbsp;
                            {lastPay ? lastRun : "N/A"}
                          </div>
                          <div className="col-xl-5 col-sm-12 mb-10">
                            <strong>Last Pay Period ID/Description:</strong>
                          </div>
                          <div className="col-xl-7 col-sm-12 mb-10">
                            :&nbsp;&nbsp;&nbsp;
                            {lastPay
                              ? lastPay.description
                                ? lastPay.description
                                : lastPay.code
                              : "N/A"}
                          </div>
                          <div className="col-xl-5 col-sm-12 mb-10">
                            <strong>Last Payment Date:</strong>
                          </div>
                          <div className="col-xl-7 col-sm-12 mb-10">
                            :&nbsp;&nbsp;&nbsp;
                            {lastPay
                              ? dateFormatter(lastPay.post_date, "MMM dd, yyyy")
                              : "N/A"}
                          </div>
                          {/* <div className="col-xl-5 col-sm-12 mb-10">
                            <strong>Overtime Schema</strong>
                          </div>
                          <div className="col-xl-7 col-sm-12 mb-10">
                            :&nbsp;&nbsp;&nbsp;
                            {company.schema_name ? company.schema_name : "N/A"}
                          </div>
                          <div className="col-xl-5 col-sm-12 mb-10">
                            <strong>Night Differentials</strong>
                          </div>
                          <div className="col-xl-7 col-sm-12 mb-10">
                            :&nbsp;&nbsp;&nbsp;
                            {company.f_night_diff_in
                              ? company.f_night_diff_in +
                                " to " +
                                company.f_night_diff_out
                              : "N/A"}
                          </div>
                          <div className="col-xl-5 col-sm-12">
                            <strong>Minimum Overtime(Hour)</strong>
                          </div>
                          <div className="col-xl-7 col-sm-12">
                            :&nbsp;&nbsp;&nbsp;
                            {company.min_overtime
                              ? company.min_overtime + " Hour(s)"
                              : "N/A"}
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-3 col-md-3 col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <span
                      className="d-block font-11 font-weight-500 text-dark text-uppercase mb-10"
                      id="leave_name_1"
                    >
                      Earning On Hold
                    </span>
                    <div className="d-flex align-items-end center-content-between text-center">
                      <div>
                        <span className="text-center">
                          <span className="display-5 font-weight-400 text-dark text-center">
                            {earnHoldCount}
                          </span>
                          <small>&nbsp;Transactions</small>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-3 col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <span
                      className="d-block font-11 font-weight-500 text-dark text-uppercase mb-10"
                      id="leave_name_1"
                    >
                      Deduction On Hold
                    </span>
                    <div className="d-flex align-items-end center-content-between text-center">
                      <div>
                        <span className="text-center">
                          <span className="display-5 font-weight-400 text-dark text-center">
                            {dedcHoldCount}
                          </span>
                          <small>&nbsp;Transactions</small>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-3 col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <span
                      className="d-block font-11 font-weight-500 text-dark text-uppercase mb-10"
                      id="leave_name_1"
                    >
                      Special Pay On Hold
                    </span>
                    <div className="d-flex align-items-end center-content-between text-center">
                      <div>
                        <span className="text-center">
                          <span className="display-5 font-weight-400 text-dark text-center">
                            {spHoldCount}
                          </span>
                          <small>&nbsp;Transactions</small>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-3 col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <span
                      className="d-block font-11 font-weight-500 text-dark text-uppercase mb-10"
                      id="leave_name_1"
                    >
                      YTD Adjustment On Hold
                    </span>
                    <div className="d-flex align-items-end center-content-between text-center">
                      <div>
                        <span className="text-center">
                          <span className="display-5 font-weight-400 text-dark text-center">
                            {ytdHoldCount}
                          </span>
                          <small>&nbsp;Adjustments</small>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {permissions.payroll > 0 ? (
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="card">
                    <div className="card-header card-header-action">
                      <h5>Regular Payroll History</h5>
                      <div className="d-flex align-items-center card-action-wrap-tools"></div>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-6">
                          <label>
                            <select
                              className="custom-select custom-select-sm form-control form-control-sm"
                              onChange={handleRegularRecordPerPage}
                            >
                              <option value="10">10</option>
                              <option value="25">25</option>
                              <option value="50">50</option>
                              <option value="100">100</option>
                            </select>
                          </label>{" "}
                          items
                        </div>
                        <div className="col-md-6">
                          <input
                            type="search"
                            className="form-control form-control-sm col-md-6 col-sm-6 float-right"
                            placeholder="Search"
                            onChange={handleRegularSearch}
                          />
                        </div>
                        <div className="col-md-12">
                          <div className="table-responsive mb-10">
                            <table className="table table-sm table-bordered table-striped mb-0">
                              <thead className="thead-secondary">
                                <tr className="text-center">
                                  <th>Pay Period ID</th>
                                  <th>Period</th>
                                  <th>Status</th>
                                  <th>Head Count</th>
                                  <th>Basic</th>
                                  <th>De Minimis</th>
                                  <th>Other Earning</th>
                                  <th>Statutories</th>
                                  <th>Other Deduction</th>
                                  <th>WH Tax</th>
                                  <th>Net Pay</th>
                                  <th>Date Posted</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {regularFilteredData.length > 0 ? (
                                  currentRegularData.map((cutoff) => (
                                    <tr
                                      key={"r" + cutoff.id}
                                      className="text-nowrap"
                                    >
                                      <td>
                                        <Link
                                          to={{
                                            pathname: `/reg_payroll/${cutoff.id}/dashboard`,
                                            query: `/reg_payroll`,
                                          }}
                                          className="text-primary"
                                          title={"View per employee"}
                                        >
                                          {cutoff.code}
                                        </Link>
                                      </td>
                                      <td className="text-nowrap">
                                        {dateFormatter(cutoff.start_date) +
                                          " to " +
                                          dateFormatter(cutoff.end_date)}
                                      </td>
                                      <td>
                                        {cutoff.status == 2 ? (
                                          <strong className="text-success">
                                            {statuses[cutoff.status]}
                                          </strong>
                                        ) : cutoff.status == 1 ? (
                                          <strong className="text-info">
                                            {statuses[cutoff.status]}
                                          </strong>
                                        ) : (
                                          <strong className="text-warning">
                                            {statuses[cutoff.status]}
                                          </strong>
                                        )}
                                      </td>
                                      <td className="text-right">
                                        {cutoff.head_count}
                                      </td>
                                      <td className="text-right">
                                        {cutoff.basic
                                          .toLocaleString()
                                          .replace(
                                            /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )}
                                      </td>
                                      <td className="text-right">
                                        {cutoff.deminimis
                                          .toLocaleString()
                                          .replace(
                                            /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )}
                                      </td>
                                      <td className="text-right">
                                        <Link
                                          to={{
                                            pathname: `/reg_break_earn/${cutoff.id}/${cutoff.paysched_id}`,
                                            query: `/reg_break_earn`,
                                          }}
                                          className="text-primary"
                                          title={"View Other Earning Breakdown"}
                                        >
                                          {(
                                            parseFloat(cutoff.other_earning) +
                                            +parseFloat(cutoff.nontax_earning) +
                                            parseFloat(cutoff.basic_earning)
                                          )
                                            .toFixed(2)
                                            .replace(
                                              /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                              ","
                                            )}
                                        </Link>
                                      </td>
                                      <td className="text-right">
                                        {(
                                          parseFloat(cutoff.basic_deduction) +
                                          parseFloat(cutoff.other_statutory)
                                        )
                                          .toFixed(2)
                                          .replace(
                                            /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )}
                                      </td>
                                      <td className="text-right">
                                        <Link
                                          to={{
                                            pathname: `/reg_break_dedc/${cutoff.id}/${cutoff.paysched_id}`,
                                            query: `/reg_break_dedc`,
                                          }}
                                          className="text-primary"
                                          title={
                                            "View Other Deduction Breakdown"
                                          }
                                        >
                                          {cutoff.other_deduction
                                            .toLocaleString()
                                            .replace(
                                              /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                              ","
                                            )}
                                        </Link>
                                      </td>
                                      <td className="text-right">
                                        {cutoff.wh_tax
                                          .toLocaleString()
                                          .replace(
                                            /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )}
                                      </td>
                                      <td
                                        className={
                                          cutoff.gross / 2 > cutoff.net
                                            ? "text-right text-danger"
                                            : "text-right"
                                        }
                                      >
                                        {parseFloat(cutoff.net)
                                          .toFixed(2)
                                          .replace(
                                            /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )}
                                      </td>
                                      <td className="text-center">
                                        {cutoff.post_date != "PENDING" ? (
                                          dateFormatter(
                                            cutoff.post_date,
                                            "MMM dd, yyyy hh:mm bb"
                                          )
                                        ) : (
                                          <strong className="text-danger">
                                            PENDING
                                          </strong>
                                        )}
                                      </td>
                                      <td>
                                        <div className="btn-group">
                                          <Link
                                            to={{
                                              pathname: `/reg_payroll/${cutoff.id}/dashboard`,
                                              query: `/reg_payroll`,
                                            }}
                                            className="btn btn-sm btn-outline-primary"
                                            title={"View per employee"}
                                          >
                                            <FindInPage />
                                          </Link>
                                        </div>
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td colSpan="13" className="text-center">
                                      No Data to show
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                          <div className="drew-pagination">
                            {regularFilteredData.length > 0
                              ? "Showing " +
                                (regularStartIndex + 1) +
                                " to " +
                                (regularEndIndex > regularFilteredData.length
                                  ? regularFilteredData.length
                                  : regularEndIndex) +
                                " of " +
                                regularFilteredData.length
                              : ""}
                            <ul className="pagination ml-2">
                              {regularPages.map((number) => (
                                <li
                                  key={number}
                                  id={number}
                                  className={
                                    regularCurrentPage == number
                                      ? "paginate_button page-item active"
                                      : "paginate_button page-item"
                                  }
                                  onClick={() => setRegularCurrentPage(number)}
                                >
                                  <a className="page-link">{number}</a>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {permissions.payroll > 0 ? (
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="card">
                    <div className="card-header card-header-action">
                      <h5>Special Payroll History</h5>
                      <div className="d-flex align-items-center card-action-wrap-tools"></div>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-6">
                          <label>
                            <select
                              className="custom-select custom-select-sm form-control form-control-sm"
                              onChange={handleSpecialRecordPerPage}
                            >
                              <option value="10">10</option>
                              <option value="25">25</option>
                              <option value="50">50</option>
                              <option value="100">100</option>
                            </select>
                          </label>{" "}
                          items
                        </div>
                        <div className="col-md-6">
                          <input
                            type="search"
                            className="form-control form-control-sm col-md-6 col-sm-6 float-right"
                            placeholder="Search"
                            onChange={handleSpecialSearch}
                          />
                        </div>
                        <div className="col-md-12">
                          <div className="table-responsive mb-10">
                            <table className="table table-sm table-bordered table-striped mb-0">
                              <thead className="thead-secondary">
                                <tr className="text-center text-nowrap">
                                  <th>Run Code</th>
                                  <th>Description</th>
                                  <th>Pay Period ID</th>
                                  <th>Status</th>
                                  <th>Head Count</th>
                                  <th>Gross Pay</th>
                                  <th>WH Tax</th>
                                  <th>Net Pay</th>
                                  <th>Date Posted</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {specialFilteredData.length > 0 ? (
                                  currentSpecialData.map((cutoff) => (
                                    <tr key={"s" + cutoff.id}>
                                      <td className="text-nowrap">
                                        <Link
                                          to={{
                                            pathname: `/spl_payroll/${cutoff.id}/dashboard`,
                                            query: `/spl_payroll`,
                                          }}
                                          className="text-primary"
                                          title={"View per employee"}
                                        >
                                          {cutoff.code}
                                        </Link>
                                      </td>
                                      <td className="text-nowrap">
                                        {cutoff.description}
                                      </td>
                                      <td className="text-center">
                                        {cutoff.ps_code}
                                      </td>
                                      <td className="text-center">
                                        {cutoff.status == 3 ? (
                                          <strong className="text-success">
                                            POSTED
                                          </strong>
                                        ) : cutoff.status == 2 ? (
                                          <strong className="text-info">
                                            FOR POSTING
                                          </strong>
                                        ) : cutoff.status == 1 ? (
                                          <strong className="text-warning">
                                            DRAFT
                                          </strong>
                                        ) : (
                                          <strong className="text-danger">
                                            OPEN
                                          </strong>
                                        )}
                                      </td>
                                      <td className="text-right">
                                        {cutoff.headcount
                                          .toString()
                                          .replace(
                                            /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )}
                                      </td>
                                      <td className="text-right">
                                        {cutoff.total_amount
                                          .toLocaleString()
                                          .replace(
                                            /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )}
                                      </td>
                                      <td className="text-right">
                                        {cutoff.wh_tax
                                          .toLocaleString()
                                          .replace(
                                            /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )}
                                      </td>
                                      <td className="text-right">
                                        {cutoff.total_amount
                                          .toLocaleString()
                                          .replace(
                                            /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )}
                                      </td>

                                      <td className="text-center text-nowrap">
                                        {cutoff.post_date ? (
                                          dateFormatter(
                                            cutoff.post_date,
                                            "MMM dd, yyyy hh:mm bb"
                                          )
                                        ) : (
                                          <strong className="text-danger">
                                            PENDING
                                          </strong>
                                        )}
                                      </td>
                                      <td>
                                        <div className="btn-group">
                                          <Link
                                            to={{
                                              pathname: `/spl_payroll/${cutoff.id}/dashboard`,
                                              query: `/spl_payroll`,
                                            }}
                                            className="btn btn-sm btn-outline-primary"
                                            title={"View per employee"}
                                          >
                                            <FindInPage />
                                          </Link>
                                        </div>
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td colSpan="10" className="text-center">
                                      No Data to show
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                          <div className="drew-pagination">
                            {specialFilteredData.length > 0
                              ? "Showing " +
                                (specialStartIndex + 1) +
                                " to " +
                                (specialEndIndex > specialFilteredData.length
                                  ? specialFilteredData.length
                                  : specialEndIndex) +
                                " of " +
                                specialFilteredData.length
                              : ""}
                            <ul className="pagination ml-2">
                              {specialPages.map((number) => (
                                <li
                                  key={number}
                                  id={number}
                                  className={
                                    specialCurrentPage == number
                                      ? "paginate_button page-item active"
                                      : "paginate_button page-item"
                                  }
                                  onClick={() => setSpecialCurrentPage(number)}
                                >
                                  <a className="page-link">{number}</a>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
            {permissions.ytd > 0 ? (
              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="card">
                    <div className="card-header card-header-action">
                      <h5>YTD Adjustment History</h5>
                      <div className="d-flex align-items-center card-action-wrap-tools"></div>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-6">
                          <label>
                            <select
                              className="custom-select custom-select-sm form-control form-control-sm"
                              onChange={handleYtdRecordPerPage}
                            >
                              <option value="10">10</option>
                              <option value="25">25</option>
                              <option value="50">50</option>
                              <option value="100">100</option>
                            </select>
                          </label>{" "}
                          items
                        </div>
                        <div className="col-md-6">
                          <input
                            type="search"
                            className="form-control form-control-sm col-md-6 col-sm-6 float-right"
                            placeholder="Search"
                            onChange={handleYtdSearch}
                          />
                        </div>
                        <div className="col-md-12">
                          <div className="table-responsive mb-10">
                            <table className="table table-sm table-bordered table-striped mb-0">
                              <thead className="thead-secondary">
                                <tr className="text-center text-nowrap">
                                  <th>Run Code</th>
                                  <th>Description</th>
                                  <th>Pay Period ID</th>
                                  <th>Status</th>
                                  <th>Head Count</th>
                                  <th>Basic</th>
                                  <th>De Minimis</th>
                                  <th>O.Earnings</th>
                                  <th>Gross Pay</th>
                                  <th>Statutories</th>
                                  <th>O.Deductions</th>
                                  <th>WH Tax</th>
                                  <th>Net Pay</th>
                                  <th>Date Posted</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {ytdFilteredData.length > 0 ? (
                                  currentYtdData.map((cutoff) => (
                                    <tr key={"s" + cutoff.id}>
                                      <td className="text-nowrap">
                                        <Link
                                          to={{
                                            pathname: `/ytd_run/${cutoff.id}/dashboard`,
                                            query: `/ytd_run`,
                                          }}
                                          className="text-primary"
                                          title={"View per employee"}
                                        >
                                          {cutoff.code}
                                        </Link>
                                      </td>
                                      <td className="text-nowrap">
                                        {cutoff.description}
                                      </td>
                                      <td className="text-center ">
                                        {cutoff.ps_code}
                                      </td>
                                      <td className="text-center">
                                        {cutoff.status == 3 ? (
                                          <strong className="text-success">
                                            POSTED
                                          </strong>
                                        ) : cutoff.status == 2 ? (
                                          <strong className="text-info">
                                            FOR POSTING
                                          </strong>
                                        ) : cutoff.status == 1 ? (
                                          <strong className="text-warning">
                                            DRAFT
                                          </strong>
                                        ) : (
                                          <strong className="text-danger">
                                            OPEN
                                          </strong>
                                        )}
                                      </td>
                                      <td className="text-right">
                                        {cutoff.headcount
                                          .toString()
                                          .replace(
                                            /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )}
                                      </td>
                                      <td className="text-right">
                                        {cutoff.basic_pay
                                          .toString()
                                          .replace(
                                            /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )}
                                      </td>
                                      <td className="text-right">
                                        {cutoff.deminimis
                                          .toString()
                                          .replace(
                                            /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )}
                                      </td>
                                      <td className="text-right">
                                        {cutoff.earnings
                                          .toString()
                                          .replace(
                                            /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )}
                                      </td>
                                      <td className="text-right">
                                        {cutoff.gross
                                          .toString()
                                          .replace(
                                            /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )}
                                      </td>
                                      <td className="text-right">
                                        {cutoff.statutories
                                          .toString()
                                          .replace(
                                            /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )}
                                      </td>
                                      <td className="text-right">
                                        {cutoff.deductions
                                          .toString()
                                          .replace(
                                            /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )}
                                      </td>
                                      <td className="text-right">
                                        {cutoff.wh_tax
                                          .toString()
                                          .replace(
                                            /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )}
                                      </td>
                                      <td className="text-right">
                                        {cutoff.net
                                          .toString()
                                          .replace(
                                            /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )}
                                      </td>

                                      <td className="text-center text-nowrap">
                                        {cutoff.post_date ? (
                                          dateFormatter(
                                            cutoff.post_date,
                                            "MMM dd, yyyy hh:mm bb"
                                          )
                                        ) : (
                                          <strong className="text-danger">
                                            PENDING
                                          </strong>
                                        )}
                                      </td>
                                      <td>
                                        <div className="btn-group">
                                          <Link
                                            to={{
                                              pathname: `/ytd_run/${cutoff.id}/dashboard`,
                                              query: `/ytd_run`,
                                            }}
                                            className="btn btn-sm btn-outline-primary"
                                            title={"View per employee"}
                                          >
                                            <FindInPage />
                                          </Link>
                                        </div>
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td colSpan="15" className="text-center">
                                      No Data to show
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                          <div className="drew-pagination">
                            {ytdFilteredData.length > 0
                              ? "Showing " +
                                (ytdStartIndex + 1) +
                                " to " +
                                (ytdEndIndex > ytdFilteredData.length
                                  ? ytdFilteredData.length
                                  : ytdEndIndex) +
                                " of " +
                                ytdFilteredData.length
                              : ""}
                            <ul className="pagination ml-2">
                              {ytdPages.map((number) => (
                                <li
                                  key={number}
                                  id={number}
                                  className={
                                    ytdCurrentPage == number
                                      ? "paginate_button page-item active"
                                      : "paginate_button page-item"
                                  }
                                  onClick={() => setYtdCurrentPage(number)}
                                >
                                  <a className="page-link">{number}</a>
                                </li>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
