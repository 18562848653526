import React, { useState, useEffect } from "react";
import axios from "axios";
import { FileCopy } from "@material-ui/icons";
import { dateFormatter } from "../../../components/DtFormatter";
import SssUpdate from "./SssUpdate";
import ExportSssTemp from "./SssTexExport";
import Cookies from "universal-cookie";
const cookies = new Cookies();


export default function SssBracket() {
  const permissions = cookies.get("permissions")["config"];
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [filteredData, setFilteredData] = useState([]);
  const [fileLoaded, setFileLoaded] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      let apiLink = "api/sss_bracket";
      const res = await axios.get(apiLink);
      setData(res.data);
      setFilteredData(res.data);
    };

    if (!fileLoaded) {
      fetchData();
      setFileLoaded(true);
    }
  });

  const handleAddCallback = (editData) => {
    if (editData) {
      setFileLoaded(false);
    }
  };

  const pages = [];
  for (let i = 1; i <= Math.ceil(filteredData.length / recordsPerPage); i++) {
    pages.push(i);
  }

  const handleRecordPerPage = (e) => {
    console.log(e.target.value);
    setRecordsPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const handleSearch = (e) => {
    const filterer = data.filter(function (data) {
      for (var key in data) {
        if (
          !data["name"].toLowerCase().includes(e.target.value.toLowerCase())
        ) {
          return false;
        }
        return true;
      }
    });
    setFilteredData(filterer);
  };

  const startIndex = currentPage * recordsPerPage - recordsPerPage;
  const endIndex = startIndex + recordsPerPage;
  const currentData = filteredData.slice(startIndex, endIndex);

  return (
    <div>
      <div className="row mb-3">
        <div className="col-lg-12 col-md-12 col-sm-12">
          {permissions > 1 ? (
            <div className="btn-group float-right">
              <SssUpdate parentCallback={handleAddCallback} />
              <ExportSssTemp />
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="table-wrap">
            <div className="table-responsive mb-10">
              <table className="table table-sm table-bordered table-striped mb-0">
                <thead className="thead-secondary">
                  <tr className="text-center">
                    <th>Lower Range</th>
                    <th>Upper Range</th>
                    <th>Premium ER</th>
                    <th>Premium EE</th>
                    <th>Provident ER</th>
                    <th>Provident EE</th>
                    <th>Compensation ER</th>
                    <th>Compensation EE</th>
                    <th>Status</th>
                    <th style={{ width: "210px" }}>Date Created</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData.length > 0 ? (
                    data.map((sss) => (
                      <tr key={sss.id}>
                        <td className="text-right">
                          {sss.sss_lower_range
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </td>
                        <td className="text-right">
                          {sss.sss_upper_range
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </td>
                        <td className="text-right">
                          {sss.premium_er
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </td>
                        <td className="text-right">
                          {sss.premium_ee
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </td>
                        <td className="text-right">
                          {sss.provident_er
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </td>
                        <td className="text-right">
                          {sss.provident_ee
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </td>
                        <td className="text-right">
                          {sss.compensation_er
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </td>
                        <td className="text-right">
                          {sss.compensation_ee
                            .toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </td>
                        <td className="text-right">
                          <strong
                            className={
                              sss.active == 0 ? "text-danger" : "text-success"
                            }
                          >
                            {sss.active == 0 ? "INACTIVE" : "ACTIVE"}
                          </strong>
                        </td>
                        <td className="text-nowrap">
                          {dateFormatter(
                            sss.created_at,
                            "MMM dd, yyyy hh:mm aa"
                          )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5" className="text-center">
                        No Data to show
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
