import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { Delete } from "@material-ui/icons";
import axios from "axios";

export default class DeleteYtdAdjustment extends Component {
  constructor(props) {
    super();
    this.state = {
      disableSubmit: false,
      showDeleteModal: false,
    };
    this.handleshowDeleteModal = this.handleshowDeleteModal.bind(this);
  }

  handleshowDeleteModal = (e) => {
    this.setState({ showDeleteModal: !this.state.showDeleteModal });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ disableSubmit: true });
    axios.delete("api/delete_adjustment/" + this.props.id).then((res) => {
      this.props.parentCallback(true);
      this.setState({ showDeleteModal: false });
      this.setState({ disableSubmit: true });
    });
  };

  render() {
    return (
      <>
        <button
          className="btn btn-outline-danger btn-sm float-right"
          title="Delete YTD Adjustment"
          onClick={this.handleshowDeleteModal}
        >
          <Delete />
        </button>
        <Modal
          size="md"
          show={this.state.showDeleteModal}
          onHide={() =>
            this.setState({ showDeleteModal: !this.state.showDeleteModal })
          }
        >
          <Modal.Header closeButton>
            <h4>Delete YTD Adjustment: {this.props.name}</h4>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={this.handleSubmit} id="contact-form">
              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  Are you sure?
                </div>
              </div>

              <div className="float-right">
                <button type="submit" className="btn btn-primary btn-sm mr-2"
                disabled={this.state.disableSubmit}>
                  Delete
                </button>
                <button
                  onClick={this.handleshowDeleteModal}
                  className="btn btn-danger btn-sm"
                  type="button"
                >
                  Cancel
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
