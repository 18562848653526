import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { Edit } from "@material-ui/icons";
import { dateFormatter } from "../../components/DtFormatter";
import Select from "react-select";
import axios from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();

export default class EditTransaction extends Component {
  constructor() {
    super();
    this.state = {
      updated_by: cookies.get("email"),
      showEditModal: false,
      disableSubmit: false,
      hasError: false,
      errMsg: ""
    };
    this.handleshowAddModal = this.handleshowAddModal.bind(this);
  }

  handleshowAddModal = (e) => {
    this.setState({
      company_id: this.props.transDetail.company_id,
      employee_id: this.props.transDetail.employee_id,
      ed_id: this.props.transDetail.ed_id,
      pay_period: Math.abs(this.props.transDetail.pay_period),
      amount: Math.abs(this.props.transDetail.amount),
      balance: this.props.transDetail.balance,
      per_payment: Math.abs(this.props.transDetail.per_payment),
      from_date: this.props.transDetail.from_date,
    });

    this.setState({
      showEditModal: !this.state.showEditModal,
      hasError: false,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ disableSubmit: true });
    axios
      .put("api/transaction/" + this.props.transDetail.id, this.state)
      .then((res) => {
        this.props.parentCallback(true);
        this.setState({
          showEditModal: false,
          hasError: false,
          disableSubmit: false,
        });
      })
      .catch((err) => {
        this.setState({ disableSubmit: false, hasError: true, errMsg: err.response.data.message });
      });
  };

  

  

  render() {
    return (
      <>
        <button
          className="btn btn-outline-success btn-sm float-right"
          title="Edit Transaction"
          onClick={this.handleshowAddModal}
        >
          <Edit />
        </button>
        <Modal
          size="md"
          show={this.state.showEditModal}
          onHide={() =>
            this.setState({ showEditModal: !this.state.showEditModal })
          }
        >
          <Modal.Header closeButton>
            <h4>Edit Transaction</h4>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <div
                  className={
                    this.state.hasError
                      ? "alert alert-danger"
                      : "alert alert-danger d-none"
                  }
                  role="alert"
                >
                  Failed to Create Transaction: {this.state.errMsg} 
                </div>
              </div>
            </div>
            <form onSubmit={this.handleSubmit} id="contact-form">
              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  <label>Employee</label>
                  <Select
                    placeholder="Select Employee"
                    options={this.props.empDD}
                    onChange={(e) => {
                      this.setState({ employee_id: e.value });
                    }}
                    defaultValue={{
                      label:
                        this.props.transDetail.last_name +
                        ", " +
                        this.props.transDetail.first_name,
                      value: this.props.transDetail.employee_id,
                    }}
                    isSearchable
                    required
                  />
                  <input
                    className="form-control hidden hidden-require"
                    type="text"
                    value={this.state.employee_id}
                    autoComplete="off"
                    required
                  />
                </div>
                <div className="form-group col-md-12 col-sm-12">
                  <label>{this.props.type} Type</label>
                  <select
                    className="form-control custom-select"
                    required
                    onChange={(e) => {
                      this.setState({
                        ed_id: e.target.value,
                      });

                      if(e.target.value == 1 || e.target.value == 8 ||e.target.value == 28){
                        if(cookies.get("transPay") == 1){
                          this.setState({
                            pay_period: "2"
                          });
                        } else {
                          this.setState({
                            pay_period: Math.abs(this.props.transDetail.pay_period),
                          });
                        }
                      } else {
                        this.setState({
                          pay_period: Math.abs(this.props.transDetail.pay_period),
                        });
                      }

                    }}
                    defaultValue={this.props.transDetail.ed_id}
                  >
                    <option value="" datapayperiod="">
                      Select Earning
                    </option>
                    {this.props.edDD.map((ed) => (
                      <option
                        key={ed.id}
                        value={ed.id}
                        datapayperiod={"" + ed.pay_period + ""}
                        hidden={
                          (this.props.transDetail.type == "EARNING" ? (
                            ed.is_add != 1 ? ("hidden"): ("")
                          ):(
                            ed.is_deduction == 1
                            ? ""
                            : ed.is_statutory == 1 &&
                              !(ed.name.toLowerCase().indexOf("adjustment") === -1)
                            ? ""
                            : "hidden"
                          ))
                        }
                      >
                        {ed.code + " - " + ed.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group col-md-12 col-sm-12">
                  <label>Pay Period Release</label>
                  <select
                    className="form-control custom-select"
                    required
                    onChange={(e) => {
                      this.setState({ pay_period: e.target.value });
                    }}
                    defaultValue={this.props.transDetail.pay_period}
                  >
                    <option value="">Select Pay Period Release</option>
                    {cookies.get("transPay") == 1 ? (
                      this.state.ed_id == 1 || this.state.ed_id == 8 || this.state.ed_id == 28 ? (
                        <>
                          <option value="2">15th (Semi-Monthly)</option>
                          <option value="4">30th (Semi-Monthly)</option>
                          <option value="1">15th & 30th (Semi-Monthly)</option>
                        </>
                      ) : (
                        <>
                          <option value="2">15th (Semi-Monthly)</option>
                          <option value="4">30th (Semi-Monthly)</option>
                          <option value="1">15th & 30th (Semi-Monthly)</option>
                        </>
                      )
                    ) : (
                      <option value="3">30th (Monthly)</option>
                    )}
                  </select>
                </div>
                <div className="form-group col-md-12 col-sm-12">
                  <label>Total Amount</label>
                  <input
                    type="number"
                    className="form-control"
                    step=".01"
                    required
                    onChange={(e) => {
                      this.setState({
                        amount: e.target.value,
                        balance: e.target.value,
                      });
                    }}
                    defaultValue={this.props.transDetail.ed_id == 28 ? (Math.abs(this.props.transDetail.amount)):(this.props.transDetail.amount)}
                  />
                </div>
                <div className="form-group col-md-12 col-sm-12">
                  <label>Per Payment Period Amount</label>
                  <input
                    type="number"
                    className="form-control"
                    step=".01"
                    required
                    onChange={(e) => {
                      this.setState({ per_payment: e.target.value });
                    }}
                    defaultValue={this.props.transDetail.ed_id == 28 ? (Math.abs(this.props.transDetail.per_payment)):(this.props.transDetail.per_payment)}
                  />
                </div>
                <div className="form-group col-md-12 col-sm-12">
                  <label>Pay Period ID From</label>
                  <Select
                    placeholder="Select Pay Period ID From"
                    options={this.props.paySchedFrom}
                    onChange={(e) => {
                      this.setState({ from_date: e.value });
                    }}
                    defaultValue={{
                      value: this.props.transDetail.from_date,
                      label:
                        this.props.transDetail.ps_from_code +
                        " (" +
                        dateFormatter(this.props.transDetail.ps_from_date) +
                        " - " +
                        dateFormatter(this.props.transDetail.ps_from_end_date) +
                        ")",
                    }}
                  />
                  <input
                    className="form-control hidden hidden-require"
                    type="text"
                    value={this.state.from_date}
                    autoComplete="off"
                    required
                  />
                </div>
              </div>

              <div className="float-right">
                <button
                  type="submit"
                  className="btn btn-primary btn-sm mr-2"
                  disabled={this.state.disableSubmit}
                >
                  Save
                </button>
                <button
                  onClick={this.handleshowAddModal}
                  className="btn btn-danger btn-sm"
                  type="button"
                >
                  Cancel
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
