import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { PlayArrow, CheckCircle } from "@material-ui/icons";
import axios from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();

export default class ContinueTransaction extends Component {
  constructor(props) {
    super();
    this.state = {
      updated_by: cookies.get("email"),
      created_by: cookies.get("email"),
      disableSubmit: false,
      hasError: false,
      showDeleteModal: false,
      errMessage: ""
    };
    this.handleshowDeleteModal = this.handleshowDeleteModal.bind(this);
  }

  handleshowDeleteModal = (e) => {
    this.setState({
      showDeleteModal: !this.state.showDeleteModal,
      hasError: false,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ disableSubmit: true });
    axios
      .put("api/transaction_continue/" + this.props.transId, this.state)
      .then((res) => {
        this.props.parentCallback(true);
        this.setState({
          showDeleteModal: false,
          disableSubmit: false,
          hasError: true,
        });
      })
      .catch((err) => {
        this.setState({ disableSubmit: false, hasError: true, errMessage: err.response.data.message });
      });
  };

  render() {
    return (
      <>
        <button
          className="btn btn-outline-info btn-sm float-right"
          title="Continue with Transaction Processing"
          onClick={this.handleshowDeleteModal}
        >
          <PlayArrow />
        </button>
        <Modal
          size="md"
          show={this.state.showDeleteModal}
          onHide={() =>
            this.setState({ showDeleteModal: !this.state.showDeleteModal })
          }
        >
          <Modal.Header closeButton>
            <h4>Continue with Transaction Processing: {this.props.name}</h4>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <div
                  className={
                    this.state.hasError
                      ? "alert alert-danger"
                      : "alert alert-danger d-none"
                  }
                  role="alert"
                >
                  {this.state.errMessage}
                </div>
              </div>
            </div>
            <form onSubmit={this.handleSubmit} id="contact-form">
              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  Are you sure?
                </div>
              </div>

              <div className="float-right">
                <button
                  type="submit"
                  className="btn btn-primary btn-sm mr-2"
                  disabled={this.state.disableSubmit}
                >
                  Continue
                </button>
                <button
                  onClick={this.handleshowDeleteModal}
                  className="btn btn-danger btn-sm"
                  type="button"
                >
                  Cancel
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
