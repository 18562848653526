import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import { Link } from "react-router-dom";
import { dateFormatter } from "../../components/DtFormatter";
import axios from "axios";
import Cookies from "universal-cookie";
import ExportPayslipEmp from "./ExportPayslipEmp";

export default function PayslipDetail(props) {
  const cookies = new Cookies();
  const ability = cookies.get("ability")[2];
  const setting = cookies.get("setting");
  const [prData, setPrData] = useState([]);
  const [empPrData, setEmpPrData] = useState([]);
  const [data, setData] = useState([]);
  const [basicStatutory, setBasicStatutory] = useState([]);
  const [basicDedc, setBasicDeduc] = useState([]);
  const [basicTax, setBasicTax] = useState([]);
  const [basic, setBasic] = useState([]);
  const [earning, setEarning] = useState([]);
  const [deduction, setDeduction] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    const fetchEmp = async () => {
      let apiLink =
        "api/payslip_detail/" +
        props.match.params.cutoff_id +
        "/" +
        props.match.params.employee_id;
      const res = await axios.get(apiLink);
      setEmpPrData(res.data.payslip);
      setPrData(res.data.payslipMain);
      setDeduction(res.data.deductionDetails);
      setEarning(res.data.earningDetails);
      setBasic(res.data.basicDetails);
      setData(res.data.otDetails);
      setFilteredData(res.data.otDetails);
      // setCutoff(res.data.cutoffDetails);
      setBasicStatutory(res.data.basicStatutoryDetails);
      setBasicDeduc(res.data.basicDedDetails);
      setBasicTax(res.data.basicTax);
    };

    fetchEmp();
  }, []);

  const handleBack = () => {
    props.history.goBack();
  };

  const otSum = data.reduce(
    (a, v) => (a = a + v.ot_minutes * v.actual_rate_mins),
    0
  );

  const basicSum = basic.reduce(
    (a, v) =>
      v.is_add == 1
        ? (a = a + parseFloat(v.amount))
        : (a = a - parseFloat(v.amount)),
    0
  );

  const basicStatSum = basicStatutory.reduce(
    (a, v) => (a = a + parseFloat(v.amount)),
    0
  );

  const basicDedcSum = basicDedc.reduce(
    (a, v) => (a = a + parseFloat(v.amount)),
    0
  );

  const basicTaxSum = basicTax.reduce(
    (a, v) => (a = a + parseFloat(v.amount)),
    0
  );

  const earningSum = earning.reduce(
    (a, v) => (a = a + parseFloat(v.amount)),
    0
  );

  const deductionSum = deduction.reduce(
    (a, v) => (a = a + parseFloat(v.amount)),
    0
  );

  let netPay =
    basicSum +
    otSum +
    earningSum -
    deductionSum -
    basicDedcSum -
    basicStatSum -
    basicTaxSum;

  return (
    <div className="hk-wrapper hk-vertical-nav">
      <div class="pg-hk-wrapper">
        <Header />
        <div className="mt-20 hk-pg-wrapper">
          {ability.permission > 0 ? (
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <h3 className="pb-10">Payslip Details</h3>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  {/* <button
                    className="btn btn-sm btn-danger float-right ml-10"
                    onClick={handleBack}
                  >
                    Back to Previous Page
                  </button> */}
                  <Link
                      to={{
                        pathname: `/payslip/${prData.id}`,
                        query: `/payslip`,
                        search: props.location.search
                      }}
                      className="btn btn-sm btn-danger float-right ml-10"
                      title={"Output per Employee"}
                    >
                      Back to Previous Page
                    </Link>
                  <ExportPayslipEmp
                    id={prData.id}
                    emp_id={empPrData.employee_id}
                    emp_name={empPrData.last_name + " " + empPrData.first_name}
                    type={1}
                    className="pb-10"
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="card">
                    {/* <div className="card-header card-header-action">
                    <h5></h5>
                    <div className="d-flex align-items-center card-action-wrap-tools">
                    </div>
                  </div> */}
                    <div className="card-body">
                      <div className="row mb-10">
                        <div className="col-xl-4 col-sm-10">
                          <strong>Employee #</strong>
                        </div>
                        <div className="col-xl-8 col-sm-10">
                          :&nbsp;&nbsp;&nbsp;
                          {empPrData.emp_no}
                        </div>
                      </div>
                      <div className="row mb-10">
                        <div className="col-xl-4 col-sm-10">
                          <strong>Name</strong>
                        </div>
                        <div className="col-xl-8 col-sm-10">
                          :&nbsp;&nbsp;&nbsp;
                          {empPrData.last_name + ", " + empPrData.first_name}
                        </div>
                      </div>

                      <div className="row mb-10">
                        <div className="col-xl-4 col-sm-10">
                          <strong>Company</strong>
                        </div>
                        <div className="col-xl-8 col-sm-10">
                          :&nbsp;&nbsp;&nbsp;
                          {prData.company_name}
                        </div>
                      </div>

                      <div className="row mb-10">
                        <div className="col-xl-4 col-sm-10">
                          <strong>Period</strong>
                        </div>
                        <div className="col-xl-8 col-sm-10">
                          :&nbsp;&nbsp;&nbsp;
                          {prData.code +
                            " (" +
                            dateFormatter(prData.start_date) +
                            " - " +
                            dateFormatter(prData.end_date) +
                            ")"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div className="card">
                    <div class="card-header p-0 pt-1">
                      <ul
                        class="nav nav-tabs"
                        id="custom-tabs-two-tab"
                        role="tablist"
                      >
                        <li class="nav-item">
                          <a
                            class="nav-link active"
                            id="custom-tabs-two-home-tab"
                            data-toggle="pill"
                            href="#summary"
                            role="tab"
                            aria-controls="custom-tabs-two-home"
                            aria-selected="true"
                          >
                            &nbsp;Summary
                          </a>
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link"
                            id="custom-tabs-two-home-tab"
                            data-toggle="pill"
                            href="#earning"
                            role="tab"
                            aria-controls="custom-tabs-two-home"
                            aria-selected="true"
                          >
                            &nbsp;Earnings
                          </a>
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link"
                            id="custom-tabs-two-home-tab"
                            data-toggle="pill"
                            href="#overtime"
                            role="tab"
                            aria-controls="custom-tabs-two-home"
                            aria-selected="true"
                          >
                            &nbsp;Overtime Pay
                          </a>
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link"
                            id="custom-tabs-two-home-tab"
                            data-toggle="pill"
                            href="#deduction"
                            role="tab"
                            aria-controls="custom-tabs-two-home"
                            aria-selected="true"
                          >
                            &nbsp;Deductions
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="card-body">
                      <div class="tab-content" id="custom-tabs-two-tabContent">
                        <div
                          class="tab-pane fade show active"
                          id="summary"
                          role="tabpanel"
                          aria-labelledby="custom-tabs-two-home-tab"
                        >
                          <table className="table table-sm table-bordered table-striped mb-0">
                            <thead className="thead-secondary">
                              <tr className="text-center">
                                <th>Particulars</th>
                                <th>Earnings</th>
                                <th>Deductions</th>
                                <th>Net Pay</th>
                              </tr>
                            </thead>
                            <tbody>
                              {basic.map((detail) => (
                                <tr>
                                  <td>{detail.name}</td>
                                  <td className="text-right">
                                    {detail.is_add == 1
                                      ? (detail.amount + "")
                                          .toLocaleString()
                                          .replace(
                                            /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )
                                      : "-"}
                                  </td>
                                  <td className="text-right">
                                    {detail.is_statutory == 1 ||
                                    detail.is_deduction == 1
                                      ? (detail.amount + "")
                                          .toLocaleString()
                                          .replace(
                                            /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )
                                      : "-"}
                                  </td>
                                  <td className="text-right">-</td>
                                </tr>
                              ))}
                              {otSum != 0 ? (
                                <tr>
                                  <td>Overtime</td>
                                  <td className="text-right">
                                    {(otSum.toFixed(2) + "")
                                      .toLocaleString()
                                      .replace(
                                        /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                        ","
                                      )}
                                  </td>
                                  <td className="text-right">-</td>
                                  <td className="text-right">
                                    {(otSum.toFixed(2) + "")
                                      .toLocaleString()
                                      .replace(
                                        /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                        ","
                                      )}
                                  </td>
                                </tr>
                              ) : (
                                ""
                              )}
                              {earning.map((detail) => (
                                <tr>
                                  <td>Other Earning: {detail.name}</td>
                                  <td className="text-right">
                                    {(detail.amount + "")
                                      .toLocaleString()
                                      .replace(
                                        /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                        ","
                                      )}
                                  </td>
                                  <td className="text-right">-</td>
                                  <td className="text-right">-</td>
                                </tr>
                              ))}

                              {basicStatutory.map((detail) => (
                                <tr>
                                  <td>{detail.name}</td>
                                  <td className="text-right">
                                    {detail.is_add == 1
                                      ? (detail.amount + "")
                                          .toLocaleString()
                                          .replace(
                                            /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )
                                      : "-"}
                                  </td>
                                  <td className="text-right">
                                    {detail.is_statutory == 1 ||
                                    detail.is_deduction == 1
                                      ? (detail.amount + "")
                                          .toLocaleString()
                                          .replace(
                                            /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )
                                      : "-"}
                                  </td>
                                  <td className="text-right">-</td>
                                </tr>
                              ))}

                              {basicDedc.map((detail) => (
                                <tr>
                                  <td>{detail.name}</td>
                                  <td className="text-right">
                                    {detail.is_add == 1
                                      ? (detail.amount + "")
                                          .toLocaleString()
                                          .replace(
                                            /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )
                                      : "-"}
                                  </td>
                                  <td className="text-right">
                                    {detail.is_statutory == 1 ||
                                    detail.is_deduction == 1
                                      ? (detail.amount + "")
                                          .toLocaleString()
                                          .replace(
                                            /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                            ","
                                          )
                                      : "-"}
                                  </td>
                                  <td className="text-right">-</td>
                                </tr>
                              ))}

                              {deduction.map((detail) => (
                                <tr>
                                  <td>Other Deduction: {detail.name}</td>
                                  <td className="text-right">-</td>
                                  <td className="text-right">
                                    {(detail.amount + "")
                                      .toLocaleString()
                                      .replace(
                                        /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                        ","
                                      )}
                                  </td>
                                  <td className="text-right">-</td>
                                </tr>
                              ))}

                              {basicTax.map((detail) => (
                                <tr>
                                  <td>{detail.name}</td>
                                  <td className="text-right">-</td>
                                  <td className="text-right">
                                    {(detail.amount + "")
                                      .toLocaleString()
                                      .replace(
                                        /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                        ","
                                      )}
                                  </td>
                                  <td className="text-right">-</td>
                                </tr>
                              ))}
                              <tr>
                                <td className="text-right" colSpan={3}>
                                  <strong>Net Pay</strong>
                                </td>
                                <td className="text-right">
                                  <strong>
                                    {netPay
                                      .toFixed(2)
                                      .toLocaleString()
                                      .replace(
                                        /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                        ","
                                      )}
                                  </strong>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div
                          class="tab-pane fade show"
                          id="overtime"
                          role="tabpanel"
                          aria-labelledby="custom-tabs-two-home-tab"
                        >
                          <div className="table-responsive mb-10">
                            <table className="table table-sm table-bordered table-striped mb-0">
                              <thead className="thead-secondary">
                                <tr className="text-center">
                                  <th>Date</th>
                                  <th>Time In</th>
                                  <th>Time Out</th>
                                  <th>Description</th>
                                  <th>Overtime Hours(Minutes)</th>
                                  <th>Overtime Rate</th>
                                  <th>Amount</th>
                                </tr>
                              </thead>
                              <tbody>
                                {data.map((detail) => (
                                  <tr>
                                    <td>{detail.f_date}</td>
                                    <td>
                                      {detail.condition_id % 3 == 0
                                        ? detail.f_nd_time_in
                                        : detail.f_time_in}
                                    </td>
                                    <td>
                                      {detail.condition_id % 3 != 0
                                        ? detail.is_nd == 1
                                          ? detail.f_nd_time_in
                                          : detail.f_time_out
                                        : detail.f_time_out}
                                    </td>
                                    <td>
                                      {detail.code + " - " + detail.description}
                                    </td>
                                    <td className="text-right">
                                      {(1 * detail.ot_minutes).toFixed(2)}
                                    </td>
                                    <td className="text-right">
                                      {(1 * detail.actual_rate).toFixed(2)}
                                    </td>
                                    <td className="text-right">
                                      {(
                                        detail.ot_minutes *
                                        detail.actual_rate_mins
                                      ).toFixed(2)}
                                    </td>
                                  </tr>
                                ))}
                                <tr>
                                  <td className="text-right" colSpan={6}>
                                    <strong>Total</strong>
                                  </td>
                                  <td className="text-right">
                                    {otSum.toFixed(2)}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div
                          class="tab-pane fade show"
                          id="earning"
                          role="tabpanel"
                          aria-labelledby="custom-tabs-two-home-tab"
                        >
                          <div className="table-responsive mb-10">
                            <table className="table table-sm table-bordered table-striped mb-0">
                              <thead className="thead-secondary">
                                <tr className="text-center">
                                  <th>Earning</th>
                                  <th>Amount</th>
                                </tr>
                              </thead>
                              <tbody>
                                {basic.map((detail) => (
                                  <tr>
                                    <td>{detail.name}</td>
                                    <td className="text-right">
                                      {detail.is_add == 1
                                        ? (detail.amount + "")
                                            .toLocaleString()
                                            .replace(
                                              /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                              ","
                                            )
                                        : "-"}
                                    </td>
                                  </tr>
                                ))}
                                {earning.map((detail) => (
                                  <tr>
                                    <td>{detail.name}</td>
                                    <td className="text-right">
                                      {(detail.amount + "")
                                        .toLocaleString()
                                        .replace(
                                          /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                          ","
                                        )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div
                          class="tab-pane fade show"
                          id="deduction"
                          role="tabpanel"
                          aria-labelledby="custom-tabs-two-home-tab"
                        >
                          <div className="table-responsive mb-10">
                            <table className="table table-sm table-bordered table-striped mb-0">
                              <thead className="thead-secondary">
                                <tr className="text-center">
                                  <th>Deduction</th>
                                  <th>Amount</th>
                                </tr>
                              </thead>
                              <tbody>
                                {basicDedc.map((detail) => (
                                  <tr>
                                    <td>{detail.name}</td>
                                    <td className="text-right">
                                      {detail.is_add == 1
                                        ? (detail.amount + "")
                                            .toLocaleString()
                                            .replace(
                                              /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                              ","
                                            )
                                        : "-"}
                                    </td>
                                  </tr>
                                ))}
                                {deduction.map((detail) => (
                                  <tr>
                                    <td>{detail.name}</td>
                                    <td className="text-right">
                                      {(detail.amount + "")
                                        .toLocaleString()
                                        .replace(
                                          /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                          ","
                                        )}
                                    </td>
                                  </tr>
                                ))}
                                {basicStatutory.map((detail) => (
                                  <tr>
                                    <td>{detail.name}</td>
                                    <td className="text-right">
                                      {(detail.amount + "")
                                        .toLocaleString()
                                        .replace(
                                          /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                          ","
                                        )}
                                    </td>
                                  </tr>
                                ))}
                                {basicTax.map((detail) => (
                                  <tr>
                                    <td>{detail.name}</td>
                                    <td className="text-right">
                                      {(detail.amount + "")
                                        .toLocaleString()
                                        .replace(
                                          /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                          ","
                                        )}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <h1 className="m-0 text-center">
              The Credentials Supplied Are Not Sufficient To Access This Module
              <br />
              Please Contact Administrator
            </h1>
          )}
        </div>
      </div>
    </div>
  );
}
