import React, { useState, useEffect } from "react";
import axios from "axios";
import { dateFormatter } from "../../../components/DtFormatter";
import EditTaxExempt from "./EditTaxExempt";
import Cookies from "universal-cookie";
const cookies = new Cookies();


export default function TaxExempt() {
  const permissions = cookies.get("permissions")["config"];
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [filteredData, setFilteredData] = useState([]);
  const [fileLoaded, setFileLoaded] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      let apiLink = "api/tax_exempt";
      const res = await axios.get(apiLink);
      setData(res.data);
      setFilteredData(res.data);
    };

    if (!fileLoaded) {
      fetchData();
      setFileLoaded(true);
    }
  });

  const handleAddCallback = (editData) => {
    if (editData) {
      setFileLoaded(false);
    }
  };

  const pages = [];
  for (let i = 1; i <= Math.ceil(filteredData.length / recordsPerPage); i++) {
    pages.push(i);
  }

  const handleRecordPerPage = (e) => {
    console.log(e.target.value);
    setRecordsPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const handleSearch = (e) => {
    const filterer = data.filter(function (data) {
      for (var key in data) {
        if (
          !data["name"].toLowerCase().includes(e.target.value.toLowerCase())
        ) {
          return false;
        }
        return true;
      }
    });
    setFilteredData(filterer);
  };

  const startIndex = currentPage * recordsPerPage - recordsPerPage;
  const endIndex = startIndex + recordsPerPage;
  const currentData = filteredData.slice(startIndex, endIndex);

  return (
    <div>
      <div className="row mb-3">
        <div className="col-lg-12 col-md-12 col-sm-12"></div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="table-wrap">
            <div className="table-responsive mb-10">
              <table className="table table-sm table-bordered table-striped mb-0">
                <thead className="thead-secondary">
                  <tr className="text-center">
                    <th>Amount</th>
                    <th>Status</th>
                    <th>Date Created</th>
                    <th>Created By</th>
                    <th style={{ width: "90px" }}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {currentData.length > 0 ? (
                    currentData.map((taxExempt) => (
                      <tr key={taxExempt.id}>
                        <td>{(taxExempt.amount+'').replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                        <td>
                          <strong
                            className={
                              taxExempt.active == 0 ? "text-danger" : "text-success"
                            }
                          >
                            {taxExempt.active == 0 ? "INACTIVE" : "ACTIVE"}
                          </strong>
                        </td>
                        <td>
                          {dateFormatter(
                            taxExempt.created_at,
                            "MMM dd, yyyy hh:mm bb"
                          )}
                        </td>
                        <td>{taxExempt.created_by}</td>
                        <td>
                          <div className="btn-group">
                            {taxExempt.active == 0 ? (
                              ""
                            ) : (
                              permissions > 2 ? (
                              <EditTaxExempt
                                parentCallback={handleAddCallback}
                              />):("")
                            )}
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5" className="text-center">
                        No Data to show
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
