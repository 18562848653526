import React, { Component } from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { timeFormatter, dateFormatter } from "../../components/DtFormatter";
import Header from "../../components/Header";
import axios from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();
const textColor = ["success", "warning", "danger", "danger"];

const access = ["No Access", "View Only", "Add", "Add / Edit", "Full Access"];

class YtdaUploadDetail extends Component {
  state = {
    permissions : cookies.get("permissions")["ytd"],
    transactionData: [],
    detailData: [],
  };
  constructor() {
    super();
    this.handleBack = this.handleBack.bind(this);
  }

  componentDidMount() {
    this.getDetails();
  }

  componentDidUpdate() {
    if (this.state.shiftEdited) {
      this.getDetails();
      this.setState({ shiftEdited: false });
    }
  }

  getDetails = () => {
    const TransactionId = this.props.match.params.id;
    axios.get("api/ytd_adjustment_single/" + TransactionId).then((res) => {
      this.setState({ transactionData: res.data });
    });

    axios.get("api/ytd_adjustment_detail/" + TransactionId).then((res) => {
      this.setState({ detailData: res.data });
    });
  };

  handleBack() {
    this.props.history.goBack();
  }

  handleEditShiftCallback = (cb) => {
    if (cb) {
      this.setState({ shiftEdited: true });
    }
  };

  render() {
    return (
      <div className="hk-wrapper hk-vertical-nav">
        <div class="pg-hk-wrapper">
          <Header />
          <div className="mt-20 hk-pg-wrapper">
            {this.state.permissions > 0 ? (
              <div className="container-fluid">
                <div className="row mb-10">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <h3>YTD Adjustment Upload Detail</h3>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12">
                    {/* <button
                      className="btn btn-sm btn-danger float-right "
                      onClick={this.handleBack}
                    >
                      Back to Previous Page
                    </button> */}
                    <Link
                      className="btn btn-sm btn-danger float-right "
                      to={{
                        pathname: `/ytd_adjustment_upload`,
                        query: `/ytd_adjustment_upload`,
                        search: "?"+this.props.location.search
                      }}
                    >
                      Back to Previous Page
                    </Link>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="card">
                      {/* <div className="card-header card-header-action">
                        <h5></h5>
                        <div className="d-flex align-items-center card-action-wrap-tools"></div>
                      </div> */}
                      <div className="card-body">
                        <div className="row">
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <div className="row">
                              <div className="col-xl-6 col-sm-12 mb-10">
                                <strong>Description</strong>
                              </div>
                              <div className="col-xl-6 col-sm-12 mb-10">
                                :&nbsp;&nbsp;&nbsp;
                                {this.state.transactionData.description}
                              </div>
                              <div className="col-xl-6 col-sm-12 mb-10">
                                <strong>Uploaded by</strong>
                              </div>
                              <div className="col-xl-6 col-sm-12 mb-10">
                                :&nbsp;&nbsp;&nbsp;
                                {this.state.transactionData.created_by}
                              </div>
                              <div className="col-xl-6 col-sm-12 mb-10">
                                <strong>Uploaded On</strong>
                              </div>
                              <div className="col-xl-6 col-sm-12 mb-10">
                                :&nbsp;&nbsp;&nbsp;
                                {dateFormatter(
                                  this.state.transactionData.created_at,
                                  "MMM dd, yyyy hh:mm bb"
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <div className="row">
                              <div className="col-xl-6 col-sm-12 mb-10">
                                <strong>File</strong>
                              </div>
                              <div className="col-xl-6 col-sm-12 mb-10">
                                :&nbsp;&nbsp;&nbsp;
                                <a
                                  href={
                                    process.env.REACT_APP_API_LINK +
                                    "ytdAdjUpload/" +
                                    this.state.transactionData.file_name
                                  }
                                  target="_blank"
                                  download
                                >
                                  {this.state.transactionData.file_name}
                                </a>
                              </div>
                              <div className="col-xl-6 col-sm-12 mb-10">
                                <strong>Updated by</strong>
                              </div>
                              <div className="col-xl-6 col-sm-12 mb-10">
                                :&nbsp;&nbsp;&nbsp;
                                {this.state.transactionData.updated_by}
                              </div>
                              <div className="col-xl-6 col-sm-12">
                                <strong>Updated On</strong>
                              </div>
                              <div className="col-xl-6 col-sm-12">
                                :&nbsp;&nbsp;&nbsp;
                                {dateFormatter(
                                  this.state.transactionData.updated_at,
                                  "MMM dd, yyyy hh:mm bb"
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 col-sm-12">
                    <div class="table-responsive">
                      <table class="table table-sm table-bordered table-striped mb-0">
                        <thead className="thead-secondary">
                          <tr className="text-center">
                              <th>Year</th>
                              <th className="text-nowrap">Employee #</th>
                              <th>Employee Name</th>
                              <th>Basic</th>
                              <th className="text-nowrap">De Minimis</th>
                              <th className="text-nowrap">
                                O.Earnings
                                <br />
                                (Tax Exempt)
                              </th>
                              <th>O.Earnings</th>
                              <th>13th Month Pay</th>
                              <th>Gross Pay</th>
                              <th>SSS Premium</th>
                              <th>SSS Provident</th>

                              <th>PhilHealth</th>
                              <th>PAGIBIG</th>
                              <th>O.Deductions</th>
                              <th>WH Tax</th>
                              <th>Net Pay</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.detailData.length > 0 ? (
                            this.state.detailData.map((detail) => (
                              <tr key={detail.id}>
                                <td>{detail.record_year}</td>
                                  <td className="text-nowrap">
                                    {detail.emp_no}
                                  </td>
                                  <td className="text-nowrap">
                                    {detail.last_name +
                                      ", " +
                                      detail.first_name}
                                  </td>
                                  <td className="text-right">
                                    {detail.basic_pay.replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                  </td>
                                  <td className="text-right">
                                    {detail.deminimis.replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                  </td>
                                  <td className="text-right">
                                    {detail.other_earning_nt.replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                  </td>

                                  <td className="text-right">
                                    {detail.other_earning.replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                  </td>
                                  <td className="text-right">
                                    {detail.t_month_pay.replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                  </td>
                                  <td className="text-right">
                                    {detail.gross_pay.replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                  </td>
                                  <td className="text-right">
                                    {detail.sss_premium.replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                  </td>
                                  <td className="text-right">
                                    {detail.sss_provident.replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                  </td>

                                  <td className="text-right">
                                    {detail.philhealth.replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                  </td>
                                  <td className="text-right">
                                    {detail.pagibig.replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                  </td>
                                  <td className="text-right">
                                    {detail.other_deduction.replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                  </td>
                                  <td className="text-right">
                                    {detail.wh_tax.replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                  </td>

                                  <td className="text-right">
                                    {detail.net_pay.replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                  </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colspan="16" className="text-center">
                                No Data to show
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <>
              <h1 className="m-0 text-center">
                Your credentials is not sufficient to access this:
                <br />
              </h1>
              <br />
              <h2 className="text-center">
                Module : YTD Adjustments
                <br />
                Access Type : {access[this.state.permissions]}
                <br />
                Please contact your Payroll Administrator.
                <br />
                <a className="btn btn-primary btn-sm" href="/#/app">
                  Back to Dashboard
                </a>
              </h2>
            </>
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default withRouter(YtdaUploadDetail);
