import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import { FindInPage, Check, DirectionsRun } from "@material-ui/icons";
import axios from "axios";
import Cookies from "universal-cookie";
import { dateFormatter } from "../../components/DtFormatter";
import ExportSssContribution from "./ExportSssContribution";
import ExportSssContributionEmp from "./ExportSssContributionEmp";
const statuses = ["FOR PROCESSING", "FOR POSTING", "POSTED", "REVOKED"];

export default function SssContribution(props) {
  const cookies = new Cookies();
  const ability = cookies.get("ability")[2];
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [filteredData, setFilteredData] = useState([]);
  const [fileLoaded, setFileLoaded] = useState(false);

  const [isChecked, setIsChecked] = useState([]);
  const [filterEmpNo, setFilterEmpNo] = useState("");
  const [filterName, setFilterName] = useState("");
  const [checkAll, setCheckAll] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      let apiLink = "api/sss_contribution/" + cookies.get("transCompany");
      const res = await axios.get(apiLink);

      setData(res.data);

      const params = getQueryParams(props.location.search);
      setFilterName(params.name ? params.name : "");
      setFilterEmpNo(params.emp_no ? params.emp_no : "");
      const filterer = searcher(res.data,params.name ? params.name : "", params.emp_no ? params.emp_no : "");
      setFilteredData(filterer);

      let getDefaultChecks = [];
      res.data.map((pr_data) =>
        getDefaultChecks.push({ id: pr_data.employee_id, checked: false })
      );
      setIsChecked(getDefaultChecks);
    };

    if (!fileLoaded) {
      fetchData();
      setFileLoaded(true);
    }
  });

  const handleAddCallback = (editData) => {
    if (editData) {
      setFileLoaded(false);
    }
  };

  const pages = [];
  for (let i = 1; i <= Math.ceil(filteredData.length / recordsPerPage); i++) {
    pages.push(i);
  }

  const handleRecordPerPage = (e) => {
    setRecordsPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const getQueryParams = (query) => {
    let params = {};
    new URLSearchParams(query).forEach((value, key) => {
      let decodedKey = decodeURIComponent(key);
      let decodedValue = decodeURIComponent(value);
      // This key is part of an array
      if (decodedKey.endsWith("[]")) {
        decodedKey = decodedKey.replace("[]", "");
        params[decodedKey] || (params[decodedKey] = []);
        params[decodedKey].push(decodedValue);
        // Just a regular parameter
      } else {
        params[decodedKey] = decodedValue;
      }
    });
  
    return params;
  };

  const searcher = (data, name= filterName, empNo=filterEmpNo ) => {
    const filter1 = data.filter(function (data) {
      for (var key in data) {
        if (
          !(data["first_name"] + " " + data["last_name"])
            .toLowerCase()
            .includes(name)
        ) {
          return false;
        }
        return true;
      }
    });

    const filter2 = filter1.filter(function (data) {
      for (var key in data) {
        if (!data["emp_no"].toLowerCase().includes(empNo)) {
          return false;
        }
        return true;
      }
    });
    return filter2;
  };

  const handleSearch = (e) => {
    const filterer = searcher(data);
    setFilteredData(filterer);
    setCurrentPage(1);
  };

  const checkAllRows = (e) => {
    let getChecks = [];
    isChecked.map((checks) => {
      getChecks.push({ id: checks.id, checked: !checkAll });
    });
    setIsChecked(getChecks);
    setCheckAll(!checkAll);
  };

  const handleCheck = (e) => {
    let getChecks = [],
      totalChecks = 0;
    isChecked.map((checks) => {
      if (checks.id == e.target.value) {
        getChecks.push({ id: checks.id, checked: !checks.checked });
      } else getChecks.push({ id: checks.id, checked: checks.checked });

      if (getChecks.checked) {
        totalChecks++;
      }
    });

    setIsChecked(getChecks);

    if (totalChecks == filteredData.length) {
      setCheckAll(true);
    } else {
      setCheckAll(false);
    }
  };

  const handleFilterChange = (e, filterField) => {
    if (filterField == "empNo") {
      setFilterEmpNo(e.target.value);
    } else if (filterField == "name") {
      setFilterName(e.target.value);
    } else if (filterField == "clear") {
      setFilterEmpNo("");
      setFilterName("");
      setCheckAll(false);
      let getChecks = [];
      isChecked.map((checks) => {
        getChecks.push({ id: checks.id, checked: false });
      });
      setIsChecked(getChecks);
      setFilteredData(data);
    }
  };

  const startIndex = currentPage * recordsPerPage - recordsPerPage;
  const endIndex = startIndex + recordsPerPage;
  const currentData = filteredData.slice(startIndex, endIndex);

  console.log(data);
  return (
    <div className="hk-wrapper hk-vertical-nav">
      <div class="pg-hk-wrapper">
        <Header />
        <div className="mt-20 hk-pg-wrapper">
          {ability.permission > 0 ? (
            <div className="container-fluid">
              <div>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <h3 className="mb-10">SSS Contributions (For Employee)</h3>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    {/* <ExportSssContribution /> */}
                  </div>

                  <div className="col-sm-12 mb-20">
                    <div className="accordion" id="accordion_1">
                      <div className="card">
                        <div className="card-header d-flex justify-content-between">
                          <a
                            role="button"
                            data-toggle="collapse"
                            href="#collapse_1"
                            aria-expanded="false"
                            className="collapse show"
                          >
                            Filters
                          </a>
                        </div>
                        <div
                          id="collapse_1"
                          className="collapse show"
                          data-parent="#accordion_1"
                          role="tabpanel"
                        >
                          <div className="card-body pa-15">
                            <div className="row">
                              <div className="form-group col-md-6 col-sm-12">
                                <label>Employee #</label>
                                <input
                                  type="text"
                                  className="form-control form-control-sm"
                                  value={filterEmpNo}
                                  onChange={(e) =>
                                    handleFilterChange(e, "empNo")
                                  }
                                />
                              </div>
                              <div className="form-group col-md-6 col-sm-12">
                                <label>Name</label>
                                <input
                                  type="text"
                                  className="form-control form-control-sm"
                                  value={filterName}
                                  onChange={(e) =>
                                    handleFilterChange(e, "name")
                                  }
                                />
                              </div>

                              <div className="form-group col-md-6 col-sm-12">
                                <button
                                  className="btn btn-sm btn-primary mr-10"
                                  onClick={handleSearch}
                                >
                                  Apply Filter
                                </button>
                                <button
                                  className="btn btn-sm btn-danger"
                                  onClick={(e) =>
                                    handleFilterChange(e, "clear")
                                  }
                                >
                                  Clear Filter
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-12">
                    <div className="table-wrap">
                      <div className="row mb-10">
                        <div className="col-md-6">
                          <label>
                            <select
                              className="custom-select custom-select-sm form-control form-control-sm"
                              onChange={handleRecordPerPage}
                            >
                              <option value="10">10</option>
                              <option value="25">25</option>
                              <option value="50">50</option>
                              <option value="100">100</option>
                            </select>
                          </label>{" "}
                          items
                        </div>
                        <div className="col-md-6">
                          {/* <input
                            type="search"
                            className="form-control form-control-sm col-md-6 col-sm-6 float-right"
                            placeholder="Search"
                            onChange={handleSearch}
                          /> */}
                          <ExportSssContribution
                            dataCheck={isChecked}
                            data={filteredData}
                          />
                        </div>
                      </div>
                      <div className="table-responsive mb-10">
                        <table className="table table-sm table-bordered table-striped mb-0">
                          <thead className="thead-secondary">
                            <tr className="text-center">
                              <th>
                                <input
                                  type="checkbox"
                                  aria-label="Checkbox for following text input"
                                  onClick={checkAllRows}
                                  checked={checkAll}
                                />
                              </th>
                              <th>Employee #</th>
                              <th>Name</th>
                              <th>SSS #</th>
                              <th>Date Period</th>
                              <th>Premium ER</th>
                              <th>Premium EE</th>
                              <th>Provident ER</th>
                              <th>Provident EE</th>
                              <th>Compensation ER</th>
                              <th>Compensation EE</th>
                              <th>Total Contribution</th>
                              <th>Actions</th>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredData.length > 0 ? (
                              currentData.map((sss) => (
                                <tr key={sss.id} className="text-nowrap">
                                  <td className="text-center">
                                    <input
                                      type="checkbox"
                                      name="payroll_data"
                                      checked={
                                        isChecked.length > 0
                                          ? isChecked.find(
                                              (x) => x.id == sss.employee_id
                                            ).checked
                                          : false
                                      }
                                      onClick={handleCheck}
                                      value={sss.employee_id}
                                    />
                                  </td>
                                  <td>{sss.emp_no}</td>
                                  <td>
                                    {sss.first_name + " " + sss.last_name}
                                  </td>
                                  <td>{sss.sss_no}</td>
                                  <td>
                                    {dateFormatter(sss.start_date) +
                                      " to " +
                                      dateFormatter(sss.end_date)}
                                  </td>

                                  <td className="text-right">
                                    {parseFloat(sss.premium_er)
                                      .toFixed(2)
                                      .replace(
                                        /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                        ","
                                      )}
                                  </td>
                                  <td className="text-right">
                                    {parseFloat(sss.premium_ee)
                                      .toFixed(2)
                                      .replace(
                                        /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                        ","
                                      )}
                                  </td>
                                  <td className="text-right">
                                    {parseFloat(sss.provident_er)
                                      .toFixed(2)
                                      .replace(
                                        /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                        ","
                                      )}
                                  </td>
                                  <td className="text-right">
                                    {parseFloat(sss.provident_ee)
                                      .toFixed(2)
                                      .replace(
                                        /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                        ","
                                      )}
                                  </td>
                                  <td className="text-right">
                                      {parseFloat(sss.compensation_er)
                                        .toFixed(2)
                                        .replace(
                                          /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                          ","
                                        )}
                                    </td>
                                    <td className="text-right">
                                      {parseFloat(sss.compensation_ee)
                                        .toFixed(2)
                                        .replace(
                                          /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                          ","
                                        )}
                                    </td>
                                  <td className="text-right">
                                    {(
                                      (parseFloat(sss.premium_er) +
                                        parseFloat(sss.premium_ee) +
                                        parseFloat(sss.provident_er) +
                                        parseFloat(sss.provident_ee)+
                                        parseFloat(sss.compensation_er) +
                                        parseFloat(sss.compensation_ee)) *
                                      1
                                    )
                                      .toFixed(2)
                                      .replace(
                                        /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                        ","
                                      )}
                                  </td>
                                  <td>
                                    <div className="btn-group">
                                      <Link
                                        to={{
                                          pathname: `/sss_contribution/${sss.employee_id}`,
                                          query: `/sss_contribution`,
                                          search: "?name="+filterName+"&emp_no="+filterEmpNo
                                        }}
                                        className="btn btn-sm btn-outline-primary"
                                        title={"View per employee"}
                                      >
                                        <FindInPage />
                                      </Link>
                                      {/* <ExportSssContributionEmp
                                        employee_id={sss.employee_id}
                                        employee_name={
                                          sss.first_name + " " + sss.last_name
                                        }
                                      /> */}
                                    </div>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="11" className="text-center">
                                  No Data to show
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="drew-pagination">
                      {filteredData.length > 0
                        ? "Showing " +
                          (startIndex + 1) +
                          " to " +
                          (endIndex > filteredData.length
                            ? filteredData.length
                            : endIndex) +
                          " of " +
                          filteredData.length
                        : ""}
                      <ul class="pagination ml-2">
                        {pages.map((number) => (
                          <li
                            key={number}
                            id={number}
                            className={
                              currentPage == number
                                ? "paginate_button page-item active"
                                : "paginate_button page-item"
                            }
                            onClick={() => setCurrentPage(number)}
                          >
                            <a class="page-link">{number}</a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <h1 className="m-0 text-center">
              The Credentials Supplied Are Not Sufficient To Access This Module
              <br />
              Please Contact Administrator
            </h1>
          )}
        </div>
      </div>
    </div>
  );
}
