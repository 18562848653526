import React, { Component } from "react";
import Header from "../../components/Header";
import { Link } from "react-router-dom";
import axios from "axios";
import EditSchema from "./EditSchema";
import AddSchemaDetail from "./AddSchemaDetail";
import EditSchemaDetail from "./EditSchemaDetail";
import DeleteSchemaDetail from "./DeleteSchemaDetail";
import multiColumnSort from "multi-column-sort";
import Cookies from "universal-cookie";
import RefreshSchema from "./RefreshSchema";
const cookies = new Cookies();
const pay_period = [
  "Weekly",
  "15th & 30th (Semi-Monthly)",
  "15th (Monthly)",
  "30th (Monthly)",
];
const access = ["No Access", "View Only", "Add", "Add / Edit", "Full Access"];

export default class ViewSchema extends Component {
  state = {
    permissions :cookies.get("permissions")['config'],
    schemaMain: [],
    schemaDetail: [],
    earningDeductDD: [],
    filteredData: [],
    nameSort: "DESC",
    typeSort: "DESC",
    pay_cycle_nameSort: "DESC",
  };

  constructor() {
    super();
    this.handleBack = this.handleBack.bind(this);
  }

  componentDidMount() {
    this.getDetails();

    axios.get("api/earning_deductionDD").then((res) => {
      this.setState({ earningDeductDD: res.data });
    });
  }

  getDetails = () => {
    const schemaId = this.props.match.params.id;
    axios.get("api/basic_schema/" + schemaId).then((res) => {
      this.setState({ schemaMain: res.data });
    });

    axios.get("api/basic_schema_detail/" + schemaId).then((res) => {
      this.setState({ schemaDetail: res.data, filteredData: res.data });
    });
  };

  handleCallback = (cb) => {
    if (cb) {
      this.getDetails();
      this.setState({ updateOccured: true });
    }
  };

  handleBack() {
    this.props.history.goBack();
  }

  componentDidUpdate() {}

  handleSearch = (e) => {
    const filterer = this.state.schemaDetail.filter(function (data) {
      for (var key in data) {
        if (
          !(
            data["name"].toLowerCase().includes(e.target.value.toLowerCase()) ||
            data["type"].toLowerCase().includes(e.target.value.toLowerCase()) ||
            data["pay_cycle_name"]
              .toLowerCase()
              .includes(e.target.value.toLowerCase())
          )
        ) {
          return false;
        }
        return true;
      }
    });
    this.setState({ filteredData: filterer });
  };

  handleSort = (columnChanged, currentSort) => {
    let setSort;
    let sorter = [];
    const getColumnValue = (column, value) => {
      switch (column) {
        default:
          return value;
      }
    };

    if (columnChanged == "name") {
      sorter = [
        ["name", this.state.nameSort],
        ["type", "ASC"],
        ["pay_cycle_name", "ASC"],
      ];
    } else if (columnChanged == "type") {
      sorter = [
        ["type", this.state.typeSort],
        ["name", "ASC"],
        ["pay_cycle_name", "ASC"],
      ];
    } else if (columnChanged == "pay_cycle_name") {
      sorter = [
        ["pay_cycle_name", this.state.pay_cycle_nameSort],
        ["name", "ASC"],
        ["type", "ASC"],
      ];
    }

    const sorted = multiColumnSort(
      this.state.filteredData,
      sorter,
      getColumnValue
    );

    if (currentSort == "ASC") setSort = "DESC";
    else setSort = "ASC";

    this.setState({ filteredData: sorted, [columnChanged + "Sort"]: setSort });
  };

  render() {
    return (
      <div className="hk-wrapper hk-vertical-nav">
        <div class="pg-hk-wrapper">
          <Header />
          <div className="mt-20 hk-pg-wrapper">
            {this.state.permissions > 0 ? (
              <div className="container-fluid">
                <div className="row pt-10 pb-10">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <h3>Basic Pay Schema View</h3>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12">
                    {/* <button
                      className="btn btn-sm btn-danger float-right"
                      onClick={this.handleBack}
                    >
                      Back to Previous Page
                    </button> */}
                    <Link
                      to={{
                        pathname: `/basic_schema`,
                        query: `/basic_schema`,
                        search: "?search="+this.props.location.search.split("=").pop()
                      }}
                      className="btn btn-sm btn-danger float-right "
                      title={"View Transaction Details"}
                    >
                      Back to Previous Page
                    </Link>
                  </div>
                </div>

                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="card">
                      <div className="card-header card-header-action">
                        <h5>Details</h5>
                        <div className="d-flex align-items-center card-action-wrap-tools">
                          {this.state.permissions > 2 ? (
                            <>
                              <EditSchema
                                parentCallback={this.handleCallback}
                                schemaMain={this.state.schemaMain}
                              />
                              <RefreshSchema
                                parentCallback={this.handleCallback}
                                id={this.state.schemaMain.id}
                              />
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="row mb-10">
                          <div className="col-xl-4 col-sm-10">
                            <strong>Code</strong>
                          </div>
                          <div className="col-xl-8 col-sm-10">
                            :&nbsp;&nbsp;&nbsp;
                            {this.state.schemaMain.code}
                          </div>
                        </div>
                        <div className="row mb-10">
                          <div className="col-xl-4 col-sm-10">
                            <strong>Description</strong>
                          </div>
                          <div className="col-xl-8 col-sm-10">
                            :&nbsp;&nbsp;&nbsp;
                            {this.state.schemaMain.description}
                          </div>
                        </div>
                        {/* <div className="row mb-10">
                          <div className="col-xl-4 col-sm-10">
                            <strong>Override 13th Month Special Pay</strong>
                          </div>
                          <div className="col-xl-8 col-sm-10">
                            :&nbsp;&nbsp;&nbsp;
                            {this.state.schemaMain.override_sp_text}
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row pb-10">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    {this.state.permissions > 1 ? (
                      <AddSchemaDetail
                        schema_id={this.props.match.params.id}
                        company_co={this.state.schemaMain.pay_period}
                        earningDeductDD={this.state.earningDeductDD}
                        parentCallback={this.handleCallback}
                      />
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="col-md-6 pt-10"></div>
                  <div className="col-md-6 pt-10">
                    <input
                      type="search"
                      className="form-control form-control-sm col-md-6 col-sm-6 float-right"
                      placeholder="Search..."
                      onChange={this.handleSearch}
                    />
                  </div>
                </div>
                <div className="table-responsive">
                  <table className="table table-sm table-bordered table-striped mb-0">
                    <thead className="thead-secondary">
                      <tr className="text-center">
                        <th
                          onClick={() =>
                            this.handleSort("name", this.state.nameSort)
                          }
                          style={{ cursor: "pointer" }}
                        >
                          Earning/Deduction &nbsp;
                          <i
                            className={
                              this.state.nameSort == "ASC"
                                ? "fa fa-arrow-down"
                                : "fa fa-arrow-up"
                            }
                          ></i>
                        </th>
                        <th
                          onClick={() =>
                            this.handleSort("type", this.state.typeSort)
                          }
                          style={{ cursor: "pointer" }}
                        >
                          Transaction Type &nbsp;
                          <i
                            className={
                              this.state.typeSort == "ASC"
                                ? "fa fa-arrow-down"
                                : "fa fa-arrow-up"
                            }
                          ></i>
                        </th>
                        <th
                          onClick={() =>
                            this.handleSort(
                              "pay_cycle_name",
                              this.state.pay_cycle_nameSort
                            )
                          }
                          style={{ cursor: "pointer" }}
                        >
                          Pay Period Release &nbsp;
                          <i
                            className={
                              this.state.pay_cycle_nameSort == "ASC"
                                ? "fa fa-arrow-down"
                                : "fa fa-arrow-up"
                            }
                          ></i>
                        </th>
                        <th style={{ width: "140px" }}>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.filteredData.length > 0 ? (
                        this.state.filteredData.map((detail) => (
                          <tr key={detail.id}>
                            <td>{detail.name}</td>
                            <td className="text-center">
                              {detail.is_add == 1 ? (
                                <strong className="text-success">
                                  Earning
                                </strong>
                              ) : (
                                ""
                              )}
                              {detail.is_statutory == 1 ? (
                                <strong className="text-warning">
                                  Statutory
                                </strong>
                              ) : (
                                ""
                              )}
                              {detail.is_deduction == 1 ? (
                                <strong className="text-danger">
                                  Deduction
                                </strong>
                              ) : (
                                ""
                              )}
                            </td>
                            <td className="text-center">
                              {detail.pay_cycle_name}
                            </td>

                            <td>
                              {this.state.permissions > 3 ? (
                                <DeleteSchemaDetail
                                  parentCallback={this.handleCallback}
                                  id={detail.id}
                                  name={detail.name}
                                />
                              ) : (
                                ""
                              )}
                              {this.state.permissions > 2 ? (
                                <EditSchemaDetail
                                  earningDeductDD={this.state.earningDeductDD}
                                  parentCallback={this.handleCallback}
                                  company_co={this.state.schemaMain.pay_period}
                                  schemaDetail={detail}
                                />
                              ) : (
                                ""
                              )}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan={10} className="text-center">
                            No Data to show
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            ) : (
              <>
                <h1 className="m-0 text-center">
                  Your credentials is not sufficient to access this:
                  <br />
                </h1>
                <br />
                <h2 className="text-center">
                  Module : Master Data
                  <br />
                  Access Type : {access[this.state.permissions]}
                  <br />
                  Please contact your Payroll Administrator.
                  <br />
                  <a className="btn btn-primary btn-sm" href="/#/app">
                    Back to Dashboard
                  </a>
                </h2>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}
