import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { Add } from "@material-ui/icons";
import { Link } from "react-router-dom";
import axios from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();
// const domain = 'payroll.my247i.com'
// const domain = 'payrolldev.my247i.com'
// const domain = 'payroll.hermosabright.com'
// const domain = 'devpayroll.hermosabright.com'
// const domain = 'payroll.uplines.net'
// const domain = 'devpayroll.uplines.net'
// const domain = 'otc.my247i.com'
// const domain = 'localhost'
const domain = process.env.REACT_APP_DOMAIN;


export default class UpdateUserDetails extends Component {
  constructor(props) {
    super();
    this.state = {
      showAddModal: false,
      withErrors: false,
      UID: cookies.get("uid"),
      name: cookies.get("name"),
      email: cookies.get("email"),
      errors: [],
    };
    this.handleshowAddModal = this.handleshowAddModal.bind(this);
  }
  

  handleshowAddModal = (e) => {
    this.setState({ showAddModal: !this.state.showAddModal });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    axios
      .put("api/user_update/" + this.state.UID, this.state)
      .then((res) => {
        cookies.set("name", this.state.name, { path: '/', domain: domain});
        cookies.set("email", this.state.email, { path: '/', domain: domain});
        // cookies.set("name", this.state.name);
        // cookies.set("email", this.state.email);
        this.setState({ showAddModal: false, withErrors: false });
        this.props.parentCallback(true);
      })
      .catch((err) => {
        this.setState({ withErrors: true, errors: "" });
      });
  };

  render() {
    return (
      <>
        <Link className="dropdown-item" onClick={this.handleshowAddModal}>
          <i className="dropdown-icon zmdi zmdi-account-box-o"></i>Update User
          Details
        </Link>
        <Modal
          size="md"
          show={this.state.showAddModal}
          backdrop="static"
          onHide={() =>
            this.setState({ showAddModal: !this.state.showAddModal })
          }
        >
          <Modal.Header closeButton>
            <h4>Update User Details</h4>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={this.handleSubmit} id="contact-form">
              <div className="row">
                {this.state.withErrors ? (
                  <div class="form-group col-md-12">
                    <div class="form-group alert alert-danger col-md-12">
                      <div>{this.state.errors.name}</div>
                      <div>{this.state.errors.email}</div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                <div className="form-group col-md-12 col-sm-12">
                  <label>Email</label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    onChange={(e) => this.setState({ email: e.target.value })}
                    defaultValue={cookies.get("email")}
                  />
                </div>
              </div>
              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  <label>Name</label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    onChange={(e) => this.setState({ name: e.target.value })}
                    defaultValue={cookies.get("name")}
                  />
                </div>
              </div>

              <div className="float-right">
                <button type="submit" className="btn btn-primary btn-sm mr-2">
                  Save
                </button>
                <button
                  onClick={this.handleshowAddModal}
                  className="btn btn-danger btn-sm"
                  type="button"
                >
                  Cancel
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
