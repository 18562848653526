import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { Add } from "@material-ui/icons";
import axios from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();
const pay_period = ["Weekly", "15th & 30th", "15th", "30th"];
export default class AddED extends Component {
  constructor(props) {
    super();
    this.state = {
      updated_by: cookies.get("email"),
      created_by: cookies.get("email"),
      showAddModal: false,
      disableSubmit: false,
      is_add: 0,
      is_statutory: 0,
      is_deduction: 0,
      number_sign: "",
      ytd_field: "",
      hasError: false,
    };
    this.handleshowAddModal = this.handleshowAddModal.bind(this);
  }

  handleshowAddModal = (e) => {
    this.setState({ showAddModal: !this.state.showAddModal, hasError: false });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ disableSubmit: true });
    let data =  {
      name: this.state.name,
      tax_exempt: this.state.tax_exempt,
      is_add: this.state.is_add,
      is_statutory: this.state.is_statutory,
      is_deduction: this.state.is_deduction,
      is_taxable: this.state.is_taxable,
      number_sign: this.state.number_sign,
      ytd_field: this.state.ytd_field,
    }
    if (
      this.state.is_add == 1 &&
      this.state.ytd_field != 1 &&
      this.state.number_sign == 1 &&
      this.state.is_taxable == 0 &&
      this.state.tax_exempt == 0
    ) {
      this.setState({ ytd_field: 11 });
      data =  {
        name: this.state.name,
        tax_exempt: this.state.tax_exempt,
        is_add: this.state.is_add,
        is_statutory: this.state.is_statutory,
        is_deduction: this.state.is_deduction,
        is_taxable: this.state.is_taxable,
        number_sign: this.state.number_sign,
        ytd_field: 11,
      }
    }
    axios
      .post("api/new_earning_deduction", data)
      .then((res) => {
        this.props.parentCallback(true);
        this.setState({ showAddModal: false, disableSubmit: false });
      })
      .catch((err) => {
        this.setState({ disableSubmit: false, hasError: true });
      });
    // this.setState({ showAddModal: false, disableSubmit: false });
  };

  render() {
    return (
      <>
        <button
          className="btn btn-outline-primary btn-sm float-right"
          title="Add New Earning/Deduction"
          onClick={this.handleshowAddModal}
        >
          <Add /> New Earning/Deduction
        </button>
        <Modal
          size="md"
          backdrop="static"
          show={this.state.showAddModal}
          onHide={() =>
            this.setState({ showAddModal: !this.state.showAddModal })
          }
        >
          <Modal.Header closeButton>
            <h4>Add Earning/Deduction</h4>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <div
                  className={
                    this.state.hasError
                      ? "alert alert-danger"
                      : "alert alert-danger d-none"
                  }
                  role="alert"
                >
                  Earning/Deduction Name Already Exist
                </div>
              </div>
            </div>
            <form onSubmit={this.handleSubmit} id="contact-form">
              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  <label>Name</label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    onChange={(e) => this.setState({ name: e.target.value })}
                  />
                </div>
                <div className="form-group col-md-12 col-sm-12">
                  <label>Type</label>
                  <select
                    class="form-control custom-select"
                    required
                    onChange={(e) => {
                      if (e.target.value == 0) {
                        this.setState({
                          is_add: 0,
                          is_statutory: 1,
                          is_deduction: 0,
                          is_taxable: 0,
                          tax_exempt: 0,
                          number_sign: 1,
                        });
                      } else if (e.target.value == 1) {
                        this.setState({
                          is_add: 1,
                          is_statutory: 0,
                          is_deduction: 0,
                        });
                      } else if (e.target.value == 2) {
                        this.setState({
                          is_add: 0,
                          is_statutory: 0,
                          is_deduction: 1,
                          is_taxable: 0,
                          tax_exempt: 0,
                          number_sign: 1,
                        });
                      }
                    }}
                  >
                    <option value="">Select Type</option>
                    <option value="0">Statutory</option>
                    <option value="1">Earning</option>
                    <option value="2">Deduction</option>
                  </select>
                </div>

                <div className="form-group col-md-12 col-sm-12">
                  <label>YTD Field</label>
                  <select
                    class="form-control custom-select"
                    required
                    defaultValue={this.state.ytd_field}
                    onChange={(e) => {
                      this.setState({ ytd_field: e.target.value });
                      if (e.target.value == 11) {
                        this.setState({
                          is_taxable: 0,
                          tax_exempt: 0,
                          number_sign: 1,
                        });
                      }
                    }}
                  >
                    <option value="">Select Type</option>
                    {this.state.is_add == 1 ? (
                      <>
                        <option value="0">Basic</option>
                        <option value="1">Deminimis</option>
                        <option value="2">O.Earning Exempt</option>
                        <option value="3">O.Earning</option>
                        <option value="11">Non Taxed Earnings</option>
                        <option value="4">13 Month Pay</option>
                      </>
                    ) : (
                      ""
                    )}
                    {this.state.is_add != 1 ? (
                      <>
                        <option value="5">SSS Premium</option>
                        <option value="6">SSS Provident</option>
                        <option value="7">PhilHealth</option>
                        <option value="8">PAGIBIG</option>
                        <option value="9">O.Deductions</option>
                        <option value="10">WH Tax</option>
                      </>
                    ) : (
                      ""
                    )}
                  </select>
                </div>

                <div className="form-group col-md-12 col-sm-12">
                  <label>Sign</label>
                  <div className="row">
                    <div className="col-sm-2">
                      <div class="custom-control custom-radio">
                        <input
                          type="radio"
                          id="signRadio1"
                          name="signRadio"
                          class="custom-control-input"
                          value="1"
                          required
                          disabled={
                            this.state.is_add == 1
                              ? this.state.ytd_field == 11
                                ? "disabled"
                                : ""
                              : "disabled"
                          }
                          onChange={(e) => {
                            this.setState({ number_sign: e.target.value });
                          }}
                          checked={
                            this.state.number_sign == 1
                              ? "checked"
                              : this.state.ytd_field == 11
                              ? "disabled"
                              : ""
                          }
                        />
                        <label class="custom-control-label" for="signRadio1">
                          Positive
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-2">
                      <div class="custom-control custom-radio">
                        <input
                          type="radio"
                          id="signRadio2"
                          name="signRadio"
                          class="custom-control-input"
                          value="0"
                          disabled={
                            this.state.is_add == 1
                              ? this.state.ytd_field == 11
                                ? "disabled"
                                : ""
                              : "disabled"
                          }
                          onChange={(e) => {
                            this.setState({ number_sign: e.target.value });
                          }}
                          checked={this.state.number_sign == 0 ? "checked" : ""}
                        />
                        <label class="custom-control-label" for="signRadio2">
                          Negative
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-group col-md-12 col-sm-12">
                  <label>Taxable</label>
                  <div className="row">
                    <div className="col-sm-2">
                      <div class="custom-control custom-radio">
                        <input
                          type="radio"
                          id="customRadio1"
                          name="customRadio"
                          class="custom-control-input"
                          value="1"
                          required
                          disabled={
                            this.state.is_add == 1
                              ? this.state.ytd_field == 11
                                ? "disabled"
                                : ""
                              : "disabled"
                          }
                          onChange={(e) => {
                            this.setState({ is_taxable: e.target.value });
                          }}
                          checked={this.state.is_taxable == 1 ? "checked" : ""}
                        />
                        <label class="custom-control-label" for="customRadio1">
                          Yes
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-2">
                      <div class="custom-control custom-radio">
                        <input
                          type="radio"
                          id="customRadio2"
                          name="customRadio"
                          class="custom-control-input"
                          value="0"
                          disabled={
                            this.state.is_add == 1
                              ? this.state.ytd_field == 11
                                ? "disabled"
                                : ""
                              : "disabled"
                          }
                          onChange={(e) => {
                            this.setState({ is_taxable: e.target.value });
                          }}
                          checked={
                            this.state.is_taxable == 0
                              ? "checked"
                              : this.state.ytd_field == 11
                              ? "checked"
                              : ""
                          }
                        />
                        <label class="custom-control-label" for="customRadio2">
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="form-group col-md-12 col-sm-12">
                  <label>Tax Exempt Ceiling</label>
                  <div className="row">
                    <div className="col-sm-2">
                      <div class="custom-control custom-radio">
                        <input
                          type="radio"
                          id="exemptRadio1"
                          name="exemptRadio"
                          class="custom-control-input"
                          value="1"
                          required
                          disabled={
                            this.state.is_add == 1
                              ? this.state.ytd_field == 11
                                ? "disabled"
                                : ""
                              : "disabled"
                          }
                          onChange={(e) => {
                            this.setState({ tax_exempt: e.target.value });
                          }}
                          checked={this.state.tax_exempt == 1 ? "checked" : ""}
                        />
                        <label class="custom-control-label" for="exemptRadio1">
                          Yes
                        </label>
                      </div>
                    </div>
                    <div className="col-sm-2">
                      <div class="custom-control custom-radio">
                        <input
                          type="radio"
                          id="exemptRadio2"
                          name="exemptRadio"
                          class="custom-control-input"
                          value="0"
                          disabled={
                            this.state.is_add == 1
                              ? this.state.ytd_field == 11
                                ? "disabled"
                                : ""
                              : "disabled"
                          }
                          onChange={(e) => {
                            this.setState({ tax_exempt: e.target.value });
                          }}
                          checked={
                            this.state.tax_exempt == 0
                              ? "checked"
                              : this.state.ytd_field == 11
                              ? "checked"
                              : ""
                          }
                        />
                        <label class="custom-control-label" for="exemptRadio2">
                          No
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="float-right">
                <button
                  type="submit"
                  className="btn btn-primary btn-sm mr-2"
                  disabled={this.state.disableSubmit}
                >
                  Save
                </button>
                <button
                  onClick={this.handleshowAddModal}
                  className="btn btn-danger btn-sm"
                  type="button"
                >
                  Cancel
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
