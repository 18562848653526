import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { Check } from "@material-ui/icons";
import axios from "axios";
import Cookies from "universal-cookie";
import Processing from "../../components/Processing";
const cookies = new Cookies();

export default class SpecialRerun extends Component {
  constructor(props) {
    super();
    this.state = {
      showModal: false,
      hasError: false,
      disableSubmit: false,
      updated_by: cookies.get("email"),
      company_id: cookies.get("transCompany"),
      refreshDisabled: false,
      errMessage: "",
    };
    this.handleshowDeleteModal = this.handleshowDeleteModal.bind(this);
  }

  handleshowDeleteModal = (e) => {
    this.setState({ hasError: false, showModal: !this.state.showModal });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    let data = {
      updated_by: cookies.get("email"),
      company_id: cookies.get("transCompany"),
      sched_year: this.props.schedData.sched_year,
      paysched_id: this.props.schedData.paysched_id,
    };
    // this.setState({ disableSubmit: true });
    this.setState({
      disableSubmit: true,
      refreshDisabled: true,
      showModal: false,
    });
    axios
      .post("api/special_draft/" + this.props.schedData.id, data)
      .then((res) => {
        // this.props.parentCallback(true);
        // this.setState({ showModal: false,hasError: false, disableSubmit: false });
        setTimeout(
          function () {
            this.props.parentCallback(true);
            this.setState({
              refreshDisabled: false,
              showModal: false,
              hasError: false,
              disableSubmit: false,
            });
          }.bind(this),
          1000
        );
      })
      .catch((err) => {
        // this.setState({ disableSubmit: false, hasError: true, errMessage: err.response.data.message });
        this.setState({
          showModal: true,
          refreshDisabled: false,
          disableSubmit: false,
          hasError: true,
          errMessage: err.response.data.message,
        });
      });
  };

  render() {
    return (
      <>
        <button
          className="btn btn-gradient-warning"
          title="Re-run Draft Special Payroll"
          onClick={this.handleshowDeleteModal}
          disabled={this.props.schedData.status <= 1 ? "" : "disabled"}
        >
          Re-Run
        </button>
        <Modal
          size="md"
          show={this.state.showModal}
          onHide={() => this.setState({ showModal: !this.state.showModal })}
        >
          <Modal.Header closeButton>
            <h4>
              Re-run Draft Special Payroll:
              <br />
            </h4>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <div
                  className={
                    this.state.hasError
                      ? "alert alert-danger"
                      : "alert alert-danger d-none"
                  }
                  role="alert"
                >
                  {this.state.errMessage}
                </div>
              </div>
            </div>
            <form onSubmit={this.handleSubmit} id="contact-form">
              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  Are you sure?
                </div>
              </div>
              <div className="float-right">
                <button
                  type="submit"
                  className="btn btn-primary btn-sm mr-2"
                  disabled={this.state.disableSubmit}
                >
                  Re-run Draft Special Payroll
                </button>
                <button
                  onClick={this.handleshowDeleteModal}
                  className="btn btn-danger btn-sm"
                  type="button"
                >
                  Cancel
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
        <Processing
          show={this.state.refreshDisabled}
          name="Special Draft Run "
        />
      </>
    );
  }
}
