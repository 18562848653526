import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { CloudUpload } from "@material-ui/icons";
import axios from "axios";
import Cookies from "universal-cookie";
import { FileUploader } from "react-drag-drop-files";
const cookies = new Cookies();

export default class SssUpdate extends Component {
  constructor(props) {
    super();
    this.state = {
      showAddModal: false,
      showCheckModal: false,
      showSuccessModal: false,
      hasError: false,
      hasWarning: false,
      disableAdd: false,
      checkData: [],
      err_no: 0,
      rec_no: 0,
      header_err: "",
    };
    this.handleshowAddModal = this.handleshowAddModal.bind(this);
  }

  handleshowAddModal = (e) => {
    this.setState({
      showAddModal: !this.state.showAddModal,
      disableAdd: false,
      hasError: false,
      hasWarning: false,
      actual_file: null,
      file_name: null,
    });
  };

  handleshowCheckModal = (e) => {
    this.setState({
      showCheckModal: !this.state.showCheckModal,
      disableAdd: false,
      hasError: false,
      hasWarning: false,
    });
  };

  handleCheck = (e) => {
    e.preventDefault();
    // this.setState({ disableAdd: true });
    let formData = new FormData();
    formData.append("uploadFile", this.state.actual_file);
    formData.append("file_name", this.state.file_name);
    formData.append("description", this.state.description);
    formData.append("record_year", this.state.record_year);
    formData.append("company_id", cookies.get("transCompany"));
    formData.append("updated_by", cookies.get("email"));
    formData.append("created_by", cookies.get("email"));

    axios.post("api/upload_sss_bracket_check", formData).then((res) => {
      this.setState({
        disableAdd: false,
        showAddModal: false,
        showCheckModal: true,
        checkData: res.data.rowData,
        rec_no: res.data.rows,
        err_no: res.data.errs.length,
        header_err: res.data.header_err,
      });
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ disableAdd: true });
    let formData = new FormData();
    formData.append("uploadFile", this.state.actual_file);
    formData.append("file_name", this.state.file_name);
    axios
      .post("api/upload_sss_bracket", formData)
      .then((res) => {
        this.setState({
          disableAdd: false,
          hasError: false,
          showAddModal: false,
          showCheckModal: false,
          showSuccessModal: true,
          actual_file: null,
          file_name: null,
        });
        this.props.parentCallback(true);
      })
      .catch((err) => {
        this.setState({ disableAdd: false, hasError: true });
      });
  };

  handleChange = (file) => {
    this.setState({ actual_file: file, file_name: file.name });
  };

  ErrCancel = (e) => {
    e.preventDefault();
    this.setState({
      disableAdd: false,
      showAddModal: false,
      showCheckModal: false,
      showSuccessModal: false,
      hasError: false,
      actual_file: null,
      file_name: null,
    });
    this.props.parentCallback(true);
  };

  render() {
    return (
      <>
        <button
          className="btn btn-outline-primary btn-sm"
          title="Upload New SSS Bracket"
          onClick={this.handleshowAddModal}
        >
          <CloudUpload /> Upload New SSS Bracket
        </button>
        <Modal
          size="md"
          backdrop="static"
          show={this.state.showAddModal}
          onHide={() =>
            this.setState({ showAddModal: !this.state.showAddModal })
          }
        >
          <Modal.Header closeButton>
            <h4>Upload New SSS Bracket</h4>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <div
                  className={
                    this.state.hasError
                      ? "alert alert-danger"
                      : "alert alert-danger d-none"
                  }
                  role="alert"
                >
                  Please Use SSS Table Template
                </div>
              </div>
            </div>
            <form onSubmit={this.handleCheck} id="contact-form">
              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  <label>SSS Template File</label>
                  <FileUploader
                    multiple={false}
                    handleChange={this.handleChange}
                    types={["XLSX"]}
                    classes="dropzone drop_area"
                  />
                  {this.state.actual_file ? (
                    <div
                      className="alert alert-success font-weight-bold"
                      role="alert"
                    >
                      Uploaded File name: {this.state.file_name}
                    </div>
                  ) : (
                    <div className="alert alert-danger" role="alert">
                      No File Uploaded
                    </div>
                  )}
                </div>
              </div>
              <div className="float-right">
                <button
                  type="submit"
                  className="btn btn-primary btn-sm mr-2"
                  disabled={this.state.disableAdd}
                >
                  Proceed
                </button>
                <button
                  onClick={this.handleshowAddModal}
                  className="btn btn-danger btn-sm"
                  type="button"
                >
                  Cancel
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
        <Modal
          size="xl"
          show={this.state.showCheckModal}
          backdrop="static"
          onHide={() =>
            this.setState({ showCheckModal: !this.state.showCheckModal })
          }
        >
          <Modal.Header closeButton>
            <h4>Upload New Tax Bracket: Checking</h4>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <div className="alert alert-info">
                  <strong>File:</strong> {this.state.file_name}
                  <br />
                  <strong>Total Records:</strong> {this.state.rec_no}
                  <br />
                  <strong>Total Errors:</strong> {this.state.err_no}
                  <br />
                </div>
              </div>
            </div>
            <div class="table-responsive mb-10">
              <table class="table table-xs table-bordered mb-0">
                <thead className="thead-secondary">
                  <tr className="text-center">
                    <th>#</th>
                    <th>LOWER RANGE</th>
                    <th>UPPER RANGE</th>
                    <th>PREMIUM ER</th>
                    <th>PREMIUM EE</th>
                    <th>PROVIDENT ER</th>
                    <th>PROVIDENT EE</th>
                    <th>COMPENSATION ER</th>
                    <th>COMPENSATION EE</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.checkData.map((row) => (
                    <tr key={row.number}>
                      <td>{row.number}</td>
                      <td
                        className={
                          row.err1 == 1 ? "bg-danger text-right" : " text-right"
                        }
                      >
                        {row.sss_lower_range}
                      </td>
                      <td
                        className={
                          row.err2 == 1 ? "bg-danger text-right" : " text-right"
                        }
                      >
                        {row.sss_upper_range}
                      </td>
                      <td
                        className={
                          row.err3 == 1 ? "bg-danger text-right" : " text-right"
                        }
                      >
                        {row.premium_er}
                      </td>
                      <td
                        className={
                          row.err4 == 1 ? "bg-danger text-right" : " text-right"
                        }
                      >
                        {row.premium_ee}
                      </td>
                      <td
                        className={
                          row.err5 == 1 ? "bg-danger text-right" : " text-right"
                        }
                      >
                        {row.provident_er}
                      </td>
                      <td
                        className={
                          row.err6 == 1 ? "bg-danger text-right" : " text-right"
                        }
                      >
                        {row.provident_ee}
                      </td>
                      <td
                        className={
                          row.err7 == 1 ? "bg-danger text-right" : " text-right"
                        }
                      >
                        {row.compensation_er}
                      </td>
                      <td
                        className={
                          row.err8 == 1 ? "bg-danger text-right" : " text-right"
                        }
                      >
                        {row.compensation_ee}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {this.state.err_no > 0 || this.state.header_err === "Failed" ? (
              this.state.header_err === "Failed" ? (
                <div className="row">
                  <div className="col-md-12 col-sm-12">
                    <div className="alert alert-danger">
                      Invalid Excel Template, please use correct the template
                      and re-upload
                    </div>
                  </div>
                </div>
              ) : (
                <div className="row">
                  <div className="col-md-12 col-sm-12">
                    <div className="alert alert-danger">
                      {this.state.err_no} Invalid record(s) found, please
                      correct the data and re-upload
                    </div>
                  </div>
                </div>
              )
            ) : (
              ""
            )}
          </Modal.Body>
          <Modal.Footer>
            {this.state.err_no > 0 || this.state.header_err === "Failed" ? (
              <div className="float-right">
                <button
                  onClick={this.ErrCancel}
                  className="btn btn-danger btn-sm"
                  type="button"
                >
                  Cancel
                </button>
              </div>
            ) : (
              <div className="float-right">
                <button
                  onClick={this.handleSubmit}
                  className="btn btn-primary btn-sm mr-2"
                  type="button"
                  disabled={this.state.disableAdd}
                >
                  Save
                </button>
                <button
                  onClick={this.handleshowCheckModal}
                  className="btn btn-danger btn-sm"
                  type="button"
                >
                  Cancel
                </button>
              </div>
            )}
          </Modal.Footer>
        </Modal>
        <Modal
          size="lg"
          show={this.state.showSuccessModal}
          backdrop="static"
          onHide={() =>
            this.setState({ showSuccessModal: !this.state.showSuccessModal })
          }
        >
          <Modal.Header closeButton>
            <h4>Upload New Tax Bracket: Successful</h4>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <div className="alert alert-success">
                  <strong>Import Result</strong>
                  <br />
                  <strong>Total Records:</strong> {this.state.rec_no}
                  <br />
                  <strong>Total Errors:</strong> {this.state.err_no}
                  <br />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="float-right">
              <button
                onClick={() =>
                  this.setState({
                    showSuccessModal: !this.state.showSuccessModal,
                  })
                }
                className="btn btn-danger btn-sm"
                type="button"
              >
                Close
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
