import React, { Component } from "react";
import auth from "../components/Auth";
import { Link, Redirect } from "react-router-dom";
import Cookies from "universal-cookie";
const cookies = new Cookies();

export default class LandingPage extends Component {
  constructor(){
    super();
    this.state = {
      email: "",
      password: "",
      dataReturned: null
    }
    this.handleLogin = this.handleLogin.bind(this);
  }

  handleLogin = (e) => {
    e.preventDefault();
    this.setState({dataReturned: auth.login(this.state)});
    console.log(this.state.dataReturned);
  }
  
  render() {
    if(cookies.get("token")){
      this.props.history.push('/app')
    }
    return (
        <div className="hk-pg-wrapper hk-auth-wrapper">
          <div className="container-fluid">
            <div className="row">
              <div className="col-xl-12 pa-0">
                <div className="auth-form-wrap pt-xl-0 pt-70 landing">
                  <div className="auth-form w-xl-35 w-lg-65 w-sm-85 w-100 card pa-25 shadow-lg">
                    <div className="media text-center mb-20">
                      <div className="media-img-wrap">
                        <img
                          className="brand-img"
                          src="logo192.png"
                          alt="brand"
                        /> 
                      </div>
                      <div className="media-body">
                        <h1 style={{"marginTop":"8px"}}>Payroll System</h1>
                      </div>
                    </div>
                    <form onSubmit={this.handleLogin}>
                      <div className="form-group">
                        <input
                          className="form-control"
                          placeholder="Email"
                          type="email"
                          required
                          onChange={(e) => (this.setState({ email: e.target.value }))}
                        />
                      </div>
                      <div className="form-group">
                        <div className="input-group">
                          <input
                            className="form-control"
                            placeholder="Password"
                            type="password"
                            required
                            onChange={(e) => (this.setState({ password: e.target.value }))}
                          />
                        </div>
                      </div>
                      <button
                        className="btn btn-success btn-block"
                        type="submit"
                      >
                        Login
                      </button>
                      <p className="font-14 text-center mt-15">
                        Having trouble logging in?{" "}
                        <Link to="/forgotPassword">Forgot Password</Link>
                      </p>
                      <a className="btn btn-red btn-block btn-wth-icon mt-15" href={process.env.REACT_APP_API_LINK+"api/login_provider"}>
                        <span className="icon-label">
                          <i className="fa fa-google"></i>
                        </span>
                        <span className="btn-text">Login with Google</span>
                      </a>
                      {(cookies.get("badCreds"))? (
                        <blockquote className="blockquote mt-15">{cookies.get("badCreds")}.</blockquote>
                      ) : (
                        ""
                      )}
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    );
  }
}
