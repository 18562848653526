import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import { FindInPage, Check, DirectionsRun, Filter } from "@material-ui/icons";
import axios from "axios";
import Cookies from "universal-cookie";
import multiColumnSort from "multi-column-sort";
import { dateFormatter } from "../../components/DtFormatter";
import ExportPrRegister from "./ExportPrRegister";
const statuses = ["FOR PROCESSING", "FOR POSTING", "POSTED", "REVOKED"];

export default function PayrollRegister() {
  const cookies = new Cookies();
  const ability = cookies.get("ability")[2];
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [filteredData, setFilteredData] = useState([]);
  const [fileLoaded, setFileLoaded] = useState(false);
  const [codeSort, setCodeSort] = useState("DESC");
  const [typeSort, setTypeSort] = useState("ASC");
  const [isChecked, setIsChecked] = useState([]);
  const [filterPrIdFrom, setFilterPrIdFrom] = useState("");
  const [filterPrIdTo, setFilterPrIdTo] = useState("");
  const [filterDesc, setFilterDesc] = useState("");
  const [filterType, setFilterType] = useState("");
  const [checkAll, setCheckAll] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      let apiLink = "api/pr_register/" + cookies.get("transCompany");
      const res = await axios.get(apiLink);

      setData(res.data);
      setFilteredData(res.data);
      let getDefaultChecks = [];
      res.data.map((pr_data) =>
        getDefaultChecks.push({ id: pr_data.code_type, checked: false })
      );
      setIsChecked(getDefaultChecks);
    };

    if (!fileLoaded) {
      fetchData();
      setFileLoaded(true);
    }
  });

  const handleAddCallback = (editData) => {
    if (editData) {
      setFileLoaded(false);
    }
  };

  const pages = [];
  for (let i = 1; i <= Math.ceil(filteredData.length / recordsPerPage); i++) {
    pages.push(i);
  }

  const handleRecordPerPage = (e) => {
    console.log(e.target.value);
    setRecordsPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const handleFilterChange = (e, filterField) => {
    if (filterField == "prFrom") {
      setFilterPrIdFrom(e.target.value);
    } else if (filterField == "prTo") {
      setFilterPrIdTo(e.target.value);
    } else if (filterField == "desc") {
      setFilterDesc(e.target.value);
    } else if (filterField == "type") {
      setFilterType(e.target.value);
    } else if (filterField == "clear") {
      setFilterPrIdFrom("");
      setFilterPrIdTo("");
      setFilterDesc("");
      setFilterType("Select Type");
      setCheckAll(false);
      let getChecks = [];
      isChecked.map((checks) => {
        getChecks.push({ id: checks.id, checked: false });
      });
      setIsChecked(getChecks);
      setFilteredData(data);
    }
  };

  const handleSearch = (e) => {
    let from = filterPrIdFrom;
    let to = filterPrIdTo === "" ? filterPrIdFrom : filterPrIdTo;
    let type = filterType === "Select Type" ? "" : filterType;
    let filter1 = [],
      filter2 = [],
      filter3 = [];
    filter1 = data.filter(function (data) {
      for (var key in data) {
        let fromValue = true,
          toValue = true;

        if (from !== "") {
          if (!(data["ps_code"] >= from)) {
            return false;
          }
        }

        if (to !== "") {
          if (!(data["ps_code"] <= to)) {
            return false;
          }
        }

        return true;
      }
    });

    filter2 = filter1.filter(function (data) {
      if (type !== "") {
        if (!(data["type"] == type)) {
          return false;
        }
      }
      return true;
    });

    filter3 = filter2.filter(function (data) {
      if (!data["description"].toLowerCase().includes(filterDesc.toLowerCase())) {
        return false;
      }
      return true;
    });

    setFilteredData(filter3);
    setCurrentPage(1);
  };

  const handleSort = (columnChanged, currentSort) => {
    let setSort;
    let sorter = [];
    const getColumnValue = (column, value) => {
      switch (column) {
        default:
          return value;
      }
    };

    if (currentSort == "ASC") setSort = "DESC";
    else setSort = "ASC";

    if (columnChanged == "code") {
      sorter = [
        ["ps_code", codeSort],
        ["type", "ASC"],
        ["last_name", "ASC"],
        ["first_name", "ASC"],
        ["emp_no", "ASC"],
      ];

      setCodeSort(setSort);
    } else if (columnChanged == "type") {
      sorter = [
        ["type", typeSort],
        ["ps_code", "DESC"],
        ["last_name", "ASC"],
        ["first_name", "ASC"],
        ["emp_no", "ASC"],
      ];

      setTypeSort(setSort);
    }

    const sorted = multiColumnSort(filteredData, sorter, getColumnValue);

    setFilteredData(sorted);
  };

  const startIndex = currentPage * recordsPerPage - recordsPerPage;
  const endIndex = startIndex + recordsPerPage;
  const currentData = filteredData.slice(startIndex, endIndex);

  const checkAllRows = (e) => {
    let getChecks = [];
    isChecked.map((checks) => {
      getChecks.push({ id: checks.id, checked: !checkAll });
    });
    setIsChecked(getChecks);
    setCheckAll(!checkAll);
  };

  const handleCheck = (e) => {
    let getChecks = [],
      totalChecks = 0;
    isChecked.map((checks) => {
      if (checks.id == e.target.value) {
        getChecks.push({ id: checks.id, checked: !checks.checked });
      } else getChecks.push({ id: checks.id, checked: checks.checked });

      if (getChecks.checked) {
        totalChecks++;
      }
    });

    setIsChecked(getChecks);

    if (totalChecks == filteredData.length) {
      setCheckAll(true);
    } else {
      setCheckAll(false);
    }
  };

  return (
    <div className="hk-wrapper hk-vertical-nav">
      <div class="pg-hk-wrapper">
        <Header />
        <div className="mt-20 hk-pg-wrapper">
          {ability.permission > 0 ? (
            <div className="container-fluid">
              <div>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <h3 className="mb-10">Payroll Register</h3>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12"></div>

                  <div className="col-sm-12 mb-20">
                    <div className="accordion" id="accordion_1">
                      <div className="card">
                        <div className="card-header d-flex justify-content-between">
                          <a
                            role="button"
                            data-toggle="collapse"
                            href="#collapse_1"
                            aria-expanded="false"
                            className="collapse show"
                          >
                            Filters
                          </a>
                        </div>
                        <div
                          id="collapse_1"
                          className="collapse show"
                          data-parent="#accordion_1"
                          role="tabpanel"
                        >
                          <div className="card-body pa-15">
                            <div className="row">
                              <div className="form-group col-md-6 col-sm-12">
                                <label>From Pay Period ID</label>
                                <input
                                  type="text"
                                  className="form-control form-control-sm"
                                  value={filterPrIdFrom}
                                  onChange={(e) =>
                                    handleFilterChange(e, "prFrom")
                                  }
                                />
                              </div>
                              <div className="form-group col-md-6 col-sm-12">
                                <label>To Pay Period ID</label>
                                <input
                                  type="text"
                                  className="form-control form-control-sm"
                                  value={filterPrIdTo}
                                  onChange={(e) =>
                                    handleFilterChange(e, "prTo")
                                  }
                                />
                              </div>

                              <div className="form-group col-md-6 col-sm-12">
                                <label>Payroll Run</label>
                                <select
                                  className="form-control form-control-sm"
                                  value={filterType}
                                  onChange={(e) =>
                                    handleFilterChange(e, "type")
                                  }
                                >
                                  <option>Select Payroll Run</option>
                                  <option value="REGULAR">REGULAR</option>
                                  <option value="SPECIAL">SPECIAL</option>
                                </select>
                              </div>

                              <div className="form-group col-md-6 col-sm-12">
                                <label>Description</label>
                                <input
                                  type="text"
                                  className="form-control form-control-sm"
                                  value={filterDesc}
                                  onChange={(e) =>
                                    handleFilterChange(e, "desc")
                                  }
                                />
                              </div>

                              <div className="form-group col-md-6 col-sm-12">
                                <button
                                  className="btn btn-sm btn-primary mr-10"
                                  onClick={handleSearch}
                                >
                                  Apply Filter
                                </button>
                                <button
                                  className="btn btn-sm btn-danger"
                                  onClick={(e) =>
                                    handleFilterChange(e, "clear")
                                  }
                                >
                                  Clear Filter
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-sm-12">
                    <div className="table-wrap">
                      <div className="row mb-10">
                        <div className="col-md-6">
                          <label>
                            <select
                              className="custom-select custom-select-sm form-control form-control-sm"
                              onChange={handleRecordPerPage}
                            >
                              <option value="10">10</option>
                              <option value="25">25</option>
                              <option value="50">50</option>
                              <option value="100">100</option>
                            </select>
                          </label>{" "}
                          items
                        </div>
                        <div className="col-md-6">
                          <ExportPrRegister
                            dataCheck={isChecked}
                            data={filteredData}
                          />
                        </div>
                      </div>
                      <div className="table-responsive mb-10">
                        <table className="table table-sm table-bordered table-striped mb-0">
                          <thead className="thead-secondary">
                            <tr className="text-center">
                              <th>
                                <input
                                  type="checkbox"
                                  aria-label="Checkbox for following text input"
                                  onClick={checkAllRows}
                                  checked={checkAll}
                                />
                              </th>
                              <th
                                className="text-nowrap"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleSort("type", typeSort)}
                              >
                                Payroll Run &nbsp;
                                <i
                                  className={
                                    typeSort == "ASC"
                                      ? "fa fa-arrow-down"
                                      : "fa fa-arrow-up"
                                  }
                                ></i>
                              </th>
                              <th
                                className="text-nowrap"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleSort("code", codeSort)}
                              >
                                Pay Period ID &nbsp;
                                <i
                                  className={
                                    codeSort == "ASC"
                                      ? "fa fa-arrow-down"
                                      : "fa fa-arrow-up"
                                  }
                                ></i>
                              </th>
                              <th>Description</th>
                              <th>Head Count</th>
                              <th>Basic</th>
                              <th>Earnings</th>
                              <th>Deductions</th>
                              <th>WH Tax</th>
                              <th>Net Pay</th>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredData.length > 0 ? (
                              currentData.map((cutoff) => (
                                <tr key={cutoff.id}>
                                  <td className="text-center">
                                    <input
                                      type="checkbox"
                                      name="payroll_data"
                                      checked={
                                        isChecked.length > 0
                                          ? isChecked.find(
                                              (x) => x.id == cutoff.code_type
                                            ).checked
                                          : false
                                      }
                                      onClick={handleCheck}
                                      value={cutoff.code_type}
                                      aria-label="Checkbox for following text input"
                                    />
                                  </td>
                                  <td className="text-nowrap">{cutoff.type}</td>
                                  <td className="text-nowrap text-center">
                                    {cutoff.ps_code}
                                  </td>
                                  <td className="text-nowrap">
                                    {cutoff.description}
                                  </td>
                                  <td className="text-right">
                                    {cutoff.headcount}
                                  </td>
                                  <td className="text-right">
                                    {cutoff.basic_pay.replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                  </td>
                                  <td className="text-right">
                                    {cutoff.earnings.replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                  </td>
                                  <td className="text-right">
                                    {cutoff.other_deduction.replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                  </td>
                                  <td className="text-right">
                                    {cutoff.wh_tax.replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                  </td>

                                  <td className="text-right">
                                    {" "}
                                    {cutoff.net_pay.replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="9" className="text-center">
                                  No Data to show
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="drew-pagination">
                      {filteredData.length > 0
                        ? "Showing " +
                          (startIndex + 1) +
                          " to " +
                          (endIndex > filteredData.length
                            ? filteredData.length
                            : endIndex) +
                          " of " +
                          filteredData.length
                        : ""}
                      <ul class="pagination ml-2">
                        {pages.map((number) => (
                          <li
                            key={number}
                            id={number}
                            className={
                              currentPage == number
                                ? "paginate_button page-item active"
                                : "paginate_button page-item"
                            }
                            onClick={() => setCurrentPage(number)}
                          >
                            <a class="page-link">{number}</a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <h1 className="m-0 text-center">
              The Credentials Supplied Are Not Sufficient To Access This Module
              <br />
              Please Contact Administrator
            </h1>
          )}
        </div>
      </div>
    </div>
  );
}
