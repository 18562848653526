import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { Check, Receipt } from "@material-ui/icons";
import axios from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();

export default class SpExportPayrollRun extends Component {
  constructor(props) {
    super();
    this.state = {
      showDeleteModal: false,
      disableSubmit: false,
      updated_by: cookies.get("email"),
      status: 2,
    };
    this.handleshowDeleteModal = this.handleshowDeleteModal.bind(this);
  }

  handleshowDeleteModal = (e) => {
    this.setState({ showDeleteModal: !this.state.showDeleteModal });
  };

  handleSubmit = (e) => {
      axios({
        url: process.env.REACT_APP_API_LINK+"api/sp_payroll_run_export/"+ cookies.get("transCompany"), //your url
        method: "POST",
        data: this.props.data,
        responseType: "blob", // important
      }).then((res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download",this.props.data.code + " Special Payroll Run.xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
  };

  render() {
    return (
      <>
        <button
          className="btn btn-outline-success btn-sm float-right"
          title="Export Special Payroll Run"
          onClick={this.handleSubmit}
        >
          <Receipt /> Export to Excel
        </button>
      </>
    );
  }
}
