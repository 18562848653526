import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import axios from "axios";
import { Link } from "react-router-dom";
import { FindInPage, PictureAsPdf } from "@material-ui/icons";
import Cookies from "universal-cookie";
import { dateFormatter } from "../../components/DtFormatter";
import ExportPayslipEmp from "./ExportPayslipEmp";
import ExportMultiPayslip from "./ExportMultiPayslip";
import EmailPayslip from "./EmailPayslip";

export default function PayslipPerEmp(props) {
  const cookies = new Cookies();
  const ability = cookies.get("ability")[2];
  const setting = cookies.get("setting");
  const [data, setData] = useState([]);
  const [mainData, setMainData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [filteredData, setFilteredData] = useState([]);

  const [isChecked, setIsChecked] = useState([]);
  const [filterYear, setFilterYear] = useState("");
  const [filterEmpNo, setFilterEmpNo] = useState("");
  const [filterName, setFilterName] = useState("");
  const [checkAll, setCheckAll] = useState(false);
  const [searchMain, setSearchMain] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      let apiLink = "api/payslip_emp/" + props.match.params.id;
      const res = await axios.get(apiLink);

      setData(res.data.payslips);
      setMainData(res.data.payslipMain);
      const params = getQueryParams(props.location.search);
      setSearchMain(params.searchMain ? params.searchMain : "");
      setFilterName(params.name ? params.name : "");
      setFilterEmpNo(params.emp_no ? params.emp_no : "");
      const filterer = searcher(
        res.data.payslips,
        params.name ? params.name : "",
        params.emp_no ? params.emp_no : ""
      );
      setFilteredData(filterer);

      let getDefaultChecks = [];
      res.data.payslips.map((pr_data) =>
        getDefaultChecks.push({
          id: pr_data.id,
          employee_id: pr_data.employee_id,
          company_id: pr_data.company_id,
          last_name: pr_data.last_name,
          first_name: pr_data.first_name,
          company_email: pr_data.company_email,
          checked: false,
        })
      );
      setIsChecked(getDefaultChecks);
    };
    fetchData();
  }, []);

  const handleRecordPerPage = (e) => {
    setRecordsPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const handleBack = () => {
    props.history.goBack();
  };

  const getQueryParams = (query) => {
    let params = {};
    new URLSearchParams(query).forEach((value, key) => {
      let decodedKey = decodeURIComponent(key);
      let decodedValue = decodeURIComponent(value);
      // This key is part of an array
      if (decodedKey.endsWith("[]")) {
        decodedKey = decodedKey.replace("[]", "");
        params[decodedKey] || (params[decodedKey] = []);
        params[decodedKey].push(decodedValue);
        // Just a regular parameter
      } else {
        params[decodedKey] = decodedValue;
      }
    });

    return params;
  };

  const handleSearch = (e) => {
    const filterer = searcher(data);
    setFilteredData(filterer);
    setCurrentPage(1);
  };

  const searcher = (data, name = filterName, empNo = filterEmpNo) => {
    const filter1 = data.filter(function (data) {
      for (var key in data) {
        if (
          !(data["first_name"] + " " + data["last_name"])
            .toLowerCase()
            .includes(name)
        ) {
          return false;
        }
        return true;
      }
    });

    const filter2 = filter1.filter(function (data) {
      for (var key in data) {
        if (!data["emp_no"].toLowerCase().includes(empNo)) {
          return false;
        }
        return true;
      }
    });
    return filter2;
  };

  const checkAllRows = (e) => {
    let getChecks = [];
    isChecked.map((checks) => {
      getChecks.push({
        id: checks.id,
        employee_id: checks.employee_id,
        company_id: checks.company_id,
        last_name: checks.last_name,
        first_name: checks.first_name,
        company_email: checks.company_email,
        checked: !checkAll,
      });
    });
    setIsChecked(getChecks);
    setCheckAll(!checkAll);
  };

  const handleCheck = (e) => {
    let getChecks = [],
      totalChecks = 0;
    isChecked.map((checks) => {
      if (checks.employee_id == e.target.value) {
        getChecks.push({
          id: checks.id,
          employee_id: checks.employee_id,
          company_id: checks.company_id,
          last_name: checks.last_name,
          first_name: checks.first_name,
          company_email: checks.company_email,
          checked: !checks.checked,
        });
      } else
        getChecks.push({
          id: checks.id,
          employee_id: checks.employee_id,
          company_id: checks.company_id,
          last_name: checks.last_name,
          first_name: checks.first_name,
          checked: checks.checked,
          company_email: checks.company_email,
        });

      if (getChecks.checked) {
        totalChecks++;
      }
    });

    setIsChecked(getChecks);

    if (totalChecks == filteredData.length) {
      setCheckAll(true);
    } else {
      setCheckAll(false);
    }
  };

  const handleFilterChange = (e, filterField) => {
    if (filterField == "empNo") {
      setFilterEmpNo(e.target.value);
    } else if (filterField == "name") {
      setFilterName(e.target.value);
    } else if (filterField == "clear") {
      setFilterEmpNo("");
      setFilterName("");
      setCheckAll(false);
      let getChecks = [];
      isChecked.map((checks) => {
        getChecks.push({
          id: checks.id,
          employee_id: checks.employee_id,
          company_id: checks.company_id,
          last_name: checks.last_name,
          first_name: checks.first_name,
          company_email: checks.company_email,
          checked: false,
        });
      });
      setIsChecked(getChecks);
      setFilteredData(data);
    }
  };

  const startIndex = currentPage * recordsPerPage - recordsPerPage;
  const endIndex = startIndex + recordsPerPage;
  const currentData = filteredData.slice(startIndex, endIndex);

  const pages = [];
  for (let i = 1; i <= Math.ceil(data.length / recordsPerPage); i++) {
    pages.push(i);
  }

  return (
    <div className="hk-wrapper hk-vertical-nav">
      <div class="pg-hk-wrapper">
        <Header />
        <div className="mt-20 hk-pg-wrapper">
          {ability.permission > 0 ? (
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-7 col-md-7 col-sm-12">
                  <h3 className="pb-10">
                    Regular Payslip Per Employee {mainData.code}{" "}
                  </h3>
                </div>
                <div className="col-lg-5 col-md-5 col-sm-12">
                  {/* <button
                    className="btn btn-sm btn-danger float-right ml-5"
                    onClick={handleBack}
                  >
                    Back to Previous Page
                  </button> */}
                  <Link
                    className="btn btn-sm btn-danger float-right "
                    to={{
                      pathname: `/payslip`,
                      query: `/payslip`,
                      search: "?search=" + searchMain,
                    }}
                  >
                    Back to Previous Page
                  </Link>
                </div>
              </div>
              <div>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="table-wrap">
                      <div className="row mb-10">
                        <div className="col-sm-12 mb-20">
                          <div className="accordion" id="accordion_1">
                            <div className="card">
                              <div className="card-header d-flex justify-content-between">
                                <a
                                  role="button"
                                  data-toggle="collapse"
                                  href="#collapse_1"
                                  aria-expanded="false"
                                  className="collapse show"
                                >
                                  Filters
                                </a>
                              </div>
                              <div
                                id="collapse_1"
                                className="collapse show"
                                data-parent="#accordion_1"
                                role="tabpanel"
                              >
                                <div className="card-body pa-15">
                                  <div className="row">
                                    <div className="form-group col-md-6 col-sm-12">
                                      <label>Employee #</label>
                                      <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        value={filterEmpNo}
                                        onChange={(e) =>
                                          handleFilterChange(e, "empNo")
                                        }
                                      />
                                    </div>
                                    <div className="form-group col-md-6 col-sm-12">
                                      <label>Name</label>
                                      <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        value={filterName}
                                        onChange={(e) =>
                                          handleFilterChange(e, "name")
                                        }
                                      />
                                    </div>

                                    <div className="form-group col-md-6 col-sm-12">
                                      <button
                                        className="btn btn-sm btn-primary mr-10"
                                        onClick={handleSearch}
                                      >
                                        Apply Filter
                                      </button>
                                      <button
                                        className="btn btn-sm btn-danger"
                                        onClick={(e) =>
                                          handleFilterChange(e, "clear")
                                        }
                                      >
                                        Clear Filter
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <label>
                            <select
                              className="custom-select custom-select-sm form-control form-control-sm"
                              onChange={handleRecordPerPage}
                            >
                              <option value="10">10</option>
                              <option value="25">25</option>
                              <option value="50">50</option>
                              <option value="100">100</option>
                            </select>
                          </label>{" "}
                          items
                        </div>
                        <div className="col-md-6">
                          {/* <input
                            type="search"
                            className="form-control form-control-sm col-md-6 col-sm-6 float-right"
                            placeholder="Search"
                            onChange={handleSearch}
                          /> */}
                          {mainData.payslip_generated == 1 && setting.payslip_email == 1 ? (
                            // <ExportMultiPayslip
                            //   dataCheck={isChecked}
                            //   data={filteredData}
                            // />
                            <>
                              <EmailPayslip
                                dataCheck={isChecked}
                                data={filteredData}
                                id={mainData.id}
                                type="REG"
                              />
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="table-responsive mb-10">
                        <table className="table table-sm table-bordered table-striped mb-0">
                          <thead className="thead-secondary">
                            <tr className="text-center">
                              <th>
                                <input
                                  type="checkbox"
                                  aria-label="Checkbox for following text input"
                                  onClick={checkAllRows}
                                  checked={checkAll}
                                />
                              </th>
                              <th>Employee #</th>
                              <th>Name</th>
                              <th>Pay Period ID</th>
                              <th>Period</th>
                              <th>Total Pay</th>
                              {/* <th>OT Hours</th> */}
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredData.length > 0 ? (
                              currentData.map((cutoff) => (
                                <tr key={cutoff.id}>
                                  <td className="text-center">
                                    <input
                                      type="checkbox"
                                      name="payroll_data"
                                      checked={
                                        isChecked.length > 0
                                          ? isChecked.find(
                                              (x) =>
                                                x.employee_id ==
                                                cutoff.employee_id
                                            ).checked
                                          : false
                                      }
                                      onClick={handleCheck}
                                      value={cutoff.employee_id}
                                    />
                                  </td>
                                  <td>{cutoff.emp_no}</td>
                                  <td>
                                    {cutoff.last_name +
                                      ", " +
                                      cutoff.first_name}
                                  </td>
                                  <td className="text-center">
                                    {mainData.code}
                                  </td>
                                  <td className="text-center">
                                    {dateFormatter(mainData.start_date) +
                                      " to " +
                                      dateFormatter(mainData.end_date)}
                                  </td>
                                  <td className="text-right">
                                    {(
                                      (parseFloat(cutoff.basic) +
                                        parseFloat(cutoff.t_month_basic) +
                                        parseFloat(cutoff.basic_earning) +
                                        parseFloat(cutoff.deminimis) +
                                        parseFloat(cutoff.other_earning) +
                                        parseFloat(cutoff.nontax_earning) -
                                        parseFloat(cutoff.ph_deduction) -
                                        parseFloat(cutoff.hmdf_deduction) -
                                        parseFloat(cutoff.sss_deduction) -
                                        parseFloat(cutoff.wh_tax) -
                                        parseFloat(cutoff.other_deductions)) *
                                      1
                                    )
                                      .toFixed(2)
                                      .replace(
                                        /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                        ","
                                      )}
                                  </td>
                                  {/* <td className="text-right">
                                    {(1 * cutoff.total_ot_hours).toFixed(2)}
                                  </td> */}
                                  <td className="text-center">
                                    <div className="btn-group">
                                      {mainData.payslip_generated == 1 ? (
                                        <a
                                          className="btn btn-outline-secondary btn-sm float-right ml-2"
                                          title="Employee PDF"
                                          href={
                                            process.env.REACT_APP_API_LINK +
                                            "payslip_pdf/" +
                                            cutoff.file_name +
                                            mainData.code +
                                            ".PDF"
                                          }
                                          target="_blank"
                                          download
                                        >
                                          <PictureAsPdf />
                                        </a>
                                      ) : (
                                        ""
                                      )}

                                      {/* <Link
                                        to={{
                                          pathname: `/payslip/${cutoff.id}/${cutoff.employee_id}`,
                                          query: `/payslip`,
                                          search: "?searchMain="+searchMain+"&name="+filterName+"&emp_no="+filterEmpNo
                                        }}
                                        className="btn btn-sm btn-outline-primary"
                                        title={"Output per Employee"}
                                      >
                                        <FindInPage />
                                      </Link> */}
                                      {/* <ExportPayslipEmp
                                        id={cutoff.id}
                                        emp_id={cutoff.employee_id}
                                        emp_name={
                                          cutoff.last_name +
                                          " " +
                                          cutoff.first_name
                                        }
                                      /> */}
                                      {/* <ExportPayslipEmp id={cutoff.id} emp_id={cutoff.employee_id}/> */}
                                    </div>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="7" className="text-center">
                                  No Data to show
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="drew-pagination">
                      {filteredData.length > 0
                        ? "Showing " +
                          (startIndex + 1) +
                          " to " +
                          (endIndex > filteredData.length
                            ? filteredData.length
                            : endIndex) +
                          " of " +
                          filteredData.length
                        : ""}
                      <ul class="pagination ml-2">
                        {pages.map((number) => (
                          <li
                            key={number}
                            id={number}
                            className={
                              currentPage == number
                                ? "paginate_button page-item active"
                                : "paginate_button page-item"
                            }
                            onClick={() => setCurrentPage(number)}
                          >
                            <a class="page-link">{number}</a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <h1 className="m-0 text-center">
              The Credentials Supplied Are Not Sufficient To Access This Module
              <br />
              Please Contact Administrator
            </h1>
          )}
        </div>
      </div>
    </div>
  );
}
