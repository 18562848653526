import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { Check } from "@material-ui/icons";
import axios from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();

export default class Processing extends Component {
  constructor(props) {
    super();
  }

  render() {
    return (
      <>
        <Modal size="sm" show={this.props.show} backdrop="static" centered>
          <Modal.Header className="text-center">
              <h4>{(this.props.name) ? this.props.name+"": ""}Processing...</h4>
          </Modal.Header>
          <Modal.Body>
            <div className="text-center">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
