import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import { FindInPage, Check, DirectionsRun } from "@material-ui/icons";
import axios from "axios";
import Cookies from "universal-cookie";
import { dateFormatter } from "../../components/DtFormatter";
import RegularDraftRun from "./RegularDraftRun";
import RegularApprove from "./RegularApprove";
import RegularPost from "./RegularPost";
import RegularCancelApprove from "./RegularCancelApprove";
import EditPaymentDate from "./EditPaymentDate";
const statuses = ["DRAFT", "FOR POSTING", "POSTED"];
const cookies = new Cookies();

const access = ["No Access", "View Only", "Add", "Add / Edit", "Full Access"];

export default function RegularPayroll(props) {
  const permissions = cookies.get("permissions")["payroll"];
  const [data, setData] = useState([]);
  const [schedData, setSchedData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(12);
  const [filteredData, setFilteredData] = useState([]);
  const [fileLoaded, setFileLoaded] = useState(false);
  const [search, setSearch] = useState(
    props.location.search
      ? decodeURI(props.location.search.split("=").pop())
      : ""
  );

  useEffect(() => {
    const fetchData = async () => {
      let company_id = 0;
      if(cookies.get("transCompany")) company_id = cookies.get("transCompany")

      if (company_id == 0) {
        cookies.set("load", "1");
        alert("Please Select Module Company");
        window.location.replace("/#/app");
      } else {

        let apiLink = "api/regular_history/" + company_id;
        const res = await axios.get(apiLink);

        setData(res.data.regularPayroll);
        setSchedData(res.data.currentPayched);
        const filterer = searcher(res.data.regularPayroll, search);
        setFilteredData(filterer);
      }
    };

    if (!fileLoaded) {
      fetchData();
      setFileLoaded(true);
    }
  });

  const handleAddCallback = (editData) => {
    if (editData) {
      setFileLoaded(false);
    }
  };

  const pages = [];
  for (let i = 1; i <= Math.ceil(filteredData.length / recordsPerPage); i++) {
    pages.push(i);
  }

  const handleRecordPerPage = (e) => {
    console.log(e.target.value);
    setRecordsPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const handleSearch = (e) => {
    const filterer = searcher(data, e.target.value);
    setFilteredData(filterer);
    setSearch(e.target.value);
    setCurrentPage(1);
  };

  const searcher = (data, searchString) => {
    const searchedData = data.filter(function (data) {
      for (var key in data) {
        if (
          !(
            data["code"].toLowerCase().includes(searchString.toLowerCase()) ||
            data["post_date_f"]
              .toLowerCase()
              .includes(searchString.toLowerCase()) ||
            (
              dateFormatter(data["start_date"]) +
              " to " +
              dateFormatter(data["end_date"])
            )
              .toLowerCase()
              .includes(searchString.toLowerCase())
          )
        ) {
          return false;
        }
        return true;
      }
    });

    return searchedData;
  };

  const startIndex = currentPage * recordsPerPage - recordsPerPage;
  const endIndex = startIndex + recordsPerPage;
  const currentData = filteredData.slice(startIndex, endIndex);

  return (
    <div className="hk-wrapper hk-vertical-nav">
      <div class="pg-hk-wrapper">
        <Header />
        <div className="mt-20 hk-pg-wrapper">
          {permissions > 0 ? (
            <div className="container-fluid">
              <h3 className="mb-10">Regular Run</h3>
              <div>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="table-wrap">
                      {permissions == 4 ? (
                        <div className="row mb-10">
                          <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="card">
                              <div className="card-header card-header-action">
                                <h5>Set-up Regular Processing</h5>
                                <div className="d-flex align-items-center card-action-wrap-tools">
                                  <EditPaymentDate
                                    data={schedData}
                                    parentCallback={handleAddCallback}
                                  />
                                </div>
                              </div>

                              <div className="card-body">
                                <div className="row mb-20">
                                  <div className="col-xl-2 col-sm-10 ">
                                    <strong>Current Pay Period ID</strong>
                                  </div>
                                  <div className="col-xl-4 col-sm-10">
                                    :&nbsp;&nbsp;&nbsp;{schedData.code}
                                  </div>

                                  <div className="col-xl-2 col-sm-10">
                                    <strong>Transaction Period</strong>
                                  </div>
                                  <div className="col-xl-4 col-sm-10">
                                    :&nbsp;&nbsp;&nbsp;
                                    {dateFormatter(schedData.start_date) +
                                      " to " +
                                      dateFormatter(schedData.end_date)}
                                  </div>

                                  <div className="col-xl-2 col-sm-10">
                                    <strong>Payment Date</strong>
                                  </div>
                                  <div className="col-xl-4 col-sm-10">
                                    :&nbsp;&nbsp;&nbsp;
                                    {dateFormatter(schedData.payment_date)}
                                  </div>

                                  <div className="col-xl-2 col-sm-10">
                                    <strong>Status</strong>
                                  </div>
                                  <div className="col-xl-4 col-sm-10">
                                    :&nbsp;&nbsp;&nbsp;
                                    {schedData.sched_status == 3 ? (
                                      <strong className="text-success">
                                        POSTED
                                      </strong>
                                    ) : schedData.sched_status == 2 ? (
                                      <strong className="text-info">
                                        FOR POSTING
                                      </strong>
                                    ) : schedData.sched_status == 1 ? (
                                      <strong className="text-warning">
                                        DRAFT
                                      </strong>
                                    ) : (
                                      <strong className="text-danger">
                                        OPEN
                                      </strong>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-12 col-md-12 col-sm-12">
                            <div className="card">
                              <div className="card-header card-header-action">
                                <h5>Processing Status</h5>
                                <div className="d-flex align-items-center card-action-wrap-tools"></div>
                              </div>

                              <div className="card-body">
                                <div className="row mb-10">
                                  <div className="col-lg-4 col-md-4 col-sm-12">
                                    <div className="row">
                                      <div className="col-lg-12 col-md-12 col-sm-12 text-center">
                                        <RegularDraftRun
                                          id={schedData.id}
                                          schedData={schedData}
                                          parentCallback={handleAddCallback}
                                        />
                                      </div>
                                      <div className="col-lg-12 col-md-12 col-sm-12 text-center mt-10">
                                        <b className="font-weight-bold text">
                                          Draft Run Date:{" "}
                                          {schedData.draft_date ? (
                                            <strong className="text-info">
                                              {dateFormatter(
                                                schedData.draft_date,
                                                "MMM dd, yyyy"
                                              )}
                                            </strong>
                                          ) : (
                                            <strong className="text-danger">
                                              PENDING
                                            </strong>
                                          )}
                                        </b>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-lg-4 col-md-4 col-sm-12">
                                    <div className="row">
                                      <div className="col-lg-12 col-md-12 col-sm-12 text-center">
                                        {schedData.sched_status < 2 ? (
                                          <RegularApprove
                                            id={schedData.id}
                                            schedData={schedData}
                                            parentCallback={handleAddCallback}
                                          />
                                        ) : (
                                          <RegularCancelApprove
                                            id={schedData.id}
                                            schedData={schedData}
                                            parentCallback={handleAddCallback}
                                          />
                                        )}
                                      </div>
                                      <div className="col-lg-12 col-md-12 col-sm-12 text-center mt-10">
                                        <b className="font-weight-bold text">
                                          Reviewed Date:{" "}
                                          {schedData.review_date ? (
                                            <strong className="text-info">
                                              {dateFormatter(
                                                schedData.review_date,
                                                "MMM dd, yyyy"
                                              )}
                                            </strong>
                                          ) : (
                                            <strong className="text-danger">
                                              PENDING
                                            </strong>
                                          )}
                                        </b>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-lg-4 col-md-4 col-sm-12">
                                    <div className="row">
                                      <div className="col-lg-12 col-md-12 col-sm-12 text-center">
                                        <RegularPost
                                          id={schedData.id}
                                          schedData={schedData}
                                          parentCallback={handleAddCallback}
                                        />
                                      </div>
                                      <div className="col-lg-12 col-md-12 col-sm-12 text-center mt-10">
                                        <b className="font-weight-bold text">
                                          Date Posted:{" "}
                                          {schedData.post_date ? (
                                            <strong className="text-info">
                                              {dateFormatter(
                                                schedData.post_date,
                                                "MMM dd, yyyy"
                                              )}
                                            </strong>
                                          ) : (
                                            <strong className="text-danger">
                                              PENDING
                                            </strong>
                                          )}
                                        </b>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="row mb-10">
                        <div className="col-lg-12 col-md-12 col-sm-12">
                          <div className="card">
                            <div className="card-header card-header-action">
                              <h5 className="mb-10">History</h5>
                            </div>
                            <div className="card-body">
                              <div className="row">
                                <div className="col-md-6">
                                  <label>
                                    <select
                                      className="custom-select custom-select-sm form-control form-control-sm"
                                      onChange={handleRecordPerPage}
                                    >
                                      <option value="10">10</option>
                                      <option value="25">25</option>
                                      <option value="50">50</option>
                                      <option value="100">100</option>
                                    </select>
                                  </label>{" "}
                                  items
                                </div>
                                <div className="col-md-6">
                                  <input
                                    type="search"
                                    className="form-control form-control-sm col-md-6 col-sm-6 float-right"
                                    placeholder="Search"
                                    defaultValue={
                                      props.location.search
                                        ? decodeURI(
                                            props.location.search
                                              .split("=")
                                              .pop()
                                          )
                                        : ""
                                    }
                                    onChange={handleSearch}
                                  />
                                </div>
                                <div className="col-md-12">
                                  <div className="table-responsive mb-10">
                                    <table className="table table-sm table-bordered table-striped mb-0">
                                      <thead className="thead-secondary">
                                        <tr className="text-center">
                                          <th>Pay Period ID</th>
                                          <th>Period</th>
                                          <th>Status</th>
                                          <th>Head Count</th>
                                          <th>Basic</th>
                                          <th>De Minimis</th>
                                          <th>Other Earning</th>
                                          <th>Gross Pay</th>
                                          <th>Statutories</th>
                                          <th>Other Deduction</th>
                                          <th>WH Tax</th>
                                          <th>Net Pay</th>
                                          <th>Date Posted</th>
                                          <th>Action</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {filteredData.length > 0 ? (
                                          currentData.map((cutoff) => (
                                            <tr key={cutoff.id}>
                                              <td className="text-nowrap">
                                                <Link
                                                  to={{
                                                    pathname: `/reg_payroll/${cutoff.id}/main`,
                                                    query: `/reg_payroll`,
                                                    search:
                                                      "?search=&searchMain=" +
                                                      search,
                                                  }}
                                                  className="text-primary"
                                                  title={"View per employee"}
                                                >
                                                  {cutoff.code}
                                                </Link>
                                              </td>
                                              <td className="text-nowrap">
                                                {dateFormatter(
                                                  cutoff.start_date
                                                ) +
                                                  " to " +
                                                  dateFormatter(
                                                    cutoff.end_date
                                                  )}
                                              </td>
                                              <td>
                                                {cutoff.status == 2 ? (
                                                  <strong className="text-success">
                                                    {statuses[cutoff.status]}
                                                  </strong>
                                                ) : cutoff.status == 1 ? (
                                                  <strong className="text-info">
                                                    {statuses[cutoff.status]}
                                                  </strong>
                                                ) : (
                                                  <strong className="text-warning">
                                                    {statuses[cutoff.status]}
                                                  </strong>
                                                )}
                                              </td>
                                              <td className="text-right">
                                                {cutoff.head_count}
                                              </td>
                                              <td className="text-right">
                                                {cutoff.basic
                                                  .toLocaleString()
                                                  .replace(
                                                    /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                                    ","
                                                  )}
                                              </td>
                                              <td className="text-right">
                                                {cutoff.deminimis
                                                  .toLocaleString()
                                                  .replace(
                                                    /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                                    ","
                                                  )}
                                              </td>
                                              <td className="text-right">
                                                <Link
                                                  to={{
                                                    pathname: `/reg_break_earn/${cutoff.id}/${cutoff.paysched_id}`,
                                                    query: `/reg_break_earn`,
                                                    search:
                                                      "?search=&searchMain=" +
                                                      search,
                                                  }}
                                                  className="text-primary"
                                                  title={
                                                    "View Other Earning Breakdown"
                                                  }
                                                >
                                                  {(
                                                    parseFloat(
                                                      cutoff.other_earning
                                                    ) +
                                                    parseFloat(
                                                      cutoff.basic_earning
                                                    ) +
                                                    parseFloat(
                                                      cutoff.nontax_earning
                                                    )
                                                  )
                                                    .toFixed(2)
                                                    .replace(
                                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                                      ","
                                                    )}
                                                </Link>
                                              </td>
                                              <td className="text-right">
                                                {(
                                                  parseFloat(cutoff.basic) +
                                                  parseFloat(cutoff.deminimis) +
                                                  parseFloat(
                                                    cutoff.basic_earning
                                                  ) +
                                                  parseFloat(
                                                    cutoff.other_earning
                                                  ) +
                                                  parseFloat(
                                                    cutoff.nontax_earning
                                                  )
                                                )
                                                  .toFixed(2)
                                                  .replace(
                                                    /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                                    ","
                                                  )}
                                              </td>
                                              <td className="text-right">
                                                {(
                                                  parseFloat(
                                                    cutoff.basic_deduction
                                                  ) +
                                                  parseFloat(
                                                    cutoff.other_statutory
                                                  )
                                                )
                                                  .toFixed(2)
                                                  .replace(
                                                    /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                                    ","
                                                  )}
                                              </td>
                                              <td className="text-right">
                                                <Link
                                                  to={{
                                                    pathname: `/reg_break_dedc/${cutoff.id}/${cutoff.paysched_id}`,
                                                    query: `/reg_break_dedc`,
                                                    search:
                                                      "?search=&searchMain=" +
                                                      search,
                                                  }}
                                                  className="text-primary"
                                                  title={
                                                    "View Other Deduction Breakdown"
                                                  }
                                                >
                                                  {cutoff.other_deduction
                                                    .toLocaleString()
                                                    .replace(
                                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                                      ","
                                                    )}
                                                </Link>
                                              </td>
                                              <td className="text-right">
                                                {cutoff.wh_tax
                                                  .toLocaleString()
                                                  .replace(
                                                    /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                                    ","
                                                  )}
                                              </td>
                                              <td
                                                className={
                                                  cutoff.gross / 2 > cutoff.net
                                                    ? "text-right text-danger"
                                                    : "text-right"
                                                }
                                              >
                                                {parseFloat(cutoff.net)
                                                  .toFixed(2)
                                                  .replace(
                                                    /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                                    ","
                                                  )}
                                              </td>
                                              <td className="text-center text-nowrap">
                                                {cutoff.post_date !=
                                                "PENDING" ? (
                                                  dateFormatter(
                                                    cutoff.post_date,
                                                    "MMM dd, yyyy"
                                                  )
                                                ) : (
                                                  <strong className="text-danger">
                                                    PENDING
                                                  </strong>
                                                )}
                                              </td>
                                              <td>
                                                <div className="btn-group">
                                                  <Link
                                                    to={{
                                                      pathname: `/reg_payroll/${cutoff.id}/main`,
                                                      query: `/reg_payroll`,
                                                      search:
                                                        "?search=&searchMain=" +
                                                        search,
                                                    }}
                                                    className="btn btn-sm btn-outline-primary"
                                                    title={"View per employee"}
                                                  >
                                                    <FindInPage />
                                                  </Link>
                                                </div>
                                              </td>
                                            </tr>
                                          ))
                                        ) : (
                                          <tr>
                                            <td
                                              colSpan="13"
                                              className="text-center"
                                            >
                                              No Data to show
                                            </td>
                                          </tr>
                                        )}
                                      </tbody>
                                    </table>
                                  </div>
                                  <div className="drew-pagination">
                                    {filteredData.length > 0
                                      ? "Showing " +
                                        (startIndex + 1) +
                                        " to " +
                                        (endIndex > filteredData.length
                                          ? filteredData.length
                                          : endIndex) +
                                        " of " +
                                        filteredData.length
                                      : ""}
                                    <ul class="pagination ml-2">
                                      {pages.map((number) => (
                                        <li
                                          key={number}
                                          id={number}
                                          className={
                                            currentPage == number
                                              ? "paginate_button page-item active"
                                              : "paginate_button page-item"
                                          }
                                          onClick={() => setCurrentPage(number)}
                                        >
                                          <a class="page-link">{number}</a>
                                        </li>
                                      ))}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <h1 className="m-0 text-center">
                Your credentials is not sufficient to access this:
                <br />
              </h1>
              <br />
              <h2 className="text-center">
                Module : Payroll Processing
                <br />
                Access Type : {access[permissions]}
                <br />
                Please contact your Payroll Administrator.
                <br />
                <a className="btn btn-primary btn-sm" href="/#/app">
                  Back to Dashboard
                </a>
              </h2>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
