import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { Check, Receipt } from "@material-ui/icons";
import axios from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();

export default class ExportPhicContributionEmp extends Component {
  constructor(props) {
    super();
    this.state = {
      showDeleteModal: false,
      disableSubmit: false,
      status: 2,
    };
    this.handleshowDeleteModal = this.handleshowDeleteModal.bind(this);
  }

  handleshowDeleteModal = (e) => {
    this.setState({ showDeleteModal: !this.state.showDeleteModal });
  };

  handleSubmit = (e) => {
    axios({
      url:
        process.env.REACT_APP_API_LINK +
        "api/phic_contribution_emp_export/" +
        cookies.get("transCompany") + "/" + this.props.employee_id, //your url
      method: "GET",
      responseType: "blob", // important
    }).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "PHILHEALTH Contribution: "+this.props.employee_name+".pdf"); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
  };

  render() {
    return (
      <>
        <button
          className="btn btn-outline-success btn-sm float-right"
          title=" Export PHILHEALTH Contribution"
          onClick={this.handleSubmit}
        >
          <Receipt />
          {this.props.type ? "  Export to PDF" : ""}
        </button>
      </>
    );
  }
}
