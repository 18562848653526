import React, { Component } from "react";
import Header from "../../components/Header";
import { withRouter } from "react-router";
import { Edit, ChromeReaderMode } from "@material-ui/icons";
import axios from "axios";
import Users from "./users/Users";
import Roles from "./user_roles/Roles";
import TaxBracket from "./tax_brackets/TaxBracket";
import SssBracket from "./sss_brackets/SssBracket";
import Statutories from "./statutories/Staturies";
import EarningDeduction from "./earning_deductions/EarningDeduction";
import Cookies from "universal-cookie";
import TaxExempt from "./tax_exempt/TaxExempt";
const cookies = new Cookies();

const access = ["No Access", "View Only", "Add", "Add / Edit", "Full Access"];

export default function DataMngt() {
  const permissions = cookies.get("permissions")["config"];
  return (
    <div className="hk-wrapper hk-vertical-nav">
      <div class="pg-hk-wrapper">
        <Header />
        <div className="mt-20 hk-pg-wrapper">
          {permissions > 0 ? (
            <>
              <div className="container-fluid">
                <div className="row mb-10">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <h3>Data Management</h3>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="btn-group float-right"></div>
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <div className="container-fluid">
                    <div class="card card-tabs">
                      <div class="card-header p-0 pt-1">
                        <ul
                          class="nav nav-tabs"
                          id="custom-tabs-two-tab"
                          role="tablist"
                        >
                          <li class="nav-item">
                            <a
                              class="nav-link active"
                              id="custom-tabs-two-home-tab"
                              data-toggle="pill"
                              href="#tax-bracket"
                              role="tab"
                              aria-controls="custom-tabs-two-home"
                              aria-selected="true"
                            >
                              <i className="dropdown-icon zmdi zmdi-money"></i>
                              &nbsp;Tax Bracket
                            </a>
                          </li>
                          <li class="nav-item">
                            <a
                              class="nav-link"
                              id="custom-tabs-two-home-tab"
                              data-toggle="pill"
                              href="#sss-premium-bracket"
                              role="tab"
                              aria-controls="custom-tabs-two-home"
                              aria-selected="true"
                            >
                              <i className="dropdown-icon zmdi zmdi-balance"></i>
                              &nbsp;SSS Bracket
                            </a>
                          </li>
                          <li class="nav-item">
                            <a
                              class="nav-link"
                              id="custom-tabs-two-home-tab"
                              data-toggle="pill"
                              href="#statutories"
                              role="tab"
                              aria-controls="custom-tabs-two-home"
                              aria-selected="true"
                            >
                              <i className="dropdown-icon zmdi zmdi-settings"></i>
                              &nbsp;PhilHealth & PAGIBIG
                            </a>
                          </li>
                          <li class="nav-item">
                            <a
                              class="nav-link"
                              id="custom-tabs-two-home-tab"
                              data-toggle="pill"
                              href="#earning-deduction"
                              role="tab"
                              aria-controls="custom-tabs-two-home"
                              aria-selected="true"
                            >
                              <i className="dropdown-icon zmdi zmdi-swap"></i>
                              &nbsp;Earnings & Deductions
                            </a>
                          </li>
                          <li class="nav-item">
                            <a
                              class="nav-link"
                              id="custom-tabs-two-home-tab"
                              data-toggle="pill"
                              href="#tax-exempt"
                              role="tab"
                              aria-controls="custom-tabs-two-home"
                              aria-selected="true"
                            >
                              <i className="dropdown-icon zmdi zmdi-graduation-cap"></i>
                              &nbsp;Tax Exempt Ceiling
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div class="card-body">
                        <div
                          class="tab-content"
                          id="custom-tabs-two-tabContent"
                        >
                          <div
                            class="tab-pane fade show active"
                            id="tax-bracket"
                            role="tabpanel"
                            aria-labelledby="custom-tabs-two-home-tab"
                          >
                            <TaxBracket />
                          </div>

                          <div
                            class="tab-pane fade show"
                            id="sss-premium-bracket"
                            role="tabpanel"
                            aria-labelledby="custom-tabs-two-home-tab"
                          >
                            <SssBracket />
                          </div>
                          <div
                            class="tab-pane fade show"
                            id="statutories"
                            role="tabpanel"
                            aria-labelledby="custom-tabs-two-home-tab"
                          >
                            <Statutories />
                          </div>
                          <div
                            class="tab-pane fade show"
                            id="earning-deduction"
                            role="tabpanel"
                            aria-labelledby="custom-tabs-two-home-tab"
                          >
                            <EarningDeduction />
                          </div>
                          <div
                            class="tab-pane fade show"
                            id="tax-exempt"
                            role="tabpanel"
                            aria-labelledby="custom-tabs-two-home-tab"
                          >
                            <TaxExempt />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <h1 className="m-0 text-center">
                Your credentials is not sufficient to access this:
                <br />
              </h1>
              <br />
              <h2 className="text-center">
                Module : Configuration
                <br />
                Access Type : {access[permissions]}
                <br />
                Please contact your Payroll Administrator.
                <br />
                <a className="btn btn-primary btn-sm" href="/#/app">
                  Back to Dashboard
                </a>
              </h2>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
