import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { Edit } from "@material-ui/icons";
import axios from "axios";
import { dateFormatter } from "../../components/DtFormatter";
import Cookies from "universal-cookie";
const cookies = new Cookies();

export default class EditPaySched extends Component {
  constructor(props) {
    super();
    this.state = {
      showEditModal: false,
      disableAdd: false,
      updated_by: cookies.get("email"),
      hasError: false,
      payment_date: props.paySchedDetails.payment_date,
    };

    this.handleshowEditModal = this.handleshowEditModal.bind(this);
  }

  handleshowEditModal = (e) => {
    this.setState({ showEditModal: !this.state.showEditModal });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    axios
      .put("api/paysched/" + this.props.paySchedDetails.id, this.state)
      .then((res) => {
        this.props.parentCallback(true);
        this.setState({
          showEditModal: false,
          disableAdd: false,
          hasError: false,
        });
      })
      .catch((err) => {
        this.setState({ disableAdd: false, hasError: true });
        console.log(err);
      });
  };

  render() {
    return (
      <>
        <button
          className="btn btn-outline-success btn-sm"
          title="Edit Pay Schedule"
          onClick={this.handleshowEditModal}
        >
          <Edit />
        </button>
        <Modal
          size="md"
          backdrop="static"
          show={this.state.showEditModal}
          onHide={() =>
            this.setState({ showEditModal: !this.state.showEditModal })
          }
        >
          <Modal.Header closeButton>
            <h4>Edit Pay Schedule</h4>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <div
                  className={
                    this.state.hasError
                      ? "alert alert-danger"
                      : "alert alert-danger d-none"
                  }
                  role="alert"
                >
                  Please enter a date between{" "}
                  {dateFormatter(
                    this.props.paySchedDetails.start_date,
                    "MMM dd, yyyy"
                  )}{" "}
                  -{" "}
                  {dateFormatter(
                    this.props.paySchedDetails.end_date,
                    "MMM dd, yyyy"
                  )}
                </div>
              </div>
            </div>
            <form onSubmit={this.handleSubmit} id="contact-form">
              <div className="row">
                <div className="col-md-12 col-sm-12">
                  <label>Pay Period ID :</label>{" "}
                  <strong>{this.props.paySchedDetails.code}</strong>
                </div>
                <div className="col-md-12 col-sm-12">
                  <label>Year :</label>{" "}
                  <strong>{this.props.paySchedDetails.sched_year}</strong>
                </div>
                <div className="col-md-12 col-sm-12">
                  <label>Start Date :</label>{" "}
                  <strong>
                    {dateFormatter(
                      this.props.paySchedDetails.start_date,
                      "MMM dd, yyyy"
                    )}
                  </strong>
                </div>
                <div className="col-md-12 col-sm-12">
                  <label>End Date :</label>{" "}
                  <strong>
                    {dateFormatter(
                      this.props.paySchedDetails.end_date,
                      "MMM dd, yyyy"
                    )}
                  </strong>
                </div>
                <div className="form-group col-md-12 col-sm-12">
                  <label>Payment Date</label>
                  <input
                    type="date"
                    className="form-control"
                    required
                    onChange={(e) =>
                      this.setState({ payment_date: e.target.value })
                    }
                    defaultValue={this.props.paySchedDetails.payment_date}
                  />
                </div>
              </div>

              <div className="float-right">
                <button
                  type="submit"
                  className="btn btn-primary btn-sm mr-2"
                  disabled={this.state.disableAdd}
                >
                  Save
                </button>
                <button
                  onClick={this.handleshowEditModal}
                  className="btn btn-danger btn-sm"
                  type="button"
                >
                  Cancel
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
