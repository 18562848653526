import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import {
  FindInPage,
  Check,
  DirectionsRun,
  PictureAsPdf,
  Email,
} from "@material-ui/icons";
import axios from "axios";
import Cookies from "universal-cookie";
import { dateFormatter } from "../../components/DtFormatter";
import GenerateSpPayslip from "./GenerateSpPayslip";
const statuses = ["FOR PROCESSING", "FOR POSTING", "POSTED", "REVOKED"];

export default function SpPayslip(props) {
  const cookies = new Cookies();
  const ability = cookies.get("ability")[2];
  const setting = cookies.get("setting");
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [filteredData, setFilteredData] = useState([]);
  const [fileLoaded, setFileLoaded] = useState(false);
  const [search, setSearch] = useState(
    props.location.search
      ? decodeURI(props.location.search.split("=").pop())
      : ""
  );

  useEffect(() => {
    const fetchData = async () => {
      let apiLink = "api/sp_payslip/" + cookies.get("transCompany");
      const res = await axios.get(apiLink);

      setData(res.data);
      const filterer = searcher(res.data, search);
      setFilteredData(filterer);
    };

    if (!fileLoaded) {
      fetchData();
      setFileLoaded(true);
    }
  });

  const handleAddCallback = (editData) => {
    if (editData) {
      setFileLoaded(false);
    }
  };

  const pages = [];
  for (let i = 1; i <= Math.ceil(filteredData.length / recordsPerPage); i++) {
    pages.push(i);
  }

  const handleRecordPerPage = (e) => {
    setRecordsPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const handleSearch = (e) => {
    const filterer = searcher(data, e.target.value);
    setFilteredData(filterer);
    setSearch(e.target.value);
    setCurrentPage(1);
  };

  const searcher = (data, searchString) => {
    const searchedData = data.filter(function (data) {
      for (var key in data) {
        if (
          !(
            data["code"].toLowerCase().includes(searchString.toLowerCase()) ||
            data["description"]
              .toLowerCase()
              .includes(searchString.toLowerCase()) ||
            (
              dateFormatter(data["start_date"]) +
              " to " +
              dateFormatter(data["end_date"])
            )
              .toLowerCase()
              .includes(searchString.toLowerCase())
          )
        ) {
          return false;
        }
        return true;
      }
    });

    return searchedData;
  };
  const startIndex = currentPage * recordsPerPage - recordsPerPage;
  const endIndex = startIndex + recordsPerPage;
  const currentData = filteredData.slice(startIndex, endIndex);

  console.log(data);
  return (
    <div className="hk-wrapper hk-vertical-nav">
      <div class="pg-hk-wrapper">
        <Header />
        <div className="mt-20 hk-pg-wrapper">
          {ability.permission > 0 ? (
            <div className="container-fluid">
              <h3 className="mb-10">Special Payslips</h3>
              <div>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="table-wrap">
                      <div className="row mb-10">
                        <div className="col-md-6">
                          <label>
                            <select
                              className="custom-select custom-select-sm form-control form-control-sm"
                              onChange={handleRecordPerPage}
                            >
                              <option value="10">10</option>
                              <option value="25">25</option>
                              <option value="50">50</option>
                              <option value="100">100</option>
                            </select>
                          </label>{" "}
                          items
                        </div>
                        <div className="col-md-6">
                          <input
                            type="search"
                            className="form-control form-control-sm col-md-6 col-sm-6 float-right"
                            placeholder="Search"
                            defaultValue={
                              props.location.search
                                ? decodeURI(
                                    props.location.search.split("=").pop()
                                  )
                                : ""
                            }
                            onChange={handleSearch}
                          />
                        </div>
                      </div>
                      <div className="table-responsive mb-10">
                        <table className="table table-sm table-bordered table-striped mb-0">
                          <thead className="thead-secondary">
                            <tr className="text-center">
                              {/* <th>Company</th> */}
                              <th>Description</th>
                              <th>Pay Period ID</th>
                              <th>Period</th>
                              <th>Head Count</th>
                              <th>Total Net Pay</th>
                              <th>Date Posted</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredData.length > 0 ? (
                              currentData.map((cutoff) => (
                                <tr key={cutoff.id} className="text-nowrap">
                                  {/* <td>{cutoff.company_name}</td> */}
                                  <td>{cutoff.description}</td>
                                  <td className="text-center">{cutoff.code}</td>
                                  <td className="text-center">
                                    {dateFormatter(cutoff.start_date) +
                                      " to " +
                                      dateFormatter(cutoff.end_date)}
                                  </td>
                                  <td className="text-right">
                                    {cutoff.headcount}
                                  </td>
                                  <td className="text-right">
                                    {(
                                      (parseFloat(cutoff.total_amount) -
                                        parseFloat(cutoff.wh_tax)) *
                                      1
                                    )
                                      .toFixed(2)
                                      .replace(
                                        /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                        ","
                                      )}
                                  </td>
                                  <td className="text-center">
                                    {dateFormatter(
                                      cutoff.post_date,
                                      "MMM dd, yyyy"
                                    )}
                                  </td>
                                  <td className="text-center">
                                    <div className="btn-group">
                                      <Link
                                        to={{
                                          pathname: `/sp_payslip/${cutoff.id}`,
                                          query: `/sp_payslip`,
                                          search:
                                            "?search=&searchMain=" + search,
                                        }}
                                        className="btn btn-sm btn-outline-primary"
                                        title={"Output per Employee"}
                                      >
                                        <FindInPage />
                                      </Link>
                                      {cutoff.payslip_generated == 0 && setting.payslip_email == 1 ? (
                                        <GenerateSpPayslip
                                          id={cutoff.id}
                                          parentCallback={handleAddCallback}
                                        />
                                      ) : (
                                        ""
                                      )}
                                      {/* <ExportSpPayslip id={cutoff.id} />  */}
                                    </div>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="8" className="text-center">
                                  No Data to show
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="drew-pagination">
                      {filteredData.length > 0
                        ? "Showing " +
                          (startIndex + 1) +
                          " to " +
                          (endIndex > filteredData.length
                            ? filteredData.length
                            : endIndex) +
                          " of " +
                          filteredData.length
                        : ""}
                      <ul class="pagination ml-2">
                        {pages.map((number) => (
                          <li
                            key={number}
                            id={number}
                            className={
                              currentPage == number
                                ? "paginate_button page-item active"
                                : "paginate_button page-item"
                            }
                            onClick={() => setCurrentPage(number)}
                          >
                            <a class="page-link">{number}</a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <h1 className="m-0 text-center">
              The Credentials Supplied Are Not Sufficient To Access This Module
              <br />
              Please Contact Administrator
            </h1>
          )}
        </div>
      </div>
    </div>
  );
}
