import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { Check } from "@material-ui/icons";
import axios from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();

export default class YtdAdjPost extends Component {
  constructor(props) {
    super();
    this.state = {
      showModal: false,
      disableSubmit: false,
      status: 3,
      updated_by: cookies.get("email"),
      company_id: cookies.get("transCompany"),
    };
    this.handleshowDeleteModal = this.handleshowDeleteModal.bind(this);
  }

  handleshowDeleteModal = (e) => {
    this.setState({ showModal: !this.state.showModal });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ disableSubmit: true });
    axios
      .post("api/ytd_post/" + this.props.schedData.id, this.state)
      .then((res) => {
        this.props.parentCallback(true);
        this.setState({ showModal: false, disableSubmit: false });
      });
  };

  render() {
    return (
      <>
        <button
          className={
            this.props.schedData.status == 2
              ? "btn btn-gradient-success"
              : "btn btn-sm btn-success"
          }
          title="Post YTD Adjustment"
          onClick={this.handleshowDeleteModal}
          disabled={this.props.schedData.status != 2 ? "disabled" : ""}
        >
          Post YTD Adjustment
        </button>
        <Modal
          size="md"
          show={this.state.showModal}
          onHide={() => this.setState({ showModal: !this.state.showModal })}
        >
          <Modal.Header closeButton>
            <h4>
              Post YTD Adjustment:
              <br />
            </h4>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={this.handleSubmit} id="contact-form">
              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  Are you sure?
                </div>
              </div>
              <div className="float-right">
                <button
                  type="submit"
                  className="btn btn-primary btn-sm mr-2"
                  disabled={this.state.disableSubmit}
                >
                  Post YTD Adjustment
                </button>
                <button
                  onClick={this.handleshowDeleteModal}
                  className="btn btn-danger btn-sm"
                  type="button"
                >
                  Cancel
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}