import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { Check } from "@material-ui/icons";
import axios from "axios";
import Cookies from "universal-cookie";
import Processing from "../../components/Processing";
const cookies = new Cookies();

export default class RegularApprove extends Component {
  constructor(props) {
    super();
    this.state = {
      showDeleteModal: false,
      disableSubmit: false,
      updated_by: cookies.get("email"),
      created_by: cookies.get("email"),
      refreshDisabled: false,
    };
    this.handleshowDeleteModal = this.handleshowDeleteModal.bind(this);
  }

  handleshowDeleteModal = (e) => {
    this.setState({ showDeleteModal: !this.state.showDeleteModal });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    let data = {
        paysched_id: this.props.id,
        updated_by: cookies.get("email"),
        created_by: cookies.get("email"),
        company_id: cookies.get("transCompany"),
    };

    this.setState({ disableSubmit: true, refreshDisabled: true, showDeleteModal: false  });
    axios.post("api/draft_approved", data).then((res) => {
      // this.props.parentCallback(true);
      // this.setState({ showDeleteModal: false, disableSubmit: false});
      setTimeout(
        function () {
          this.props.parentCallback(true);
          this.setState({refreshDisabled: false, disableSubmit: false  });
        }.bind(this),
        1000
      );
    });
  };

  render() {
    return (
      <>
        <button
          className={this.props.schedData.sched_status == 1 ? "btn btn-gradient-info": "btn btn-sm btn-info"}
          title="Reviewed & Approved For Posting"
          onClick={this.handleshowDeleteModal}
          disabled={this.props.schedData.sched_status != 1 ? ("disabled"):("")}
        >
          Reviewed For Posting
        </button>
        <Modal
          size="md"
          show={this.state.showDeleteModal}
          onHide={() =>
            this.setState({ showDeleteModal: !this.state.showDeleteModal })
          }
        >
          <Modal.Header closeButton>
            <h4>
                Reviewed & Approved For Posting :<br />
            </h4>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={this.handleSubmit} id="contact-form">
              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  Are you sure?
                </div>
              </div>
              <div className="float-right">
                <button
                  type="submit"
                  className="btn btn-primary btn-sm mr-2"
                  disabled={this.state.disableSubmit}
                >
                  Approve
                </button>
                <button
                  onClick={this.handleshowDeleteModal}
                  className="btn btn-danger btn-sm"
                  type="button"
                >
                  Cancel
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
        <Processing show={this.state.refreshDisabled} name="Regular Review Run "/>
      </>
    );
  }
}
