import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { Add } from "@material-ui/icons";
import axios from "axios";
import Cookies from "universal-cookie";
import Select from "react-select";
const cookies = new Cookies();
const pay_period = ["Weekly", "15th & 30th", "15th", "30th"];

export default class AddTransaction extends Component {
  constructor() {
    super();
    this.state = {
      updated_by: cookies.get("email"),
      created_by: cookies.get("email"),
      type: "EARNING",
      pay_period: "",
      company_id: cookies.get("transCompany"),
      showAddModal: false,
      disableSubmit: false,
      hasError: false,
      errMsg: ''
    };
    this.handleshowAddModal = this.handleshowAddModal.bind(this);
  }

  handleshowAddModal = (e) => {
    this.setState({ showAddModal: !this.state.showAddModal, hasError: false });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ disableSubmit: true });
    axios
      .post("api/transaction", this.state)
      .then((res) => {
        this.props.parentCallback(true);
        this.setState({
          showAddModal: false,
          hasError: false,
          disableSubmit: false,
        });
      })
      .catch((err) => {
        this.setState({ disableSubmit: false, hasError: true, errMsg: err.response.data.message });
      });
  };

  render() {
    return (
      <>
        <button
          className="btn btn-outline-primary btn-sm float-right"
          title="Add New Transaction"
          onClick={this.handleshowAddModal}
        >
          <Add /> New Transaction
        </button>
        <Modal
          size="md"
          show={this.state.showAddModal}
          onHide={() =>
            this.setState({ showAddModal: !this.state.showAddModal })
          }
        >
          <Modal.Header closeButton>
            <h4>Add Earning Transaction</h4>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <div
                  className={
                    this.state.hasError
                      ? "alert alert-danger"
                      : "alert alert-danger d-none"
                  }
                  role="alert"
                >
                  Failed to Create Transaction: {this.state.errMsg} 
                </div>
              </div>
            </div>
            <form onSubmit={this.handleSubmit} id="contact-form">
              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  <label>Employee</label>
                  <Select
                    placeholder="Select Employee"
                    options={this.props.empDD}
                    onChange={(e) => {
                      this.setState({ employee_id: e.value });
                    }}
                    isSearchable
                    required
                  />
                  <input
                    className="form-control hidden hidden-require"
                    type="text"
                    value={this.state.employee_id}
                    autoComplete="off"
                    required
                  />
                </div>
                <div className="form-group col-md-12 col-sm-12">
                  <label>Earning Type</label>
                  <select
                    className="form-control custom-select"
                    required
                    onChange={(e) => {
                      this.setState({
                        ed_id: e.target.value,
                      });

                      if(e.target.value == 1 || e.target.value == 8 ||e.target.value == 28){
                        if(cookies.get("transPay") == 1){
                          this.setState({
                            pay_period: "2"
                          });
                        } else {
                          this.setState({
                            pay_period: ""
                          });
                        }
                      } else {
                        this.setState({
                          pay_period: ""
                        });
                      }
                      
                    }}
                  >
                    <option value="" datapayperiod="">
                      Select Earning
                    </option>
                    {this.props.edDD.map((ed) => (
                      <option
                        key={ed.id}
                        value={ed.id}
                        datapayperiod={"" + ed.pay_period + ""}
                        hidden={ed.is_add == 1 ? "" : "hidden"}
                      >
                        {ed.code + " - " + ed.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group col-md-12 col-sm-12">
                  <label>Pay Period Release</label>
                  <select
                    id="payPeriodSelect"
                    className="form-control custom-select"
                    required
                    value={this.state.pay_period}
                    onChange={(e) => {
                      this.setState({ pay_period: e.target.value });
                    }}
                  >
                    <option value="">Select Pay Period Release</option>
                    {cookies.get("transPay") == 1 ? (
                      this.state.ed_id == 1 || this.state.ed_id == 8 || this.state.ed_id == 28 ? (
                        <>
                          <option value="2">15th (Semi-Monthly)</option>
                          <option value="4">30th (Semi-Monthly)</option>
                          <option value="1">15th & 30th (Semi-Monthly)</option>
                        </>
                      ) : (
                        <>
                          <option value="2">15th (Semi-Monthly)</option>
                          <option value="4">30th (Semi-Monthly)</option>
                          <option value="1">15th & 30th (Semi-Monthly)</option>
                        </>
                      )
                    ) : (
                      <option value="3">30th (Monthly)</option>
                    )}
                  </select>
                </div>
                <div className="form-group col-md-12 col-sm-12">
                  <label>Total Amount</label>
                  <input
                    type="number"
                    className="form-control"
                    step=".01"
                    required
                    onChange={(e) => {
                      this.setState({
                        amount: e.target.value,
                        balance: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="form-group col-md-12 col-sm-12">
                  <label>Per Payment Period Amount</label>
                  <input
                    type="number"
                    className="form-control"
                    step=".01"
                    required
                    onChange={(e) => {
                      this.setState({ per_payment: e.target.value });
                    }}
                  />
                </div>
                <div className="form-group col-md-12 col-sm-12">
                  <label>Pay Period ID From</label>
                  <Select
                    placeholder="Select Pay Period ID From"
                    options={this.props.paySchedFrom}
                    onChange={(e) => {
                      this.setState({ from_date: e.value });
                    }}
                  />
                  <input
                    className="form-control hidden hidden-require"
                    type="text"
                    value={this.state.from_date}
                    autoComplete="off"
                    required
                  />
                </div>
              </div>

              <div className="float-right">
                <button
                  type="submit"
                  className="btn btn-primary btn-sm mr-2"
                  disabled={this.state.disableSubmit}
                >
                  Save
                </button>
                <button
                  onClick={this.handleshowAddModal}
                  className="btn btn-danger btn-sm"
                  type="button"
                >
                  Cancel
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
