import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { Add } from "@material-ui/icons";
import axios from "axios";
import Cookies from "universal-cookie";
import Select from "react-select";
const cookies = new Cookies();
const pay_period = ["Weekly", "15th & 30th", "15th", "30th"];

export default class AddYtdAdjustment extends Component {
  constructor() {
    super();
    this.state = {
      updated_by: cookies.get("email"),
      created_by: cookies.get("email"),
      // type: "EARNING",
      // pay_period: cookies.get("transPay"),
      company_id: cookies.get("transCompany"),
      showAddModal: false,
      disableSubmit: false,
      hasError: false,
    };
    this.handleshowAddModal = this.handleshowAddModal.bind(this);
  }

  handleshowAddModal = (e) => {
    this.setState({ showAddModal: !this.state.showAddModal, hasError: false });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ disableSubmit: true });
    axios
      .post("api/add_adjustment", this.state)
      .then((res) => {
        this.props.parentCallback(true);
        this.setState({
          showAddModal: false,
          hasError: false,
          disableSubmit: false,
        });
      })
      .catch((err) => {
        this.setState({ disableSubmit: false, hasError: true });
      });
  };

  render() {
    return (
      <>
        <button
          className="btn btn-outline-primary btn-sm float-right"
          title="Add New YTD Adjustment"
          onClick={this.handleshowAddModal}
        >
          <Add /> New YTD Adjustment
        </button>
        <Modal
          size="lg"
          show={this.state.showAddModal}
          onHide={() =>
            this.setState({ showAddModal: !this.state.showAddModal })
          }
        >
          <Modal.Header closeButton>
            <h4>Add YTD Adjustment</h4>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <div
                  className={
                    this.state.hasError
                      ? "alert alert-danger"
                      : "alert alert-danger d-none"
                  }
                  role="alert"
                >
                  Failed to YTD Adjustment
                </div>
              </div>
            </div>
            <form onSubmit={this.handleSubmit} id="contact-form">
              <div className="row">
                <div className="form-group col-md-6 col-sm-12">
                  <label>Year</label>
                  <Select
                    placeholder="Select YTD Year"
                    options={this.props.yearDD}
                    onChange={(e) => {
                      this.setState({ record_year: e.value });
                    }}
                    isSearchable
                    required
                  />
                  <input
                    className="form-control hidden hidden-require"
                    type="text"
                    value={this.state.record_year}
                    autoComplete="off"
                    required
                  />
                </div>
              </div>
              <div className="row">
                <div className="form-group col-md-6 col-sm-12">
                  <label>Employee</label>
                  <Select
                    placeholder="Select Employee"
                    options={this.props.empDD}
                    onChange={(e) => {
                      this.setState({ employee_id: e.value });
                    }}
                    isSearchable
                    required
                  />
                  <input
                    className="form-control hidden hidden-require"
                    type="text"
                    value={this.state.employee_id}
                    autoComplete="off"
                    required
                  />
                </div>
                <div className="form-group col-md-6 col-sm-12">
                  <label>Basic Pay</label>
                  <input
                    type="number"
                    step=".01"
                    className="form-control"
                    required
                    onChange={(e) => {
                      this.setState({
                        basic_pay: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="form-group col-md-6 col-sm-12">
                  <label>De Minimis</label>
                  <input
                    type="number"
                    step=".01"
                    className="form-control"
                    required
                    onChange={(e) => {
                      this.setState({
                        deminimis: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="form-group col-md-6 col-sm-12">
                  <label>O.Earnings (Tax Exempt)</label>
                  <input
                    type="number"
                    step=".01"
                    className="form-control"
                    required
                    onChange={(e) => {
                      this.setState({
                        other_earning_nt: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="form-group col-md-6 col-sm-12">
                  <label>O.Earnings</label>
                  <input
                    type="number"
                    step=".01"
                    className="form-control"
                    required
                    onChange={(e) => {
                      this.setState({
                        other_earning: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="form-group col-md-6 col-sm-12">
                  <label>13th Month Pay</label>
                  <input
                    type="number"
                    step=".01"
                    className="form-control"
                    required
                    onChange={(e) => {
                      this.setState({
                        t_month_pay: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="form-group col-md-6 col-sm-12">
                  <label>SSS Premium</label>
                  <input
                    type="number"
                    step=".01"
                    className="form-control"
                    required
                    onChange={(e) => {
                      this.setState({
                        sss_premium: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="form-group col-md-6 col-sm-12">
                  <label>SSS Provident</label>
                  <input
                    type="number"
                    step=".01"
                    className="form-control"
                    required
                    onChange={(e) => {
                      this.setState({
                        sss_provident: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="form-group col-md-6 col-sm-12">
                  <label>PhilHealth</label>
                  <input
                    type="number"
                    step=".01"
                    className="form-control"
                    required
                    onChange={(e) => {
                      this.setState({
                        philhealth: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="form-group col-md-6 col-sm-12">
                  <label>PAGIBIG</label>
                  <input
                    type="number"
                    step=".01"
                    className="form-control"
                    required
                    onChange={(e) => {
                      this.setState({
                        pagibig: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="form-group col-md-6 col-sm-12">
                  <label>O.Deduction</label>
                  <input
                    type="number"
                    step=".01"
                    className="form-control"
                    required
                    onChange={(e) => {
                      this.setState({
                        other_deduction: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="form-group col-md-6 col-sm-12">
                  <label>WH Tax</label>
                  <input
                    type="number"
                    step=".01"
                    className="form-control"
                    required
                    onChange={(e) => {
                      this.setState({
                        wh_tax: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>

              <div className="float-right">
                <button
                  type="submit"
                  className="btn btn-primary btn-sm mr-2"
                  disabled={this.state.disableSubmit}
                >
                  Save
                </button>
                <button
                  onClick={this.handleshowAddModal}
                  className="btn btn-danger btn-sm"
                  type="button"
                >
                  Cancel
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
