import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import axios from "axios";
import ReactExport from "react-data-export";
import Cookies from "universal-cookie";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

export default function EmpReport(props) {
  const cookies = new Cookies();
  const ability = cookies.get("ability")[3];
  const [data, setData] = useState([]);
  const [employee, setEmployee] = useState([]);
  const [cutoff, setCutoff] = useState([]);
  const [rates, setRates] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      let apiLink =
        "api/overtime_detail/" +
        props.match.params.cutoff_id +
        "/" +
        props.match.params.employee_id;
      const res = await axios.get(apiLink);

      setData(res.data);
      setFilteredData(res.data);
    };

    const fetchEmp = async () => {
      let apiLink = "api/employee/" + props.match.params.employee_id;
      const res = await axios.get(apiLink);
      setEmployee(res.data);
    };

    const fetchCutoff = async () => {
      let apiLink = "api/cutoff/" + props.match.params.cutoff_id;
      const res = await axios.get(apiLink);
      setCutoff(res.data);
    };

    const fetchRates = async () => {
      let apiLink = "api/rates/" + props.match.params.employee_id;
      const res = await axios.get(apiLink);
      setRates(res.data);
    };

    fetchEmp();
    fetchCutoff();
    fetchData();
    fetchRates();
  }, []);

  const handleBack = () => {
    props.history.goBack();
  };

  const getTotalAmt = data.reduce(
    (total, currentValue) =>
      (total = total + parseFloat(currentValue.pay_amt_mins)),
    0
  );

  const getTotalNetAmt = data.reduce(
    (total, currentValue) =>
      (total = total + parseFloat(currentValue.pay_net_amt_mins)),
    0
  );

  const dataSet = [
    {
      columns: [{ title: "Company Name" }, { title: employee.company_name }],
      data: [["", ""]],
    },
    {
      ySteps: -1,
      columns: [
        { title: "Employee Name" },
        { title: employee.last_name + ", " + employee.first_name },
      ],
      data: [["", ""]],
    },
    {
      ySteps: -1,
      columns: [{ title: "Department" }, { title: employee.department }],
      data: [["", ""]],
    },
    {
      ySteps: -1,
      columns: [{ title: "Position" }, { title: employee.position }],
      data: [["", ""]],
    },
    {
      ySteps: -1,
      columns: [
        { title: "Pay Period" },
        { title: cutoff.f_co_from_date + " - " + cutoff.f_co_to_date },
      ],
      data: [["", ""]],
    },
    {
      ySteps: -1,
      columns: [
        { title: "Hour Rate" },
        { title: "" + parseFloat(employee.hour_rate).toFixed(2) },
      ],
      data: [["", ""]],
    },
    {
      ySteps: -1,
      columns: [
        { title: "Minute Rate" },
        { title: "" + parseFloat(employee.minute_rate).toFixed(2) },
      ],
      data: [["", ""]],
    },
    {
      columns: [
        { title: "Type of Day" },
        { title: "Rate(Hours)" },
        { title: "Rate(Mins)" },
      ],
      data: rates.map((order, index) => {
        return [
          { value: order.description },
          { value: parseFloat(order.ot_rate).toFixed(2) },
          { value: (order.ot_rate / 60).toFixed(2) },
        ];
      }),
    },
    {
      ySteps: 1,
      columns: [
        { title: "Date" },
        { title: "Day" },
        { title: "Description" },
        { title: "Time In" },
        { title: "Time Out" },
        { title: "Overtime Hours(Minutes)" },
        { title: "Rate" },
        { title: "Amount" },
      ],
      data: data.map((order, index) => {
        return [
          { value: order.f_date },
          { value: order.date_day },
          { value: order.description },
          {
            value:
              order.condition_id % 3 == 0
                ? order.f_nd_time_in
                : order.f_time_in,
          },
          { value: order.condition_id % 3 != 0
            ? order.is_nd == 1 ? order.f_nd_time_in : order.f_time_out
            : order.f_time_out },
          { value: parseFloat(order.ot_minutes).toFixed(2) },
          { value: parseFloat(order.actual_rate).toFixed(2) },
          { value: parseFloat(order.pay_amt_mins).toFixed(2) },
        ];
      }),
    },
    {
      columns: [
        { title: "" },
        { title: "" },
        { title: "" },
        { title: "" },
        { title: "" },
        { title: "" },
        { title: "Total OT pay (Gross)" },
        { title: "" + getTotalAmt.toFixed(2) },
      ],
      data: [["", "", "", "", "", "", ""]],
    },
    {
      ySteps: -1,
      columns: [
        { title: "" },
        { title: "" },
        { title: "" },
        { title: "" },
        { title: "" },
        { title: "" },
        { title: "Tax(" + (employee.ot_tax * 100).toFixed(2) + " %)" },
        { title: "-" + (getTotalAmt - getTotalNetAmt).toFixed(2) },
      ],
      data: [["", "", "", "", "", "", ""]],
    },
    {
      ySteps: -1,
      columns: [
        { title: "" },
        { title: "" },
        { title: "" },
        { title: "" },
        { title: "" },
        { title: "" },
        { title: "Total OT pay (Net)" },
        { title: "" + getTotalNetAmt.toFixed(2) },
      ],
      data: [["", "", "", "", "", "", ""]],
    },
  ];

  return (
    <div className="hk-pg-wrapper">
        <Header />
        <div className="mt-20">
        {ability.permission > 0 ? (
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12 ">
                <h3 className="pb-10">Overtime Details</h3>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12">
                <button
                  className="btn btn-sm btn-danger float-right "
                  onClick={handleBack}
                >
                  Back to Previous Page
                </button>
                {ability.permission > 1 ? (
                  <ExcelFile
                    element={
                      <button className="btn btn-sm btn-success float-right mr-10">
                        <i class="fa fa-file-excel-o" aria-hidden="true"></i>{" "}
                        Export to Excel
                      </button>
                    }
                    filename={
                      "Total Overtime " +
                      employee.last_name +
                      ", " +
                      employee.first_name +
                      " " +
                      cutoff.f_co_from_date +
                      " - " +
                      cutoff.f_co_to_date
                    }
                  >
                    <ExcelSheet dataSet={dataSet} name="Total Overtime" />
                  </ExcelFile>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="card" style={{ height: "93%" }}>
                  <div className="card-header card-header-action">
                    <h5>Employee Details</h5>
                    <div className="d-flex align-items-center card-action-wrap-tools"></div>
                  </div>
                  <div className="card-body">
                    <div className="row mb-10">
                      <div className="col-xl-4 col-sm-10">
                        <strong>Company</strong>
                      </div>
                      <div className="col-xl-8 col-sm-10">
                        :&nbsp;&nbsp;&nbsp;
                        {cutoff.company_name}
                      </div>
                    </div>

                    <div className="row mb-10">
                      <div className="col-xl-4 col-sm-10">
                        <strong>Name</strong>
                      </div>
                      <div className="col-xl-8 col-sm-10">
                        :&nbsp;&nbsp;&nbsp;
                        {employee.last_name + ", " + employee.first_name}
                      </div>
                    </div>

                    <div className="row mb-10">
                      <div className="col-xl-4 col-sm-10">
                        <strong>Department</strong>
                      </div>
                      <div className="col-xl-8 col-sm-10">
                        :&nbsp;&nbsp;&nbsp;
                        {employee.department}
                      </div>
                    </div>

                    <div className="row mb-10">
                      <div className="col-xl-4 col-sm-10">
                        <strong>Position</strong>
                      </div>
                      <div className="col-xl-8 col-sm-10">
                        :&nbsp;&nbsp;&nbsp;
                        {employee.position}
                      </div>
                    </div>

                    <div className="row mb-10">
                      <div className="col-xl-4 col-sm-10">
                        <strong>Pay Period</strong>
                      </div>
                      <div className="col-xl-8 col-sm-10">
                        :&nbsp;&nbsp;&nbsp;
                        {cutoff.f_co_from_date + " - " + cutoff.f_co_to_date}
                      </div>
                    </div>

                    <div className="row mb-10">
                      <div className="col-xl-4 col-sm-10">
                        <strong>Employee's Hourly Rate</strong>
                      </div>
                      <div className="col-xl-8 col-sm-10">
                        :&nbsp;&nbsp;&nbsp;
                        {(1 * employee.hour_rate).toFixed(2)}
                      </div>
                    </div>

                    <div className="row mb-10">
                      <div className="col-xl-4 col-sm-10">
                        <strong>Rate per minute</strong>
                      </div>
                      <div className="col-xl-8 col-sm-10">
                        :&nbsp;&nbsp;&nbsp;
                        {(1 * employee.minute_rate).toFixed(2)}
                      </div>
                    </div>

                    <div className="row mb-10">
                      <div className="col-xl-4 col-sm-10">
                        <strong>Minimum OT Hours</strong>
                      </div>
                      <div className="col-xl-8 col-sm-10">
                        :&nbsp;&nbsp;&nbsp;
                        {employee.min_overtime} Hour(s)
                      </div>
                    </div>
                    <div className="row mb-10">
                      <div className="col-xl-4 col-sm-10">
                        <strong>Tax Rate</strong>
                      </div>
                      <div className="col-xl-8 col-sm-10">
                        :&nbsp;&nbsp;&nbsp;
                        {employee.ot_tax * 100} %
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-lg-6 col-md-6 col-sm-12">
                <div className="card">
                  {/* <div className="card-header card-header-action">
                  <h5>Rates</h5>
                  <div className="d-flex align-items-center card-action-wrap-tools"></div>
                </div> */}
                  <div className="card-body">
                    <table className="table table-sm table-bordered table-striped mb-0">
                      <thead className="thead-secondary">
                        <tr>
                          <th>Type of Day</th>
                          <th>Rate(Hours)</th>
                          <th>Rate(Mins)</th>
                        </tr>
                      </thead>
                      <tbody>
                        {rates.map((rate) => (
                          <tr>
                            <td>{rate.description}</td>
                            <td className="text-right">
                              {(1 * rate.ot_rate).toFixed(2)}
                            </td>
                            <td className="text-right">
                              {(rate.ot_rate / 60).toFixed(2)}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div className="table-responsive mb-10">
                  <table className="table table-sm table-bordered table-striped mb-0">
                    <thead className="thead-secondary">
                      <tr>
                        <th>Date</th>
                        <th>Day</th>
                        <th>Time In</th>
                        <th>Time Out</th>
                        <th>Type of Day</th>
                        <th>Overtime Hours(Minutes)</th>
                        <th>Rate(Hour)</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((detail) => (
                        <tr>
                          <td>{detail.f_date}</td>
                          <td>{detail.date_day}</td>
                          <td>
                            {detail.condition_id % 3 == 0
                              ? detail.f_nd_time_in
                              : detail.f_time_in}
                          </td>
                          <td>
                            {detail.condition_id % 3 != 0
                              ? detail.is_nd == 1 ? detail.f_nd_time_in : detail.f_time_out
                              : detail.f_time_out}
                          </td>
                          <td>{detail.code + " - " + detail.description}</td>
                          <td className="text-right">
                            {(1 * detail.ot_minutes).toFixed(2)}
                          </td>
                          <td className="text-right">
                            {parseFloat(detail.actual_rate).toFixed(2)}
                          </td>
                          <td className="text-right">
                            {(
                              detail.ot_minutes * detail.actual_rate_mins
                            ).toFixed(2)}
                          </td>
                        </tr>
                      ))}
                      {data.length > 0 ? (
                        <>
                          <tr>
                            <td colSpan="7" className="text-right">
                              <strong>Total Overtime Pay(Gross)</strong>
                            </td>
                            <td className="text-right">
                              {getTotalAmt.toFixed(2)}
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="7" className="text-right">
                              <strong>Tax({employee.ot_tax * 100} %)</strong>
                            </td>
                            <td className="text-right">
                              - {(getTotalAmt - getTotalNetAmt).toFixed(2)}
                            </td>
                          </tr>
                          <tr>
                            <td colSpan="7" className="text-right">
                              <strong>Total Overtime Pay(Net)</strong>
                            </td>
                            <td className="text-right">
                              {getTotalNetAmt.toFixed(2)}
                            </td>
                          </tr>
                        </>
                      ) : (
                        ""
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <h1 className="m-0 text-center">
            The Credentials Supplied Are Not Sufficient To Access This Module
            <br />
            Please Contact Administrator
          </h1>
        )}
      </div>
    </div>
  );
}
