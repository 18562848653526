import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { AccountCircle } from "@material-ui/icons";
import axios from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();

export default class ResetUser extends Component {
  constructor(props) {
    super();
    this.state = {
      updated_by: cookies.get("email"),
      created_by: cookies.get("email"),
      showEditModal: false,
      disableSubmit: false,
    };
    this.handleshowEditModal = this.handleshowEditModal.bind(this);
  }

  handleshowEditModal = (e) => {
    this.setState({ showEditModal: !this.state.showEditModal });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ disableSubmit: true });
    axios.post("api/reset/"+this.props.user.id, this.state).then((res) => {
      this.props.parentCallback(true);
      this.setState({ showEditModal: false, disableSubmit: false });
    });
  };

  render() {
    return (
      <>
      {this.props.type == 1 ? (
          <div
            className="dropdown-item"
            title="Reset User"
            onClick={this.handleshowEditModal}
          >
            <AccountCircle  className="text-info"/> Reset User Password
          </div>
        ) : (
          <button
          className="btn btn-outline-info btn-sm float-right"
          title="Reset User"
          onClick={this.handleshowEditModal}
        >
          <AccountCircle />
        </button>
        )}
        
        <Modal
          size="md"
          backdrop="static"
          show={this.state.showEditModal}
          onHide={() =>
            this.setState({ showEditModal: !this.state.showEditModal })
          }
        >
          <Modal.Header closeButton>
            <h4> Reset User: {this.props.user.name}</h4>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={this.handleSubmit} id="contact-form">
              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  <label>Are you sure?</label>
                </div>
              </div>
            <div className="float-right">
                <button
                  type="submit"
                  className="btn btn-primary btn-sm mr-2"
                  disabled={this.state.disableSubmit}
                >
                  Save
                </button>
                <button
                  onClick={this.handleshowEditModal}
                  className="btn btn-danger btn-sm"
                  type="button"
                >
                  Cancel
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
