import React, { Component } from "react";
import { timeFormatter } from "../../components/DtFormatter";
import { Modal } from "react-bootstrap";
import { Edit } from "@material-ui/icons";
import axios from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();

export default class EditCompany extends Component {
  constructor(props) {
    super();
    this.state = {
      updated_by: cookies.get('email'),
      showEditModal: false,
      hasError: false,
    };
    this.handleshowEditModal = this.handleshowEditModal.bind(this);
  }

  handleshowEditModal = (e) => {
    this.setState({ showEditModal: !this.state.showEditModal });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    axios.put("api/company/" + this.props.companyDetail.id, this.state).then((res) => {
      this.props.parentCallback(true);
      this.setState({ showEditModal: false });
    }).catch((err) => {
      this.setState({ disableAdd: false, hasError: true });
      console.log(err);
    });
  };

  render() {
    return (
      <>
        <button
          className="btn btn-outline-success btn-sm"
          title="Edit Company Details"
          onClick={this.handleshowEditModal}
        >
          <Edit />
        </button>
        <Modal
          size="md"
          backdrop="static"
          show={this.state.showEditModal}
          onHide={() =>
            this.setState({ showEditModal: !this.state.showEditModal })
          }
        >
          <Modal.Header closeButton>
            <h4>Edit Company Details</h4>
          </Modal.Header>
          <Modal.Body>
          <div className="row">
              <div className="col-md-12 col-sm-12">
                <div
                  className={
                    this.state.hasError
                      ? "alert alert-danger"
                      : "alert alert-danger d-none"
                  }
                  role="alert"
                >
                  Company code already existing.
                </div>
              </div>
            </div>
            <form onSubmit={this.handleSubmit} id="contact-form">
              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  <label>Code</label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    onChange={(e) => this.setState({ code: e.target.value })}
                    defaultValue={this.props.companyDetail.code}
                  />
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  <label>Name</label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    onChange={(e) => this.setState({ name: e.target.value })}
                    defaultValue={this.props.companyDetail.name}
                  />
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  <label>Address Line 1</label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    onChange={(e) => this.setState({ address_1: e.target.value })}
                    defaultValue={this.props.companyDetail.address_1}
                  />
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  <label>Address Line 2</label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    onChange={(e) => this.setState({ address_2: e.target.value })}
                    defaultValue={this.props.companyDetail.address_2}
                  />
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  <label>Contact #</label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    onChange={(e) => this.setState({ contact_no: e.target.value })}
                    defaultValue={this.props.companyDetail.contact_no}
                  />
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  <label>Basic Schema</label><br/>
                  <label><strong>{this.props.companyDetail.bschema_name}</strong></label>
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  <label>Overtime Schema</label>
                  <select
                    class="form-control custom-select"
                    required
                    onChange={(e) =>
                      this.setState({ default_schema: e.target.value })
                    }
                  >
                    <option value="" >Select Overtime Schema</option>
                    {this.props.schemaDD.map((schema) => (
                      <option
                        key={schema.id}
                        value={schema.id}
                        selected={
                          this.props.companyDetail.default_schema == schema.id ? "selected" : ""
                        }
                      >
                        {schema.description}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  <label>Night Differential Time In</label>
                  <input
                    type="time"
                    className="form-control"
                    required
                    onChange={(e) =>
                      this.setState({ night_diff_in: e.target.value })
                    }
                    defaultValue={this.props.companyDetail.night_diff_in}
                  />
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  <label>Night Differential Time Out</label>
                  <input
                    type="time"
                    className="form-control"
                    required
                    onChange={(e) =>
                      this.setState({ night_diff_out: e.target.value })
                    }
                    defaultValue={this.props.companyDetail.night_diff_out}
                  />
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  <label>Minumum Overtime(Hours)</label>
                  <input
                    type="number"
                    className="form-control"
                    required
                    onChange={(e) => this.setState({ min_overtime: e.target.value })}
                    defaultValue={this.props.companyDetail.min_overtime}
                  />
                </div>
              </div>
              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  <label>Payroll Cycle</label>
                  <select
                    className="form-control custom-select"
                    required
                    onChange={(e) => {
                      this.setState({ pay_period: e.target.value });
                    }}
                    defaultValue={this.props.companyDetail.pay_period}
                  >
                    <option value="">Select Payroll Cycle</option>
                    {/* <option value="0">Weekly</option> */}
                    <option value="1">15th & 30th (Semi-Monthly)</option>
                    {/* <option value="2">15th</option> */}
                    <option value="3">30th (Monthly)</option>
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  <label>13th month Special Pay W/O Withholding Tax</label>
                  <select
                    className="form-control custom-select"
                    required
                    onChange={(e) => {
                      this.setState({ t_month_tax: e.target.value });
                    }}
                    defaultValue={this.props.companyDetail.t_month_tax}
                  >
                    <option value="">Select Payroll Cycle</option>
                    <option value="1">YES</option>
                    <option value="0">NO</option>
                  </select>
                </div>
              </div>

              <div className="float-right">
                <button type="submit" className="btn btn-primary btn-sm mr-2">
                  Save
                </button>
                <button
                  onClick={this.handleshowEditModal}
                  className="btn btn-danger btn-sm"
                  type="button"
                >
                  Cancel
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
