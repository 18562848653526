import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { Add } from "@material-ui/icons";
import axios from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();
const permission = [
  { value: 0, label: "No Access" },
  { value: 1, label: "View Only" },
  // { value: 2, label: "Add" },
  { value: 3, label: "Add / Edit" },
  { value: 4, label: "Full Access" },
];

export default class AddRoles extends Component {
  constructor(props) {
    super();
    this.state = {
      updated_by: cookies.get("email"),
      created_by: cookies.get("email"),
      showAddModal: false,
      disableSubmit: false,
    };
    this.handleshowAddModal = this.handleshowAddModal.bind(this);
  }

  handleshowAddModal = (e) => {
    this.setState({ showAddModal: !this.state.showAddModal });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ disableSubmit: true });
    axios.post("api/roles", this.state).then((res) => {
      this.props.parentCallback(true);
      this.setState({ showAddModal: false, disableSubmit: false });
    });
  };

  render() {
    return (
      <>
        <button
          className="btn btn-outline-primary btn-sm float-right"
          title="Add New Role"
          onClick={this.handleshowAddModal}
        >
          <Add /> New Role
        </button>
        <Modal
          size="lg"
          backdrop="static"
          show={this.state.showAddModal}
          onHide={() =>
            this.setState({ showAddModal: !this.state.showAddModal })
          }
        >
          <Modal.Header closeButton>
            <h4>Add New Role</h4>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={this.handleSubmit} id="contact-form">
              <div className="row">
                <div className="form-group col-md-6 col-sm-12">
                  <label>Name</label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    onChange={(e) => this.setState({ name: e.target.value })}
                  />
                </div>
                <div className="form-group col-md-6 col-sm-12"></div>
                <div className="form-group col-md-6 col-sm-12">
                  <label>Timesheet</label>
                  <select
                    class="form-control custom-select"
                    required
                    onChange={(e) =>
                      this.setState({ timesheet: e.target.value })
                    }
                  >
                    <option value="">Select Permission</option>
                    {permission.map((perm) => (
                      <option key={perm.value} value={perm.value}>
                        {perm.label}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="form-group col-md-6 col-sm-12">
                  <label>Transaction</label>
                  <select
                    class="form-control custom-select"
                    required
                    onChange={(e) =>
                      this.setState({ transaction: e.target.value })
                    }
                  >
                    <option value="">Select Permission</option>
                    {permission.map((perm) => (
                      <option key={perm.value} value={perm.value}>
                        {perm.label}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="form-group col-md-6 col-sm-12">
                  <label>Payroll Processing</label>
                  <select
                    class="form-control custom-select"
                    required
                    onChange={(e) => this.setState({ payroll: e.target.value })}
                  >
                    <option value="">Select Permission</option>
                    {permission.map((perm) =>
                      perm.value !== 2 && perm.value !== 3 ? (
                        <option key={perm.value} value={perm.value}>
                          {perm.label}
                        </option>
                      ) : (
                        ""
                      )
                    )}
                  </select>
                </div>

                <div className="form-group col-md-6 col-sm-12">
                  <label>YTD Adjustments</label>
                  <select
                    class="form-control custom-select"
                    required
                    onChange={(e) => this.setState({ ytd: e.target.value })}
                  >
                    <option value="">Select Permission</option>
                    {permission.map((perm) =>
                      perm.value !== 2 || perm.value !== 3 ? (
                        <option key={perm.value} value={perm.value}>
                          {perm.label}
                        </option>
                      ) : (
                        ""
                      )
                    )}
                  </select>
                </div>

                <div className="form-group col-md-6 col-sm-12">
                  <label>Master Data</label>
                  <select
                    class="form-control custom-select"
                    required
                    onChange={(e) =>
                      this.setState({ master_data: e.target.value })
                    }
                  >
                    <option value="">Select Permission</option>
                    {permission.map((perm) =>
                      perm.value !== 2 || perm.value !== 3 ? (
                        <option key={perm.value} value={perm.value}>
                          {perm.label}
                        </option>
                      ) : (
                        ""
                      )
                    )}
                  </select>
                </div>

                <div className="form-group col-md-6 col-sm-12">
                  <label>User Management</label>
                  <select
                    class="form-control custom-select"
                    required
                    onChange={(e) =>
                      this.setState({ user_management: e.target.value })
                    }
                  >
                    <option value="">Select Permission</option>
                    {permission.map((perm) =>
                      perm.value !== 2 || perm.value !== 3 ? (
                        <option key={perm.value} value={perm.value}>
                          {perm.label}
                        </option>
                      ) : (
                        ""
                      )
                    )}
                  </select>
                </div>

                <div className="form-group col-md-6 col-sm-12">
                  <label>Configuration</label>
                  <select
                    class="form-control custom-select"
                    required
                    onChange={(e) => this.setState({ config: e.target.value })}
                  >
                    <option value="">Select Permission</option>
                    {permission.map((perm) =>
                      perm.value !== 2 || perm.value !== 3 ? (
                        <option key={perm.value} value={perm.value}>
                          {perm.label}
                        </option>
                      ) : (
                        ""
                      )
                    )}
                  </select>
                </div>

                <div className="form-group col-md-6 col-sm-12">
                  <label>Report</label>
                  <select
                    class="form-control custom-select"
                    required
                    onChange={(e) => this.setState({ report: e.target.value })}
                  >
                    <option value="">Select Permission</option>
                    {permission.map((perm) =>
                      perm.value === 0 || perm.value === 4 ? (
                        <option key={perm.value} value={perm.value}>
                          {perm.label}
                        </option>
                      ) : (
                        ""
                      )
                    )}
                  </select>
                </div>
              </div>

              <div className="float-right">
                <button
                  type="submit"
                  className="btn btn-primary btn-sm mr-2"
                  disabled={this.state.disableSubmit}
                >
                  Save
                </button>
                <button
                  onClick={this.handleshowAddModal}
                  className="btn btn-danger btn-sm"
                  type="button"
                >
                  Cancel
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
