import React, { Component } from "react";
import { timeFormatter } from "../../components/DtFormatter";
import { Modal } from "react-bootstrap";
import { PhotoCamera } from "@material-ui/icons";
import axios from "axios";
import { FileUploader } from "react-drag-drop-files";
import Cookies from "universal-cookie";
const cookies = new Cookies();
const allowedfiles = [
  'png','jpg','jpeg'
]

export default class EditLogo extends Component {
  constructor(props) {
    super();
    this.state = {
      updated_by: cookies.get("email"),
      showEditModal: false,
      hasError: false,
      logo_file_name: "",
      logo_file: null,
    };
    this.handleshowEditModal = this.handleshowEditModal.bind(this);
  }

  handleshowEditModal = (e) => {
    this.setState({ showEditModal: !this.state.showEditModal });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    console.log(this.state);
    let formData = new FormData();
    formData.append("uploadFile", this.state.logo_file);
    formData.append("logo_file_name", this.state.logo_file_name);
    formData.append("updated_by", cookies.get("email"));
    formData.append("created_by", cookies.get("email"));

    axios
      .post("api/upload_logo/" + this.props.companyId, formData)
      .then((res) => {
        this.setState({
          disableAdd: false,
          showEditModal: false,
          hasError: false,
        });
        this.props.parentCallback(true);
        // window.location.reload();
      })
      .catch((err) => {
        this.setState({ disableAdd: false, hasError: true });
      });
  };

  handleChange = (file) => {
    this.setState({ logo_file: file, logo_file_name: file.name });
  };

  render() {
    return (
      <>
        <button
          className="btn btn-outline-success btn-sm ml-2"
          title="Edit Company Logo"
          onClick={this.handleshowEditModal}
        >
          <PhotoCamera />
        </button>
        <Modal
          size="md"
          backdrop="static"
          show={this.state.showEditModal}
          onHide={() =>
            this.setState({ showEditModal: !this.state.showEditModal })
          }
        >
          <Modal.Header closeButton>
            <h4>Edit Company Logo</h4>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <div
                  className={
                    this.state.hasError
                      ? "alert alert-danger"
                      : "alert alert-danger d-none"
                  }
                  role="alert"
                >
                  Upload Error
                </div>
              </div>
            </div>
            <form onSubmit={this.handleSubmit} id="contact-form" >
              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  <label>Logo file</label>
                  <FileUploader
                    multiple={false}
                    required
                    handleChange={this.handleChange}
                    name="file"
                    types={allowedfiles}
                  />
                  <p>
                    {this.state.logo_file
                      ? `File name: ${this.state.logo_file_name}`
                      : "no files uploaded yet"}
                  </p>
                </div>
              </div>

              <div className="float-right">
                <button
                  type="submit"
                  className="btn btn-primary btn-sm mr-2"
                  disabled={this.state.disableAdd}
                >
                  Save
                </button>
                <button
                  onClick={this.handleshowEditModal}
                  className="btn btn-danger btn-sm"
                  type="button"
                >
                  Cancel
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
