import React, { Component } from "react";
import { FileCopy, Person } from "@material-ui/icons";
import axios from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();

export default class ExportEmployee extends Component {
  constructor(props) {
    super();
    this.state = {
      company_id: cookies.get("transCompany"),
      showDeleteModal: false,
      disableSubmit: false,
      status: 2,
    };
    this.handleshowDeleteModal = this.handleshowDeleteModal.bind(this);
  }

  handleshowDeleteModal = (e) => {
    this.setState({ showDeleteModal: !this.state.showDeleteModal });
  };

  handleSubmit = (e) => {
    axios({
      url: process.env.REACT_APP_API_LINK+"api/employee_temp/"+this.state.company_id, //your url
      method: "GET",
      responseType: "blob", // important
    }).then((res) => {
      const url = window.URL.createObjectURL(new Blob([res.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Employee_Temp.xlsx"); //or any other extension
      document.body.appendChild(link);
      link.click();
    });
    
  };

  render() {
    return (
      <>
        <button
          className="dropdown-item"
          title="Export Employee Template"
          onClick={this.handleSubmit}
        >
          <Person />&nbsp;Download Employee Template
        </button>
      </>
    );
  }
}
