import React from "react";
import { ProtectedRoute } from "./components/Protected.Route";
import { HashRouter as Router, Route, Switch } from "react-router-dom";
import Public from "./pages/Public";
import Employee from "./pages/employees/Employee";
import ViewEmployee from "./pages/employees/ViewEmployee";
import LandingPage from "./pages/LandingPage";
import Cutoff from "./pages/timekeep/Cutoff";
import CutoffPerEmp from "./pages/timekeep/CutoffPerEmp";
import Attendance from "./pages/timekeep/Attendance";
import Company from "./pages/companies/Company";
import ViewCompamy from "./pages/companies/ViewCompany";
import CalcSchema from "./pages/calcSchema/CalcSchema";
import SchemaDetail from "./pages/calcSchema/SchemaDetail";
import SessExpired from "./pages/SessExpired";
import UploadAttendanceList from "./pages/timekeep/UploadAttendanceList";
import EmpReport from "./pages/reports/EmpReport";
import TotalReport from "./pages/reports/TotalReport";
import TotalPerEmp from "./pages/reports/TotalPerEmp";
import ForgotPassword from "./pages/ForgotPassword";
import Administration from "./pages/administration/Administration";
import YearTotal from "./pages/reports/YearTotal";
import YearPerEmp from "./pages/reports/YearPerEmp";
import YearEmpReport from "./pages/reports/YearEmpReport";
import DataMngt from "./pages/administration/DataMngt";
import Testpage from "./pages/Testpage";
import BasicSchema from "./pages/basicSchema/BasicSchema";
import ViewSchema from "./pages/basicSchema/ViewSchema";
import Transaction from "./pages/transaction/Transaction";
import ViewTransaction from "./pages/transaction/ViewTransaction";
import TransactionDed from "./pages/transaction/TransactionDed";
import YtdPerEmp from "./pages/ytd/YtdPerEmp";
import YtdEmpDetail from "./pages/ytd/YtdEmpDetail";
import TransactionUpload from "./pages/transaction/TransactionUpload";
import TransactionUploadDetail from "./pages/transaction/TransactionUploadDetail";
import SpecialUpload from "./pages/specialPayroll/SpecialUpload";
import SpecialUploadDetail from "./pages/specialPayroll/SpecialUploadDetail";
import SpecialPay from "./pages/specialPayroll/SpecialPay";
import RegularPayroll from "./pages/payroll/RegularPayroll";
import RegularPayrollPerEmp from "./pages/payroll/RegularPayrollPerEmp";
import RegularPayslip from "./pages/payroll/RegularPayslip";
import BreakdownEarning from "./pages/payroll/BreakdownEarning";
import BreakdownDeduction from "./pages/payroll/BreakdownDeduction";
import SpecialPayroll from "./pages/specialPayroll/SpecialPayroll";
import SpecialPayrollPerEmp from "./pages/specialPayroll/SpecialPayrollPerEmp";
import SpecialPayrollDetail from "./pages/specialPayroll/SpecialPayrollDetail";
import YtdAdjustment from "./pages/ytd_adjustments/YtdAdjustment";
import YtdAdjustmentUpload from "./pages/ytd_adjustments/YtdAdjustmentUpload";
import YtdaUploadDetail from "./pages/ytd_adjustments/YtdaUploadDetail";
import YtdAdjustmentRun from "./pages/ytd_adjustments/YtdAdjusmentRun";
import YtdAdjPerEmp from "./pages/ytd_adjustments/YtdAdjPerEmp";
import Payslip from "./pages/payslips/Payslip";
import PayslipPerEmp from "./pages/payslips/PayslipPerEmp";
import PayslipDetail from "./pages/payslips/PayslipDetail";
import SpPayslip from "./pages/payslips/SpPayslip";
import SpPayslipPerEmp from "./pages/payslips/SpPayslipEmp";
import SpPayslipDetail from "./pages/payslips/SpPayslipDetail";
import SssContribution from "./pages/reports/SssContribution";
import SssContributionEmp from "./pages/reports/SssContributionEmp";
import PagibigContribution from "./pages/reports/PagibigContribution";
import PagibigContributionEmp from "./pages/reports/PagibigContributionEmp";
import PhicContribution from "./pages/reports/PhicContribution";
import PhicContributionEmp from "./pages/reports/PhicContributionEmp";
import SssPrContribution from "./pages/reports/SssPrContribution";
import SssPrContributionEmp from "./pages/reports/SssPrContributionEmp";
import PagibigPrContribution from "./pages/reports/PagibigPrContribution";
import PagibigPrContributionEmp from "./pages/reports/PagibigPrContributionEmp";
import PhicPrContribution from "./pages/reports/PhicPrContribution";
import PhicPrContributionEmp from "./pages/reports/PhicPrContributionEmp";
import YtdPerPeriod from "./pages/ytd/YtdPerPeriod";
import YtdPrDetail from "./pages/ytd/YtdPrDetail";
import Bank from "./pages/banks/Bank";
import PayrollRegister from "./pages/reports/PayrollRegister";
import BankRegister from "./pages/reports/BankRegister";
import AppLogout from "./components/AppLogout.jsx";
import EdRegister from "./pages/reports/EdRegister.jsx";
import DeptRegister from "./pages/reports/DeptRegister";


export default function App() {
  return (
    <div className="App">
      <AppLogout>
      <Router>
      <Switch>
        <Route exact path="/" component={LandingPage} />
        <Route exact path="/forgotPassword" component={ForgotPassword} />
        <Route exact path="/sess_expired" component={SessExpired}/>
        <Route exact path="/testpage" component={Testpage}/>
        <ProtectedRoute exact path="/user_management" component={Administration} />
        <ProtectedRoute exact path="/data_management" component={DataMngt} />
        <ProtectedRoute exact path="/employee" component={Employee} />
        <ProtectedRoute exact path="/employee/view/:id" component={ViewEmployee} />
        <ProtectedRoute exact path="/attendance_upload" component={UploadAttendanceList} />
        <ProtectedRoute exact path="/cutoff" component={Cutoff} />
        <ProtectedRoute exact path="/cutoff/peremp/:id" component={CutoffPerEmp} />
        <ProtectedRoute exact path="/cutoff/peremp/view/:cutoff_id/:employee_id" component={Attendance} />
        <ProtectedRoute exact path="/reg_payroll" component={RegularPayroll} />
        <ProtectedRoute exact path="/reg_payroll/:id/:from" component={RegularPayrollPerEmp} />
        <ProtectedRoute exact path="/reg_payroll/:cutoff_id/:employee_id/:paysched_id/:from/:next" component={RegularPayslip} />
        <ProtectedRoute exact path="/reg_break_earn/:cutoff_id/:paysched_id" component={BreakdownEarning} />
        <ProtectedRoute exact path="/reg_break_dedc/:cutoff_id/:paysched_id" component={BreakdownDeduction} />

        <ProtectedRoute exact path="/spl_payroll" component={SpecialPayroll} />
        <ProtectedRoute exact path="/spl_payroll/:id/:from" component={SpecialPayrollPerEmp} />
        <ProtectedRoute exact path="/spl_payroll/:id/:from/:emp_id/:next" component={SpecialPayrollDetail} />
        
        <ProtectedRoute exact path="/ytd_adjustment" component={YtdAdjustment} />
        <ProtectedRoute exact path="/ytd_adjustment_upload" component={YtdAdjustmentUpload} />
        <ProtectedRoute exact path="/ytd_adjustment_upload/:id" component={YtdaUploadDetail} />
        <ProtectedRoute exact path="/ytd_run" component={YtdAdjustmentRun} />
        <ProtectedRoute exact path="/ytd_run/:id/:from" component={YtdAdjPerEmp} />

        <ProtectedRoute exact path="/company" component={Company} />
        <ProtectedRoute exact path="/company/view/:id" component={ViewCompamy} />
        <ProtectedRoute exact path="/schema" component={CalcSchema} />
        <ProtectedRoute exact path="/schema/view/:id" component={SchemaDetail} />
        <ProtectedRoute exact path="/basic_schema" component={BasicSchema} />
        <ProtectedRoute exact path="/basic_schema/view/:id" component={ViewSchema} />
        <ProtectedRoute exact path="/app" component={Public} />
        <ProtectedRoute exact path="/report/total/:cutoff_id/:employee_id" component={EmpReport} />
        <ProtectedRoute exact path="/report/total/:id" component={TotalPerEmp} />
        <ProtectedRoute exact path="/report/total" component={TotalReport} />
        <ProtectedRoute exact path="/report/year_total" component={YearTotal} />
        <ProtectedRoute exact path="/report/year_total/:year" component={YearPerEmp} />
        <ProtectedRoute exact path="/report/year_total/:year/:employee_id" component={YearEmpReport} />
        <ProtectedRoute exact path="/transaction_upload" component={TransactionUpload} />
        <ProtectedRoute exact path="/transaction_upload/:id" component={TransactionUploadDetail} />
        <ProtectedRoute exact path="/special_upload" component={SpecialUpload} />
        <ProtectedRoute exact path="/special_upload/:id" component={SpecialUploadDetail} />
        <ProtectedRoute exact path="/special_pay" component={SpecialPay} />
        <ProtectedRoute exact path="/transaction" component={Transaction} />
        <ProtectedRoute exact path="/transaction_ded" component={TransactionDed} />
        <ProtectedRoute exact path="/transaction/view/:type/:id" component={ViewTransaction} />
        
        <ProtectedRoute exact path="/payslip" component={Payslip}/>
        <ProtectedRoute exact path="/payslip/:id" component={PayslipPerEmp} />
        <ProtectedRoute exact path="/payslip/:cutoff_id/:employee_id" component={PayslipDetail} />
        
        <ProtectedRoute exact path="/sp_payslip" component={SpPayslip}/>
        <ProtectedRoute exact path="/sp_payslip/:id" component={SpPayslipPerEmp} />
        <ProtectedRoute exact path="/sp_payslip/:cutoff_id/:employee_id" component={SpPayslipDetail} />
        
        <ProtectedRoute exact path="/sss_contribution" component={SssContribution} />
        <ProtectedRoute exact path="/sss_contribution/:employee_id" component={SssContributionEmp} />
        <ProtectedRoute exact path="/sss_pr_contribution" component={SssPrContribution} />
        <ProtectedRoute exact path="/sss_pr_contribution/:payroll_id" component={SssPrContributionEmp} />
        
        <ProtectedRoute exact path="/pagibig_contribution" component={PagibigContribution} />
        <ProtectedRoute exact path="/pagibig_contribution/:employee_id" component={PagibigContributionEmp} />
        <ProtectedRoute exact path="/pagibig_pr_contribution" component={PagibigPrContribution} />
        <ProtectedRoute exact path="/pagibig_pr_contribution/:payroll_id" component={PagibigPrContributionEmp} />

        <ProtectedRoute exact path="/phic_contribution" component={PhicContribution} />
        <ProtectedRoute exact path="/phic_contribution/:employee_id" component={PhicContributionEmp} />
        <ProtectedRoute exact path="/phic_pr_contribution" component={PhicPrContribution} />
        <ProtectedRoute exact path="/phic_pr_contribution/:payroll_id" component={PhicPrContributionEmp} />

        <ProtectedRoute exact path="/ytd" component={YtdPerEmp} />
        <ProtectedRoute exact path="/ytd/:year" component={YtdPerEmp} />
        <ProtectedRoute exact path="/ytd/:year/:employee_id" component={YtdEmpDetail} />
        <ProtectedRoute exact path="/ytd_pr" component={YtdPerPeriod} />
        <ProtectedRoute exact path="/ytd_pr/:year" component={YtdPerPeriod} />
        <ProtectedRoute exact path="/ytd_pr/:year/:paysched_id" component={YtdPrDetail} />

        <ProtectedRoute exact path="/bank" component={Bank} />
        <ProtectedRoute exact path="/pr_register" component={PayrollRegister} />
        <ProtectedRoute exact path="/bank_register" component={BankRegister} />
        <ProtectedRoute exact path="/ed_register" component={EdRegister} />
        <ProtectedRoute exact path="/dept_register" component={DeptRegister} />
        <Route path="*" component={() => "404 NOT FOUND"} />

      </Switch>
      </Router>
      </AppLogout>
    </div>
  );
}

