import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import axios from "axios";
import { Link } from "react-router-dom";
import { FindInPage } from "@material-ui/icons";
import { dateFormatter } from "../../components/DtFormatter";
import ComputeOt from "./ComputeOt";
import RevokeCompute from "./RevokeCompute";
import Cookies from "universal-cookie";
const access = ["No Access", "View Only", "Add", "Add / Edit", "Full Access"];
const cookies = new Cookies();


export default function UploadAttendanceList() {
  const permissions = cookies.get("permissions")["timesheet"];
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [filteredData, setFilteredData] = useState([]);
  const [fileLoaded, setFileLoaded] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      let apiLink = "api/cutoff_list/" + cookies.get("transCompany");
      const res = await axios.get(apiLink);
      setData(res.data);
      setFilteredData(res.data);
    };

    if (!fileLoaded) {
      fetchData();
      setFileLoaded(true);
    }
  });

  const pages = [];
  for (let i = 1; i <= Math.ceil(data.length / recordsPerPage); i++) {
    pages.push(i);
  }

  const handleRecordPerPage = (e) => {
    console.log(e.target.value);
    setRecordsPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const handleAddCallback = (editData) => {
    if (editData) {
      setFileLoaded(false);
    }
  };

  const handleSearch = (e) => {
    const filterer = data.filter(function (data) {
      for (var key in data) {
        // if (
        //   !(
        //     data["first_name"]
        //       .toLowerCase()
        //       .includes(e.target.value.toLowerCase()) ||
        //     data["last_name"]
        //       .toLowerCase()
        //       .includes(e.target.value.toLowerCase()) ||
        //     data["emp_no"]
        //       .toLowerCase()
        //       .includes(e.target.value.toLowerCase()) ||
        //     data["company_name"]
        //       .toLowerCase()
        //       .includes(e.target.value.toLowerCase()) ||
        //     data["schema_name"]
        //       .toLowerCase()
        //       .includes(e.target.value.toLowerCase())
        //   )
        // ) {
        //   return false;
        // }
        return true;
      }
    });
    setFilteredData(filterer);
  };

  const startIndex = currentPage * recordsPerPage - recordsPerPage;
  const endIndex = startIndex + recordsPerPage;
  const currentData = filteredData.slice(startIndex, endIndex);

  return (
    <div className="hk-wrapper hk-vertical-nav">
      <div class="pg-hk-wrapper">
        <Header />
        <div className="mt-20 hk-pg-wrapper">
          {permissions > 0 ? (
            <div className="container-fluid">
              <div className="row mb-10">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <h3>Attendance Cutoff</h3>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="btn-group float-right">
                  </div>
                </div>
              </div>
              <div class="row">
              <div class="col-sm-12 mt-10">
                <div class="table-wrap">
                  <div class="row mb-10">
                    <div class="col-md-6">
                      <label>
                        <select
                          class="custom-select custom-select-sm form-control form-control-sm"
                          onChange={handleRecordPerPage}
                        >
                          <option value="10">10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                      </label>{" "}
                      items
                    </div>
                    <div class="col-md-6">
                      <input
                        type="search"
                        class="form-control form-control-sm col-md-6 col-sm-6 float-right"
                        placeholder="Search"
                        onChange={handleSearch}
                      />
                    </div>
                  </div>
                  <div class="table-responsive mb-10">
                    <table class="table table-sm table-bordered table-striped mb-0">
                      <thead className="thead-secondary">
                        <tr className="text-center">
                          <th>Company</th>
                          <th>Cutoff Period</th>
                          <th>Status</th>
                          <th>Date Created</th>
                          <th>Created by</th>
                          <th style={{ width: "100px" }}>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {filteredData.length > 0 ? (
                          currentData.map((cutoff) => (
                            <tr key={cutoff.id}>
                              <td>{cutoff.company_name}</td>
                              <td>
                                {dateFormatter(cutoff.co_from_date) +
                                  " - " +
                                  dateFormatter(cutoff.co_to_date)}
                              </td>
                              <td>{cutoff.status_name}</td>
                              <td>
                                {dateFormatter(
                                  cutoff.created_at,
                                  "MMM dd, yyyy hh:mm bb"
                                )}
                              </td>
                              <td>{cutoff.created_by}</td>
                              <td>
                                <div className="btn-group">
                                  {permissions > 2 ? (
                                    cutoff.status == 1 ? (
                                      <ComputeOt
                                        parentCallback={handleAddCallback}
                                        id={cutoff.id}
                                        name={cutoff.company_name}
                                        period={
                                          dateFormatter(cutoff.co_from_date) +
                                          " - " +
                                          dateFormatter(cutoff.co_to_date)
                                        }
                                      />
                                    ) : (
                                      ""
                                    )
                                  ) : (
                                    ""
                                  )}
                                  {permissions > 2 ? (
                                    cutoff.status == 2 ? (
                                      <RevokeCompute
                                        parentCallback={handleAddCallback}
                                        id={cutoff.id}
                                        name={cutoff.company_name}
                                        period={
                                          dateFormatter(cutoff.co_from_date) +
                                          " - " +
                                          dateFormatter(cutoff.co_to_date)
                                        }
                                      />
                                    ) : (
                                      ""
                                    )
                                  ) : (
                                    ""
                                  )}

                                  <Link
                                    to={{
                                      pathname: `/cutoff/peremp/${cutoff.id}`,
                                      query: `/cutoff`,
                                    }}
                                    className="btn btn-sm btn-outline-primary"
                                    title={"View Cutoff Details"}
                                  >
                                    <FindInPage />
                                  </Link>
                                </div>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colspan="7" className="text-center">
                              No Data to show
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    {filteredData.length > 0
                      ? "Showing " +
                        (startIndex + 1) +
                        " to " +
                        (endIndex > filteredData.length
                          ? filteredData.length
                          : endIndex) +
                        " of " +
                        filteredData.length
                      : ""}
                  </div>
                  <div class="col-sm-6 col-md-6">
                    <ul class="pagination float-right">
                      {pages.map((number) => (
                        <li
                          key={number}
                          id={number}
                          className={
                            currentPage == number
                              ? "paginate_button page-item active"
                              : "paginate_button page-item"
                          }
                          onClick={() => setCurrentPage(number)}
                        >
                          <a class="page-link">{number}</a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            </div>
          ) : (
            <>
              <h1 className="m-0 text-center">
                Your credentials is not sufficient to access this:
                <br />
              </h1>
              <br />
              <h2 className="text-center">
                Module : Timesheets
                <br />
                Access Type : {access[permissions]}
                <br />
                Please contact your Payroll Administrator.
                <br />
                <a className="btn btn-primary btn-sm" href="/#/app">
                  Back to Dashboard
                </a>
              </h2>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
