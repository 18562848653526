import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { Edit } from "@material-ui/icons";
import axios from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();
const permission = [
  { value: 0, label: "No Access" },
  { value: 1, label: "View Only" },
  // { value: 2, label: "Add" },
  { value: 3, label: "Add / Edit" },
  { value: 4, label: "Full Access" },
];

export default class EditRoles extends Component {
  constructor(props) {
    super();
    this.state = {
      updated_by: cookies.get("email"),
      showEditModal: false,
      disableSubmit: false,
      updated: false,
      name: props.role.name,
      timesheet: props.role.timesheet,
      transaction: props.role.transaction,
      payroll: props.role.payroll,
      ytd: props.role.ytd,
      master_data: props.role.master_data,
      user_management: props.role.user_management,
      config: props.role.config,
      report: props.role.report,
      status: props.role.status,
      perms: [],
    };
    this.handleshowEditModal = this.handleshowEditModal.bind(this);
  }

  handleshowEditModal = (e) => {
    this.setState({ showEditModal: !this.state.showEditModal });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ disableSubmit: true });
    axios
      .put("api/roles/" + this.props.role.user_role_id, this.state)
      .then((res) => {
        this.props.parentCallback(true);
        this.setState({
          showEditModal: false,
          disableSubmit: false,
          updated: true,
        });
      });
  };

  render() {
    return (
      <>
        {this.props.buttonType == 1 ? (
          <button
            className="btn btn-outline-success btn-sm float-right"
            title="Update Role"
            onClick={this.handleshowEditModal}
          >
            <Edit />
          </button>
        ) : (
          <span title="Update Role" onClick={this.handleshowEditModal}>
            {this.props.role.name}
          </span>
        )}

        <Modal
          size="lg"
          backdrop="static"
          show={this.state.showEditModal}
          onHide={() =>
            this.setState({ showEditModal: !this.state.showEditModal })
          }
        >
          <Modal.Header closeButton>
            <h4>Update Role: {this.props.role.name}</h4>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={this.handleSubmit} id="contact-form">
              <div className="row">
                <div
                  className={
                    this.props.role.user_role_id == 1
                      ? "form-group col-md-12 col-sm-12"
                      : "form-group col-md-6 col-sm-12"
                  }
                >
                  <label>Name</label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    onChange={(e) => this.setState({ name: e.target.value })}
                    defaultValue={this.props.role.name}
                  />
                </div>
                {this.props.role.user_role_id == 1 ? (
                  ""
                ) : (
                  <>
                    <div className="form-group col-md-6 col-sm-12">
                      <label>Status</label>
                      <select
                        class="form-control custom-select"
                        required
                        onChange={(e) =>
                          this.setState({ status: e.target.value })
                        }
                        defaultValue={this.props.role.status}
                      >
                        <option value="">Select Status</option>
                        <option value="1">ACTIVE</option>
                        <option value="0">INACTIVE</option>
                      </select>
                    </div>
                    <div className="form-group col-md-6 col-sm-12">
                      <label>Timesheet</label>
                      <select
                        class="form-control custom-select"
                        required
                        onChange={(e) =>
                          this.setState({ timesheet: e.target.value })
                        }
                        defaultValue={this.props.role.timesheet}
                      >
                        <option value="">Select Permission</option>
                        {permission.map((perm) => (
                          <option key={perm.value} value={perm.value}>
                            {perm.label}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="form-group col-md-6 col-sm-12">
                      <label>Transaction</label>
                      <select
                        class="form-control custom-select"
                        required
                        onChange={(e) =>
                          this.setState({ transaction: e.target.value })
                        }
                        defaultValue={this.props.role.transaction}
                      >
                        <option value="">Select Permission</option>
                        {permission.map((perm) => (
                          <option key={perm.value} value={perm.value}>
                            {perm.label}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="form-group col-md-6 col-sm-12">
                      <label>Payroll Processing</label>
                      <select
                        class="form-control custom-select"
                        required
                        onChange={(e) =>
                          this.setState({ payroll: e.target.value })
                        }
                        defaultValue={this.props.role.payroll}
                      >
                        <option value="">Select Permission</option>
                        {permission.map((perm) =>
                          perm.value !== 2 && perm.value !== 3 ? (
                            <option key={perm.value} value={perm.value}>
                              {perm.label}
                            </option>
                          ) : (
                            ""
                          )
                        )}
                      </select>
                    </div>

                    <div className="form-group col-md-6 col-sm-12">
                      <label>YTD Adjustments</label>
                      <select
                        class="form-control custom-select"
                        required
                        onChange={(e) => this.setState({ ytd: e.target.value })}
                        defaultValue={this.props.role.ytd}
                      >
                        <option value="">Select Permission</option>
                        {permission.map((perm) =>
                          perm.value !== 2 || perm.value !== 3 ? (
                            <option key={perm.value} value={perm.value}>
                              {perm.label}
                            </option>
                          ) : (
                            ""
                          )
                        )}
                      </select>
                    </div>

                    <div className="form-group col-md-6 col-sm-12">
                      <label>Master Data</label>
                      <select
                        class="form-control custom-select"
                        required
                        onChange={(e) =>
                          this.setState({ master_data: e.target.value })
                        }
                        defaultValue={this.props.role.master_data}
                      >
                        <option value="">Select Permission</option>
                        {permission.map((perm) =>
                          perm.value !== 2 || perm.value !== 3 ? (
                            <option key={perm.value} value={perm.value}>
                              {perm.label}
                            </option>
                          ) : (
                            ""
                          )
                        )}
                      </select>
                    </div>

                    <div className="form-group col-md-6 col-sm-12">
                      <label>User Management</label>
                      <select
                        class="form-control custom-select"
                        required
                        onChange={(e) =>
                          this.setState({ user_management: e.target.value })
                        }
                        defaultValue={this.props.role.user_management}
                      >
                        <option value="">Select Permission</option>
                        {permission.map((perm) =>
                          perm.value !== 2 || perm.value !== 3 ? (
                            <option key={perm.value} value={perm.value}>
                              {perm.label}
                            </option>
                          ) : (
                            ""
                          )
                        )}
                      </select>
                    </div>

                    <div className="form-group col-md-6 col-sm-12">
                      <label>Configuration</label>
                      <select
                        class="form-control custom-select"
                        required
                        onChange={(e) =>
                          this.setState({ config: e.target.value })
                        }
                        defaultValue={this.props.role.config}
                      >
                        <option value="">Select Permission</option>
                        {permission.map((perm) =>
                          perm.value !== 2 || perm.value !== 3 ? (
                            <option key={perm.value} value={perm.value}>
                              {perm.label}
                            </option>
                          ) : (
                            ""
                          )
                        )}
                      </select>
                    </div>

                    <div className="form-group col-md-6 col-sm-12">
                      <label>Report</label>
                      <select
                        class="form-control custom-select"
                        required
                        onChange={(e) =>
                          this.setState({ report: e.target.value })
                        }
                        defaultValue={this.props.role.report}
                      >
                        <option value="">Select Permission</option>
                        {permission.map((perm) =>
                          perm.value === 0 || perm.value === 4 ? (
                            <option key={perm.value} value={perm.value}>
                              {perm.label}
                            </option>
                          ) : (
                            ""
                          )
                        )}
                      </select>
                    </div>
                  </>
                )}
              </div>

              <div className="float-right">
                <button
                  type="submit"
                  className="btn btn-primary btn-sm mr-2"
                  disabled={this.state.disableSubmit}
                >
                  Save
                </button>
                <button
                  onClick={this.handleshowEditModal}
                  className="btn btn-danger btn-sm"
                  type="button"
                >
                  Cancel
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
