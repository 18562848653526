import React, { Component } from "react";
import Select from "react-select";
import { Modal } from "react-bootstrap";
import { Add } from "@material-ui/icons";
import axios from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();

export default class AddUser extends Component {
  constructor(props) {
    super();
    this.state = {
      updated_by: cookies.get("email"),
      created_by: cookies.get("email"),
      showAddModal: false,
      disableSubmit: false,
      hasError: false,
      errMessage: "",
    };
    this.handleshowAddModal = this.handleshowAddModal.bind(this);
  }

  handleshowAddModal = (e) => {
    this.setState({ showAddModal: !this.state.showAddModal });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ disableSubmit: true });
    axios
      .post("api/users", this.state)
      .then((res) => {
        this.props.parentCallback(true);
        this.setState({ showAddModal: false, disableSubmit: false });
      })
      .catch((err) => {
        this.setState({
          disableSubmit: false,
          hasError: true,
          errMessage: err.response.data.message,
        });
      });
  };

  render() {
    return (
      <>
        <button
          className="btn btn-outline-primary btn-sm float-right"
          title="Add New User"
          onClick={this.handleshowAddModal}
        >
          <Add /> New User
        </button>
        <Modal
          size="md"
          backdrop="static"
          show={this.state.showAddModal}
          onHide={() =>
            this.setState({ showAddModal: !this.state.showAddModal })
          }
        >
          <Modal.Header closeButton>
            <h4>Add New User</h4>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <div
                  className={
                    this.state.hasError
                      ? "alert alert-danger"
                      : "alert alert-danger d-none"
                  }
                  role="alert"
                >
                  {this.state.errMessage}
                </div>
              </div>
            </div>
            <form onSubmit={this.handleSubmit} id="contact-form">
              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  <label>Name</label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    onChange={(e) => this.setState({ name: e.target.value })}
                  />
                </div>
              </div>
              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  <label>Email</label>
                  <input
                    type="email"
                    className="form-control"
                    required
                    onChange={(e) => this.setState({ email: e.target.value })}
                  />
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  <label>Role</label>
                  <select
                    class="form-control custom-select"
                    required
                    onChange={(e) =>
                      this.setState({ user_role: e.target.value, company_id: null })
                    }
                  >
                    <option value="">Select Role</option>
                    {this.props.role.map((role) => (
                      <option key={role.id} value={role.id}>
                        {role.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  <label>Company</label>
                  <Select
                    options={this.props.companyOption}
                    onChange={(e) => {
                      this.setState({ company_id: e });
                    }}
                    value={this.state.company_id}
                    isDisabled={this.state.user_role == 0 ? (true):(false)}
                    closeMenuOnSelect={false}
                    required
                    isMulti
                  />
                  <input
                    className="form-control hidden hidden-require"
                    type="text"
                    value={this.state.company_id}
                    autoComplete="off"
                    required={this.state.user_role == 0 ? (true):(false)}
                  />
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  <label>Validity From</label>
                  <input
                    type="date"
                    className="form-control"
                    required
                    onChange={(e) =>
                      this.setState({ valid_from: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  <label>Validity To</label>
                  <input
                    type="date"
                    className="form-control"
                    required
                    min={this.state.valid_from}
                    onChange={(e) =>
                      this.setState({ valid_to: e.target.value })
                    }
                  />
                </div>
              </div>

              

              <div className="float-right">
                <button
                  type="submit"
                  className="btn btn-primary btn-sm mr-2"
                  disabled={this.state.disableSubmit}
                >
                  Save
                </button>
                <button
                  onClick={this.handleshowAddModal}
                  className="btn btn-danger btn-sm"
                  type="button"
                >
                  Cancel
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
