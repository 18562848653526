import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { Check, Receipt } from "@material-ui/icons";
import axios from "axios";
import { data } from "jquery";

export default class ExportMultiSpPayslip extends Component {
  constructor(props) {
    super();
    this.state = {
      showDeleteModal: false,
      disableSubmit: false,
      status: 2,
    };
    this.handleshowDeleteModal = this.handleshowDeleteModal.bind(this);
  }

  handleshowDeleteModal = (e) => {
    let checked = this.props.dataCheck;
    let checkCount = 0;
    checked.map((checkData) => {
      if (checkData["checked"]) {
        checkCount++;
      }
    });
    if (checkCount > 0) {
      this.setState({ showDeleteModal: !this.state.showDeleteModal });
    } else {
      alert("No Data Selected");
    }
  };

  handleSubmit = (e) => {
    this.setState({ disableSubmit: true });
    let checked = this.props.dataCheck;
    let checkCount = 0;
    checked.map((checkData) => {
      if (checkData["checked"]) {
        checkCount++;
      }
    });

    if (checkCount > 0) {
      checked.map((payslip) => {
        if (payslip.checked) {
          axios({
            url:
              process.env.REACT_APP_API_LINK +
              "api/sp_payslip_emp_export/" +
              payslip.sp_id +
              "/" +
              payslip.id, //your url
            method: "GET",
            responseType: "blob", // important
          }).then((res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute(
              "download",
              payslip.first_name +
                " " +
                payslip.last_name +
                " Special Payslip.pdf"
            ); //or any other extension
            document.body.appendChild(link);
            link.click();
          });

          this.setState({
            disableSubmit: false,
            showDeleteModal: !this.state.showDeleteModal,
          });
        }
      });
    } else {
      alert("No Data Selected");
    }
  };

  render() {
    return (
      <>
        <button
          className="btn btn-outline-success btn-sm float-right"
          title="Export to PDF"
          onClick={this.handleshowDeleteModal}
        >
          Export to PDF
        </button>
        <Modal
          size="md"
          show={this.state.showDeleteModal}
          onHide={() =>
            this.setState({ showDeleteModal: !this.state.showDeleteModal })
          }
        >
          <Modal.Header closeButton>
            <h4>
              Export Payslips to PDF :<br />
            </h4>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="form-group col-md-12 col-sm-12">
                Are you sure?
              </div>
            </div>
            <div className="float-right">
              <button
                type="button"
                className="btn btn-primary btn-sm mr-2"
                disabled={this.state.disableSubmit}
                onClick={this.handleSubmit}
              >
                Print Payslips
              </button>
              <button
                onClick={this.handleshowDeleteModal}
                className="btn btn-danger btn-sm"
                type="button"
              >
                Cancel
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
