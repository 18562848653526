import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { Edit, Close } from "@material-ui/icons";
import axios from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();

export default class CloseTransaction extends Component {
  constructor() {
    super();
    this.state = {
      updated_by: cookies.get("email"),
      created_by: cookies.get("email"),
      showEditModal: false,
      disableSubmit: false,
      closer: 1,
      status: 3
    };
    this.handleshowAddModal = this.handleshowAddModal.bind(this);
  }

  handleshowAddModal = (e) => {
    this.setState({ showEditModal: !this.state.showEditModal });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ disableSubmit: true });
    axios.put("api/transaction_close/"+this.props.transId, this.state).then((res) => {
      this.props.parentCallback(true);
      this.setState({ showEditModal: false, disableSubmit: false });
    });

  };

  render() {
    return (
      <>
        <button
          className="btn btn-outline-danger btn-sm float-right"
          title="Cancel Transaction"
          onClick={this.handleshowAddModal}
        >
          <Close />
        </button>
        <Modal
          size="md"
          show={this.state.showEditModal}
          onHide={() =>
            this.setState({ showEditModal: !this.state.showEditModal })
          }
        >
          <Modal.Header closeButton>
            <h4>Cancel Transaction</h4>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={this.handleSubmit} id="contact-form">
              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  <label>Are you sure to close this transactions</label></div>
              </div>

              <div className="float-right">
                <button
                  type="submit"
                  className="btn btn-primary btn-sm mr-2"
                  disabled={this.state.disableSubmit}
                >
                  Cancel Transaction
                </button>
                <button
                  onClick={this.handleshowAddModal}
                  className="btn btn-danger btn-sm"
                  type="button"
                >
                  Cancel
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
