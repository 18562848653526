import React, { useState, useEffect } from "react";
import { Menu } from "@material-ui/icons";
import { Link, NavLink } from "react-router-dom";
import Cookies from "universal-cookie";
import auth from "../../src/components/Auth";
import UpdateUserDetails from "../../src/components/UpdateUserDetail";
import ChangePassword from "../../src/components/ChangePassword";

export default function Public(props) {
  auth.checklogin();
  const cookies = new Cookies();
  const ability = cookies.get("ability");
  const [fileLoaded, setFileLoaded] = useState(false);

  useEffect(() => {
    if (!fileLoaded) {
      setFileLoaded(true);
    }
  });

  const handleAddCallback = (editData) => {
    if (editData) {
      setFileLoaded(false);
    }
  };

  return (
    <div className="hk-wrapper hk-vertical-nav">
      <nav className="navbar navbar-expand-xl navbar-dark fixed-top hk-navbar bg-danger">
        <a id="navbar_toggle_btn" class="navbar-toggle-btn nav-link-hover">
          <Menu />
        </a>

        <Link className="navbar-brand" to="/app">
          <div className="media">
            <div className="media-img-wrap">
              <div className="avatar avatar-sm">
                <img
                  src="/247-logo-sq.png"
                  alt="user"
                  className="avatar-img circle"
                />
              </div>
            </div>
            <div className="media-body">
              <h4 className="mt-10 ml-5 text-white">247i PAYROLL</h4>
            </div>
          </div>
        </Link>
        <ul className="navbar-nav hk-navbar-content order-xl-2">
          <li className="nav-item dropdown dropdown-authentication">
            <a
              className="nav-link dropdown-toggle no-caret"
              href="#"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <div className="media">
                <div className="media-img-wrap"></div>
                <div className="media-body">
                  <span>
                    <i className="material-icons mr-1">account_circle</i>
                    {cookies.get("name")}
                    <i className="zmdi zmdi-chevron-down"></i>
                  </span>
                </div>
              </div>
            </a>
            <div
              className="dropdown-menu dropdown-menu-right"
              data-dropdown-in="flipInX"
              data-dropdown-out="flipOutX"
            >
              <div className="dropdown-item">
                <strong>
                  {cookies.get("name")}
                  <br />
                  {"(" + cookies.get("email") + ")"}
                </strong>
              </div>
              <UpdateUserDetails parentCallback={handleAddCallback} />
              <ChangePassword />
              <a
                styles={"cursor:pointer"}
                className="dropdown-item"
                target="_blank"
                href={
                  process.env.REACT_APP_API_LINK +
                  "OT_Calculation_System_User_Guide.pdf"
                }
              >
                <i className="dropdown-icon zmdi zmdi-help-outline"></i>
                OTC Quick Reference Guide
              </a>
              <div className="dropdown-divider"></div>
              <button
                className="dropdown-item"
                onClick={() => {
                  auth.logout();
                }}
              >
                <i className="dropdown-icon zmdi zmdi-power"></i>
                <span>Log out</span>
              </button>
            </div>
          </li>
        </ul>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ml-0"></ul>
        </div>
      </nav>

      {/* <!-- Vertical Nav --> */}
      <nav className="hk-nav hk-nav-light">
        <a id="hk_nav_close" className="hk-nav-close">
          <span className="feather-icon">
            <i data-feather="x"></i>
          </span>
        </a>
        <div className="nicescroll-bar">
          <div className="navbar-nav-wrap">
            <ul className="navbar-nav flex-column">
              <li className="nav-item">
                <NavLink className="nav-link" to="/app">
                  <i className="ion ion-md-analytics"></i>
                  <span className="nav-link-text">Dashboard</span>
                </NavLink>
              </li>
            </ul>
            <hr className="nav-separator" />
            <div className="nav-header">
              <span>Modules</span>
              <span>MD</span>
            </div>
            <ul className="navbar-nav flex-column">
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-toggle="collapse"
                  data-target="#Components_drp"
                >
                  <i className="ion ion-md-time"></i>
                  <span className="nav-link-text">Timesheets</span>
                </a>
                <ul
                  id="Components_drp"
                  className="nav flex-column collapse collapse-level-1"
                >
                  <li className="nav-item">
                    <ul className="nav flex-column">
                      <li className="nav-item">
                        <NavLink className="nav-link" to="/attendance_upload">
                          Upload Timesheets
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink className="nav-link" to="/cutoff">
                          View Timesheets
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-toggle="collapse"
                  data-target="#content_drp"
                >
                  <i className="ion ion-md-cash"></i>
                  <span className="nav-link-text">Payroll Processing</span>
                </a>
                <ul
                  id="content_drp"
                  className="nav flex-column collapse collapse-level-1"
                >
                  <li className="nav-item">
                    <ul className="nav flex-column">
                      <li className="nav-item">
                        <NavLink className="nav-link" to="/attendance_upload">
                          Regular Payroll
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink className="nav-link" to="/attendance_upload">
                          Special Payroll
                        </NavLink>
                      </li>
                      <li className="nav-item">
                        <NavLink className="nav-link" to="/attendance_upload">
                          13th Month
                        </NavLink>
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
            <hr className="nav-separator" />
            <div className="nav-header">
              <span>Reports</span>
              <span>RP</span>
            </div>
            <ul className="navbar-nav flex-column">
              <li className="nav-item">
                <NavLink className="nav-link" to="/attendance_upload">
                  <i className="ion ion-md-bookmarks"></i>
                  <span className="nav-link-text">Payslip</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/attendance_upload">
                  <i className="ion ion-md-bookmarks"></i>
                  <span className="nav-link-text">Mandated Reports</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/attendance_upload">
                  <i className="ion ion-md-bookmarks"></i>
                  <span className="nav-link-text">YTD</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/attendance_upload">
                  <i className="ion ion-md-bookmarks"></i>
                  <span className="nav-link-text">BIR Form 2316</span>
                </NavLink>
              </li>
            </ul>
            <hr className="nav-separator" />
            <div className="nav-header">
              <span>Administration</span>
              <span>AD</span>
            </div>
            <ul className="navbar-nav flex-column">
              <li className="nav-item">
                <NavLink className="nav-link" to="/company">
                  <i className="ion ion-md-home"></i>
                  <span className="nav-link-text">Company Information</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/employee">
                  <i className="ion ion-md-body"></i>
                  <span className="nav-link-text">Employee Data</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/data_management">
                  <i className="ion ion-md-folder"></i>
                  <span className="nav-link-text">Data Management</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/user_management">
                  <i className="ion ion-md-people"></i>
                  <span className="nav-link-text">User Management</span>
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" to="/employee">
                  <i className="ion ion-md-cog"></i>
                  <span className="nav-link-text">Configuration</span>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div id="hk_nav_backdrop" className="hk-nav-backdrop"></div>
      {/* <!-- /Vertical Nav --> */}
    </div>
  );
}
