import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { Add, AirlineSeatIndividualSuite } from "@material-ui/icons";
import axios from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();
const d = new Date();

export default class AddPaySched extends Component {
  constructor(props) {
    super();
    this.state = {
      updated_by: cookies.get("email"),
      created_by: cookies.get("email"),
      sched_year: d.getFullYear(),
      showAddModal: false,
      disableAdd: false,
      hasError: false,
    };
    this.handleshowAddModal = this.handleshowAddModal.bind(this);
  }

  handleshowAddModal = (e) => {
    this.setState({ showAddModal: !this.state.showAddModal });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    let request = {
      updated_by: cookies.get("email"),
      created_by: cookies.get("email"),
      sched_year: this.state.sched_year,
      company_id: this.props.companyDetail.id,
      pay_period: this.props.companyDetail.pay_period,
    };
    axios
      .post("api/paysched", request)
      .then((res) => {
        this.props.parentCallback(true);
        this.setState({ showAddModal: false, disableAdd: false });
      })
      .catch((err) => {
        this.setState({ disableAdd: false, hasError: true });
        console.log(err);
      });
  };

  render() {
    return (
      <>
        <button
          className="btn btn-outline-primary btn-sm float-right"
          title="Add New Pay Schedule"
          onClick={this.handleshowAddModal}
        >
          <Add /> New Pay Schedule
        </button>
        <Modal
          size="md"
          backdrop="static"
          show={this.state.showAddModal}
          onHide={() =>
            this.setState({ showAddModal: !this.state.showAddModal })
          }
        >
          <Modal.Header closeButton>
            <h4>Add New Pay Schedule</h4>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <div
                  className={
                    this.state.hasError
                      ? "alert alert-danger"
                      : "alert alert-danger d-none"
                  }
                  role="alert"
                >
                  Year already has an existing pay schedule
                </div>
              </div>
            </div>
            <form onSubmit={this.handleSubmit} id="contact-form">
              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  <label>Pay Schedule Year</label>
                  <input
                    type="number"
                    min="1900"
                    max="9999"
                    step="1"
                    defaultValue={d.getFullYear()}
                    className="form-control"
                    required
                    onChange={(e) =>
                      this.setState({ sched_year: e.target.value })
                    }
                  />
                </div>
              </div>

              <div className="float-right">
                <button
                  type="submit"
                  className="btn btn-primary btn-sm mr-2"
                  disabled={this.state.disableAdd}
                >
                  Save
                </button>
                <button
                  onClick={this.handleshowAddModal}
                  className="btn btn-danger btn-sm"
                  type="button"
                >
                  Cancel
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
