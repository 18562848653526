import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { Edit } from "@material-ui/icons";
import axios from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();

export default class EditEdDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updated_by: cookies.get("email"),
      showEditModal: false,
      readOnlyAmount: false,
      disableAdd: false,
    };

    this.handleshowEditModal = this.handleshowEditModal.bind(this);
  }

  handleshowEditModal = (e) => {
    this.setState({
      ed_id: this.props.edDetail.ed_id,
      amount: this.props.edDetail.amount,
      pay_period: this.props.edDetail.pay_period,
      prev_pay_period: this.props.edDetail.pay_period,
    });
    this.setState({ showEditModal: !this.state.showEditModal });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ disableAdd: true });
    axios
      .put("api/edit_ed_detail/" + this.props.edDetail.id, this.state)
      .then((res) => {
        this.props.parentCallback(true);
        this.setState({ showEditModal: false, disableAdd: false });
      });
  };

  readOnlyAmountField = () => {
    switch (this.props.edDetail.ed_id) {
      case 1:
        return true;
      case 16:
        return true;
      case 17:
        return true;
      case 18:
        return true;
      case 20:
        return true;
      case 22:
        return true;
      case 24:
        return true;
      default:
        return false;
    }
  };

  render() {
    return (
      <>
        <button
          className="btn btn-outline-success btn-sm"
          title="Edit Employee Salary Details"
          onClick={this.handleshowEditModal}
        >
          <Edit />
        </button>
        <Modal
          size="md"
          show={this.state.showEditModal}
          onHide={() =>
            this.setState({ showEditModal: !this.state.showEditModal })
          }
        >
          <Modal.Header closeButton>
            <h4>
              Edit Recurring Earning & Deduction:
              <br />
              {this.props.edDetail.type} - {this.props.edDetail.name}
            </h4>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={this.handleSubmit} id="contact-form">
              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  <label>Transaction Type</label>:{" "}
                  <strong>{this.props.edDetail.type}</strong>
                  <br />
                  <label>Earning/Deduction</label>:{" "}
                  <strong>{this.props.edDetail.name}</strong>
                  <br />
                  <label>Amount</label>
                  <input
                    type="number"
                    step=".00001"
                    className="form-control"
                    required
                    onChange={(e) =>
                      this.setState({
                        amount: e.target.value,
                      })
                    }
                    disabled={this.readOnlyAmountField()}
                    defaultValue={this.props.edDetail.amount}
                  />
                </div>
                <div className="form-group col-md-12 col-sm-12">
                  <label>Pay Period Release</label>
                  <select
                    className="form-control custom-select"
                    required
                    onChange={(e) => {
                      this.setState({ pay_period: e.target.value });
                    }}
                    defaultValue={this.props.edDetail.pay_period}
                  >
                    <option value="">Select Pay Period Release</option>
                    {this.props.payCycle == 1 ? (
                      <>
                        <option value="2">15th (Semi-Monthly)</option>
                        <option value="4">30th (Semi-Monthly)</option>
                        <option value="1">15th & 30th (Semi-Monthly)</option>
                      </>
                    ) : (
                      <option value="3">30th (Monthly)</option>
                    )}
                  </select>
                </div>
              </div>

              <div className="float-right">
                <button
                  type="submit"
                  className="btn btn-primary btn-sm mr-2"
                  disabled={this.state.disableAdd}
                >
                  Save
                </button>
                <button
                  onClick={this.handleshowEditModal}
                  className="btn btn-danger btn-sm"
                  type="button"
                >
                  Cancel
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
