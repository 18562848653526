import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import Cookies from "universal-cookie";
import axios from "axios";
const cookies = new Cookies();
axios.defaults.baseURL = process.env.REACT_APP_API_LINK;
axios.defaults.headers.common['Authorization'] = 'Bearer ' + cookies.get("token");
axios.defaults.withCredentials = true;


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);


