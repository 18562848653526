import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { Edit } from "@material-ui/icons";
import axios from "axios";

export default class EditSchema extends Component {
  constructor(props) {
    super();
    this.state = {
      showEditModal: false,
      hasError: false,
      disableSubmit: false,
    };
    this.handleshowEditModal = this.handleshowEditModal.bind(this);
  }

  handleshowEditModal = (e) => {
    this.setState({
      showEditModal: !this.state.showEditModal,
      hasError: false,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ disableSubmit: true });
    axios
      .put("api/schema/" + this.props.schemaMain.id, this.state)
      .then((res) => {
        this.props.parentCallback(true);
        this.setState({ showEditModal: false, disableSubmit: false });
      })
      .catch((err) => {
        this.setState({ disableSubmit: false, hasError: true });
        console.log(err);
      });
  };

  render() {
    return (
      <>
        <button
          className="btn btn-outline-success btn-sm float-right"
          title="Edit Schema"
          onClick={this.handleshowEditModal}
        >
          <Edit />
        </button>
        <Modal
          size="md"
          show={this.state.showEditModal}
          onHide={() =>
            this.setState({ showEditModal: !this.state.showEditModal })
          }
        >
          <Modal.Header closeButton>
            <h4>Edit Schema</h4>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <div
                  className={
                    this.state.hasError
                      ? "alert alert-danger"
                      : "alert alert-danger d-none"
                  }
                  role="alert"
                >
                  Overtime schema code already existing.
                </div>
              </div>
            </div>
            <form onSubmit={this.handleSubmit} id="contact-form">
              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  <label>Code</label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    onChange={(e) => this.setState({ code: e.target.value })}
                    defaultValue={this.props.schemaMain.code}
                  />
                </div>
              </div>
              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  <label>Description</label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    onChange={(e) =>
                      this.setState({ description: e.target.value })
                    }
                    defaultValue={this.props.schemaMain.description}
                  />
                </div>
              </div>

              <div className="float-right">
                <button
                  type="submit"
                  className="btn btn-primary btn-sm mr-2"
                  disabled={this.state.disableSubmit}
                >
                  Save
                </button>
                <button
                  onClick={this.handleshowEditModal}
                  className="btn btn-danger btn-sm"
                  type="button"
                >
                  Cancel
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
