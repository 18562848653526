import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { NavLink, Link } from "react-router-dom";
import axios from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();

export default class ClearEntries extends Component {
  constructor(props) {
    super();
    this.state = {
      showAddModal: false,
      disableSubmit: false,
      company_id: cookies.get("transCompany"),
      company_name: cookies.get("transText")
    };
    this.handleshowAddModal = this.handleshowAddModal.bind(this);
  }

  handleshowAddModal = (e) => {
    if (cookies.get("transCompany") == "") {
      alert("Please Select Module Company at Dashboard");
      window.location.replace("/#/app");
    } else 
      this.setState({ showAddModal: !this.state.showAddModal });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ disableSubmit: true });
    axios
      .get("api/clear_entries/"+this.state.company_id)
      .then((res) => {
        this.setState({
          showAddModal: false,
          disableSubmit: false,
        });
        let  Url = /[^/]*$/.exec(window.location)[0];

        if(Url == 'app')
          window.location.reload();
        else
          window.location.replace("/#/app");
      })
      .catch((err) => {
        this.setState({ disableSubmit: false, hasError: true });
      });
  };

  render() {
    return (
      <>
        <Link className="nav-link" onClick={this.handleshowAddModal}>
          <i className="material-icons">point_of_sale</i>Clear Entries
        </Link>
        <Modal
          size="md"
          backdrop="static"
          show={this.state.showAddModal}
          onHide={() =>
            this.setState({ showAddModal: !this.state.showAddModal })
          }
        >
          <Modal.Header closeButton>
            <h4>Clear Data Entries</h4>
          </Modal.Header>
          <Modal.Body>
            <form onSubmit={this.handleSubmit} id="contact-form">
              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  <label>
                    Are you sure? this action will clear data from Transactions,
                    Regular Runs And Special Runs for <strong>{this.state.company_name }</strong>
                  </label>
                </div>
              </div>

              <div className="float-right">
                <button type="submit" className="btn btn-primary btn-sm mr-2" disabled={this.state.disableSubmit}>
                  Clear Data
                </button>
                <button
                  onClick={this.handleshowAddModal}
                  className="btn btn-danger btn-sm"
                  type="button"
                >
                  Cancel
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
