import React, { Component } from "react";
import Header from "../../components/Header";
import { Edit, ChromeReaderMode } from "@material-ui/icons";
import axios from "axios";
import Users from "./users/Users";
import Roles from "./user_roles/Roles";
import Cookies from "universal-cookie";
const cookies = new Cookies();

const access = ["No Access", "View Only", "Add", "Add / Edit", "Full Access"];

export default function Administration() {
  const permissions = cookies.get("permissions")["user_management"];
  return (
    <div className="hk-wrapper hk-vertical-nav">
      <div class="pg-hk-wrapper">
        <Header />
        <div className="mt-20 hk-pg-wrapper">
          {permissions > 0 ? (
            <div className="container-fluid">
              <h3 className="pb-10">User Management</h3>

              <div>
                <div className="container-fluid">
                  <div class="card card-tabs">
                    <div class="card-header p-0 pt-1">
                      <ul
                        class="nav nav-tabs"
                        id="custom-tabs-two-tab"
                        role="tablist"
                      >
                        <li class="nav-item">
                          <a
                            class="nav-link active"
                            id="custom-tabs-two-home-tab"
                            data-toggle="pill"
                            href="#user"
                            role="tab"
                            aria-controls="custom-tabs-two-home"
                            aria-selected="true"
                          >
                            <i className="dropdown-icon zmdi zmdi-accounts-alt"></i>
                            &nbsp;Users
                          </a>
                        </li>
                        <li class="nav-item">
                          <a
                            class="nav-link"
                            id="custom-tabs-two-home-tab"
                            data-toggle="pill"
                            href="#user-roles"
                            role="tab"
                            aria-controls="custom-tabs-two-home"
                            aria-selected="true"
                          >
                            <i className="dropdown-icon zmdi zmdi-accounts-list"></i>
                            &nbsp;Users Roles
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div class="card-body">
                      <div class="tab-content" id="custom-tabs-two-tabContent">
                        <div
                          class="tab-pane fade show active"
                          id="user"
                          role="tabpanel"
                          aria-labelledby="custom-tabs-two-home-tab"
                        >
                          <Users />
                        </div>
                        <div
                          class="tab-pane fade show"
                          id="user-roles"
                          role="tabpanel"
                          aria-labelledby="custom-tabs-two-home-tab"
                        >
                          <Roles />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <h1 className="m-0 text-center">
                Your credentials is not sufficient to access this:
                <br />
              </h1>
              <br />
              <h2 className="text-center">
                Module : User Management
                <br />
                Access Type : {access[permissions]}
                <br />
                Please contact your Payroll Administrator.
                <br />
                <a className="btn btn-primary btn-sm" href="/#/app">
                  Back to Dashboard
                </a>
              </h2>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
