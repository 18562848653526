import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import { timeFormatter } from "../../components/DtFormatter";
import { Link } from "react-router-dom";
import axios from "axios";
import { FindInPage, Add } from "@material-ui/icons";
import Cookies from "universal-cookie";
import AddBank from "./AddBank";
import EditBank from "./EditBank";
import DeleteBank from "./DeleteBank";
const pay_period = [
  "Weekly",
  "15th & 30th (Semi-Monthly)",
  "15th (Monthly)",
  "30th (Monthly)",
];
const cookies = new Cookies();
const access = ["No Access", "View Only", "Add", "Add / Edit", "Full Access"];

export default function Bank() {
  const permissions = cookies.get("permissions")["master_data"];
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [filteredData, setFilteredData] = useState([]);
  const [fileLoaded, setFileLoaded] = useState(false);
  const [search, setSearch] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      let apiLink = "api/banks";
      const res = await axios.get(apiLink);
      setData(res.data);
      const filterer = searcher(res.data, search);
      setFilteredData(filterer);
    };

    if (!fileLoaded) {
      fetchData();
      setFileLoaded(true);
    }
  });

  const handleAddCallback = (editData) => {
    if (editData) {
      setFileLoaded(false);
    }
  };

  const pages = [];
  for (let i = 1; i <= Math.ceil(filteredData.length / recordsPerPage); i++) {
    pages.push(i);
  }

  const handleRecordPerPage = (e) => {
    console.log(e.target.value);
    setRecordsPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const handleSearch = (e) => {
    const filterer = searcher(data, e.target.value)
    setFilteredData(filterer);
    setSearch(e.target.value)
    setCurrentPage(1)
  };

  const searcher = (data, searchString) =>{
    const searchedData = data.filter(function (data) {
      for (var key in data) {
        if (
          !(
            data["name"].toLowerCase().includes(searchString.toLowerCase()) ||
            data["code"].toLowerCase().includes(searchString.toLowerCase()) ||
            data["contact_person"]
              .toLowerCase()
              .includes(searchString.toLowerCase())
          )
        ) {
          return false;
        }
        return true;
      }
    });

    return searchedData;
  }

  const startIndex = currentPage * recordsPerPage - recordsPerPage;
  const endIndex = startIndex + recordsPerPage;
  const currentData = filteredData.slice(startIndex, endIndex);

  return (
    <div className="hk-wrapper hk-vertical-nav">
      <div class="pg-hk-wrapper">
        <Header />
        <div className="mt-20 hk-pg-wrapper">
          {permissions > 0 ? (
            <div className="container-fluid">
              <div className="row mb-10">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <h3>Bank Data</h3>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="btn-group float-right">
                    {permissions > 1 ? (
                      <AddBank parentCallback={handleAddCallback} />
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-12">
                  <div className="table-wrap">
                    <div className="row mb-10">
                      <div className="col-md-6">
                        <label>
                          <select
                            className="custom-select custom-select-sm form-control form-control-sm"
                            onChange={handleRecordPerPage}
                          >
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </label>{" "}
                        items
                      </div>
                      <div className="col-md-6">
                        <input
                          type="search"
                          className="form-control form-control-sm col-md-6 col-sm-6 float-right"
                          placeholder="Search"
                          onChange={handleSearch}
                        />
                      </div>
                    </div>
                    <div className="table-responsive mb-10">
                      <table className="table table-sm table-bordered table-striped mb-0">
                        <thead className="thead-secondary">
                          <tr className="text-center">
                            <th>Code</th>
                            <th>Name</th>
                            <th>Contact Person</th>
                            <th>Contact #</th>
                            <th>Email</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {filteredData.length > 0 ? (
                            currentData.map((bank) => (
                              <tr key={bank.id}>
                                <td>{bank.code}</td>
                                <td>{bank.name}</td>
                                <td>{bank.contact_person}</td>
                                <td>{bank.contact_no}</td>
                                <td>{bank.contact_email}</td>
                                <td className="text-center">
                                  <strong
                                    className={
                                        bank.status == "ACTIVE"
                                        ? "text-success"
                                        : "text-danger"
                                    }
                                  >
                                    {bank.status}
                                  </strong>
                                </td>
                                <td className="text-center">
                                  <div className="btn-group">
                                    {permissions > 2 ? (
                                      <EditBank
                                        bankData={bank}
                                        parentCallback={handleAddCallback}
                                      />
                                    ) : (
                                      ""
                                    )}
                                    {permissions > 3 ? (
                                      <DeleteBank
                                      name={bank.name}
                                        id={bank.id}
                                        status={bank.status}
                                      parentCallback={handleAddCallback}
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="7" className="text-center">
                                No Data to show
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="drew-pagination">
                    {filteredData.length > 0
                      ? "Showing " +
                        (startIndex + 1) +
                        " to " +
                        (endIndex > filteredData.length
                          ? filteredData.length
                          : endIndex) +
                        " of " +
                        filteredData.length
                      : ""}
                    <ul class="pagination ml-2">
                      {pages.map((number) => (
                        <li
                          key={number}
                          id={number}
                          className={
                            currentPage == number
                              ? "paginate_button page-item active"
                              : "paginate_button page-item"
                          }
                          onClick={() => setCurrentPage(number)}
                        >
                          <a class="page-link">{number}</a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <h1 className="m-0 text-center">
                Your credentials is not sufficient to access this:
                <br />
              </h1>
              <br />
              <h2 className="text-center">
                Module : Master Data
                <br />
                Access Type : {access[permissions]}
                <br />
                Please contact your Payroll Administrator.
                <br />
                <a className="btn btn-primary btn-sm" href="/#/app">
                  Back to Dashboard
                </a>
              </h2>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
