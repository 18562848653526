import React, { Component } from "react";
import Select from "react-select";
import { FormLabel, Modal } from "react-bootstrap";
import { Edit } from "@material-ui/icons";
import axios from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();

export default class EditUser extends Component {
  constructor(props) {
    super();
    let c_name = props.user.company_name.split(",");
    let c_id = props.user.company_id.split(",");
    let i = 0;
    let defaultDD = {};
    defaultDD = c_name.map((data)=>{
      let value =  {value: parseInt(c_id[i]), label: c_name[i]}
      i++;
      return value;
    })    
    this.state = {
      updated_by: cookies.get("email"),
      created_by: cookies.get("email"),
      user_role: props.user.user_role,
      company_id : defaultDD,
      showEditModal: false,
      disableSubmit: false,
      hasError: false,
      errMessage: "",
    };
    this.handleshowEditModal = this.handleshowEditModal.bind(this);
  }

  handleshowEditModal = (e) => {
    this.setState({ showEditModal: !this.state.showEditModal });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ disableSubmit: true });
    axios.put("api/users/" + this.props.user.id, this.state).then((res) => {
      this.props.parentCallback(true);
      this.setState({ showEditModal: false, disableSubmit: false });
    }).catch((err) => {
      this.setState({
        disableSubmit: false,
        hasError: true,
        errMessage: err.response.data.message,
      });
    });
  };

  render() {
    
    return (
      <>
        {this.props.type == 1 ? (
          <div
            className="dropdown-item"
            title="Update User"
            onClick={this.handleshowEditModal}
          >
            <Edit className="text-success" /> Edit User
          </div>
        ) : (
          <button
            className="btn btn-outline-success btn-sm float-right"
            title="Update User"
            onClick={this.handleshowEditModal}
          >
            <Edit />
          </button>
        )}

        <Modal
          size="md"
          backdrop="static"
          show={this.state.showEditModal}
          onHide={() =>
            this.setState({ showEditModal: !this.state.showEditModal })
          }
        >
          <Modal.Header closeButton>
            <h4> Update User: {this.props.user.name}</h4>
          </Modal.Header>
          <Modal.Body>
          <div className="row">
              <div className="col-md-12 col-sm-12">
                <div
                  className={
                    this.state.hasError
                      ? "alert alert-danger"
                      : "alert alert-danger d-none"
                  }
                  role="alert"
                >
                  {this.state.errMessage}
                </div>
              </div>
            </div>
            <form onSubmit={this.handleSubmit} id="contact-form">
              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  <label>Name</label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    onChange={(e) => this.setState({ name: e.target.value })}
                    defaultValue={this.props.user.name}
                  />
                </div>
              </div>
              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  <label>Email</label>
                  <input
                    type="email"
                    className="form-control"
                    required
                    onChange={(e) => this.setState({ email: e.target.value })}
                    defaultValue={this.props.user.email}
                  />
                </div>
              </div>
              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  <label>Role</label>
                  <select
                    class="form-control custom-select"
                    required
                    onChange={(e) =>
                      this.setState({ user_role: e.target.value,company_id: null})
                    }
                  >
                    <option value="">Select Role</option>
                    {this.props.role.map((role) => (
                      <option
                        key={role.id}
                        value={role.id}
                        selected={
                          role.id == this.props.user.user_role ? "selected" : ""
                        }
                      >
                        {role.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  <label>Company</label>
                  <Select
                    options={this.props.companyOption}
                    defaultValue = {this.state.company_id}
                    value = {this.state.company_id}
                    onChange={(e) => {
                      this.setState({ company_id: e });
                    }}
                    isDisabled={this.state.user_role == 0 ? (true):(false)}
                    closeMenuOnSelect={false}
                    required
                    isMulti
                  />
                  <input
                    className="form-control hidden hidden-require"
                    type="text"
                    value={this.state.company_id}
                    autoComplete="off"
                    required={this.state.user_role == 0 ? (true):(false)}
                  />
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  <label>Validity From</label>
                  <input
                    type="date"
                    className="form-control"
                    required
                    onChange={(e) => this.setState({ valid_from: e.target.value })}
                    defaultValue={this.props.user.valid_from}
                  />
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  <label>Validity To</label>
                  <input
                    type="date"
                    className="form-control"
                    required
                    min={this.state.valid_from}
                    onChange={(e) => this.setState({ valid_to: e.target.value })}
                    defaultValue={this.props.user.valid_to}
                  />
                </div>
              </div>

              <div className="float-right">
                <button
                  type="submit"
                  className="btn btn-primary btn-sm mr-2"
                  disabled={this.state.disableSubmit}
                >
                  Save
                </button>
                <button
                  onClick={this.handleshowEditModal}
                  className="btn btn-danger btn-sm"
                  type="button"
                >
                  Cancel
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
