import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { Add } from "@material-ui/icons";
import axios from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();

export default class AddCompany extends Component {
  constructor(props) {
    super();
    this.state = {
      updated_by: cookies.get("email"),
      created_by: cookies.get("email"),
      showAddModal: false,
      hasError: false,
    };
    this.handleshowAddModal = this.handleshowAddModal.bind(this);
  }

  handleshowAddModal = (e) => {
    this.setState({ showAddModal: !this.state.showAddModal });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post("api/company", this.state)
      .then((res) => {
        this.props.parentCallback(true);
        this.setState({ showAddModal: false });
      })
      .catch((err) => {
        this.setState({ disableAdd: false, hasError: true });
        console.log(err);
      });
  };

  render() {
    return (
      <>
        <button
          className="btn btn-outline-primary btn-sm float-right"
          title="Add New Company"
          onClick={this.handleshowAddModal}
        >
          <Add /> New Company
        </button>
        <Modal
          size="md"
          backdrop="static"
          show={this.state.showAddModal}
          onHide={() =>
            this.setState({ showAddModal: !this.state.showAddModal })
          }
        >
          <Modal.Header closeButton>
            <h4>Add New Company</h4>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <div
                  className={
                    this.state.hasError
                      ? "alert alert-danger"
                      : "alert alert-danger d-none"
                  }
                  role="alert"
                >
                  Company code already existing.
                </div>
              </div>
            </div>
            <form onSubmit={this.handleSubmit} id="contact-form">
              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  <label>Code</label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    onChange={(e) => this.setState({ code: e.target.value })}
                  />
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  <label>Name</label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    onChange={(e) => this.setState({ name: e.target.value })}
                  />
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  <label>Address Line 1</label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    onChange={(e) => this.setState({ address_1: e.target.value })}
                  />
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  <label>Address Line 2</label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    onChange={(e) => this.setState({ address_2: e.target.value })}
                  />
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  <label>Contact #</label>
                  <input
                    type="text"
                    className="form-control"
                    required
                    onChange={(e) => this.setState({ contact_no: e.target.value })}
                  />
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  <label>Basic Schema</label>
                  <select
                    class="form-control custom-select"
                    required
                    onChange={(e) =>
                      this.setState({ default_bschema: e.target.value })
                    }
                  >
                    <option value="">Select Basic Schema</option>
                    {this.props.bschemaDD.map((schema) => (
                      <option key={schema.id} value={schema.id}>
                        {schema.description}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  <label>Overtime Schema</label>
                  <select
                    class="form-control custom-select"
                    required
                    onChange={(e) =>
                      this.setState({ default_schema: e.target.value })
                    }
                  >
                    <option value="">Select Overtime Schema</option>
                    {this.props.schemaDD.map((schema) => (
                      <option key={schema.id} value={schema.id}>
                        {schema.description}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  <label>Night Differential Time In</label>
                  <input
                    type="time"
                    className="form-control"
                    required
                    onChange={(e) =>
                      this.setState({ night_diff_in: e.target.value })
                    }
                  />
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  <label>Night Differential Time Out</label>
                  <input
                    type="time"
                    className="form-control"
                    required
                    onChange={(e) =>
                      this.setState({ night_diff_out: e.target.value })
                    }
                  />
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  <label>Minumum Overtime(Hours)</label>
                  <input
                    type="number"
                    className="form-control"
                    required
                    onChange={(e) =>
                      this.setState({ min_overtime: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  <label>Payroll Cycle</label>
                  <select
                    className="form-control custom-select"
                    required
                    onChange={(e) => {
                      this.setState({ pay_period: e.target.value });
                    }}
                    value={this.state.pay_period}
                  >
                    <option value="">Select Payroll Cycle</option>
                    {/* <option value="0">Weekly</option> */}
                    <option value="1">15th & 30th (Semi-Monthly)</option>
                    {/* <option value="2">15th</option> */}
                    <option value="3">30th (Monthly)</option>
                  </select>
                </div>
              </div>

              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  <label>13th month Special Pay W/O Withholding Tax</label>
                  <select
                    className="form-control custom-select"
                    required
                    onChange={(e) => {
                      this.setState({ t_month_tax: e.target.value });
                    }}
                  >
                    <option value="">Select Payroll Cycle</option>
                    <option value="1">YES</option>
                    <option value="0">NO</option>
                  </select>
                </div>
              </div>

              <div className="float-right">
                <button type="submit" className="btn btn-primary btn-sm mr-2">
                  Save
                </button>
                <button
                  onClick={this.handleshowAddModal}
                  className="btn btn-danger btn-sm"
                  type="button"
                >
                  Cancel
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
