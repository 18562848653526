import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import axios from "axios";
import { Link } from "react-router-dom";
import { FileCopy, FindInPage } from "@material-ui/icons";
import { dateFormatter } from "../../components/DtFormatter";
import Cookies from "universal-cookie";
import UploadSpecial from "./AddSpecialUpload";
import DeleteSpecialUpload from "./DeleteSpecialUpload";
import PostSpecialUpload from "./PostSpecialUpload";
const cookies = new Cookies();

const access = ["No Access", "View Only", "Add", "Add / Edit", "Full Access"];

export default function SpecialUpload(props) {
  const permissions = cookies.get("permissions")["transaction"];
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [filteredData, setFilteredData] = useState([]);
  const [fileLoaded, setFileLoaded] = useState(false);
  const [search, setSearch] = useState(
    props.location.search ? decodeURI(props.location.search.split("=").pop()) : ""
  );

  useEffect(() => {
    const fetchData = async () => {
      let company_id = 0;
      if (cookies.get("transCompany")) company_id = cookies.get("transCompany");
      if (company_id == 0) {
        cookies.set("load", "1");
        alert("Please Select Module Company");
        window.location.replace("/#/app");
      } else {
        let apiLink = "api/special_upload/" + company_id;
        const res = await axios.get(apiLink);
        setData(res.data);
        const filterer = searcher(res.data, search);
        setFilteredData(filterer);
      }
    };

    if (!fileLoaded) {
      fetchData();
      setFileLoaded(true);
    }
  });

  const handleAddCallback = (editData, errs = []) => {
    if (editData) {
      setFileLoaded(false);
    }

    if (errs.length > 0) {
      alert("wee");
    }
  };

  const pages = [];
  for (let i = 1; i <= Math.ceil(data.length / recordsPerPage); i++) {
    pages.push(i);
  }

  const handleRecordPerPage = (e) => {
    console.log(e.target.value);
    setRecordsPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const handleSearch = (e) => {
    const filterer = searcher(data, e.target.value);
    setFilteredData(filterer);
    setSearch(e.target.value);
    setCurrentPage(1);
  };

  const searcher = (data, searchString) => {
    const searchedData = data.filter(function (data) {
      for (var key in data) {
        if (
          !(
            data["description"]
              .toLowerCase()
              .includes(searchString.toLowerCase()) ||
            data["file_name"]
              .toLowerCase()
              .includes(searchString.toLowerCase()) ||
            data["status"]
              .toLowerCase()
              .includes(searchString.toLowerCase())
          )
        ) {
          return false;
        }
        return true;
      }
    });

    return searchedData;
  };

  const startIndex = currentPage * recordsPerPage - recordsPerPage;
  const endIndex = startIndex + recordsPerPage;
  const currentData = filteredData.slice(startIndex, endIndex);

  return (
    <div className="hk-wrapper hk-vertical-nav">
      <div class="pg-hk-wrapper">
        <Header />
        <div className="mt-20 hk-pg-wrapper">
          {permissions > 0 ? (
            <div className="container-fluid">
              <div className="row mb-10">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <h3>Special Transaction Upload</h3>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  {cookies.get("transCompany") == "" ? (
                    ""
                  ) : permissions > 1 ? (
                    <>
                      <a
                        className="btn btn-outline-secondary btn-sm float-right ml-2"
                        title="Download Template"
                        href={
                          process.env.REACT_APP_API_LINK + "Special_Temp.xlsx"
                        }
                        target="_blank"
                        download
                      >
                        <FileCopy /> Download Template
                      </a>
                      <UploadSpecial parentCallback={handleAddCallback} />
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12">
                  <div class="table-wrap">
                    <div class="row mb-10">
                      <div class="col-md-6">
                        <label>
                          <select
                            class="custom-select custom-select-sm form-control form-control-sm"
                            onChange={handleRecordPerPage}
                          >
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </label>{" "}
                        items
                      </div>
                      <div class="col-md-6">
                        <input
                          type="search"
                          class="form-control form-control-sm col-md-6 col-sm-6 float-right"
                          placeholder="Search"
                          defaultValue={props.location.search ? decodeURI(props.location.search.split("=").pop()) : ""}
                          onChange={handleSearch}
                        />
                      </div>
                    </div>
                    <div class="table-responsive mb-10">
                      <table class="table table-sm table-bordered table-striped mb-0">
                        <thead className="thead-secondary">
                          <tr className="text-center">
                            <th>Description</th>
                            <th>File</th>
                            <th>No. of Records </th>
                            <th>Total Amount</th>
                            <th>Uploaded On</th>
                            <th>Updated On</th>
                            <th>Status</th>
                            <th style={{ width: "110px" }}>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {filteredData.length > 0 ? (
                            currentData.map((schema) => (
                              <tr key={schema.id}>
                                <td>{schema.description}</td>
                                <td>
                                  <a
                                    href={
                                      process.env.REACT_APP_API_LINK +
                                      "specialUpload/" +
                                      schema.file_name
                                    }
                                    target="_blank"
                                    download
                                  >
                                    {schema.file_name}
                                  </a>
                                </td>
                                <td className="text-right">
                                  {schema.trans_count
                                    .toLocaleString()
                                    .replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                </td>
                                <td className="text-right">
                                  {schema.total_amount
                                    .toLocaleString()
                                    .replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                </td>
                                <td>
                                  {dateFormatter(
                                    schema.created_at,
                                    "MMM dd, yyyy hh:mm b"
                                  )}
                                </td>
                                <td>
                                  {dateFormatter(
                                    schema.updated_at,
                                    "MMM dd, yyyy hh:mm b"
                                  )}
                                </td>
                                <td className="text-center">
                                  <strong
                                    className={
                                      schema.status == "FOR REVIEW"
                                        ? "text-danger"
                                        : "text-success"
                                    }
                                  >
                                    {schema.status}
                                  </strong>
                                </td>
                                <td>
                                  <div className="btn-group">
                                    <Link
                                      to={{
                                        pathname: `/special_upload/${schema.id}`,
                                        query: `/special_upload`,
                                        search: "?search=" + search,
                                      }}
                                      className="btn btn-sm btn-outline-primary"
                                      title={"View Special Upload Details"}
                                    >
                                      <FindInPage />
                                    </Link>
                                    {schema.status == "FOR REVIEW" ? (
                                      <>
                                        {permissions > 2 ? (
                                          <PostSpecialUpload
                                            id={schema.id}
                                            name={schema.description}
                                            parentCallback={handleAddCallback}
                                          />
                                        ) : (
                                          ""
                                        )}
                                        {permissions > 3 ? (
                                          <DeleteSpecialUpload
                                            id={schema.id}
                                            name={schema.description}
                                            parentCallback={handleAddCallback}
                                          />
                                        ) : (
                                          ""
                                        )}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan="8" className="text-center">
                                No Data to show
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      {filteredData.length > 0
                        ? "Showing " +
                          (startIndex + 1) +
                          " to " +
                          (endIndex > filteredData.length
                            ? filteredData.length
                            : endIndex) +
                          " of " +
                          filteredData.length
                        : ""}
                    </div>
                    <div class="col-sm-6 col-md-6">
                      <ul class="pagination float-right">
                        {pages.map((number) => (
                          <li
                            key={number}
                            id={number}
                            className={
                              currentPage == number
                                ? "paginate_button page-item active"
                                : "paginate_button page-item"
                            }
                            onClick={() => setCurrentPage(number)}
                          >
                            <a class="page-link">{number}</a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <h1 className="m-0 text-center">
                Your credentials is not sufficient to access this:
                <br />
              </h1>
              <br />
              <h2 className="text-center">
                Module : Transactions
                <br />
                Access Type : {access[permissions]}
                <br />
                Please contact your Payroll Administrator.
                <br />
                <a className="btn btn-primary btn-sm" href="/#/app">
                  Back to Dashboard
                </a>
              </h2>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
