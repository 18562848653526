import React, { useState, useEffect } from "react";
import axios from "axios";
import { dateFormatter } from "../../../components/DtFormatter";
import { Menu } from "@material-ui/icons";
import AddUser from "./AddUser";
import EditUser from "./EditUser";
import ResetUser from "./ResetUser";
import DeleteUser from "./DeleteUser";
import DestroyUser from "./DestroyUser";
import Cookies from "universal-cookie";
const cookies = new Cookies();


export default function Users() {
  const permissions = cookies.get("permissions")["user_management"];
  const [data, setData] = useState([]);
  const [roleDD, setRoleDD] = useState([]);
  const [companyDD, setCompanyDD] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [filteredData, setFilteredData] = useState([]);
  const [fileLoaded, setFileLoaded] = useState(false);
  const [search, setSearch] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      let apiLink = "api/user_mng_view/"+cookies.get('uid');
      const res = await axios.get(apiLink);
      setData(res.data.users);
      const filterer = searcher(res.data.users, search);
      setFilteredData(filterer);
      setRoleDD(res.data.roles);
      setCompanyDD(res.data.companies);
    };

    if (!fileLoaded) {
      fetchData();
      setFileLoaded(true);
    }
  });

  const companyOption = companyDD.map((data) => {
    return {
      value: data.id,
      label: data.name,
    };
  });

  const handleAddCallback = (editData) => {
    if (editData) {
      setFileLoaded(false);
    }
  };

  const pages = [];
  for (let i = 1; i <= Math.ceil(filteredData.length / recordsPerPage); i++) {
    pages.push(i);
  }

  const handleRecordPerPage = (e) => {
    console.log(e.target.value);
    setRecordsPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const handleSearch = (e) => {
    const filterer = searcher(data, e.target.value);
    setFilteredData(filterer);
    setSearch(e.target.value);
    setCurrentPage(1);
  };

  const searcher = (data, searchString) => {
    const searchedData = data.filter(function (data) {
      for (var key in data) {
        if (
          !(
            data["name"].toLowerCase().includes(searchString.toLowerCase()) 
          )
        ) {
          return false;
        }
        return true;
      }
    });

    return searchedData;
  };

  const startIndex = currentPage * recordsPerPage - recordsPerPage;
  const endIndex = startIndex + recordsPerPage;
  const currentData = filteredData.slice(startIndex, endIndex);

  return (
    <div>
      <div className="row mb-3">
        <div className="col-lg-12 col-md-12 col-sm-12">
          {permissions > 1 ? (
            <AddUser
              role={roleDD}
              companyDD={companyDD}
              companyOption={companyOption}
              parentCallback={handleAddCallback}
            />
          ) : (
            ""
          )}
        </div>
      </div>
      <div className="row">
        <div className="col-sm-12">
          <div className="table-wrap">
            <div className="row mb-10">
              <div className="col-md-6">
                <label>
                  <select
                    className="custom-select custom-select-sm form-control form-control-sm"
                    onChange={handleRecordPerPage}
                  >
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="100">100</option>
                  </select>
                </label>{" "}
                items
              </div>
              <div className="col-md-6">
                <input
                  type="search"
                  className="form-control form-control-sm col-md-6 col-sm-6 float-right"
                  placeholder="Search"
                  onChange={handleSearch}
                />
              </div>
            </div>
            <div className="table-responsive mb-10">
              <table className="table table-sm table-bordered table-striped mb-0">
                <thead className="thead-secondary">
                  <tr className="text-center text-nowrap">
                    <th>User</th>
                    <th>Companies</th>
                    <th>Role</th>
                    <th>Validity from</th>
                    <th>Validity to</th>
                    <th>Status</th>
                    <th>
                      Last Updated/
                      <br />
                      Updated By
                    </th>
                    <th style={{ width: "100px" }}>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData.length > 0 ? (
                    currentData.map((user) => (
                      <tr key={user.id} className="text-nowrap">
                        <td>
                          <div className="btn-group">
                            <div className="dropdown">
                              <button
                                className="btn btn-default text-left text-primary"
                                type="button"
                                id="dropdownMenuButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                {user.name}
                                <br />
                                <span className="text-secondary">
                                  {user.email}
                                </span>
                              </button>
                              {permissions > 2 ? (
                                <div className="dropdown-menu dropdown-menu-left">
                                  <EditUser
                                    role={roleDD}
                                    user={user}
                                    companyDD={companyDD}
                                    companyOption={companyOption}
                                    parentCallback={handleAddCallback}
                                    type={1}
                                  />
                                  <DeleteUser
                                    parentCallback={handleAddCallback}
                                    id={user.id}
                                    name={user.name}
                                    status={user.status}
                                    type={1}
                                  />
                                  {permissions > 3 ? (
                                    <DestroyUser
                                      parentCallback={handleAddCallback}
                                      id={user.id}
                                      name={user.name}
                                      status={user.status}
                                      type={1}
                                    />
                                  ) : (
                                    ""
                                  )}
                                  <ResetUser user={user} type={1} />
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </td>
                        <td>
                          {user.company_name.length != 0 ? (
                            <ul style={{ "list-style-type": "square" }}>
                              {user.company_name.split(",").map((data) => (
                                <li>{data}</li>
                              ))}
                            </ul>
                          ) : (
                            user.user_role == 0 ?("All Active Companies") :("")
                          )}
                        </td>
                        <td className="text-center">{user.role_name}</td>
                        <td className="text-center">
                          {dateFormatter(user.valid_from)}
                        </td>
                        <td className="text-center">
                          {dateFormatter(user.valid_to)}
                        </td>
                        <td className="text-center">
                          <strong
                            className={
                              user.status == "ACTIVE"
                                ? "text-success"
                                : "text-danger"
                            }
                          >
                            {user.status}
                          </strong>
                        </td>
                        <td className="text-center">
                          {dateFormatter(
                            user.updated_at,
                            "MMM dd, yyyy hh:mm aa"
                          )}
                          <br />
                          {user.updated_by}
                        </td>
                        <td>
                          {permissions > 2 ? (
                            <div className="btn-group">
                              <div className="dropdown">
                                <button
                                  className="btn btn-secondary dropdown-toggle"
                                  type="button"
                                  id="dropdownMenuButton"
                                  data-toggle="dropdown"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <Menu />
                                </button>
                                <div
                                  className="dropdown-menu dropdown-menu-right"
                                  aria-labelledby="dropdownMenuButton"
                                >
                                  <EditUser
                                    role={roleDD}
                                    user={user}
                                    companyDD={companyDD}
                                    companyOption={companyOption}
                                    parentCallback={handleAddCallback}
                                    type={1}
                                  />
                                  <DeleteUser
                                    parentCallback={handleAddCallback}
                                    id={user.id}
                                    name={user.name}
                                    status={user.status}
                                    type={1}
                                  />
                                  {permissions > 3 ? (
                                    <DestroyUser
                                      parentCallback={handleAddCallback}
                                      id={user.id}
                                      name={user.name}
                                      status={user.status}
                                      type={1}
                                    />
                                  ) : (
                                    ""
                                  )}
                                  <ResetUser user={user} type={1} />
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="8" className="text-center">
                        No Data to show
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          <div className="drew-pagination">
            {filteredData.length > 0
              ? "Showing " +
                (startIndex + 1) +
                " to " +
                (endIndex > filteredData.length
                  ? filteredData.length
                  : endIndex) +
                " of " +
                filteredData.length
              : ""}
            <ul class="pagination ml-2">
              {pages.map((number) => (
                <li
                  key={number}
                  id={number}
                  className={
                    currentPage == number
                      ? "paginate_button page-item active"
                      : "paginate_button page-item"
                  }
                  onClick={() => setCurrentPage(number)}
                >
                  <a class="page-link">{number}</a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
