import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import { Link } from "react-router-dom";
import { dateFormatter } from "../../components/DtFormatter";
import axios from "axios";
import Cookies from "universal-cookie";
import ExportPayslipEmp from "./ExportPayslipEmp";
import ExportSpPayslipEmp from "./ExportSpPayslipEmp";

export default function SpPayslipDetail(props) {
  const cookies = new Cookies();
  const ability = cookies.get("ability")[2];
  const setting = cookies.get("setting");
  const [prData, setPrData] = useState([]);
  const [empPrData, setEmpPrData] = useState([]);
  const [data, setData] = useState([]);
  const [basicStatutory, setBasicStatutory] = useState([]);
  const [basicDedc, setBasicDeduc] = useState([]);
  const [basicTax, setBasicTax] = useState([]);
  const [basic, setBasic] = useState([]);
  const [earning, setEarning] = useState([]);
  const [deduction, setDeduction] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [filteredData, setFilteredData] = useState([]);
  useEffect(() => {
    const fetchEmp = async () => {
      let apiLink =
        "api/sp_payslip_detail/" +
        props.match.params.cutoff_id +
        "/" +
        props.match.params.employee_id;
      const res = await axios.get(apiLink);
      setEmpPrData(res.data.payslip);
      setPrData(res.data.payslipMain);
      setBasic(res.data.basicDetails);
    };

    fetchEmp();
  }, []);

  const handleBack = () => {
    props.history.goBack();
  };

  const amountSum = basic.reduce((a, v) => (a = a + parseFloat(v.amount)), 0);
  const whTaxSum = basic.reduce((a, v) => (a = a + parseFloat(v.wh_tax)), 0);

  let netPay = amountSum - whTaxSum;

  return (
    <div className="hk-wrapper hk-vertical-nav">
      <div class="pg-hk-wrapper">
        <Header />
        <div className="mt-20 hk-pg-wrapper">
          {ability.permission > 0 ? (
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <h3 className="pb-10">Special Payslip Details</h3>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12">
                  {/* <button
                    className="btn btn-sm btn-danger float-right ml-10"
                    onClick={handleBack}
                  >
                    Back to Previous Page
                  </button> */}
                  <Link
                      to={{
                        pathname: `/sp_payslip/${prData.id}`,
                        query: `/sp_payslip`,
                        search: props.location.search
                      }}
                      className="btn btn-sm btn-danger float-right ml-10"
                      title={"View per employee"}
                    >
                      Back to Previous Page
                    </Link>
                  <ExportSpPayslipEmp
                    id={prData.id}
                    emp_id={empPrData.employee_id}
                    emp_name={empPrData.last_name + " " + empPrData.first_name}
                    type={1}
                    className="pb-10"
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <div className="card">
                    {/* <div className="card-header card-header-action">
                    <h5></h5>
                    <div className="d-flex align-items-center card-action-wrap-tools">
                    </div>
                  </div> */}
                    <div className="card-body">
                      <div className="row mb-10">
                        <div className="col-xl-4 col-sm-10">
                          <strong>Employee #</strong>
                        </div>
                        <div className="col-xl-8 col-sm-10">
                          :&nbsp;&nbsp;&nbsp;
                          {empPrData.emp_no}
                        </div>
                      </div>
                      <div className="row mb-10">
                        <div className="col-xl-4 col-sm-10">
                          <strong>Name</strong>
                        </div>
                        <div className="col-xl-8 col-sm-10">
                          :&nbsp;&nbsp;&nbsp;
                          {empPrData.last_name + ", " + empPrData.first_name}
                        </div>
                      </div>

                      <div className="row mb-10">
                        <div className="col-xl-4 col-sm-10">
                          <strong>Company</strong>
                        </div>
                        <div className="col-xl-8 col-sm-10">
                          :&nbsp;&nbsp;&nbsp;
                          {prData.company_name}
                        </div>
                      </div>

                      <div className="row mb-10">
                        <div className="col-xl-4 col-sm-10">
                          <strong>Description</strong>
                        </div>
                        <div className="col-xl-8 col-sm-10">
                          :&nbsp;&nbsp;&nbsp;
                          {prData.description}
                        </div>
                      </div>

                      <div className="row mb-10">
                        <div className="col-xl-4 col-sm-10">
                          <strong>Period</strong>
                        </div>
                        <div className="col-xl-8 col-sm-10">
                          :&nbsp;&nbsp;&nbsp;
                          {prData.code +
                            " (" +
                            dateFormatter(prData.start_date) +
                            " - " +
                            dateFormatter(prData.end_date) +
                            ")"}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-12 col-md-12 col-sm-12">
                  <div
                    class="tab-pane fade show active"
                    id="summary"
                    role="tabpanel"
                    aria-labelledby="custom-tabs-two-home-tab"
                  >
                    <table className="table table-sm table-bordered table-striped mb-0">
                      <thead className="thead-secondary">
                        <tr className="text-center">
                          <th>Particulars</th>
                          <th>Earnings</th>
                          <th>Deductions</th>
                          <th>Net Pay</th>
                        </tr>
                      </thead>
                      <tbody>
                        {basic.map((detail) => (
                          <tr>
                            <td>{detail.ed_name}</td>
                            <td className="text-right">
                              {(detail.amount + "")
                                .toLocaleString()
                                .replace(
                                  /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                  ","
                                )}
                            </td>
                            <td className="text-right"></td>
                            <td className="text-right">-</td>
                          </tr>
                        ))}
                        <tr>
                          <td>Withholding Tax</td>
                          <td className="text-right"></td>
                          <td className="text-right">
                            {whTaxSum
                              .toFixed('2')
                              .replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")}
                          </td>
                          <td className="text-right">-</td>
                        </tr>

                        <tr>
                          <td className="text-right" colSpan={3}>
                            <strong>Net Pay</strong>
                          </td>
                          <td className="text-right">
                            <strong>
                              {netPay
                                .toFixed(2)
                                .toLocaleString()
                                .replace(
                                  /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                  ","
                                )}
                            </strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <h1 className="m-0 text-center">
              The Credentials Supplied Are Not Sufficient To Access This Module
              <br />
              Please Contact Administrator
            </h1>
          )}
        </div>
      </div>
    </div>
  );
}
