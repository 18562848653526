import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Header";
import { FindInPage, FileCopy } from "@material-ui/icons";
import { dateFormatter } from "../../components/DtFormatter";
import axios from "axios";
import Cookies from "universal-cookie";
import multiColumnSort from "multi-column-sort";
import AddYtdAdjustment from "./AddYtdAdjustment";
import EditYtdAdjustment from "./EditYtdAdjustment";
import DeleteYtdAdjustment from "./DeleteYtdAdjustment";
import ContinueYtdAdj from "./ContinueYtdAdj";
const textColor = ["success", "warning", "success", "info", "info"];
const cookies = new Cookies();
const access = ["No Access", "View Only", "Add", "Add / Edit", "Full Access"];

export default function YtdAdjustment() {
  const permissions = cookies.get("permissions")["ytd"];
  const [empDD, setEmpDD] = useState([]);
  const [yearDD, setYearDD] = useState([]);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [filteredData, setFilteredData] = useState([]);
  const [fileLoaded, setFileLoaded] = useState(false);
  const [nameSort, setNameSort] = useState("DESC");
  const [empNoSort, setEmpNoSort] = useState("DESC");
  const [statusSort, setStatusSort] = useState("ASC");
  const [postDateSort, setPostDateSort] = useState("DESC");
  const [search, setSearch] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      let company_id = 0;
      if (cookies.get("transCompany")) company_id = cookies.get("transCompany");
      if (company_id == 0) {
        cookies.set("load", "1");
        alert("Please Select Module Company");
        window.location.replace("/#/app");
      } else {
        let apiLink = "api/ytd_adjustment/" + company_id;
        const res = await axios.get(apiLink);

        setYearDD(res.data.ytdLegder);
        setData(res.data.ytdAdj);
        const filterer = searcher(res.data.ytdAdj, search);
        setFilteredData(filterer);
      }
    };

    const fetchDD = async () => {
      const getEmp = await axios.get(
        "api/employeeDD/" + cookies.get("transCompany")
      );

      setEmpDD(getEmp.data);
    };

    if (!fileLoaded) {
      fetchData();
      setFileLoaded(true);
      fetchDD();
    }
  });

  const optionData = empDD.map((data) => {
    return {
      value: data.id,
      label: data.last_name + ", " + data.first_name,
    };
  });

  const optionYearData = yearDD.map((data) => {
    return {
      value: data.record_year,
      label: data.record_year,
    };
  });

  const handleAddCallback = (editData) => {
    if (editData) {
      setFileLoaded(false);
    }
  };

  const pages = [];
  for (let i = 1; i <= Math.ceil(filteredData.length / recordsPerPage); i++) {
    pages.push(i);
  }

  const handleRecordPerPage = (e) => {
    console.log(e.target.value);
    setRecordsPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const handleSearch = (e) => {
    const filterer = searcher(data, e.target.value)
    setFilteredData(filterer);
    setSearch(e.target.value)
    setCurrentPage(1)
  };

  const searcher = (data, searchString) =>{
    const searchedData = data.filter(function (data) {
      for (var key in data) {
        if (
          !(
            data["record_year"]
              .toString()
              .toLowerCase()
              .includes(searchString.toLowerCase()) ||
            data["first_name"]
              .toString()
              .toLowerCase()
              .includes(searchString.toLowerCase()) ||
            data["last_name"]
              .toString()
              .toLowerCase()
              .includes(searchString.toLowerCase()) ||
            (data["first_name"] + " " + data["last_name"])
              .toLowerCase()
              .includes(searchString.toLowerCase()) ||
            data["emp_no"]
              .toString()
              .toLowerCase()
              .includes(searchString.toLowerCase()) ||
            data["updated_at_f"]
              .toString()
              .toLowerCase()
              .includes(searchString.toLowerCase()) ||
            data["status_name"]
              .toString()
              .toLowerCase()
              .includes(searchString.toLowerCase())
          )
        ) {
          return false;
        }
        return true;
      }
    });

    return searchedData;
  } 

  const handleSort = (columnChanged, currentSort) => {
    let setSort;
    let sorter = [];
    const getColumnValue = (column, value) => {
      switch (column) {
        default:
          return value;
      }
    };

    if (currentSort == "ASC") setSort = "DESC";
    else setSort = "ASC";

    if (columnChanged == "name") {
      sorter = [
        ["last_name", nameSort],
        ["first_name", "ASC"],
        ["emp_no", "ASC"],
        ["status", "ASC"],
        ["updated_at_f", "ASC"],
      ];

      setNameSort(setSort);
    } else if (columnChanged == "empNo") {
      sorter = [
        ["emp_no", empNoSort],
        ["last_name", "ASC"],
        ["first_name", "ASC"],
        ["status", "ASC"],
        ["updated_at_f", "ASC"],
      ];

      setEmpNoSort(setSort);
    } else if (columnChanged == "status") {
      sorter = [
        ["status", statusSort],
        ["last_name", "ASC"],
        ["first_name", "ASC"],
        ["emp_no", "ASC"],
        ["updated_at_f", "ASC"],
      ];

      setStatusSort(setSort);
    } else if (columnChanged == "postDate") {
      sorter = [
        ["updated_at_f", postDateSort],
        ["last_name", "ASC"],
        ["first_name", "ASC"],
        ["emp_no", "ASC"],
        ["status", "ASC"],
      ];

      setPostDateSort(setSort);
    }

    const sorted = multiColumnSort(filteredData, sorter, getColumnValue);

    setFilteredData(sorted);
  };

  const startIndex = currentPage * recordsPerPage - recordsPerPage;
  const endIndex = startIndex + recordsPerPage;
  const currentData = filteredData.slice(startIndex, endIndex);

  return (
    <div className="hk-wrapper hk-vertical-nav">
      <div class="pg-hk-wrapper">
        <Header />
        <div className="mt-20 hk-pg-wrapper">
          {permissions > 0 ? (
            <div className="container-fluid">
              <div>
                <div className="row mb-10">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <h3>Maintain YTD</h3>
                  </div>

                  <div className="col-lg-6 col-md-6 col-sm-12">
                    {cookies.get("transCompany") == "" ? (
                      ""
                    ) : permissions > 1 ? (
                      <>
                        <AddYtdAdjustment
                          empDD={optionData}
                          yearDD={optionYearData}
                          parentCallback={handleAddCallback}
                        />
                      </>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 mt-10">
                    <div className="table-wrap">
                      <div className="row mb-10">
                        <div className="col-md-6">
                          <label>
                            <select
                              className="custom-select custom-select-sm form-control form-control-sm"
                              onChange={handleRecordPerPage}
                            >
                              <option value="10">10</option>
                              <option value="25">25</option>
                              <option value="50">50</option>
                              <option value="100">100</option>
                            </select>
                          </label>{" "}
                          items
                        </div>
                        <div className="col-md-6">
                          <input
                            type="search"
                            className="form-control form-control-sm col-md-6 col-sm-6 float-right"
                            placeholder="Search"
                            onChange={handleSearch}
                          />
                        </div>
                      </div>
                      <div className="table-responsive mb-10">
                        <table className="table table-sm table-bordered table-striped mb-0">
                          <thead className="thead-secondary">
                            <tr className="text-center text-nowrap">
                              <th>Year</th>
                              <th
                                onClick={() => handleSort("empNo", empNoSort)}
                                style={{ cursor: "pointer" }}
                              >
                                Employee # &nbsp;
                                <i
                                  className={
                                    empNoSort == "ASC"
                                      ? "fa fa-arrow-down"
                                      : "fa fa-arrow-up"
                                  }
                                ></i>
                              </th>
                              <th
                                onClick={() => handleSort("name", nameSort)}
                                style={{ cursor: "pointer" }}
                              >
                                Employee Name &nbsp;
                                <i
                                  className={
                                    nameSort == "ASC"
                                      ? "fa fa-arrow-down"
                                      : "fa fa-arrow-up"
                                  }
                                ></i>
                              </th>
                              <th>Basic</th>
                              <th>De Minimis</th>
                              <th>
                                O.Earnings
                                <br />
                                (Tax Exempt)
                              </th>
                              <th>O.Earnings</th>
                              <th>13th Month Pay</th>
                              <th>Gross Pay</th>
                              <th>SSS Premium</th>
                              <th>SSS Provident</th>

                              <th>PhilHealth</th>
                              <th>PAGIBIG</th>
                              <th>O.Deductions</th>
                              <th>WH Tax</th>

                              <th>Net Pay</th>
                              <th
                                onClick={() =>
                                  handleSort("postDate", postDateSort)
                                }
                                style={{ cursor: "pointer" }}
                              >
                                Updated On &nbsp;
                                <i
                                  className={
                                    postDateSort == "ASC"
                                      ? "fa fa-arrow-down"
                                      : "fa fa-arrow-up"
                                  }
                                ></i>
                              </th>
                              <th
                                onClick={() => handleSort("status", statusSort)}
                                style={{ cursor: "pointer" }}
                              >
                                Status &nbsp;
                                <i
                                  className={
                                    statusSort == "ASC"
                                      ? "fa fa-arrow-down"
                                      : "fa fa-arrow-up"
                                  }
                                ></i>
                              </th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredData.length > 0 ? (
                              currentData.map((cutoff) => (
                                <tr key={cutoff.id}>
                                  <td>{cutoff.record_year}</td>
                                  <td className="text-nowrap">
                                    {cutoff.emp_no}
                                  </td>
                                  <td
                                    className={
                                      cutoff.emp_status == 1
                                        ? "text-nowrap"
                                        : "text-nowrap text-danger"
                                    }
                                  >
                                    {cutoff.last_name +
                                      ", " +
                                      cutoff.first_name}
                                    {cutoff.emp_status == 1
                                      ? ""
                                      : " (INACTIVE)"}
                                  </td>
                                  <td className="text-right">
                                    {cutoff.basic_pay.replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                  </td>
                                  <td className="text-right">
                                    {cutoff.deminimis.replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                  </td>
                                  <td className="text-right">
                                    {cutoff.other_earning_nt.replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                  </td>

                                  <td className="text-right">
                                    {cutoff.other_earning.replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                  </td>
                                  <td className="text-right">
                                    {cutoff.t_month_pay.replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                  </td>
                                  <td className="text-right">
                                    {cutoff.gross_pay.replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                  </td>
                                  <td className="text-right">
                                    {cutoff.sss_premium.replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                  </td>
                                  <td className="text-right">
                                    {cutoff.sss_provident.replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                  </td>

                                  <td className="text-right">
                                    {cutoff.philhealth.replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                  </td>
                                  <td className="text-right">
                                    {cutoff.pagibig.replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                  </td>
                                  <td className="text-right">
                                    {cutoff.other_deduction.replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                  </td>
                                  <td className="text-right">
                                    {cutoff.wh_tax.replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                  </td>

                                  <td className="text-right">
                                    {cutoff.net_pay.replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                  </td>
                                  <td className="text-nowrap">
                                    {cutoff.updated_at ? (
                                      dateFormatter(
                                        cutoff.updated_at,
                                        "MMM dd, yyyy hh:mm bb"
                                      )
                                    ) : (
                                      <strong className="text-danger"></strong>
                                    )}
                                  </td>
                                  <td className="text-nowrap">
                                    <strong
                                      className={
                                        "text-" + textColor[cutoff.status]
                                      }
                                    >
                                      {cutoff.status_name}
                                    </strong>
                                  </td>
                                  <td>
                                    <div className="btn-group">
                                      {cutoff.status < 2 ? (
                                        <>
                                          {permissions > 2 ? (
                                            <EditYtdAdjustment
                                              AdjDetail={cutoff}
                                              empDD={optionData}
                                              yearDD={optionYearData}
                                              parentCallback={handleAddCallback}
                                            />
                                          ) : (
                                            ""
                                          )}
                                          {permissions > 3 ? (
                                            <DeleteYtdAdjustment
                                              id={cutoff.id}
                                              name={
                                                cutoff.record_year +
                                                " " +
                                                cutoff.last_name +
                                                ", " +
                                                cutoff.first_name
                                              }
                                              parentCallback={handleAddCallback}
                                            />
                                          ) : (
                                            ""
                                          )}
                                        </>
                                      ) : (
                                        ""
                                      )}
                                      {cutoff.status == 3 ? (
                                        <>
                                          <ContinueYtdAdj
                                            transId={cutoff.id}
                                            parentCallback={handleAddCallback}
                                          />
                                          <DeleteYtdAdjustment
                                            id={cutoff.id}
                                            name={
                                              cutoff.record_year +
                                              " " +
                                              cutoff.last_name +
                                              ", " +
                                              cutoff.first_name
                                            }
                                            parentCallback={handleAddCallback}
                                          />
                                        </>
                                      ) : (
                                        ""
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="19" className="text-center">
                                  No Data to show
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="drew-pagination">
                      {filteredData.length > 0
                        ? "Showing " +
                          (startIndex + 1) +
                          " to " +
                          (endIndex > filteredData.length
                            ? filteredData.length
                            : endIndex) +
                          " of " +
                          filteredData.length
                        : ""}
                      <ul class="pagination ml-2">
                        {pages.map((number) => (
                          <li
                            key={number}
                            id={number}
                            className={
                              currentPage == number
                                ? "paginate_button page-item active"
                                : "paginate_button page-item"
                            }
                            onClick={() => setCurrentPage(number)}
                          >
                            <a class="page-link">{number}</a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <h1 className="m-0 text-center">
                Your credentials is not sufficient to access this:
                <br />
              </h1>
              <br />
              <h2 className="text-center">
                Module : YTD Adjustments
                <br />
                Access Type : {access[permissions]}
                <br />
                Please contact your Payroll Administrator.
                <br />
                <a className="btn btn-primary btn-sm" href="/#/app">
                  Back to Dashboard
                </a>
              </h2>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
