import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import axios from "axios";
import { Link } from "react-router-dom";
import { FindInPage } from "@material-ui/icons";
import { dateFormatter } from "../../components/DtFormatter";
import Cookies from "universal-cookie";
import multiColumnSort from "multi-column-sort";
import AddSpecialPay from "./AddSpecialPay";
import EditSpecialPay from "./EditSpecialPay";
import CancelSpecialPay from "./CancelSpecialPay";
import HoldSpecialPay from "./HoldSpecialPay";
import ContinueSpecialPay from "./ContinueSpecialPay";
const textColor = ["success", "warning", "success", "danger", "info"];
const cookies = new Cookies();
const access = ["No Access", "View Only", "Add", "Add / Edit", "Full Access"];

export default function SpecialPay(props) {
  const permissions = cookies.get("permissions")["transaction"];
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [filteredData, setFilteredData] = useState([]);
  const [fileLoaded, setFileLoaded] = useState(false);
  const [companyDD, setCompanyDD] = useState([]);
  const [edDD, setEdDD] = useState([]);
  const [empDD, setEmpDD] = useState([]);
  const [paySchedDD, setPaySchedDD] = useState([]);
  const [nameSort, setNameSort] = useState("DESC");
  const [empNoSort, setEmpNoSort] = useState("DESC");
  const [edSort, setEdSort] = useState("DESC");
  const [updateDateSort, setUpdateDateSort] = useState("DESC");
  const [statusSort, setStatusSort] = useState("ASC");
  const [search, setSearch] = useState(
    props.location.search ? decodeURI(props.location.search.split("=").pop()) : ""
  );

  useEffect(() => {
    const fetchData = async () => {
      let company_id = 0;
      if (cookies.get("transCompany")) company_id = cookies.get("transCompany");
      if (company_id == 0) {
        cookies.set("load", "1");
        alert("Please Select Module Company");
        window.location.replace("/#/app");
      } else {
        let apiLink = "api/special_pay/" + company_id;
        const res = await axios.get(apiLink);
        setData(res.data);
        const filterer = searcher(res.data, search);
        setFilteredData(filterer);
      }
    };

    const fetchDD = async () => {
      const getCompany = await axios.get("api/company");
      const getEmp = await axios.get(
        "api/employeeDD/" + cookies.get("transCompany")
      );
      const getPaySched = await axios.get(
        "api/pay_schedDD/" + cookies.get("transCompany")
      );

      const getEd = await axios.get("api/earning_deductionDD");
      setEdDD(getEd.data);
      setEmpDD(getEmp.data);
      setCompanyDD(getCompany.data);
      setPaySchedDD(getPaySched.data);
    };

    if (!fileLoaded) {
      fetchData();
      setFileLoaded(true);
      fetchDD();
    }
  });

  const optionData = empDD.map((data) => {
    return {
      value: data.id,
      label: data.last_name + ", " + data.first_name,
    };
  });

  const fromData = paySchedDD.map((data) => {
    return {
      value: data.id,
      label:
        data.code +
        " (" +
        dateFormatter(data.start_date) +
        " - " +
        dateFormatter(data.end_date) +
        ")",
    };
  });
  const toData = paySchedDD.map((data) => {
    return {
      value: data.id,
      label:
        data.code +
        " (" +
        dateFormatter(data.end_date) +
        " - " +
        dateFormatter(data.end_date) +
        ")",
    };
  });

  const pages = [];
  for (let i = 1; i <= Math.ceil(filteredData.length / recordsPerPage); i++) {
    pages.push(i);
  }

  const handleRecordPerPage = (e) => {
    console.log(e.target.value);
    setRecordsPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const handleAddCallback = (editData) => {
    if (editData) {
      setFileLoaded(false);
    }
  };

  const handleSearch = (e) => {
    const filterer = searcher(data, e.target.value);
    setFilteredData(filterer);
    setSearch(e.target.value);
    setCurrentPage(1);
  };

  const searcher = (data, searchString) => {
    const searchedData = data.filter(function (data) {
      for (var key in data) {
        if (
          !(
            data["first_name"]
              .toLowerCase()
              .includes(searchString.toLowerCase()) ||
            data["last_name"]
              .toLowerCase()
              .includes(searchString.toLowerCase()) ||
            data["emp_no"]
              .toLowerCase()
              .includes(searchString.toLowerCase()) ||
            data["ed_name"]
              .toLowerCase()
              .includes(searchString.toLowerCase()) ||
            data["ed_code"]
              .toLowerCase()
              .includes(searchString.toLowerCase()) ||
            data["code"].toLowerCase().includes(searchString.toLowerCase()) ||
            data["status_name"]
              .toLowerCase()
              .includes(searchString.toLowerCase()) ||
            data["updated_at_f"]
              .toLowerCase()
              .includes(searchString.toLowerCase())
          )
        ) {
          return false;
        }
        return true;
      }
    });

    return searchedData;
  };

  const handleSort = (columnChanged, currentSort) => {
    let setSort;
    let sorter = [];
    const getColumnValue = (column, value) => {
      switch (column) {
        default:
          return value;
      }
    };

    if (currentSort == "ASC") setSort = "DESC";
    else setSort = "ASC";

    if (columnChanged == "name") {
      sorter = [
        ["last_name", nameSort],
        ["first_name", "ASC"],
        ["emp_no", "ASC"],
        ["ed_code", "ASC"],
        ["pay_cycle_name", "ASC"],
        ["status", "ASC"],
        ["updated_at_f", "ASC"],
      ];

      setNameSort(setSort);
    } else if (columnChanged == "empNo") {
      sorter = [
        ["emp_no", empNoSort],
        ["last_name", "ASC"],
        ["first_name", "ASC"],
        ["ed_name", "ASC"],
        ["pay_cycle_name", "ASC"],
        ["status", "ASC"],
        ["updated_at_f", "ASC"],
      ];

      setEmpNoSort(setSort);
    } else if (columnChanged == "status") {
      sorter = [
        ["status", statusSort],
        ["last_name", "ASC"],
        ["first_name", "ASC"],
        ["emp_no", "ASC"],
        ["ed_code", "ASC"],
        ["pay_cycle_name", "ASC"],
        ["updated_at_f", "ASC"],
      ];

      setStatusSort(setSort);
    } else if (columnChanged == "ed") {
      sorter = [
        ["ed_code", edSort],
        ["last_name", "ASC"],
        ["first_name", "ASC"],
        ["emp_no", "ASC"],
        ["pay_cycle_name", "ASC"],
        ["status", "ASC"],
        ["updated_at_f", "ASC"],
      ];

      setEdSort(setSort);
    } else if (columnChanged == "updateDate") {
      sorter = [
        ["updated_at_f", updateDateSort],
        ["last_name", "ASC"],
        ["first_name", "ASC"],
        ["ed_code", "ASC"],
        ["emp_no", "ASC"],
        ["pay_cycle_name", "ASC"],
        ["status", "ASC"],
      ];

      setUpdateDateSort(setSort);
    }

    const sorted = multiColumnSort(filteredData, sorter, getColumnValue);

    setFilteredData(sorted);
  };

  const startIndex = currentPage * recordsPerPage - recordsPerPage;
  const endIndex = startIndex + recordsPerPage;
  const currentData = filteredData.slice(startIndex, endIndex);

  return (
    <div className="hk-wrapper hk-vertical-nav">
      <div class="pg-hk-wrapper">
        <Header />
        <div className="mt-20 hk-pg-wrapper">
          {permissions > 0 ? (
            <div className="container-fluid">
              <div className="row mb-10">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  <h3>Special Transaction</h3>
                </div>

                <div className="col-lg-6 col-md-6 col-sm-12">
                  {cookies.get("transCompany") == "" ? (
                    ""
                  ) : permissions > 1 ? (
                    <>
                      <AddSpecialPay
                        empDD={optionData}
                        edDD={edDD}
                        parentCallback={handleAddCallback}
                      />
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div class="row">
                <div class="col-sm-12 mt-10">
                  <div class="table-wrap">
                    <div class="row mb-10">
                      <div class="col-md-6">
                        <label>
                          <select
                            class="custom-select custom-select-sm form-control form-control-sm"
                            onChange={handleRecordPerPage}
                          >
                            <option value="10">10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                        </label>{" "}
                        items
                      </div>
                      <div class="col-md-6">
                        <input
                          type="search"
                          class="form-control form-control-sm col-md-6 col-sm-6 float-right"
                          placeholder="Search"
                          onChange={handleSearch}
                        />
                      </div>
                    </div>
                    <div class="table-responsive mb-10">
                      <table class="table table-sm table-bordered table-striped mb-0">
                        <thead className="thead-secondary">
                          <tr className="text-center text-nowrap">
                            <th
                              onClick={() => handleSort("empNo", empNoSort)}
                              style={{ cursor: "pointer" }}
                            >
                              Employee # &nbsp;
                              <i
                                className={
                                  empNoSort == "ASC"
                                    ? "fa fa-arrow-down"
                                    : "fa fa-arrow-up"
                                }
                              ></i>
                            </th>
                            <th
                              onClick={() => handleSort("name", nameSort)}
                              style={{ cursor: "pointer" }}
                            >
                              Employee Name &nbsp;
                              <i
                                className={
                                  nameSort == "ASC"
                                    ? "fa fa-arrow-down"
                                    : "fa fa-arrow-up"
                                }
                              ></i>
                            </th>
                            <th
                              onClick={() => handleSort("ed", edSort)}
                              style={{ cursor: "pointer" }}
                            >
                              Earning &nbsp;
                              <i
                                className={
                                  edSort == "ASC"
                                    ? "fa fa-arrow-down"
                                    : "fa fa-arrow-up"
                                }
                              ></i>
                            </th>
                            <th>Amount</th>
                            <th>Run Code</th>
                            <th
                              onClick={() => handleSort("status", statusSort)}
                              style={{ cursor: "pointer" }}
                            >
                              Status &nbsp;
                              <i
                                className={
                                  statusSort == "ASC"
                                    ? "fa fa-arrow-down"
                                    : "fa fa-arrow-up"
                                }
                              ></i>
                            </th>
                            <th
                              onClick={() => handleSort("updateDate", updateDateSort)}
                              style={{ cursor: "pointer" }}
                            >
                              Updated On &nbsp;
                              <i
                                className={
                                  updateDateSort == "ASC"
                                    ? "fa fa-arrow-down"
                                    : "fa fa-arrow-up"
                                }
                              ></i>
                              
                            </th>
                            <th style={{ width: "80px" }}>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {filteredData.length > 0 ? (
                            currentData.map((trans) => (
                              <tr key={trans.id}>
                                <td>{trans.emp_no}</td>
                                <td
                                  className={
                                    trans.emp_status == 0 ? "text-danger" : ""
                                  }
                                >
                                  {trans.last_name + ", " + trans.first_name}
                                  {trans.emp_status == 0 ? (
                                    <>
                                      <br />
                                      (INACTIVE)
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </td>
                                <td>{trans.ed_code + " - " + trans.ed_name}</td>
                                <td className="text-right">
                                  {trans.amount
                                    .toLocaleString()
                                    .replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                </td>
                                <td>{trans.code}</td>

                                <td>
                                  <strong
                                    className={
                                      "text-" + textColor[trans.status]
                                    }
                                  >
                                    {trans.status_name}
                                  </strong>
                                </td>
                                <td>
                                  {dateFormatter(
                                    trans.updated_at,
                                    "MMM dd, yyyy hh:mm bb"
                                  )}
                                </td>
                                <td>
                                  <div className="btn-group">
                                    {trans.status == 0 ? (
                                      permissions > 2 ? (
                                        <EditSpecialPay
                                          transDetail={trans}
                                          edDD={edDD}
                                          empDD={optionData}
                                          parentCallback={handleAddCallback}
                                        />
                                      ) : (
                                        ""
                                      )
                                    ) : (
                                      ""
                                    )}

                                    {trans.status <= 4 &&
                                    trans.status != 2 &&
                                    trans.status != 3 ? (
                                      permissions > 3 ? (
                                        <CancelSpecialPay
                                          transId={trans.id}
                                          parentCallback={handleAddCallback}
                                        />
                                      ) : (
                                        ""
                                      )
                                    ) : (
                                      ""
                                    )}

                                    {trans.status < 3 && trans.status != 2 ? (
                                      permissions > 2 ? (
                                        <HoldSpecialPay
                                          transId={trans.id}
                                          parentCallback={handleAddCallback}
                                        />
                                      ) : (
                                        ""
                                      )
                                    ) : (
                                      ""
                                    )}

                                    {trans.status == 4 ? (
                                      permissions > 2 ? (
                                        <ContinueSpecialPay
                                          transId={trans.id}
                                          parentCallback={handleAddCallback}
                                        />
                                      ) : (
                                        ""
                                      )
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colspan="9" className="text-center">
                                No Data to show
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      {filteredData.length > 0
                        ? "Showing " +
                          (startIndex + 1) +
                          " to " +
                          (endIndex > filteredData.length
                            ? filteredData.length
                            : endIndex) +
                          " of " +
                          filteredData.length
                        : ""}
                    </div>
                    <div class="col-sm-6 col-md-6">
                      <ul class="pagination float-right">
                        {pages.map((number) => (
                          <li
                            key={number}
                            id={number}
                            className={
                              currentPage == number
                                ? "paginate_button page-item active"
                                : "paginate_button page-item"
                            }
                            onClick={() => setCurrentPage(number)}
                          >
                            <a class="page-link">{number}</a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <h1 className="m-0 text-center">
                Your credentials is not sufficient to access this:
                <br />
              </h1>
              <br />
              <h2 className="text-center">
                Module : Transactions
                <br />
                Access Type : {access[permissions]}
                <br />
                Please contact your Payroll Administrator.
                <br />
                <a className="btn btn-primary btn-sm" href="/#/app">
                  Back to Dashboard
                </a>
              </h2>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
