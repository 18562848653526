import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { Edit } from "@material-ui/icons";
import axios from "axios";
import Cookies from "universal-cookie";
import Select from "react-select";
const cookies = new Cookies();
const pay_period = ["Weekly", "15th & 30th", "15th", "30th"];

export default class EditSpecialPay extends Component {
  constructor() {
    super();
    this.state = {
      updated_by: cookies.get("email"),
      type: "EARNING",
      pay_period: cookies.get("transPay"),
      company_id: cookies.get("transCompany"),
      showEditModal: false,
      disableSubmit: false,
      hasError: false,
    };
    this.handleshowEditModal = this.handleshowEditModal.bind(this);
  }

  handleshowEditModal = (e) => {
    this.setState({
      showEditModal: !this.state.showEditModal,
      hasError: false,
    });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ disableSubmit: true });
    axios
      .post("api/special_edit/" + this.props.transDetail.id, this.state)
      .then((res) => {
        this.props.parentCallback(true);
        this.setState({
          showEditModal: false,
          hasError: false,
          disableSubmit: false,
        });
      })
      .catch((err) => {
        this.setState({ disableSubmit: false, hasError: true });
      });
  };

  render() {
    return (
      <>
        <button
          className="btn btn-outline-success btn-sm float-right"
          title="Edit New Transaction"
          onClick={this.handleshowEditModal}
        >
          <Edit />
        </button>
        <Modal
          size="md"
          show={this.state.showEditModal}
          onHide={() =>
            this.setState({ showEditModal: !this.state.showEditModal })
          }
        >
          <Modal.Header closeButton>
            <h4>Edit Special Transaction</h4>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <div
                  className={
                    this.state.hasError
                      ? "alert alert-danger"
                      : "alert alert-danger d-none"
                  }
                  role="alert"
                >
                  Failed to Create Special Transaction
                </div>
              </div>
            </div>
            <form onSubmit={this.handleSubmit} id="contact-form">
              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  <label>Employee</label>
                  <Select
                    placeholder="Select Employee"
                    options={this.props.empDD}
                    onChange={(e) => {
                      this.setState({ employee_id: e.value });
                    }}
                    defaultValue={{
                      label:
                        this.props.transDetail.last_name +
                        ", " +
                        this.props.transDetail.first_name,
                      value: this.props.transDetail.employee_id,
                    }}
                    isSearchable
                    required
                  />
                  <input
                    className="form-control hidden hidden-require"
                    type="text"
                    value={this.props.transDetail.employee_id}
                    autoComplete="off"
                    required
                  />
                </div>
                <div className="form-group col-md-12 col-sm-12">
                  <label>Earning Type</label>
                  <select
                    className="form-control custom-select"
                    required
                    onChange={(e) => {
                      this.setState({
                        ed_id: e.target.value,
                      });
                    }}
                    defaultValue={this.props.transDetail.ed_id}
                  >
                    <option value="">Select Earning</option>
                    {this.props.edDD.map((ed) => (
                      <option
                        key={ed.id}
                        value={ed.id}
                        hidden={ed.is_add == 1 ? "" : "hidden"}
                      >
                        {ed.code + " - " + ed.name}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="form-group col-md-12 col-sm-12">
                  <label>Amount</label>
                  <input
                    type="number"
                    className="form-control"
                    required
                    onChange={(e) => {
                      this.setState({
                        amount: e.target.value,
                      });
                    }}
                    defaultValue={this.props.transDetail.amount}
                  />
                </div>
              </div>

              <div className="float-right">
                <button
                  type="submit"
                  className="btn btn-primary btn-sm mr-2"
                  disabled={this.state.disableSubmit}
                >
                  Save
                </button>
                <button
                  onClick={this.handleshowEditModal}
                  className="btn btn-danger btn-sm"
                  type="button"
                >
                  Cancel
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
