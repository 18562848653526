import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import { Link } from "react-router-dom";
import { dateFormatter } from "../../components/DtFormatter";
import axios from "axios";
import Cookies from "universal-cookie";
import multiColumnSort from "multi-column-sort";
const statuses = ["DRAFT", "FOR POSTING", "POSTED"];

export default function BreakdownEarning(props) {
  const cookies = new Cookies();
  const ability = cookies.get("ability")[2];
  const [runData, setRunData] = useState([]);
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [filteredData, setFilteredData] = useState([]);
  const [nameSort, setNameSort] = useState("DESC");
  const [edSort, setEdSort] = useState("DESC");
  const [payPeriodSort, setPayPeriodSort] = useState("DESC");
  const [empNoSort, setEmpNoSort] = useState("DESC");

  useEffect(() => {
    const fetchData = async () => {
      let apiLink =
        "api/regular_other_earn/" +
        props.match.params.cutoff_id +
        "/" +
        props.match.params.paysched_id;
      const res = await axios.get(apiLink);

      let datas = [];
      res.data.basicEarning.map((values) =>
        datas.push({
          id: "b" + values.id,
          emp_no: values.emp_no,
          emp_name: values.last_name + ", " + values.first_name,
          first_name: values.first_name,
          last_name: values.last_name,
          ed_name: values.ed_code + " - " + values.name,
          pay_period: values.pay_period_name,
          amount: values.amount,
          period_from: runData.start_date,
          period_to: runData.end_date,
          created_at: values.created_at,
        })
      );

      res.data.transacEarning.map((values) =>
        datas.push({
          id: "t" + values.id,
          emp_no: values.emp_no,
          emp_name: values.last_name + ", " + values.first_name,
          first_name: values.first_name,
          last_name: values.last_name,
          ed_name: "Other Earning: " + values.ed_code + "-" + values.name,
          pay_period: values.pay_period_name,
          amount: values.amount,
          period_from: values.period_from,
          period_to: values.period_to,
          created_at: values.created_on,
        })
      );

      setRunData(res.data.regularPayroll);
      setData(datas);
      setFilteredData(datas);
    };
    fetchData();
  }, []);

  const handleSearch = (e) => {
    const filterer = data.filter(function (data) {
      for (var key in data) {
        if (
          !(
            data["first_name"]
              .toLowerCase()
              .includes(e.target.value.toLowerCase()) ||
            data["last_name"]
              .toLowerCase()
              .includes(e.target.value.toLowerCase()) ||
            data["ed_name"]
              .toLowerCase()
              .includes(e.target.value.toLowerCase()) ||
            data["emp_no"]
              .toLowerCase()
              .includes(e.target.value.toLowerCase()) ||
            data["pay_period"]
              .toLowerCase()
              .includes(e.target.value.toLowerCase())
          )
        ) {
          return false;
        }
        return true;
      }
    });
    setFilteredData(filterer);
    setCurrentPage(1);
  };

  const handleSort = (columnChanged, currentSort) => {
    let setSort;
    let sorter = [];
    const getColumnValue = (column, value) => {
      switch (column) {
        default:
          return value;
      }
    };

    if (currentSort == "ASC") setSort = "DESC";
    else setSort = "ASC";

    if (columnChanged == "name") {
      sorter = [
        ["last_name", nameSort],
        ["first_name", "ASC"],
        ["ed_name", "ASC"],
        ["pay_period", "ASC"],
      ];

      setNameSort(setSort);
    } else if (columnChanged == "ed") {
      sorter = [
        ["ed_name", edSort],
        ["last_name", "ASC"],
        ["first_name", "ASC"],
        ["pay_period", "ASC"],
      ];

      setEdSort(setSort);
    } else if (columnChanged == "payPeriod") {
      sorter = [
        ["pay_period", payPeriodSort],
        ["last_name", "ASC"],
        ["first_name", "ASC"],
        ["ed_name", "ASC"],
      ];

      setPayPeriodSort(setSort);
    } else if (columnChanged == "empNo") {
      sorter = [
        ["emp_no", empNoSort],
        ["last_name", "ASC"],
        ["first_name", "ASC"],
        ["ed_name", "ASC"],
        ["pay_period", "ASC"],
      ];

      setEmpNoSort(setSort);
    }

    const sorted = multiColumnSort(filteredData, sorter, getColumnValue);

    setFilteredData(sorted);
  };

  const handleBack = () => {
    props.history.goBack();
  };

  return (
    <div className="hk-wrapper hk-vertical-nav">
      <div class="pg-hk-wrapper">
        <Header />
        <div className="mt-20 hk-pg-wrapper">
          {ability.permission > 0 ? (
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-10 col-md-10 col-sm-12">
                  <h3 className="pb-10">Other Earnings Breakdown</h3>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-12">
                  <button
                    className="btn btn-sm btn-danger float-right "
                    onClick={handleBack}
                  >
                    Back to Previous Page
                  </button>
                </div>
              </div>
              <div>
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="card">
                      <div className="card-header card-header-action">
                        <h5>Run Details</h5>
                        <div className="d-flex align-items-center card-action-wrap-tools"></div>
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <div className="row">
                              <div className="col-xl-6 col-sm-12 mb-10">
                                <strong>Pay Period ID</strong>
                              </div>
                              <div className="col-xl-6 col-sm-12 mb-10">
                                :&nbsp;&nbsp;&nbsp;{runData.code}
                              </div>
                              <div className="col-xl-6 col-sm-12 mb-10">
                                <strong>Period</strong>
                              </div>
                              <div className="col-xl-6 col-sm-12 mb-10">
                                :&nbsp;&nbsp;&nbsp;
                                {dateFormatter(runData.start_date) +
                                  " to " +
                                  dateFormatter(runData.end_date)}
                              </div>
                              <div className="col-xl-6 col-sm-12 mb-10">
                                <strong>Created By</strong>
                              </div>
                              <div className="col-xl-6 col-sm-12 mb-10">
                                :&nbsp;&nbsp;&nbsp;
                                {runData.created_by}
                              </div>
                              <div className="col-xl-6 col-sm-12 mb-10">
                                <strong>Created On</strong>
                              </div>
                              <div className="col-xl-6 col-sm-12 mb-10">
                                :&nbsp;&nbsp;&nbsp;
                                {dateFormatter(
                                  runData.created_at,
                                  "MMM dd, yyyy hh:mm bb"
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                            <div className="row">
                              <div className="col-xl-6 col-sm-12 mb-10">
                                <strong>Status</strong>
                              </div>
                              <div className="col-xl-6 col-sm-12 mb-10">
                                :&nbsp;&nbsp;&nbsp;
                                {runData.status == 2 ? (
                                  <strong className="text-success">
                                    {statuses[runData.status]}
                                  </strong>
                                ) : runData.status == 1 ? (
                                  <strong className="text-info">
                                    {statuses[runData.status]}
                                  </strong>
                                ) : (
                                  <strong className="text-warning">
                                    {statuses[runData.status]}
                                  </strong>
                                )}
                              </div>
                              <div className="col-xl-6 col-sm-12 mb-10">
                                <strong>Draft Run Date</strong>
                              </div>
                              <div className="col-xl-6 col-sm-12 mb-10">
                                :&nbsp;&nbsp;&nbsp;
                                {runData.draft_date ? (
                                  <strong className="text-info">
                                    {dateFormatter(
                                      runData.draft_date,
                                      "MMM dd, yyyy hh:mm bb"
                                    )}
                                  </strong>
                                ) : (
                                  <strong className="text-danger">
                                    PENDING
                                  </strong>
                                )}
                              </div>
                              <div className="col-xl-6 col-sm-12 mb-10">
                                <strong>Review Date</strong>
                              </div>
                              <div className="col-xl-6 col-sm-12 mb-10">
                                :&nbsp;&nbsp;&nbsp;
                                {runData.review_date ? (
                                  <strong className="text-info">
                                    {dateFormatter(
                                      runData.review_date,
                                      "MMM dd, yyyy hh:mm bb"
                                    )}
                                  </strong>
                                ) : (
                                  <strong className="text-danger">
                                    PENDING
                                  </strong>
                                )}
                              </div>
                              <div className="col-xl-6 col-sm-12 mb-10">
                                <strong>Post Date</strong>
                              </div>
                              <div className="col-xl-6 col-sm-12 mb-10">
                                :&nbsp;&nbsp;&nbsp;
                                {runData.post_date  != 'PENDING'? (
                                  <strong className="text-info">
                                    {dateFormatter(
                                      runData.post_date,
                                      "MMM dd, yyyy hh:mm bb"
                                    )}
                                  </strong>
                                ) : (
                                  <strong className="text-danger">
                                    PENDING
                                  </strong>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="table-wrap">
                      <div className="row mb-10">
                        <div className="col-md-6"></div>
                        <div className="col-md-6">
                          <input
                            type="search"
                            className="form-control form-control-sm col-md-6 col-sm-6 float-right"
                            placeholder="Search"
                            onChange={handleSearch}
                          />
                        </div>
                      </div>
                      <div className="table-responsive mb-10">
                        <table className="table table-sm table-bordered table-striped mb-0">
                          <thead className="thead-secondary">
                            <tr className="text-center">
                              <th
                                onClick={() => handleSort("empNo", empNoSort)}
                                style={{ cursor: "pointer" }}
                              >
                                Employee # &nbsp;
                                <i
                                  className={
                                    nameSort == "ASC"
                                      ? "fa fa-arrow-down"
                                      : "fa fa-arrow-up"
                                  }
                                ></i>
                              </th>
                              <th
                                onClick={() => handleSort("name", nameSort)}
                                style={{ cursor: "pointer" }}
                              >
                                Employee Name &nbsp;
                                <i
                                  className={
                                    nameSort == "ASC"
                                      ? "fa fa-arrow-down"
                                      : "fa fa-arrow-up"
                                  }
                                ></i>
                              </th>
                              <th
                                onClick={() => handleSort("ed", edSort)}
                                style={{ cursor: "pointer" }}
                              >
                                Earning Transaction &nbsp;
                                <i
                                  className={
                                    edSort == "ASC"
                                      ? "fa fa-arrow-down"
                                      : "fa fa-arrow-up"
                                  }
                                ></i>
                              </th>
                              <th
                                onClick={() =>
                                  handleSort("payPeriod", payPeriodSort)
                                }
                                style={{ cursor: "pointer" }}
                              >
                                Pay Period Release &nbsp;
                                <i
                                  className={
                                    payPeriodSort == "ASC"
                                      ? "fa fa-arrow-down"
                                      : "fa fa-arrow-up"
                                  }
                                ></i>
                              </th>
                              <th>Amount</th>
                              <th>Created On</th>
                            </tr>
                          </thead>
                          <tbody>
                            {filteredData.length > 0 ? (
                              filteredData.map((cutoff) => (
                                <tr key={cutoff.id}>
                                  <td>{cutoff.emp_no}</td>
                                  <td>{cutoff.emp_name}</td>
                                  <td>{cutoff.ed_name}</td>
                                  <td>{cutoff.pay_period}</td>
                                  <td className="text-right">
                                    {cutoff.amount
                                      .toLocaleString()
                                      .replace(
                                        /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                        ","
                                      )}
                                  </td>
                                  <td>
                                    {dateFormatter(
                                      cutoff.created_at,
                                      "MMM dd, yyyy hh:mm bb"
                                    )}
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colspan="5" className="text-center">
                                  No Data to show
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <h1 className="m-0 text-center">
              The Credentials Supplied Are Not Sufficient To Access This Module
              <br />
              Please Contact Administrator
            </h1>
          )}
        </div>
      </div>
    </div>
  );
}
