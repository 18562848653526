import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { CloudUpload } from "@material-ui/icons";
import axios from "axios";
import { FileUploader } from "react-drag-drop-files";
import Cookies from "universal-cookie";
const cookies = new Cookies();
const d = new Date();

export default class UploadHoliday extends Component {
  constructor(props) {
    super();
    this.state = {
      showAddModal: false,
      showCheckModal: false,
      showSuccessModal: false,
      hasError: false,
      disableAdd: false,
      sched_year: d.getFullYear(),
      errs: [],
      checkData: [],
      err_no: 0,
      rec_no: 0,
    };
    this.handleshowAddModal = this.handleshowAddModal.bind(this);
  }

  handleshowAddModal = (e) => {
    this.setState({ showAddModal: !this.state.showAddModal });
  };

  handleshowCheckModal = (e) => {
    this.setState({ showCheckModal: !this.state.showCheckModal });
  };

  handleCheck = (e) => {
    e.preventDefault();
    this.setState({ disableAdd: true });
    let formData = new FormData();
    formData.append("uploadFile", this.state.actual_file);
    formData.append("file_name", this.state.file_name);
    formData.append("sched_year", this.state.sched_year);
    formData.append("updated_by", cookies.get("email"));
    formData.append("created_by", cookies.get("email"));

    axios
      .post("api/upload_holiday_check/" + this.props.companyId, formData)
      .then((res) => {
        this.setState({
          disableAdd: false,
          showAddModal: false,
          showCheckModal: true,
          checkData: res.data.rowData,
          rec_no: res.data.rows,
          err_no: res.data.errs.length,
        });
        console.log(res.data);
      })
      .catch((err) => {
        this.setState({ disableAdd: false, hasError: true });
      });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ disableAdd: true });
    let formData = new FormData();
    formData.append("uploadFile", this.state.actual_file);
    formData.append("file_name", this.state.file_name);
    formData.append("sched_year", this.state.sched_year);
    formData.append("updated_by", cookies.get("email"));
    formData.append("created_by", cookies.get("email"));

    axios
      .post("api/upload_holiday/" + this.props.companyId, formData)
      .then((res) => {
        this.setState({
          disableAdd: false,
          showAddModal: false,
          showCheckModal: false,
          showSuccessModal: true,
          hasError: false,
        });
        this.props.parentCallback(true);
      })
      .catch((err) => {
        this.setState({ disableAdd: false, hasError: true });
      });
  };

  handleChange = (file) => {
    this.setState({ actual_file: file, file_name: file.name });
  };

  ErrCancel = (e) => {
    e.preventDefault();
    this.setState({
      disableAdd: false,
      showAddModal: false,
      showCheckModal: false,
      showSuccessModal: false,
      hasError: false,
      actual_file: null,
      file_name: null,
    });
    this.props.parentCallback(true);
  };

  render() {
    return (
      <>
        <button
          className="btn btn-outline-primary btn-sm ml-5 float-right"
          title="Upload Holiday"
          onClick={this.handleshowAddModal}
        >
          <CloudUpload /> Upload Holiday
        </button>
        <Modal
          size="md"
          show={this.state.showAddModal}
          backdrop="static"
          onHide={() =>
            this.setState({ showAddModal: !this.state.showAddModal })
          }
        >
          <Modal.Header closeButton>
            <h4>Upload Holiday</h4>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <div className="alert alert-warning">
                  <strong>Warning:</strong> This action will delete existing
                  holidays within the entered year value to avoid duplication.
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <div
                  className={
                    this.state.hasError
                      ? "alert alert-danger"
                      : "alert alert-danger d-none"
                  }
                  role="alert"
                >
                  Holiday upload failed
                </div>
              </div>
            </div>

            <form onSubmit={this.handleCheck} id="contact-form">
              <div className="row">
                <div className="form-group col-md-12 col-sm-12">
                  <label>Holiday Year</label>
                  <input
                    type="number"
                    min={d.getFullYear()}
                    max="9999"
                    step="1"
                    defaultValue={d.getFullYear()}
                    className="form-control"
                    required
                    onChange={(e) =>
                      this.setState({ sched_year: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="row">
                <div className="form-group fallback col-md-12 col-sm-12">
                  <FileUploader
                    multiple={false}
                    handleChange={this.handleChange}
                    types={["XLSX"]}
                    classes="dropzone drop_area"
                  />
                  {this.state.actual_file ? (
                    <div
                      className="alert alert-success font-weight-bold"
                      role="alert"
                    >
                      Uploaded File name: {this.state.file_name}
                    </div>
                  ) : (
                    <div className="alert alert-danger" role="alert">
                      No Files Uploaded
                    </div>
                  )}
                </div>
              </div>
              <div className="float-right">
                <button
                  type="submit"
                  className="btn btn-primary btn-sm mr-2"
                  disabled={this.state.disableAdd}
                >
                  Proceed
                </button>
                <button
                  onClick={this.handleshowAddModal}
                  className="btn btn-danger btn-sm"
                  type="button"
                >
                  Cancel
                </button>
              </div>
            </form>
          </Modal.Body>
        </Modal>

        <Modal
          size="lg"
          show={this.state.showCheckModal}
          backdrop="static"
          onHide={() =>
            this.setState({ showCheckModal: !this.state.showCheckModal })
          }
        >
          <Modal.Header closeButton>
            <h4>Upload Holiday: Checking</h4>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <div className="alert alert-info">
                  <strong>File:</strong> {this.state.file_name}
                  <br />
                  <strong>Total Records:</strong> {this.state.rec_no}
                  <br />
                  <strong>Total Errors:</strong> {this.state.err_no}
                  <br />
                </div>
              </div>
            </div>
            <div class="table-responsive mb-10">
              <table class="table table-xs table-bordered mb-0">
                <thead className="thead-secondary">
                  <tr className="text-center">
                    <th>#</th>
                    <th>HOLIDAY TYPE</th>
                    <th>HOLIDAY NAME</th>
                    <th>HOLIDAY DATE</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.checkData.map((row) => (
                    <tr key={row.number}>
                      <td>{row.number}</td>
                      <td className={row.err_type == 1 ? "bg-danger" : ""}>
                        {row.type}
                      </td>
                      <td className={row.err_name == 1 ? "bg-danger" : ""}>
                        {row.name}
                      </td>
                      <td className={row.err_date == 1 ? "bg-danger" : ""}>
                        {row.date}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            {this.state.err_no > 0 ? (
              <div className="row">
                <div className="col-md-12 col-sm-12">
                  <div className="alert alert-danger">
                  {this.state.err_no} Invalid record(s) found, please correct the data and re-upload
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </Modal.Body>
          <Modal.Footer>
            {this.state.err_no > 0 ? (
              <div className="float-right">
                <button
                  onClick={this.ErrCancel}
                  className="btn btn-danger btn-sm"
                  type="button"
                >
                  Cancel
                </button>
              </div>
            ) : (
              <div className="float-right">
                <button
                  onClick={this.handleSubmit}
                  className="btn btn-primary btn-sm mr-2"
                  type="button"
                  disabled={this.state.disableAdd}
                >
                  Save
                </button>
                <button
                  onClick={this.handleshowCheckModal}
                  className="btn btn-danger btn-sm"
                  type="button"
                >
                  Cancel
                </button>
              </div>
            )}
          </Modal.Footer>
        </Modal>

        <Modal
          size="lg"
          show={this.state.showSuccessModal}
          backdrop="static"
          onHide={() =>
            this.setState({ showSuccessModal: !this.state.showSuccessModal })
          }
        >
          <Modal.Header closeButton>
            <h4>Upload Holiday: Successful</h4>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <div className="alert alert-success">
                  <strong>Import Result</strong><br/>
                  <strong>Total Records:</strong> {this.state.rec_no}
                  <br />
                  <strong>Total Errors:</strong> {this.state.err_no}
                  <br />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="float-right">
              <button
                onClick={() =>
                  this.setState({
                    showSuccessModal: !this.state.showSuccessModal,
                  })
                }
                className="btn btn-danger btn-sm"
                type="button"
              >
                Close
              </button>
            </div>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
