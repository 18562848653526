import React, { useState, useEffect } from "react";
import Header from "../../components/Header";
import axios from "axios";
import { dateFormatter } from "../../components/DtFormatter";
import { Link } from "react-router-dom";
import { FindInPage, Add } from "@material-ui/icons";
import Cookies from "universal-cookie";
import multiColumnSort from "multi-column-sort";
import ExportYTD from "./ExportYTD";
import ExportYtdPr from "./ExportYtdPr";
const cookies = new Cookies();
const access = ["No Access", "View Only", "Add", "Add / Edit", "Full Access"];

export default function YtdPerPeriod(props) {
  const permissions = cookies.get("permissions")["report"];
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage, setRecordsPerPage] = useState(10);
  const [filteredData, setFilteredData] = useState([]);
  const [nameSort, setNameSort] = useState("ASC");
  const [empNoSort, setEmpNoSort] = useState("ASC");

  const [isChecked, setIsChecked] = useState([]);
  const [filterYear, setFilterYear] = useState("");
  const [filterPrIdFrom, setFilterPrIdFrom] = useState("");
  const [filterPrIdTo, setFilterPrIdTo] = useState("");
  const [checkAll, setCheckAll] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      let apiLink = "api/ytd_ps/" + cookies.get("transCompany");
      const res = await axios.get(apiLink);

      setData(res.data);
      setFilteredData(res.data);
      let getDefaultChecks = [];
      res.data.map((pr_data) =>
        getDefaultChecks.push({ id: pr_data.paysched_id, checked: false })
      );
      setIsChecked(getDefaultChecks);
    };
    fetchData();
  }, []);

  const handleRecordPerPage = (e) => {
    console.log(e.target.value);
    setRecordsPerPage(parseInt(e.target.value));
    setCurrentPage(1);
  };

  const handleBack = () => {
    props.history.goBack();
  };

  const handleSearch = (e) => {
    let from = filterPrIdFrom;
    let to = filterPrIdTo === "" ? filterPrIdFrom : filterPrIdTo;
    let filter1 = [],
      filter2 = [];
    filter1 = data.filter(function (data) {
      for (var key in data) {
        if (
          !(data["record_year"] + "")
            .toLowerCase()
            .includes(filterYear.toLowerCase())
        ) {
          return false;
        }
        return true;
      }
    });

    filter2 = filter1.filter(function (data) {
      for (var key in data) {
        if (from !== "") {
          if (!(data["ps_code"] >= from)) {
            return false;
          }
        }

        if (to !== "") {
          if (!(data["ps_code"] <= to)) {
            return false;
          }
        }

        return true;
      }
    });

    setFilteredData(filter2);
    setCurrentPage(1);
  };

  const handleSort = (columnChanged, currentSort) => {
    let setSort;
    let sorter = [];
    const getColumnValue = (column, value) => {
      switch (column) {
        default:
          return value;
      }
    };

    if (currentSort == "ASC") setSort = "DESC";
    else setSort = "ASC";

    if (columnChanged == "name") {
      sorter = [
        ["ps_code", nameSort],
        // ["first_name", "ASC"],
        // ["emp_no", "ASC"],
      ];

      setNameSort(setSort);
    }
    // else if (columnChanged == "empNo") {
    //   sorter = [
    //     ["emp_no", empNoSort],
    //     ["last_name", "ASC"],
    //     ["first_name", "ASC"],
    //   ];

    //   setEmpNoSort(setSort);
    // }

    const sorted = multiColumnSort(filteredData, sorter, getColumnValue);

    setFilteredData(sorted);
  };

  const checkAllRows = (e) => {
    let getChecks = [];
    isChecked.map((checks) => {
      getChecks.push({ id: checks.id, checked: !checkAll });
    });
    setIsChecked(getChecks);
    setCheckAll(!checkAll);
  };

  const handleCheck = (e) => {
    let getChecks = [],
      totalChecks = 0;
    isChecked.map((checks) => {
      if (checks.id == e.target.value) {
        getChecks.push({ id: checks.id, checked: !checks.checked });
      } else getChecks.push({ id: checks.id, checked: checks.checked });

      if (getChecks.checked) {
        totalChecks++;
      }
    });

    setIsChecked(getChecks);

    if (totalChecks == filteredData.length) {
      setCheckAll(true);
    } else {
      setCheckAll(false);
    }
  };

  const handleFilterChange = (e, filterField) => {
    if (filterField == "prFrom") {
      setFilterPrIdFrom(e.target.value);
    } else if (filterField == "prTo") {
      setFilterPrIdTo(e.target.value);
    } else if (filterField == "year") {
      setFilterYear(e.target.value);
    } else if (filterField == "clear") {
      setFilterPrIdFrom("");
      setFilterPrIdTo("");
      setCheckAll(false);
      let getChecks = [];
      isChecked.map((checks) => {
        getChecks.push({ id: checks.id, checked: false });
      });
      setIsChecked(getChecks);
      setFilteredData(data);
    }
  };

  const startIndex = currentPage * recordsPerPage - recordsPerPage;
  const endIndex = startIndex + recordsPerPage;
  const currentData = filteredData.slice(startIndex, endIndex);

  const pages = [];
  for (let i = 1; i <= Math.ceil(filteredData.length / recordsPerPage); i++) {
    pages.push(i);
  }

  return (
    <div className="hk-wrapper hk-vertical-nav">
      <div class="pg-hk-wrapper">
        <Header />
        <div className="mt-20 hk-pg-wrapper">
          {permissions > 0 ? (
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-8 col-md-8 col-sm-12">
                  <h3 className="pb-10">
                    {props.match.params.year} Accounting YTD Ledger
                  </h3>
                </div>
                <div className="col-lg-4 col-md-4 col-sm-12">
                  {/* <button
                    className="ml-10 btn btn-sm btn-danger float-right"
                    onClick={handleBack}
                  >
                    Back to Previous Page
                  </button> */}
                  {/* <ExportYTD record_year={props.match.params.year} /> */}
                </div>
              </div>

              <div>
                <div className="row">
                  <div className="col-sm-12">
                    <div className="table-wrap">
                      <div className="row mb-10">
                        <div className="col-sm-12 mb-20">
                          <div className="accordion" id="accordion_1">
                            <div className="card">
                              <div className="card-header d-flex justify-content-between">
                                <a
                                  role="button"
                                  data-toggle="collapse"
                                  href="#collapse_1"
                                  aria-expanded="false"
                                  className="collapse show"
                                >
                                  Filters
                                </a>
                              </div>
                              <div
                                id="collapse_1"
                                className="collapse show"
                                data-parent="#accordion_1"
                                role="tabpanel"
                              >
                                <div className="card-body pa-15">
                                  <div className="row">
                                    <div className="form-group col-md-6 col-sm-12">
                                      <label>Calendar Year</label>
                                      <input
                                        type="number"
                                        className="form-control form-control-sm"
                                        value={filterYear}
                                        onChange={(e) =>
                                          handleFilterChange(e, "year")
                                        }
                                      />
                                    </div>
                                    <div className="form-group col-md-6 col-sm-12"></div>
                                    <div className="form-group col-md-6 col-sm-12">
                                      <label>From Pay Period ID</label>
                                      <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        value={filterPrIdFrom}
                                        onChange={(e) =>
                                          handleFilterChange(e, "prFrom")
                                        }
                                      />
                                    </div>
                                    <div className="form-group col-md-6 col-sm-12">
                                      <label>To Pay Period ID</label>
                                      <input
                                        type="text"
                                        className="form-control form-control-sm"
                                        value={filterPrIdTo}
                                        onChange={(e) =>
                                          handleFilterChange(e, "prTo")
                                        }
                                      />
                                    </div>

                                    <div className="form-group col-md-6 col-sm-12">
                                      <button
                                        className="btn btn-sm btn-primary mr-10"
                                        onClick={handleSearch}
                                      >
                                        Apply Filter
                                      </button>
                                      <button
                                        className="btn btn-sm btn-danger"
                                        onClick={(e) =>
                                          handleFilterChange(e, "clear")
                                        }
                                      >
                                        Clear Filter
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <label>
                            <select
                              className="custom-select custom-select-sm form-control form-control-sm"
                              onChange={handleRecordPerPage}
                            >
                              <option value="10">10</option>
                              <option value="25">25</option>
                              <option value="50">50</option>
                              <option value="100">100</option>
                            </select>
                          </label>{" "}
                          items
                        </div>
                        <div className="col-md-6">
                          {/* <input
                            type="search"
                            className="form-control form-control-sm col-md-6 col-sm-6 float-right"
                            placeholder="Search"
                            onChange={handleSearch}
                          /> */}
                          <ExportYtdPr
                            dataCheck={isChecked}
                            data={filteredData}
                          />
                        </div>
                      </div>
                      <div className="table-responsive mb-10">
                        <table className="table table-sm table-bordered table-striped mb-0">
                          <thead className="thead-secondary">
                            <tr className="text-center">
                              <th>
                                <input
                                  type="checkbox"
                                  aria-label="Checkbox for following text input"
                                  onClick={checkAllRows}
                                  checked={checkAll}
                                />
                              </th>
                              <th
                                className="text-nowrap"
                                style={{ cursor: "pointer" }}
                                onClick={() => handleSort("name", nameSort)}
                              >
                                Pay Period ID &nbsp;
                                <i
                                  className={
                                    nameSort == "ASC"
                                      ? "fa fa-arrow-down"
                                      : "fa fa-arrow-up"
                                  }
                                ></i>
                              </th>
                              <th>Date Posted</th>
                              <th>Basic</th>
                              <th className="text-nowrap">De Minimis</th>
                              <th className="text-nowrap">
                                O.Earnings
                                <br />
                                (Tax Exempt)
                              </th>
                              <th>O.Earnings</th>
                              <th className="text-nowrap">
                                Non-Taxed
                                <br />
                                Earnings
                              </th>
                              <th>13th Month Pay</th>
                              <th>Gross Pay</th>

                              <th>SSS Premium</th>
                              <th>SSS Provident</th>
                              <th>PhilHealth</th>
                              <th>PAGIBIG</th>
                              <th>O.Deductions</th>
                              <th>WH Tax</th>

                              <th>Net Pay</th>
                              {/* <th>Action</th> */}
                            </tr>
                          </thead>
                          <tbody>
                            {filteredData.length > 0 ? (
                              currentData.map((cutoff) => (
                                <tr key={cutoff.id}>
                                  <td className="text-center">
                                    <input
                                      type="checkbox"
                                      name="payroll_data"
                                      checked={
                                        isChecked.length > 0
                                          ? isChecked.find(
                                              (x) => x.id == cutoff.paysched_id
                                            ).checked
                                          : false
                                      }
                                      onClick={handleCheck}
                                      value={cutoff.paysched_id}
                                    />
                                  </td>
                                  <td className="text-nowrap text-center">
                                    {cutoff.ps_code}
                                  </td>
                                  <td className="text-nowrap text-center">
                                    {dateFormatter(cutoff.post_date, "MMM dd, yyyy hh:mm aa")}
                                  </td>
                                  <td className="text-right">
                                    {cutoff.basic_pay.replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                  </td>
                                  <td className="text-right">
                                    {cutoff.deminimis.replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                  </td>
                                  <td className="text-right">
                                    {cutoff.other_earning_nt.replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                  </td>
                                  <td className="text-right">
                                    {cutoff.other_earning.replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                  </td>
                                  <td className="text-right">
                                    {cutoff.non_tax_earning.replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                  </td>
                                  <td className="text-right">
                                    {cutoff.t_month_pay_total.replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                  </td>
                                  <td className="text-right">
                                    {cutoff.gross_pay.replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                  </td>
                                  <td className="text-right">
                                    {cutoff.sss_premium.replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                  </td>
                                  <td className="text-right">
                                    {cutoff.sss_provident.replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                  </td>
                                  <td className="text-right">
                                    {cutoff.philhealth.replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                  </td>
                                  <td className="text-right">
                                    {cutoff.pagibig.replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                  </td>
                                  <td className="text-right">
                                    {cutoff.other_deduction.replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                  </td>
                                  <td className="text-right">
                                    {cutoff.wh_tax.replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                  </td>

                                  <td className="text-right">
                                    {" "}
                                    {cutoff.net_pay.replace(
                                      /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g,
                                      ","
                                    )}
                                  </td>
                                  {/* <td>
                                    <div className="btn-group">
                                      <Link
                                        to={{
                                          pathname: `/ytd_pr/${cutoff.record_year}/${cutoff.paysched_id}`,
                                          query: `/ytd_pr`,
                                        }}
                                        className="btn btn-sm btn-outline-primary"
                                        title={"View Details"}
                                      >
                                        <FindInPage />
                                      </Link>
                                    </div>
                                  </td> */}
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td colSpan="16" className="text-center">
                                  No Data to show
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="drew-pagination">
                      {filteredData.length > 0
                        ? "Showing " +
                          (startIndex + 1) +
                          " to " +
                          (endIndex > filteredData.length
                            ? filteredData.length
                            : endIndex) +
                          " of " +
                          filteredData.length
                        : ""}
                      <ul class="pagination ml-2">
                        {pages.map((number) => (
                          <li
                            key={number}
                            id={number}
                            className={
                              currentPage == number
                                ? "paginate_button page-item active"
                                : "paginate_button page-item"
                            }
                            onClick={() => setCurrentPage(number)}
                          >
                            <a class="page-link">{number}</a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <>
              <h1 className="m-0 text-center">
                Your credentials is not sufficient to access this:
                <br />
              </h1>
              <br />
              <h2 className="text-center">
                Module : Reports
                <br />
                Access Type : {access[permissions]}
                <br />
                Please contact your Payroll Administrator.
                <br />
                <a className="btn btn-primary btn-sm" href="/#/app">
                  Back to Dashboard
                </a>
              </h2>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
